/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Table from "@components/Table";
import ActionsColumn from "@components/ActionsColumn";
import Button from "@components/Button";
import Modal from "@components/Modal";
import MaterialIcon, { TIcon } from "@components/MaterialIcon";

import { IRow } from "@models/Table.type";
import {
  useCloneForm,
  useDeleteform,
  useGetForm,
  useGetForms,
} from "@redux/forms/api";
import { formatDate } from "@helpers/formatDate";
import StepStatus from "@components/StepStatus";
import { Spinner } from "react-activity";
import emptyState from "../../assets/no-forms.svg";
import warningImg from "../../assets/warning.svg";

import useWindowSize from "@hooks/useWindowSize";
import { Card } from "@views/Forms/shared/Create/types";
import useAppDispatch from "@hooks/useAppDispatch";
import { createToast } from "@helpers/createToast";
import { threeDotsText } from "@helpers/threeDotsText";
import AdminHttpProvider from "@api/AdminHttpProvider";
import PreviewModal from "@components/modals/PreviewModal";
import useAppSelector from "@hooks/useAppSelector";

const IN_USE_MODAL_EDIT_TITLE = "You can’t edit this form";
const IN_USE_MODAL_EDIT_TEXT =
  "This form is being used in a process' step and can't be edited. If you still desire to edit this form, please clone it first and then edit the cloning.";

const IN_USE_MODAL_DELETE_TITLE = "You can’t delete this form";
const IN_USE_MODAL_DELETE_TEXT =
  "This form is being used in a process' step and can't be deleted. If you still desire to remove this form, please make sure to remove it from the step first.";

const baseSrcUrl =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL : "";

const adminApi = new AdminHttpProvider();

const AllFormSection: React.FC = () => {
  const user = useAppSelector((state) => state.user);

  const navigate = useNavigate();
  const { responsive } = useWindowSize();
  const dispatch = useAppDispatch();

  const { data, isFetching, refetch } = useGetForms({});

  const [deleteForm] = useDeleteform();
  const [cloneForm] = useCloneForm();

  const [clone, setClone] = useState<{ id: number; title: string }>();
  const [formToDelete, setFormToDelete] = useState<{
    id: number;
    title: string;
  }>();
  const [previewId, setPreviewId] = useState<string>();
  const [inUseModal, setInUseModal] = useState<{
    title: string;
    text: string;
  } | null>(null);

  const { form, setForm, isLoading }: any = useGetForm(previewId);

  const actionList = [
    {
      id: "preview",
      icon: "visibility" as TIcon,
      text: "Preview",
      onClick: ({ id }: any) => {
        setPreviewId(id.toString());
      },
    },
    {
      id: "edit",
      icon: "edit" as TIcon,
      text: "Edit",
      onClick: ({ id }: any) => {
        adminApi.request("get", `api/forms/${id}/exists`).then(({ data }) => {
          const { exists } = data.data;
          if (exists) {
            setInUseModal({
              title: IN_USE_MODAL_EDIT_TITLE,
              text: IN_USE_MODAL_EDIT_TEXT,
            });
          } else {
            navigate(`/admin/all-forms/edit-form?id=${id}`);
          }
        });
      },
      hidden: !user.data?.permissions.Form.includes("write"),
    },
    {
      id: "clone",
      icon: "copy_all" as TIcon,
      text: "Clone",
      onClick: ({ id, title }: any) => {
        setClone({ id, title });
      },
      hidden: !user.data?.permissions.Form.includes("write"),
    },
    {
      id: "delete",
      icon: "delete" as TIcon,
      text: "Delete",
      onClick: async ({ id, title }: any) => {
        adminApi.request("get", `api/forms/${id}/exists`).then(({ data }) => {
          const { exists } = data.data;
          if (exists) {
            setInUseModal({
              title: IN_USE_MODAL_DELETE_TITLE,
              text: IN_USE_MODAL_DELETE_TEXT,
            });
          } else {
            setFormToDelete({ id, title });
          }
        });
      },
      hidden: !user.data?.permissions.Form.includes("write"),
    },
  ];

  const columns = [
    {
      header: "Name",
      id: "name",
      field: "title",
      body: (data: any) => (
        <div className="d-flex align-items-center gap-2">
          <MaterialIcon icon="assignment" size={20} />
          <span className="o-ft-sm-600 text-truncate">{data.title}</span>
        </div>
      ),
    },
    {
      header: "Date",
      id: "created_at",
      field: "created_at",
      parsedString: ({ created_at }: IRow) => formatDate(created_at),
    },
    {
      header: "Actions",
      id: "3",
      field: "",
      body: (file: IRow) => (
        <ActionsColumn
          actionList={actionList}
          file={file}
          dotsShowLength={4}
          context="forms"
        />
      ),
      hiddenMobileTitle: true,
      fixedWidth: { xs: "40px", lg: "212px" },
    },
  ];

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div>
      {user.data?.permissions.Form.includes("write") && (
        <Button
          value="Add Form"
          onClick={() => navigate("/admin/all-forms/add-form")}
        />
      )}
      <div className="my-3">
        {isFetching ? (
          <div className="mt-5 d-flex align-items-center justify-content-center h-100">
            <Spinner color="o-cl-brand-secondary" size={20} />
          </div>
        ) : data?.length ? (
          <Table columns={columns} data={data || []} />
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <img
              className="mt-4"
              src={`${baseSrcUrl}${emptyState}`}
              alt="empty state"
              width={responsive.md ? 350 : 250}
            />
            <h3
              className={`o-cl-dark-grey mt-4 mb-3 ${responsive.md ? "o-ft-2xl-600" : "o-ft-xl-600"}`}
            >
              There are no forms here!
            </h3>
            <p
              className={`v-my-stores__empty-state-description o-cl-dark-grey text-center ${responsive.md ? "o-ft-lg-400" : "o-ft-sm-400"}`}
            >
              It seems that this space doesn&apos;t have any form yet,
              <br />
              please click on &quot;Add Form&quot; to create one.
            </p>
          </div>
        )}
      </div>

      <Modal
        onClose={() => setClone(undefined)}
        withCloseIcon={false}
        onConfirm={async () => {
          try {
            await cloneForm({ id: clone?.id });
            setClone(undefined);
            const text = `Form Cloned <br/>"${threeDotsText(clone?.title ?? "", 30)}" form has been cloned successfully.`;
            createToast(text, "success", dispatch);
          } catch (error) {
            const text =
              "Something went wrong while processing your request. Please try again.";
            createToast(text, "danger", dispatch);
          }
        }}
        isOpen={!!clone}
        title="Are you sure you want to clone this form?"
      />

      <Modal
        onClose={() => setFormToDelete(undefined)}
        withCloseIcon={false}
        onConfirm={async () => {
          try {
            await deleteForm({ id: formToDelete?.id });
            setFormToDelete(undefined);
            refetch();
            const text = `<strong>Form Deleted<br/>"${threeDotsText(formToDelete?.title ?? "", 30)}" form</strong> has been deleted successfully.`;
            createToast(text, "success", dispatch);
          } catch (error) {
            const text =
              "Something went wrong while processing your request. Please try again.";
            createToast(text, "danger", dispatch);
          }
        }}
        isOpen={!!formToDelete}
        title="Are you sure you want to delete this form?"
      >
        <span className="o-ft-sm-400">
          Remember that you won&apos;t be able to recover it again
        </span>
      </Modal>

      {inUseModal ? (
        <>
          <div className="c-modal" style={{ width: responsive.md ? 400 : 300 }}>
            <img
              src={`${baseSrcUrl}${warningImg}`}
              alt="warning"
              className="mb-4"
            />
            <h3 className="o-ft-2xl-500 text-center">{inUseModal.title}</h3>
            <p className="o-ft-md-400 text-center">{inUseModal.text}</p>
            <div className="d-flex justify-content-center">
              <Button
                value="Ok"
                onClick={() => setInUseModal(null)}
                size="medium-big"
                className="w-50 mt-2"
              />
            </div>
          </div>
          <div
            className="c-modal__overlay d-flex justify-content-center"
            onClick={() => setInUseModal(null)}
            aria-hidden
          />
        </>
      ) : (
        <></>
      )}

      <PreviewModal
        onClose={() => {
          setPreviewId(undefined);
          setForm(undefined);
        }}
        onConfirm={() => {}}
        withFooter={false}
        width={900}
        isOpen={!!previewId}
        title="Preview"
      >
        {isLoading ? (
          <div className="d-flex align-items-center justify-content-center h-100">
            <Spinner color="o-cl-brand-secondary" size={24} />
          </div>
        ) : (
          form && (
            <div className="c-process-step c-process-step--selected w-100">
              <div
                className="c-process-step__container d-flex align-items-center justify-content-between o-cursor-pointer px-4"
                onClick={() => {}}
                aria-hidden
              >
                <div className="c-process-step__section d-flex align-items-center gap-2 w-100">
                  <div className="c-process-step__step d-flex align-items-center justify-content-center">
                    <span className="c-process-step__step-number o-ft-base-700 o-cl-grey-200">
                      1
                    </span>
                  </div>
                  <span
                    className={`c-process-step__title o-ft-base-700 o-cl-grey-100 text-truncate d-inline-block ${!responsive.md ? "w-50" : ""}`}
                  >
                    {form.title}
                  </span>
                </div>
                <div className="c-process-step__section">
                  <StepStatus currentIndex={0} stepId={0} disabled />
                </div>
              </div>

              <div className="px-4 my-4 pb-4">
                <span>{form.description}</span>
              </div>

              <hr className="o-cl-grey-200" />

              {form.cards.map((card: Card, i: number) => {
                return (
                  <React.Fragment key={i}>
                    <div className="px-4 d-flex flex-column mb-4">
                      <div className="pb-2">
                        <span
                          className={`o-ft-2xl-400 ${card.required ? "c-form-card--required" : ""}`}
                        >
                          {card.title}
                        </span>
                      </div>
                      <span className="o-ft-md-400 pb-4">
                        {card.description}
                      </span>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            "repeat(auto-fit, minmax(100px, 1fr))",
                          gap: "2rem",
                        }}
                      >
                        {card.inputs.map((input: any, i: number) => {
                          if (input.code === "file_multiple") {
                            return (
                              <div key={i} className="d-flex flex-column mt-4">
                                <Button
                                  className="c-form-card__upload-button"
                                  size="big"
                                  icon={{
                                    icon: "upload",
                                    size: 24,
                                    position: "left",
                                  }}
                                  value={input.title}
                                  disabled
                                />
                                <span className="o-cl-grey-200 mt-2">
                                  {input.description}
                                </span>
                              </div>
                            );
                          }

                          if (input.code === "textarea") {
                            return (
                              <div
                                key={i}
                                className="d-flex flex-column w-100 mt-2"
                              >
                                <label className="o-cl-grey-200 o-ft-sm-400 mb-1 mx-2">
                                  {input.title}
                                </label>
                                <textarea
                                  className="p-2"
                                  style={{ resize: "none" }}
                                  rows={4}
                                  disabled
                                  placeholder={input.description}
                                />
                              </div>
                            );
                          }

                          if (input.code === "text") {
                            return (
                              <div
                                key={i}
                                className="d-flex flex-column w-100 mb-3"
                              >
                                <label className="mx-2 o-cl-grey-200 o-ft-sm-400 mb-1">
                                  {input.title}
                                </label>
                                <input
                                  type="text"
                                  placeholder={input.description}
                                  disabled
                                />
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>

                    <hr className="o-cl-grey-200" />
                  </React.Fragment>
                );
              })}

              <div className="px-4 d-flex justify-content-end py-4">
                <Button
                  value="Submit"
                  disabled
                  size="medium-big"
                  className={responsive.md ? "w-25" : "w-50"}
                />
              </div>
            </div>
          )
        )}
      </PreviewModal>
    </div>
  );
};

export default AllFormSection;
