import { Button as ButtonB, Modal } from "react-bootstrap";
import React, { useRef, useState } from "react";

import ActionPopUp from "./ActionPopUp";
import Avatar from "@components/Avatar";
import Button from "@components/Button";
import { EToastTypes } from "@models/toast/Toast.type";
import LogOut from "./LogOut";
import MaterialIcon from "./MaterialIcon";
import { RootState } from "@redux/store";
import TextClick from "@components/TextClick";
import { addToast } from "@redux/toasts/slices/toastsSlice";
import classNames from "classnames";
import { getCookie } from "@api/AdminHttpProvider";
import profileImg from "../assets/profile-img.png";
import { updateLoggedUser } from "@redux/users/thunks/userThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import useHandleClickOutside from "@hooks/useHandleClickOutside";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useWindowSize from "@hooks/useWindowSize";

function ProfileSettingsModal() {
  const dispatch = useAppDispatch();
  const [disable, setDisable] = useState(true);
  const [showProfile, setShowProfile] = useState(false); //profile setting modal
  const [showActionModal, setShowActionModal] = useState(false); // modal to confirm
  const { data, pending } = useSelector((state: RootState) => state.user);
  const [profilePicture, setProfilePicture] = useState<File | null>(null);

  const { responsive } = useWindowSize();
  const ref = useRef<null | HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  useHandleClickOutside(ref, () => setIsOpen(false));

  const handleShow = (
    isVisibleFunction: React.Dispatch<React.SetStateAction<boolean>>
  ) => isVisibleFunction(true);
  const handleClose = (
    isVisibleFunction: React.Dispatch<React.SetStateAction<boolean>>
  ) => isVisibleFunction(false);

  const email = data?.email;
  const last_name = data?.last_name;
  const first_name = data?.first_name;
  const avatar_file = data?.avatar_file;
  const permissions = data?.permissions;
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();

  const closeProfileModal = () => {
    handleClose(setShowActionModal);
    handleClose(setShowProfile);
    setProfilePicture(null);
    setDisable(true);
  };

  const onSubmit = () => {
    let user = {
      ...data,
      avatar_file: profilePicture,
    };
    dispatch(updateLoggedUser({ onSuccess, onError, user }));
  };

  const onSuccess = () => {
    handleClose(setShowProfile);
    setProfilePicture(null);
    setDisable(true);
  };

  const onError = (error: any) => {
    let toast = {
      id: Date.now(),
      text:
        error.response.statusText === "Unprocessable Content"
          ? "The picture is too heavy to be uploaded. It should be 1MB max."
          : "Error. Try again later",
      type: EToastTypes.DANGER,
      autohide: true,
    };
    dispatch(addToast(toast));
  };
  const modalClass = classNames({
    "c-profile-modal--blur": showActionModal,
  });

  const handleClick = () => {
    const newUrl = `${process.env.REACT_APP_BACK_BASE_URL}auth/login-from-platform?access_token=${token}`;
    window.open(newUrl, "_blank");
  };

  return (
    <div ref={ref}>
      <ButtonB
        variant=""
        onClick={() => setIsOpen(!isOpen)}
        className="d-flex flex-column w-100"
      >
        <Avatar
          onClick={() => {
            !responsive.md && handleShow(setShowProfile);
            setIsOpen(false);
          }}
        />
        {!responsive.md && (
          <div className="mt-4">
            {permissions && Object.keys(permissions).length > 0 && (
              <div
                className="d-flex py-2 o-cursor-pointer"
                onClick={handleClick}
              >
                <MaterialIcon
                  icon="settings_b_roll"
                  color="o-cl-grey-100"
                  size={20}
                  className="px-2"
                />
                <span className="c-profile-modal__link  o-cl-grey-100">
                  Management Dashboard
                </span>
                <MaterialIcon
                  icon="open_in_new"
                  color="o-cl-grey-100"
                  size={20}
                  className="px-2"
                />
              </div>
            )}
            <div className="d-flex">
              <LogOut />
            </div>
          </div>
        )}
      </ButtonB>

      {isOpen && responsive.md && (
        <div className="position-absolute c-profile-modal__links o-bg-white">
          <div
            className="d-flex py-4 c-profile-modal__links-section o-cursor-pointer"
            onClick={() => {
              handleShow(setShowProfile);
              setIsOpen(false);
            }}
          >
            <MaterialIcon
              icon="manage_accounts"
              color="o-cl-grey-100"
              size={20}
              className="px-2"
            />
            <span className="c-profile-modal__link o-cl-grey-100">
              Profile Setting
            </span>
          </div>
          {permissions && Object.keys(permissions).length > 0 && (
            <div className="d-flex py-4 o-cursor-pointer" onClick={handleClick}>
              <MaterialIcon
                icon="settings_b_roll"
                color="o-cl-grey-100"
                size={20}
                className="px-2"
              />
              <span className="c-profile-modal__link o-cl-grey-100">
                Management Dashboard
              </span>
              <MaterialIcon
                icon="open_in_new"
                color="o-cl-grey-100"
                size={20}
                className="px-2"
              />
            </div>
          )}
        </div>
      )}

      <Modal
        dialogClassName="my-0"
        centered
        show={showProfile}
        onHide={() =>
          !pending &&
          (profilePicture
            ? handleShow(setShowActionModal)
            : handleClose(setShowProfile))
        }
        className={`c-profile-modal ${modalClass}`}
      >
        <Modal.Header closeButton>
          <div>
            <h2 className="c-profile-modal__title o-ft-3xl-500">
              Profile Setting
            </h2>
            <p className="c-profile-modal__subtitle o-ft-base-300">
              My profile
            </p>
          </div>
        </Modal.Header>
        <hr className="c-profile-modal__separator"></hr>
        <Modal.Body className="c-profile-modal__container">
          <div className="container p-0">
            <div className="row">
              <div className="col-12 col-md-4 d-flex flex-column align-items-center justify-content-center">
                <label
                  htmlFor="user-upload"
                  className="c-profile-modal__user-image"
                >
                  <input
                    accept="image/*"
                    type="file"
                    id="user-upload"
                    onChange={(e) => {
                      e.target.files &&
                        e.target.files[0] &&
                        setProfilePicture(e.target.files[0]);
                      setDisable(false);
                    }}
                  />
                  {
                    <div className="position-relative">
                      <div className="c-profile-modal__photo-shadow d-flex justify-content-center align-items-center">
                        <MaterialIcon
                          className="c-profile-modal__photo-icon"
                          size={60}
                          icon="edit"
                          color="#ffff"
                        />
                      </div>
                      <img
                        className="c-profile-modal__photo"
                        src={
                          profilePicture
                            ? URL.createObjectURL(profilePicture)
                            : avatar_file
                            ? avatar_file.url
                            : profileImg
                        }
                        alt="profile image"
                      />
                    </div>
                  }

                  <TextClick />
                </label>
              </div>

              <div className="col-12 col-md-8">
                <div className="c-profile-modal__section">
                  <label className="c-profile-modal__label o-ft-xl-300">
                    First Name
                  </label>
                  <input
                    type="text"
                    placeholder={first_name}
                    className="c-profile-modal__input"
                    disabled
                  ></input>
                </div>
                <div className="c-profile-modal__section">
                  <label className="c-profile-modal__label o-ft-xl-300">
                    Last Name
                  </label>
                  <input
                    type="text"
                    placeholder={last_name}
                    className="c-profile-modal__input"
                    disabled
                  ></input>
                </div>
                <div className="c-profile-modal__section">
                  <label className="c-profile-modal__label o-ft-xl-300">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder={email}
                    className="c-profile-modal__input"
                    disabled
                  ></input>
                </div>
              </div>
              <div className="d-flex justify-content-center justify-content-md-end mt-5">
                <Button
                  value="Save"
                  disabled={disable}
                  onClick={onSubmit}
                  isLoading={pending}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <ActionPopUp
          show={showActionModal}
          onHide={() => handleClose(setShowActionModal)}
          title="Are you sure you want to close?"
          subtitle="You are going to close without saving the changes made. Are you sure you want to close?"
          onClickRightButton={() => closeProfileModal()}
          onClickLeftButton={() => handleClose(setShowActionModal)}
        />
      </Modal>
    </div>
  );
}

export default ProfileSettingsModal;
