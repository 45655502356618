/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ReactElement,
  createContext,
  useContext,
  useMemo,
  useReducer,
} from "react";
import {
  useRemovePinSectionDashboard,
  useRemovePinSectionBottomBar,
} from "@redux/documents/api";
import {
  useGetMobileMenuSections,
  useGetDashboardSections,
} from "@redux/sections/api";
import { IDocument } from "@models/document/NewDocument.type";
import useAppDispatch from "@hooks/useAppDispatch";
import { createToast } from "@helpers/createToast";
import { setPinnedData } from "@redux/search/slice";

export enum EModulesToPin {
  Navigation = "navigation",
  Dashboard = "dashboard",
}
export type TModulesToPin = EModulesToPin.Dashboard | EModulesToPin.Navigation;

export type TEndpoints = "getDocument" | "getDocuments" | "getDocumentsByVault";

export type ContextType =
  | "results"
  | "documents"
  | "vaults"
  | "file-management"
  | "trash"
  | undefined;
interface IPinUnPinState {
  isOpenPinModal: boolean;
  module: TModulesToPin;
  document: IDocument | null;
  vaultId?: number;
  context: ContextType;
  endpoint?: TEndpoints;
}
enum PinUnPinActions {
  SET_IS_OPEN_PIN_MODAL = "SET_IS_OPEN_PIN_MODAL",
  SET_MODULE = "SET_MODULE",
  SET_DOCUMENT = "SET_DOCUMENT",
  SET_ENDPOINT = "SET_ENDPOINT",
}

type PinUnPinContextType = {
  setIsOpenPinModal: (isOpen: boolean) => void;
  tooglePinDashboard: (document: IDocument, endpoint?: TEndpoints) => void;
  tooglePinBottomBar: (document: IDocument, endpoint?: TEndpoints) => void;
} & IPinUnPinState;

export const PinUnpinContext = createContext<PinUnPinContextType | null>(null);

type PinUnPinAction = {
  type: PinUnPinActions;
  payload?: number | boolean | any;
};

const pinUnPinReducer = (state: IPinUnPinState, action: PinUnPinAction) => {
  switch (action.type) {
    case PinUnPinActions.SET_IS_OPEN_PIN_MODAL:
      return {
        ...state,
        isOpenPinModal: action.payload,
      };
    case PinUnPinActions.SET_MODULE:
      return {
        ...state,
        module: action.payload,
      };
    case PinUnPinActions.SET_DOCUMENT:
      return {
        ...state,
        document: action.payload,
      };
    case PinUnPinActions.SET_ENDPOINT:
      return {
        ...state,
        endpoint: action.payload,
      };
    default:
      return state;
  }
};

const PinUnpinProvider = ({
  children,
  vaultId,
  context,
}: {
  children: ReactElement;
  vaultId?: number;
  context?: ContextType;
}) => {
  const initialState: IPinUnPinState = {
    isOpenPinModal: false,
    module: EModulesToPin.Dashboard,
    document: null,
    vaultId,
    context,
  };
  const [removePinSectionDashboard] = useRemovePinSectionDashboard();
  const [removePinSectionBottomBar] = useRemovePinSectionBottomBar();
  const { refetch: refetchDashboardSections } = useGetDashboardSections(
    undefined,
    {
      skip: context === "file-management",
    },
  );
  const { refetch: refetchMobileMenuSections } = useGetMobileMenuSections(
    undefined,
    {
      skip: context === "file-management",
    },
  );
  const [state, dispatchAction] = useReducer(pinUnPinReducer, initialState);
  const dispatch = useAppDispatch();
  const setIsOpenPinModal = (isOpen: boolean) => {
    dispatchAction({
      type: PinUnPinActions.SET_IS_OPEN_PIN_MODAL,
      payload: isOpen,
    });
  };

  const setModule = (module: TModulesToPin) => {
    dispatchAction({
      type: PinUnPinActions.SET_MODULE,
      payload: module,
    });
  };

  const setDocument = (document: IDocument) => {
    dispatchAction({
      type: PinUnPinActions.SET_DOCUMENT,
      payload: document,
    });
  };

  const setEndpoint = (endpoint: TEndpoints) => {
    dispatchAction({
      type: PinUnPinActions.SET_ENDPOINT,
      payload: endpoint,
    });
  };

  const tooglePinDashboard = (document: IDocument, endpoint?: TEndpoints) => {
    if (document.is_dashboard_pinned) {
      removePinSectionDashboard({
        pineableId: Number(document.id) || 0,
        parentId: document.parent_id || 0,
        vaultId,
        endpoint,
      })
        .unwrap()
        .then(() => {
          if (context === "results") {
            dispatch(
              setPinnedData({
                documentId: document.id || 0,
                isPinned: false,
                pinAttribute: "is_dashboard_pinned",
              }),
            );
          }
          refetchDashboardSections();
        })
        .then(() => {
          createToast(
            `Folder has been removed from dashboard successfully`,
            "success",
            dispatch,
          );
        })
        .catch((err) => console.log({ err }));
    } else {
      if (endpoint) setEndpoint(endpoint);
      setModule(EModulesToPin.Dashboard);
      setDocument(document);
      setIsOpenPinModal(true);
    }
  };

  const tooglePinBottomBar = (document: IDocument, endpoint?: TEndpoints) => {
    if (document.is_bottom_bar_pinned) {
      removePinSectionBottomBar({
        pineableId: document.id || 0,
        parentId: document.parent_id || 0,
        vaultId,
        endpoint,
      }).then(() => {
        refetchMobileMenuSections();
        createToast(
          `Folder has benn removed from bottom bar successfully`,
          "success",
          dispatch,
        );
      });
    } else {
      if (endpoint) setEndpoint(endpoint);
      setModule(EModulesToPin.Navigation);
      setDocument(document);
      setIsOpenPinModal(true);
    }
  };

  const contextValue = useMemo(
    () => ({
      ...state,
      setIsOpenPinModal,
      tooglePinDashboard,
      tooglePinBottomBar,
    }),
    [state, setIsOpenPinModal],
  );
  return (
    <PinUnpinContext.Provider value={contextValue}>
      {children}
    </PinUnpinContext.Provider>
  );
};

export default PinUnpinProvider;
// Create a custom hook to use the context
export const usePinUnpinContext = () => {
  const context = useContext(PinUnpinContext);
  if (!context) {
    throw new Error(
      "usePinUnpinContext must be used within a PinUnpinProvider",
    );
  }
  return context;
};
