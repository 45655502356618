import _ from "lodash";
import { IRow } from "@models/Table.type";
import { IFile } from "@models/files/File.type";
import { addFile } from "@redux/files/slices/fileSlice";
import { getFiles } from "@redux/files/thunks/fileThunk";
import TrashController from "@controllers/TrashController";
import { createToast } from '../../../app/helpers/createToast';
import { createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { RadioNames, TRadioButtonName } from "@components/RadioButton";

declare global {
  interface Window {
    Noty: any;
  }
}

const getNotyText = ({ text, description }: { text: string; description: string; }) => {
  return `<strong>${text}</strong><br>${description}`;
}

const trashController = new TrashController();

export const getTrash = createAsyncThunk(
  'trash/index',
  async (arg: void, { rejectWithValue }) => {
    try {
      let response = await trashController.index();
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFolderTrash = createAsyncThunk(
  'trash/folder/index',
  async (parentId: string | null, { rejectWithValue }) => {
    try {
      let response = await trashController.specificTrash(parentId ? parentId : null);
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const restoreFile = createAsyncThunk(
  'trash/restore',
  async (args: { file: IFile[] | IRow[], parent_id: number | null, section_id?: number, action?: TRadioButtonName }, { rejectWithValue, dispatch }) => {
    const { file: files, parent_id, action = RadioNames.Keep } = args;
    let restoredFiles: any[] = [];

    await Promise.all(files.map(async (file: IFile | IRow, index) => {
      await trashController.restore(file.id, action).then(async (restoredFile) => {
        let text = `<strong>Deleted File Restored</strong><br>"${file.name}" has been restored successfully.<br/>
        <a style="color: white" href="${process.env.REACT_APP_BACK_BASE_URL}admin/file-management/${restoredFile.data.parent_id ? restoredFile.data.parent_id : ""}" target="_blank">Find it here</a>`
        restoredFiles.push(file)
        if (action === RadioNames.Replace) {
          await dispatch(getFiles(parent_id));
        } else {
          dispatch(addFile(restoredFile.data))
        }
        createToast(text, "success", dispatch)
      }).catch((error) => {
        console.log(error)
        return errorRequest(error, rejectWithValue, dispatch)
      })
    }))
    if (restoredFiles.length)
      return restoredFiles
    else
      throw files
  }
);

export const deleteTrashFiles = createAsyncThunk(
  'trash/delete',
  async (files: IFile[] | IRow[], { rejectWithValue, dispatch }) => {
    return await Promise.all(files.map(async (file: IFile | IRow, index) => {
      try {
        await trashController.delete(file.id)
        return file;
      }
      catch (error: any) {
        if (_.isEqual(error.response.data.message, 'Attempt to read property "id" on array')) {
          return file
        }
        else {
          return null
        }
      }
    })).then((response) => {
      let errors = []
      errors = response.filter((res) => _.isNull(res))
      let deletedFiles = []
      deletedFiles = response.filter((res) => !_.isNull(res))
      let text
      if (deletedFiles.length) {
        if (deletedFiles.length > 1) {
          text = `<strong>Files permanently deleted</strong><br>${deletedFiles.length} files have been deleted successfully.`
        }
        else {
          text = `<strong>File permanently deleted</strong><br>File "${deletedFiles[0]?.name}" has been deleted successfully.`
        }
        createToast(text, "success", dispatch)
        if (errors.length) {
          text = 'Something went wrong while processing your request. Please try again.'
          createToast(text, "danger", dispatch)
        }
      }
      else {
        throw errorRequest(response, rejectWithValue, dispatch)
      }
      return deletedFiles
    })
  }
);

const errorRequest = (err: any, rejectWithValue: (arg0: any) => any, dispatch: ThunkDispatch<any, any, any>) => {
  createToast('Something went wrong while processing your request. Please try again.', "danger", dispatch)
  return rejectWithValue(err.response.data);
}
