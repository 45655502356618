import React from 'react';

const TextClick = () => {
  return (
    <div className='c-text-click'>
      <div>
        <p className='c-text-click__link'>Change Photo</p>
        <hr className='c-text-click__separator'></hr>
      </div>
    </div>
  );
};

export default TextClick;
