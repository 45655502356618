export const formatWeight = (size: number, SI: boolean) => {
  // SI true to use metric (SI) units, powers of 1000. false to use binary(IEC), powers of 1024
  if (size) {
    let unitSize = SI ? 1000 : 1024;
    let i = size === 0 ? 0 : Math.min(Math.floor(Math.log(size) / Math.log(unitSize)), 8);
    return +(size / Math.pow(unitSize, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][i];
  }
  else
    return "0 B"
}
