/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";

import { postFolder } from "@redux/files/thunks/fileThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import React, { useState } from "react";
import { IFolder } from "../app/types/files/File.type";
import FormModal from "./FormModal";

interface IAddFolderModalProps {
  folderId: number | null;
  isVisible: boolean;
  setIsVisible: (a: boolean) => void;
  onConfirmFolder?: (folder: any) => void;
}

interface IFolderForm {
  name: string;
}

const FolderSchema = Yup.object().shape({
  name: Yup.string().email("Name is invalid").required("Name is required"),
});

const AddFolderModal = ({
  isVisible,
  folderId,
  setIsVisible,
  onConfirmFolder,
}: IAddFolderModalProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values: IFolderForm) => {
    if (isLoading) return;

    try {
      setIsLoading(true);

      const folder: IFolder = {
        id: 0,
        file: null,
        section: null,
        parent_id: folderId,
        ...values,
      };

      if (typeof onConfirmFolder === "function") {
        onConfirmFolder(folder);
      } else {
        dispatch(postFolder(folder));
      }
    } finally {
      setIsLoading(false);
      setIsVisible(false);
    }
  };

  return (
    <FormModal
      inputs={[
        { id: "name", name: "Name", type: "text", placeholder: "Folder Name" },
      ]}
      show
      title="Add Folder"
      isLoading={isLoading}
      initialValues={{ name: "" }}
      validationSchema={FolderSchema}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onHandleSubmit={onSubmit}
    />
  );
};

export default AddFolderModal;
