/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";

import FormikStepper, { FormikStep } from "./WalkthroughSteps/FormikStepper";
import React, { useEffect, useState } from "react";

import { CtaBackToLogin } from "./Walkthrough-styled";
import HttpProvider from "@api/HttpProvider";
import ProgressBar from "@components/ProgressBar";
import RequestAccessProvider from "../providers/request-access-provider";
import Step1 from "./WalkthroughSteps/Step1";
import Step2 from "./WalkthroughSteps/Step2";
import Step3 from "./WalkthroughSteps/Step3";
import Step4 from "./WalkthroughSteps/Step4";
import Step5 from "./WalkthroughSteps/Step5";
import { createToast } from "@helpers/createToast";
import { images } from "@theme/images";
import sortBy from "lodash/sortBy";
import useAppDispatch from "@hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";

const api = new HttpProvider();

export type Values = {
  wasCompanySelected: boolean;
  iamfield: string;
  rolefield: string;
  firstNameField: string;
  companyFullNameField: string;
  emailAddressField: string;
  lastNameField: string;
  jobTitleField: string;
  address_one: string;
  address_two: string;
  city_field: string;
  state_field: string;
  zipcode_field: string;
  brands: { name: string; code: string }[];
  country: string;
  reasonAccess: string;
  referralName: string;
  referralEmail: string;
};

const initialValues: Values = {
  wasCompanySelected: false,
  iamfield: "",
  rolefield: "",
  firstNameField: "",
  companyFullNameField: "",
  emailAddressField: "",
  lastNameField: "",
  jobTitleField: "",
  address_one: "",
  address_two: "",
  city_field: "",
  state_field: "",
  zipcode_field: "",
  brands: [],
  country: "United States",
  reasonAccess: "",
  referralName: "",
  referralEmail: "",
};

const step1Schema = Yup.object({
  iamfield: Yup.string().trim().required("This field is required"),
  rolefield: Yup.string()
    .trim()
    .when("iamfield", (iamfield, schema) =>
      iamfield === "Other" ? schema.required("This field is required") : schema,
    ),
});

const step2Schema = Yup.object({
  firstNameField: Yup.string().trim().required("This field is required"),
  wasCompanySelected: Yup.boolean(),
  companyFullNameField: Yup.mixed().test({
    name: "wasCompanySelected",
    message: "This field is required, you have to select or add a new company",
    test() {
      const { wasCompanySelected } = this.parent;

      return wasCompanySelected === true;
    },
  }),
  emailAddressField: Yup.string()
    .trim()
    .email("This field must be a valid email")
    .required("This field is required"),
  lastNameField: Yup.string().trim().required("This field is required"),
  jobTitleField: Yup.string().trim().required("This field is required"),
});

const step3Schema = Yup.object({
  address_one: Yup.string().trim().required("This field is required"),
  address_two: Yup.string().trim(),
  city_field: Yup.string().trim().required("This field is required"),
  state_field: Yup.string().trim().required("This field is required"),
  zipcode_field: Yup.string().trim().required("This field is required"),
  country: Yup.string().trim().required("This field is required"),
});

const step4Schema = Yup.object({
  brands: Yup.array().min(1).required("This field is required"),
});

const step5Schema = Yup.object({
  reasonAccess: Yup.string()
    .min(25, "Minimum 25 characters.")
    .max(500, "This field must be at most 500 characters")
    .trim()
    .required("This field is required"),
  referralName: Yup.string()
    .trim()
    .min(3, "Minimum 3 characters.")
    .max(100, "This field must be at most 100 characters")
    .matches(/^[A-Za-z\s]+$/, "Only letters allowed")
    .required("This field is required"),
  referralEmail: Yup.string()
    .trim()
    .email("Invalid email format.")
    .required("This field is required"),
});

const validationSchema = [
  step1Schema,
  step2Schema,
  step3Schema,
  step4Schema,
  step5Schema,
];

const Walkthrough = () => {
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);
  const [brands, setBrands] = useState<any[]>([]);
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: Values) => {
    const params = new FormData();

    params.append("first_name", values.firstNameField);
    params.append("last_name", values.lastNameField);
    params.append("position", values.iamfield);
    params.append("company_full_name", values.companyFullNameField);
    params.append("job_title", values.jobTitleField);
    params.append("email", values.emailAddressField);
    params.append("address_1", values.address_one);
    params.append("city", values.city_field);
    params.append("state", values.state_field);
    params.append("zip_code", values.zipcode_field);
    params.append("country", values.country);
    params.append("request_reason", values.reasonAccess);
    params.append("referral_name", values.referralName);
    params.append("referral_email", values.referralEmail);

    if (values.rolefield) {
      params.append("external_role", values.rolefield);
    }

    if (values.address_two) {
      params.append("address_2", values.address_two);
    }

    values.brands.forEach((brand) => {
      params.append("brands[]", brand.code);
    });

    try {
      await api.request("post", "api/access-request/handler", params);
      setProgress(5);
    } catch (error) {
      createToast(
        "An error occurred. Please try again later.",
        "danger",
        dispatch,
      );
    }
  };

  useEffect(() => {
    api.request("get", "api/brands").then(({ data }: any) => {
      const sortedData = sortBy(data.data, ["name"]);

      const midpoint = Math.ceil(sortedData.length / 2);
      const column1 = sortedData.slice(0, midpoint);
      const column2 = sortedData.slice(midpoint);

      setBrands([column1, column2]);
    });
  }, []);

  return (
    <RequestAccessProvider value={{}}>
      <div
        style={{
          height: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
          paddingBottom: "9rem",
        }}
      >
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <img
              className="c-form-header__onboarding-logo-img"
              src={images.verticalBrandLogo}
              alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
            />
          </div>
          <div className="col-12 col-md-8 offset-md-2 px-4">
            {progress < 5 ? (
              <>
                <span className="c-form-header__title o-ft-xl-400 o-ft-2xl@lg text-left d-block">
                  Request Access
                </span>
                <p className="o-ft-sm-400">
                  {progress === 0
                    ? "Please tell us about yourself:"
                    : "Please complete the following information."}
                </p>
                <ProgressBar value={progress} max={5} />

                <br />

                <FormikStepper
                  initialValues={initialValues}
                  validateOnChange={false}
                  validateOnBlur
                  onStepChange={(step: number) => {
                    setProgress(step);
                  }}
                  onSubmit={(values) => handleSubmit(values as Values)}
                >
                  <FormikStep validationSchema={validationSchema[0]}>
                    <Step1 />
                  </FormikStep>
                  <FormikStep validationSchema={validationSchema[1]}>
                    <Step2 />
                  </FormikStep>
                  <FormikStep validationSchema={validationSchema[2]}>
                    <Step3 />
                  </FormikStep>
                  <FormikStep validationSchema={validationSchema[3]}>
                    <Step4 columns={brands} />
                  </FormikStep>
                  <FormikStep validationSchema={validationSchema[4]}>
                    <Step5 />
                  </FormikStep>
                </FormikStepper>
              </>
            ) : (
              <div className="row d-flex flex-column">
                <div className="col-10 col-md-6 mx-auto d-flex flex-column justify-content-between mt-5">
                  <h3 className="text-center mb-4 o-ft-2xl-400 o-cl-grey-100">
                    Your request for access has been submitted successfully
                  </h3>
                  <p className="text-center mb-5 o-ft-sm-400 o-cl-grey-100">
                    This submission will be reviewed and you will receive a
                    response to the email provided.
                  </p>
                </div>
                <div className="col-12 d-flex justify-content-between">
                  <CtaBackToLogin onClick={() => navigate("/login")}>
                    Back to login
                  </CtaBackToLogin>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </RequestAccessProvider>
  );
};

export default Walkthrough;
