import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { formatCapitalizeFirstLetter } from '@helpers/formatCapitalizeFirstLetter'

type CrumbProps = {
	currentLink: string;
	isActive: boolean;
	label: string;
}

const Crumb: React.FC<CrumbProps> = ({ currentLink, isActive, label }) => {
	const { state } = useLocation()
	const navigate = useNavigate()
	const activeClass = isActive ? 'c-breadcrumb__text--active' : ''
	const classNames = `c-breadcrumb__text ${activeClass}`

	return (
		<>
			<div className={classNames} role='link' onClick={() => navigate(currentLink, { state })}>
				{formatCapitalizeFirstLetter(label)}
			</div>
			<span className="c-breadcrumb__separator">/</span>
		</>
	)
}

export type Props = {
	offset?: number;
	withIds?: boolean;
}

const BreadCrumb: React.FC<Props> = ({ offset = 0, withIds = false }) => {
	const { pathname } = useLocation()

	const paths = pathname.split('/').filter(p => p !== '')

	let currentLink = ''
	const crumbs = paths.map((crumb, index, arr) => {
		currentLink += '/' + crumb.replaceAll('_', ' ');
	  
		const strings = crumb.split(/[-_]/); // Split using both '-' and '_'
		const lastIsNumber = !isNaN(Number(strings[strings.length - 1]));
	  
		let label = strings;
		if (lastIsNumber && !withIds) {
		  label = strings.slice(0, strings.length - 1);
		}
	  
		if (index < offset) return;
	  
		const props: CrumbProps = {
		  currentLink,
		  label: label.join(' '),
		  isActive: index + 1 === arr.length
		}
	  
		return <Crumb key={index} {...props} />
	  })

	return <div className='c-breadcrumb d-flex flex-row'>{crumbs}</div>
}

export default React.memo(BreadCrumb)