/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from "@components/Button";
import MaterialIcon from "@components/MaterialIcon";
import { Modal } from "react-bootstrap";
import React from "react";
import useWindowSize from "@hooks/useWindowSize";

interface Action {
  action: React.ReactNode;
}
interface IGenericModal {
  show: boolean;
  isLoading?: boolean;
  disabledConfirmButton?: boolean;
  title: string | null;
  cancelModalText?: string;
  onCancelButton?: () => void;
  confirmModalText: string;
  onConfirmButton?: () => void;
  children: React.ReactNode;
  withCancelButton: boolean;
  subtitle?: React.ReactNode | string | null;
  onArrowButtonClick?: () => void;
  withArrowButton?: boolean;
  search?: React.ReactNode;
  otherActions?: Action[];
  otherButton?: React.ReactNode;
  container?: any;
}

const GenericModal = ({
  show,
  isLoading,
  onCancelButton,
  title,
  subtitle,
  cancelModalText,
  onConfirmButton,
  confirmModalText,
  children,
  withCancelButton,
  onArrowButtonClick,
  withArrowButton = false,
  search,
  disabledConfirmButton,
  otherActions,
  otherButton,
  container,
}: IGenericModal) => {
  const { responsive } = useWindowSize();
  return (
    <Modal
      show={show}
      onHide={onCancelButton}
      onExited={() => console.log("on exited")}
      contentClassName="c-documents-modal border-0 rounded-0"
      dialogClassName="c-documents-modal__dialog"
      className="c-documents-modal"
      centered
      size="lg"
      scrollable
      container={container || undefined}
    >
      <Modal.Header className="my-md-1 border-0">
        <div className="d-flex align-items-center justify-content-center c-header-module__left-container">
          <button
            type="button"
            className="bg-transparent border-0 px-3"
            onClick={onArrowButtonClick}
            disabled={!withArrowButton}
          >
            <MaterialIcon
              icon="arrow_back"
              size={40}
              color={`${withArrowButton ? "o-cl-brand-primary" : "o-cl-grey-200"}`}
            />
          </button>
          <div className="c-header-module__left-container">
            <div className="d-flex align-items-center">
              <span className="c-header-module__title o-cl-grey-100 o-ft-3xl-600">
                {title}
              </span>
            </div>
            <div className="c-breadcrumb d-flex flex-row w-100">
              <span className="c-breadcrumb__text--active d-md-inline">
                {subtitle}
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          {search}
          <button
            type="button"
            className="bg-transparent border-0 px-3"
            onClick={onCancelButton}
          >
            <MaterialIcon icon="close" size={40} color="o-cl-grey-100" />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0 c-documents-modal__body">
        {children}
      </Modal.Body>
      <Modal.Footer className="py-4 border-0 my-md-2 justify-content-between">
        <div>{otherActions?.map((action) => action.action)}</div>
        <div>
          {withCancelButton && (
            <Button
              isLoading={isLoading}
              disabled={isLoading}
              className="ms-2 ms-sm-3 ms-md-1"
              onClick={onCancelButton}
              size={responsive.md ? "big" : "medium-big"}
              value={cancelModalText || "Cancel"}
            />
          )}
          <div className="d-flex gap-2">
            {otherButton}
            <Button
              isLoading={isLoading}
              disabled={isLoading || disabledConfirmButton}
              className="ms-2 ms-sm-3 ms-md-1"
              onClick={onConfirmButton}
              size={responsive.md ? "big" : "medium-big"}
              value={confirmModalText}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default GenericModal;
