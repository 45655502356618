import { IRow } from "@models/Table.type";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { getNestedValue } from "@helpers/getNestedValue";
import { previewFile } from "@helpers/previewFile";
import useAppSelector from "@hooks/useAppSelector";
import { useDragAndDrop } from "@hooks/useDragAndDrop";
import { useNavigate } from "react-router-dom";
import useWindowSize from "@hooks/useWindowSize";
import { v4 as uuidv4 } from "uuid";

interface IResponsive {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
}

interface IColumn {
  id: string; // column identifier to differentiate keys of different columns (it could be removed and use header or field instead)
  header: string; // title of column
  field: string; // name of the property of the item object to show, E.g.: If the items is {title: 'Example', author: {name: 'Lorem', age: 54}}, field could be 'title', 'author.name' and so on.
  body?: (arg: IRow) => JSX.Element; // in case of needing a custom component and not just a text string
  collapsable?: boolean; // collapses a column before breakpoint lg - improvement: make collapse breakpoint dynamic like fixedWidth
  collapsableDesktop?: boolean;
  colspan?: IResponsive; // allows to add an specific colspan starting from an specific breakpoint, E.g: {sm: '1', lg: '2'}
  fixedWidth?: IResponsive; // allows to add an specific width starting from an specific breakpoint, E.g: {sm: '44px', lg: '20%', xl: 'unset'}, in case of not added or value 'unset' columns width will behave as auto
  hiddenMobileTitle?: boolean; // hides a column header title before breakpoint lg - improvement: make hidden breakpoint dynamic like fixedWidth
  parsedString?: (arg: IRow) => string; // similar to body, instead of returning a new component it modifies the string. E.g.: it can be used to parse a date to a readable form.
}
interface ITableItemProps {
  data: IRow; // Row data
  index: number;
  columns: IColumn[]; // Each column info
  dynamicRowClassName?: (arg: IRow) => string; // Add a class string to a row based on a condition
  onRowClick?: (arg: IRow, e: React.MouseEvent<HTMLTableRowElement>) => void; // Add onClick to each row
  handleFileLoad?: (e: React.ChangeEvent) => void;
  onContextMenu?: any;
  draggable?: boolean;
  canClickRow?: boolean;
}

interface ITableRow {
  data: IRow;
  tdClass: string;
  colspan: string;
  column: IColumn;
  columnIndex: number;
  canClickRow?: boolean;
  onHandleClick: (column: IColumn) => void;
}

const TableRow = ({
  tdClass,
  colspan,
  column,
  data,
  canClickRow,
  onHandleClick,
}: ITableRow) => {
  return (
    <td
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(canClickRow && { onClick: () => onHandleClick(column) })}
      // onClick={() => onHandleClick(column)}
      // onDoubleClick={(e) => responsive.md && onHandleClick(column)}
      colSpan={Number.parseInt(colspan, 10)}
      className={`c-table__row o-cursor-pointer ps-2 pe-2 ${tdClass}`}
      key={`${data.id}-${column.id}-td`}
    >
      {column.body ? (
        column.body(data)
      ) : (
        <span className="o-ft-sm-400 o-cl-grey-200">
          {column.parsedString
            ? column.parsedString(data)
            : getNestedValue(data, column.field)}
        </span>
      )}
    </td>
  );
};

const TableItem = ({
  data,
  index,
  columns,
  dynamicRowClassName,
  onRowClick,
  handleFileLoad,
  onContextMenu,
  draggable,
  canClickRow,
}: ITableItemProps) => {
  const navigate = useNavigate();
  const { responsive } = useWindowSize();
  const componentId = uuidv4(); // table identifier to differentiate keys of different tables with the same column data
  const {
    file: { draggingId },
  } = useAppSelector((state) => state);
  const {
    handleDrop,
    handleDrag,
    handleDragEnter,
    handleDragOver,
    handleDragLeave,
    handleDragStart,
    handleDragEnd,
  } = useDragAndDrop();
  const currentWebsite = localStorage.getItem("current_website");

  const tableRowClass = classNames({
    "c-table__row--border": !data.type && _.isEqual(draggingId, data.id),
  });

  const onHandleClick = (column: IColumn) => {
    if (data.store_type) {
      navigate(`/stores/${data.name.replaceAll(" ", "-")}-${data.id}`);
      return;
    }

    if (data.project_type) {
      navigate(
        `/stores/${data.store.name.replaceAll(" ", "-")}-${data.store.id}/${(data.name || data.code).replaceAll(" ", "-")}-${data.id}`,
      );
      return;
    }
    // if is a file to upload do not redirect or preview
    // if (!data.file) return
    // if (data.file.lastModified) return

    if (
      !_.isEqual(column.id, "actions") &&
      !_.isEqual(column.id, "checkbox") &&
      data.name
    ) {
      if (!data.type && !data.deleted_at) {
        if (_.isEqual(currentWebsite, "admin")) {
          navigate(`/admin/file-management/${data.id}`, { replace: true });
        } else {
          navigate(`/${data.route}`);
        }
      } else {
        previewFile(data);
      }
    }
  };

  const onChange = (e: any, files: any) => {
    e.stopPropagation();
    e.preventDefault();

    const target: any = {
      target: {
        files,
      },
    };

    if (handleFileLoad) handleFileLoad(target);
  };

  return (
    <tr
      onContextMenu={onContextMenu}
      draggable={!data.deleted_at && draggable}
      onDrag={handleDrag}
      onDrop={(e) => handleDrop(e, data, onChange)}
      onDragOver={(e) => handleDragOver(e, data)}
      onDragLeave={(e) => handleDragLeave(e, data)}
      onDragStart={(e) => handleDragStart(e, data)}
      onDragEnter={(e) => handleDragEnter(e, data)}
      onDragEnd={handleDragEnd}
      className={`c-table__row ${tableRowClass} ${dynamicRowClassName && dynamicRowClassName(data)}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(onRowClick && { onClick: (e) => onRowClick(data, e) })}
      key={`${componentId}-${index}`}
    >
      {columns.length > 0 &&
        columns.map((column: IColumn, columnIndex: number) => {
          const tdClass = classNames({
            // Eliminar la condicion de abajo cuando se quiera agregar seleccion multiple en Trash
            "c-table__row-data": !data.deleted_at,
            "c-table__row--collapsable": column.collapsable,
            "c-table__row--collapsable-desktop": column.collapsableDesktop,
          });
          let colspan = "0";
          if (column.colspan) {
            Object.keys(responsive).forEach((property) => {
              if (
                responsive[property] &&
                column.colspan![property as keyof IResponsive]
              ) {
                colspan = column.colspan![property as keyof IResponsive]!;
              }
            });
          }
          return _.isEqual(column.id, "checkbox") ? (
            responsive.md && (
              <TableRow
                // eslint-disable-next-line react/no-array-index-key
                key={columnIndex}
                {...{
                  tdClass,
                  column,
                  colspan,
                  columnIndex,
                  data,
                  canClickRow,
                  onHandleClick,
                }}
              />
            )
          ) : (
            <TableRow
              // eslint-disable-next-line react/no-array-index-key
              key={columnIndex}
              {...{
                tdClass,
                column,
                colspan,
                columnIndex,
                data,
                canClickRow,
                onHandleClick,
              }}
            />
          );
        })}
    </tr>
  );
};

export default TableItem;
