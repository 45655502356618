/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import classNames from "classnames";
import MaterialIcon, { TIcon } from "./MaterialIcon";
import { NavLink } from "react-router-dom";
import useWindowSize from "@hooks/useWindowSize";
import { useGetMobileMenuSections } from "@redux/sections/api";

const BottomTabNavigator = () => {
  const { responsive } = useWindowSize();
  const { data: menuSections } = useGetMobileMenuSections();

  return (
    <div
      id="bottomTabOnboarding"
      className="c-bottom-tab-navigator w-100 o-bg-white"
    >
      {menuSections?.data?.map((section: any) => {
        return (
          <NavLink
            key={section.id}
            className={({ isActive }) => {
              return `c-bottom-tab-navigator__button px-2 align-items-center text-decoration-none flex-column
                ${classNames({
                  "o-ft-700": isActive,
                })}`;
            }}
            to={`/${section.pinned?.path || section.path}`}
          >
            {({ isActive }) => (
              <div
                id={`${section.name.toLowerCase()}BottomTabOnboarding`}
                className="d-flex w-100 flex-column align-items-center"
              >
                <MaterialIcon
                  className="c-button__icon"
                  icon={
                    section.pinned ? "inventory_2" : (section.icon as TIcon)
                  }
                  size={responsive.xs && !responsive.sm ? 30 : 40}
                  color={isActive ? "o-cl-brand-primary" : "o-cl-grey-100"}
                />
                <span className="c-bottom-tab-navigator__text text-center o-cl-grey-100">
                  {section.pinned?.name || section.name}
                </span>
              </div>
            )}
          </NavLink>
        );
      })}
    </div>
  );
};

export default BottomTabNavigator;
