import _ from 'lodash';
import classNames from 'classnames';
import { Spinner } from 'react-activity';
import useWindowSize from '@hooks/useWindowSize';


export type Props = {
  placeholder: string;
  size?: "sm" | "md" | "lg";
}

const PaginationLoader: React.FC<Props> = ({ placeholder, size }) => {
  const { responsive } = useWindowSize()

  const selectedSize = () => {
    switch (size) {
      case "sm":
        return 15
      case "md":
        return 18
      case "lg":
        return 20
      default:
        return 18
    }
  }

  const placeholderClassName = classNames({
    "o-ft-sm-100": _.isEqual(size, "lg"),
    "o-ft-sm-200": !size,
    "o-ft-sm-300": _.isEqual(size, "sm")
  })

  const loadingMoreClassName = classNames({
    'p-4': !responsive.md,
    'p-5': responsive.md,
  });

  return (
    <div className={`d-flex justify-content-center ${loadingMoreClassName}`}>
      <Spinner size={selectedSize()} />
      <span className={`ms-2 o-cl-grey-200 ${placeholderClassName}`}>{placeholder}</span>
    </div>
  )
}

export default PaginationLoader
