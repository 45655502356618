import { EToastTypes, IToast } from "@models/toast/Toast.type";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { addToast } from "@redux/toasts/slices/toastsSlice";

const toastType = (type: string) => {
  switch (type) {
    case "success":
      return EToastTypes.PRIMARY;
    case "danger":
      return EToastTypes.DANGER;
    case "warning":
      return EToastTypes.WARNING;
    default:
      return EToastTypes.PRIMARY;
  }
};

export const createToast = (
  text: string,
  type: "success" | "danger" | "warning" | "loading",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<any, any, any> | any,
  autohide = true,
  withClose = true,
  delay = 5000,
  id: string | number = Date.now(),
): IToast | undefined => {
  if (window.Noty) {
    new window.Noty({
      type,
      text,
      layout: type === "warning" ? "topCenter" : "topRight",
      progressBar: false,
      timeout: delay,
    }).show();
  } else {
    const toast: IToast = {
      id,
      text,
      type: toastType(type),
      autohide,
      withClose,
      delay,
    };
    dispatch(addToast(toast));
    return toast;
  }
  return undefined;
};
