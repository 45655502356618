import React from 'react';
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

interface IDashboardProps {
}

const LoadingView: React.FC<IDashboardProps> = () => {
  return (
    <div className='o-screen-container'>
      {
        <div className="c-header-module">
          <div>
            <SkeletonTheme baseColor="#D8D8D8" highlightColor="#FFFFFF">
              <h3 className="c-header-module__title o-cl-grey-100"><Skeleton width={"15%"} /></h3>
              <h5 className="c-breadcrumb__text"><Skeleton width={"10%"} /></h5>
            </SkeletonTheme>
          </div>
        </div>
      }
    </div>
  );
}

export default LoadingView