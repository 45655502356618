import styled from "styled-components";

export const InputTitle = styled.h2<{ $error?: boolean }>`
  color: ${(props) => (props.$error ? "var(--red)" : "#565250")};
  font-family: Open Sans;
  // margin-left: .5rem;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const InputSubtitle = styled.h2<{
  $error?: boolean;
  $marginLeft?: boolean;
}>`
  color: ${(props) => (props.$error ? "var(--red)" : "#9FA0A0")};
  font-family: Open Sans;
  margin-left: ${(props) => (props.$marginLeft ? "8px" : "0")};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const InputText = styled.div<{ $error?: boolean }>`
  input {
    width: 100%;
    display: flex;
    padding: 6px 8px;
    flex-direction: column;
    border-radius: 0.25rem;
    border: 1px solid ${(props) => (props.$error ? "var(--red)" : "#9FA0A0")};
    outline: none;
    font-family: "Open Sans", sans-serif !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    transition: 0.5s all;
    &:focus {
      border: 1px solid ${(props) => (props.$error ? "var(--red)" : "#CC00FF")};
    }
  }
  textarea {
    width: 100%;
    border: 1px solid ${(props) => (props.$error ? "var(--red)" : "#9FA0A0")};
    padding: 6px 8px;
    outline: none !important;
    border-radius: 0.25rem;
    font-family: "Open Sans", sans-serif !important;
    font-size: 14px;
    transition: 0.5s all;
    &:focus {
      border: 1px solid ${(props) => (props.$error ? "var(--red)" : "#CC00FF")};
    }
    &::placeholder {
      padding-left: 8px;
    }
  }
`;
