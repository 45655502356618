import { RadioButton, TRadioButton } from "@components/RadioButton";
import _ from "lodash";

interface IRadioSwitchProps {
  onChange: (elem: TRadioButton) => void;
  values: Array<TRadioButton>;
  currentIndex: number;
  setCurrentIndex: (idx: number) => void;
  variant?: "block";
}

export const RadioSwitch = ({ onChange, values, currentIndex, setCurrentIndex, variant }: IRadioSwitchProps) => {
  const handleChangeRadioButton = (elem: TRadioButton, index: number) => {
    if (!_.isEqual(index, currentIndex)) {
      setCurrentIndex(index);
      onChange(elem);
    }
  }

  const renderRadioButtons = (values || []).map((item, idx: number) => (
    <RadioButton
      variant={variant}
      index={idx}
      key={item.id}
      onClick={handleChangeRadioButton}
      item={item}
      active={_.isEqual(currentIndex, idx)}
    />
  ));

  return (
    <>
      {renderRadioButtons}
    </>
  );
}
