import styled from "styled-components";
import { colors } from "@theme/colors";

export const StyledSelect = styled.div<{ $error?: boolean }>`
  .react-select__control {
    border-radius: 0.25rem;
    border: 1px solid ${(props) => (props.$error ? colors.red : "#9FA0A0")};
    transition: 0.5s all;
    &:focus {
      border: 1px solid
        ${(props) => (props.$error ? colors.red : colors.brandColorPrimary)};
    }
  }

  .react-select__control--is-focused {
    border: 1px solid
      ${(props) => (props.$error ? colors.red : colors.brandColorPrimary)};
  }

  .react-select__multi-value {
    background-color: #e9ecef;
    border-radius: 0.25rem;
  }
`;
