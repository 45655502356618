import MaterialIcon, { TIcon } from "@components/MaterialIcon"
import classNames from "classnames";

type Props = {
	isArrowOpen: boolean;
}

const ArrowIcon: React.FC<Props> = ({ isArrowOpen }) => {
	const iconClass = classNames({
		'keyboard_arrow_down': isArrowOpen,
		'keyboard_arrow_right': !isArrowOpen
	}) as TIcon;

	const colorClass = classNames({
		'o-cl-grey-100': isArrowOpen,
		'o-cl-grey-200': !isArrowOpen
	});

	return (
		<MaterialIcon icon={iconClass} color={colorClass} size={18} />
	)
}

export default ArrowIcon