/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import PinUnpinProvider from "../providers/PinUnpinProvider";
import VaultDocumentsSection from "../sections/Vaults/VaultDocumentsSection";

const VaultDocuments = () => {
  return (
    <div>
      <PinUnpinProvider>
        <VaultDocumentsSection />
      </PinUnpinProvider>
    </div>
  );
};

export default VaultDocuments;
