import * as Yup from "yup";

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import FormCaption from "@components/FormCaption";
import FormHeader from "@components/FormHeader";
import FormView from "@components/FormView";
import HttpProvider from "@api/HttpProvider";
import ValidateTwoFALinks from "./ValidateTwoFALinks";
import { colors } from "@theme/colors";
import { createToast } from "@helpers/createToast";
import styled from "styled-components";
import useAppDispatch from "@hooks/useAppDispatch";

export const CodeInputContainer = styled.div<{ $error: boolean }>(
  ({ $error }) => ({
    width: 51,
    height: 62,
    padding: "0 16px 18px 16px",
    border: `1px solid ${$error ? "red" : "#565250"}`,
    borderRadius: 3,
    fontSize: 20,
    display: "flex",
    alignItems: "end",
    "@media only screen and (min-width: 1025px)": {
      width: 51,
      height: 62,
    },
  }),
);

export const CodeInput = styled.input<{ $value: string }>(({ $value }) => ({
  border: "none",
  borderBottom: $value.length ? "1px solid transparent" : "1px solid #565250",
  padding: 0,
  textAlign: "center",
  fontWeight: 700,
  width: "100%",
  outline: "none",
}));

const CtaBackToLogin = styled.button`
  color: var(--brand-color-secondary);
  border: 1px solid var(--brand-color-secondary);
  width: 230px;
  border-radius: 23px;
  padding: 8px;
  font-size: 16px;
  margin: 25px auto;
  background: none;
  font-weight: 700;

  &[aria-disabled="true"] {
    color: #9fa0a0;
    border-color: #9fa0a0;
  }
`;

export const GoBackBtn = styled.button({
  color: colors.brandColorSecondary,
  display: "inline-block",
  width: "fit-content",
  background: "none",
  fontWeight: "bold",
  border: "none",
  borderBottom: `2px solid${colors.brandColorSecondary}`,
  paddingBottom: 5,
});

const initialValues = {
  code_1: "",
  code_2: "",
  code_3: "",
  code_4: "",
};

const validationSchema = Yup.object({
  code_1: Yup.string().matches(/^(\d)/).required("This field is required"),
  code_2: Yup.string().matches(/^(\d)/).required("This field is required"),
  code_3: Yup.string().matches(/^(\d)/).required("This field is required"),
  code_4: Yup.string().matches(/^(\d)/).required("This field is required"),
});

const emailTo = "support@franly.com";
const subject = "Phone number update request";
const emailBody =
  "Please enter below this line the update you are requesting." +
  "%0D%0A%0D%0A" +
  "[New Phone Number]";

const api = new HttpProvider();

/**
 * [X] - resend code
 * [X] - disable submit if not valid
 * [] - paste first four characters --optional
 * [X] - loading submit
 * [] - tfa=1 si hay que redirigir a code
 */

export interface UserProp {
  username: string;
  password: string;
  phone: string;
}

const TwoFAValidCode: React.FC<object> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data = useLocation().state as UserProp;

  const [isTimerActive, setIsTimerActive] = useState(true);

  const codeInput1Ref = useRef<HTMLInputElement>(null);
  const codeInput2Ref = useRef<HTMLInputElement>(null);
  const codeInput3Ref = useRef<HTMLInputElement>(null);
  const codeInput4Ref = useRef<HTMLInputElement>(null);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    // Enfocar el siguiente input después de completar el actual
    switch (name) {
      case "code_1":
        if (value.length === 1) {
          codeInput2Ref.current?.focus();
        }
        break;
      case "code_2":
        if (value.length === 1) {
          codeInput3Ref.current?.focus();
        } else {
          codeInput1Ref.current?.focus();
        }
        break;
      case "code_3":
        if (value.length === 1) {
          codeInput4Ref.current?.focus();
        } else {
          codeInput2Ref.current?.focus();
        }
        break;
      case "code_4":
        if (value.length === 0) {
          codeInput3Ref.current?.focus();
        }
        break;
      default:
        break;
    }
  };

  const resendCode = async () => {
    const params = {
      username: data.username,
      password: data.password,
      phone: data.phone,
    };

    try {
      await api.request("post", "api/auth/phone/verification", params);
      createToast("Code sent successfully", "success", dispatch);
      setIsTimerActive(true);
    } catch (error) {
      createToast("Unexpected error", "danger", dispatch);
    }
  };

  const handleSubmit = async (values: any) => {
    const body = {
      username: data.username,
      code: `${values.code_1}${values.code_2}${values.code_3}${values.code_4}`,
      phone: data.phone[0] !== "*" ? data.phone : undefined,
    };

    try {
      await api.request("post", "api/auth/user/2fa-validate", body);
    } catch (error) {
      createToast("Code does not match", "danger", dispatch);
      return;
    }

    createToast("Correct verification code", "success", dispatch);
    localStorage.removeItem("has_phone_number");
    navigate("/dashboard", { replace: true });
  };

  return (
    <FormView
      renderHeader={<FormHeader title="" subtitle="Enter Verification Code" />}
      renderFooter={
        <ValidateTwoFALinks
          isTimerActive={isTimerActive}
          setIsTimerActive={setIsTimerActive}
          resendCode={resendCode}
          emailTo={emailTo}
          subject={subject}
          emailBody={emailBody}
        />
      }
    >
      <FormCaption
        content={
          <span>
            We’ve sent a code to <b>****{data.phone.slice(-4)}</b>:
          </span>
        }
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, isSubmitting, isValid, setFieldValue, values }) => {
          return (
            <Form
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <div className="d-flex flex-column align-items-center">
                <div className="d-flex gap-1 gap-md-3 mb-2 mt-3">
                  <CodeInputContainer $error={!!Object.values(errors).length}>
                    <CodeInput
                      $value={values.code_1}
                      autoComplete="off"
                      ref={codeInput1Ref}
                      name="code_1"
                      maxLength={1}
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue("code_1", e.target.value);
                      }}
                      className="o-cl-grey-100"
                    />
                  </CodeInputContainer>
                  <CodeInputContainer $error={!!Object.values(errors).length}>
                    <CodeInput
                      $value={values.code_2}
                      autoComplete="off"
                      ref={codeInput2Ref}
                      name="code_2"
                      maxLength={1}
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue("code_2", e.target.value);
                      }}
                      className="o-cl-grey-100"
                    />
                  </CodeInputContainer>
                  <CodeInputContainer $error={!!Object.values(errors).length}>
                    <CodeInput
                      $value={values.code_3}
                      autoComplete="off"
                      ref={codeInput3Ref}
                      name="code_3"
                      maxLength={1}
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue("code_3", e.target.value);
                      }}
                      className="o-cl-grey-100"
                    />
                  </CodeInputContainer>
                  <CodeInputContainer $error={!!Object.values(errors).length}>
                    <CodeInput
                      $value={values.code_4}
                      autoComplete="off"
                      ref={codeInput4Ref}
                      name="code_4"
                      maxLength={1}
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue("code_4", e.target.value);
                      }}
                      className="o-cl-grey-100"
                    />
                  </CodeInputContainer>
                </div>
                {Object.values(errors).length > 0 && (
                  <p className="o-ft-sm-400 o-cl-red mt-3">Wrong Code</p>
                )}
              </div>

              <div className="d-flex justify-content-center flex-column">
                <CtaBackToLogin
                  aria-disabled={!isValid || isSubmitting}
                  disabled={!isValid || isSubmitting}
                  type="submit"
                >
                  {isSubmitting ? "Sending" : "Submit"}
                </CtaBackToLogin>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="d-flex justify-content-center">
        <GoBackBtn
          onClick={() =>
            navigate("/external/create-2fa", {
              replace: true,
              state: {
                email: data.username,
                password: data.password,
              },
            })
          }
        >
          Go Back
        </GoBackBtn>
      </div>
    </FormView>
  );
};

export default TwoFAValidCode;
