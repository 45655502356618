import AdminResultsHome from "../../sections/AdminResults/AdminResults";
import DocumentsProvider from "../../providers/DocumentsProvider";
import React from "react";
import ToastsProvider from "../../providers/ToastsProvider";
import PinUnpinProvider from "../../providers/PinUnpinProvider";

const AdminResultsView = () => {
  return (
    <PinUnpinProvider>
      <ToastsProvider>
        <DocumentsProvider>
          <AdminResultsHome />
        </DocumentsProvider>
      </ToastsProvider>
    </PinUnpinProvider>
  );
};

export default AdminResultsView;
