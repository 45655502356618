import { createJSONStorage, persist } from "zustand/middleware";

import { create } from "zustand";

interface DocumentProps {
  lastFile: string | null;
}

const initialState = {
  lastFile: null,
};

type DocumentActions = {
  setLastFile: (lastFile: string | null) => void;

  reset: () => void;
};

export const useDocumentSlice = create<DocumentProps & DocumentActions>()(
  persist(
    (set) => ({
      ...initialState,
      setLastFile: (lastFile: string | null) => set({ lastFile }),
      reset: () => set(initialState),
    }),
    {
      name: "documents-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    },
  ),
);
