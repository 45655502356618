import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import * as Yup from 'yup';
// import { Form as FormikForm, Formik } from 'formik';
// import AuthController from '@controllers/AuthController';
import FormLinks from '@components/FormLinks';
import FormView from '@components/FormView';
import Button from '@components/Button';
import FormHeader from '@components/FormHeader';
import TextLink from '@components/TextLink';
import { useIubenda } from '@hooks/useIubenda';
import { setLoader } from '@redux/loader/loader.slice';
import useAppDispatch from '@hooks/useAppDispatch';

const Login: React.FC = () => {
  useIubenda()

  const navigate = useNavigate();
  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()

  const onExternalLoginClick = () => {
    navigate('/external')
  }

  const onTraditionalLoginClick = () => {
    if (process.env.REACT_APP_AUTH_PROVIDER) {
      navigate('/login/' + process.env.REACT_APP_AUTH_PROVIDER)
    } else {
      navigate('/traditional')
    }
  }

  // const onError = (error: any) => {
  //   setIsLoading(false);
  //   setErrorNotification(true);
  // };

  // const saveToken = (key: string, value: string) => localStorage.setItem(key, value);

  // const onSuccess = (data: any) => {
  //   setIsLoading(false);
  //   saveToken('access_token', data.access_token);
  //   saveToken('refresh_token', data.refresh_token);
  //   navigate('/dashboard', { replace: true });
  // }

  // const onSubmit = (values: ILoginForm) => {
  //   navigate('enter-code')
  // authController.auth(onSuccess, onError, values);
  // }

  // const validateFormHasEmptyFields = (values: ILoginForm) => {
  //   const { email, password } = values;
  //   if (!isEmpty(email) && !isEmpty(password))
  //     return false;
  //   else
  //     return true;
  // }

  useEffect(() => {
    const origin = searchParams.get('origin') || localStorage.getItem('origin')
    const access_token = localStorage.getItem('access_token')

    if (origin === 'manage' && !access_token) {
      localStorage.setItem('origin', origin)
    }

    if (origin === 'manage' && searchParams.get('access_token')) {
      localStorage.setItem('access_token', searchParams.get('access_token')!)
      window.location.href = `${process.env.REACT_APP_BACK_BASE_URL}auth/login-from-platform?access_token=${searchParams.get('access_token')}`;
      localStorage.removeItem('origin')
      dispatch(setLoader(true))
    }

    if (!origin && searchParams.get('access_token')) {
      localStorage.setItem('access_token', searchParams.get('access_token')!)
      navigate('/dashboard')
    }

    if (origin === 'manage' && access_token) {
      localStorage.removeItem('origin')
      localStorage.setItem('access_token', access_token)
      window.location.href = `${process.env.REACT_APP_BACK_BASE_URL}auth/login-from-platform?access_token=${access_token}`;
      dispatch(setLoader(true))
    }
  }, [navigate, searchParams, dispatch])

  return (
    <FormView
      renderHeader={<FormHeader title="Welcome" />}
      renderFooter={<FormLinks hideLink />}
    >
      <div className='mb-5 mt-4'>
        <div className="mb-2 d-flex flex-grow-1 justify-content-center ">
          <Button
            onClick={onTraditionalLoginClick}
            size="big"
            typeStyle="block"
            value="Employee Login"
          />
        </div>
        <div className="d-flex flex-row justify-content-center">
          <span className="c-form-links__bottom-label o-base-lg-400">Need Access?</span>
          <TextLink text="Request it here" type="href" route="https://inspirebrands.identitynow.com/ui/d/request-center/request-access" smallFontSize />
        </div>
      </div>

      <div className='mb-5 mt-2'>
        <div className="mb-2 d-flex flex-grow-1 justify-content-center ">
          <Button
            onClick={onExternalLoginClick}
            size="big"
            typeStyle="block"
            value="External Login"
          />
        </div>

        <div className="d-flex flex-row justify-content-center">
          <span className="c-form-links__bottom-label o-base--400">Need Access?</span>
          <TextLink text="Request it here" type="route" route="/request-access" smallFontSize />
        </div>
      </div>

    </FormView>
  );
}

export default Login
