import { useParams } from "react-router-dom";

import Grid from "@components/Grid";
import { IAction } from "@models/Action.type";
import { IFile } from "@models/files/File.type";
import { IRow } from "@models/Table.type";
import { Spinner } from "react-activity";
import { TIcon } from "@components/MaterialIcon";
import _ from "lodash";
import { colors } from "@theme/colors";
import { createToast } from "@helpers/createToast";
import { trackInteractions } from "@redux/files/thunks/fileThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { useHandleFavorite } from "@hooks/useHandleFavorite";
import { useShowDocument } from "@hooks/fetchs/useShowDocument";
import useWindowSize from "@hooks/useWindowSize";
import React from "react";
import PinModal from "@components/PinModal";
import { usePinUnpinContext } from "../../providers/PinUnpinProvider";
import { useGetDocumentsByVault } from "@redux/documents/api";

const VaultsSection = () => {
  const { id } = useParams<{ id: string }>();
  const { isOpenPinModal, setIsOpenPinModal } = usePinUnpinContext();
  const { selectedFiles } = useAppSelector((state) => state.file);
  const { saved: favorites } = useAppSelector((state) => state.favorites);
  const { data: documents, isFetching } = useGetDocumentsByVault({
    id: Number(id),
  });
  const dispatch = useAppDispatch();

  const { getData } = useShowDocument();

  const { handleFavorite } = useHandleFavorite();

  const fileDownloadIcon: TIcon = "download";

  const { responsive } = useWindowSize();

  const handleDownload = (objectId: string) => {
    getData(objectId).then((e) => {
      const { url } = e.data.file;
      const { name, type } = e.data;

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const objectUrl = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = objectUrl;
          link.download = `${name}.${type}`;
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(objectUrl);
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
        });
    });
  };

  const actionList: IAction[] = [
    {
      id: "favorite",
      icon: "favorite" as TIcon,
      text: "",
      type: "favorite",
      onClick: (file: IFile) => {
        handleFavorite(file);
      },
    },
    {
      id: "copy-link",
      icon: "file_copy" as TIcon,
      text: "Copy Link",
      onClick: (file: IFile) => {
        const selectId = file?.id;
        if (selectId) {
          navigator.clipboard.writeText(
            `${window.location.origin}/document/${selectId}`,
          );
          createToast("Copied", "success", dispatch);
        }
        dispatch(trackInteractions({ data: [file.id], type: "share" }));
      },
    },
    {
      id: "download",
      icon: fileDownloadIcon,
      text: "Download",
      onClick: (file: IFile) => {
        const selectId = file?.id;
        handleDownload(`${selectId}`);
      },
    },
  ];

  return (
    <div>
      <PinModal pinModal={isOpenPinModal} setPinModal={setIsOpenPinModal} />
      {isFetching ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner
            className="v-contacts__spinner"
            color={colors.brandColorSecondary}
          />
        </div>
      ) : (
        <Grid
          data={documents.data ?? []}
          layout="c-grid--sm-layout"
          context="vaults"
          {...{ actionList, favorites }}
          dynamicCardClassName={({ id: cardId, type }: IRow) => {
            let className = "o-bg-transparent";
            if (responsive.md && type) {
              className = "";
              if (
                selectedFiles.some((selectedFile) =>
                  _.isEqual(selectedFile.id, cardId),
                )
              ) {
                className = "c-grid__child--selected o-bg-grey-300";
              }
            }
            return className;
          }}
        />
      )}
    </div>
  );
};

export default VaultsSection;
