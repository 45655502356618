/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { RefObject, useState } from "react";

import ActionModal from "./ActionModal";
import AddFolderModal from "./AddFolderModal";
import AdminHeaderModule from "./AdminHeaderModule";
import AdminSearchBar from "./AdminSearchBar";
import Button from "@components/Button";
import Chip from "./Chip";
import DotsMenu from "./DotsMenu";
import FileUpload from "@components/FileUpload";
import FolderFilter from "./filters/FolderFilter";
import { ISortListItem } from "./filters/SortList";
import { Sort } from "./filters/Sort";
import SwitchMode from "@components/SwitchMode";
import _ from "lodash";
import classNames from "classnames";
import { deleteFiles } from "@redux/files/thunks/fileThunk";
import { downloadFiles } from "@helpers/downloadFiles";
import { formatCapitalizeFirstLetter } from "@helpers/formatCapitalizeFirstLetter";
import { setFilters } from "@redux/files/slices/fileSlice";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { useLocation } from "react-router-dom";
import useWindowSize from "@hooks/useWindowSize";

interface IOrderingProps {
  orderFilter: number | null;
  orderFilterName: string | null;
  orderingFunction?: (obj: any) => void;
}
interface IAdminHeaderProps {
  title?: string;
  folder?: any;
  actionList?: any;
  handleFileLoad?: (e: React.ChangeEvent) => void;
  uploadButtonRef?: RefObject<HTMLInputElement | null>;
  ordering: IOrderingProps;
  handleUploadFolder?: (e: React.ChangeEvent) => void;
}

interface IUploadFileProps {
  downloading?: boolean;
  handleFileLoad: (e: React.ChangeEvent) => void;
  uploadButtonRef?: RefObject<HTMLInputElement | null>;
  size?: "small" | "medium" | "medium-big" | "big";
  hasIcon?: boolean;
  value?: string;
  className?: string;
}

export const UploadFile = ({
  downloading,
  handleFileLoad,
  uploadButtonRef,
  size,
  hasIcon,
  value,
  className,
}: IUploadFileProps) => {
  return (
    <FileUpload
      disabled={downloading}
      className={`me-2 ${className}`}
      handleFileDocument={handleFileLoad}
      icon={{
        icon: "upload",
        size: 18,
      }}
      size={size}
      hasIcon={hasIcon}
      id="add-file-header"
      ref={uploadButtonRef}
      value={value as any}
    />
  );
};

const AdminHeader = ({
  handleFileLoad,
  handleUploadFolder,
  uploadButtonRef,
  actionList,
  folder,
  title,
  ordering: { orderFilter, orderFilterName, orderingFunction },
}: IAdminHeaderProps) => {
  const { data: user } = useAppSelector((state) => state.user);
  const {
    toggle: { views },
  } = useAppSelector((state) => state);
  const {
    trash: { pending: trashPending },
  } = useAppSelector((state) => state);
  const { selectedFiles, data, pendingDelete, folderFilters, downloading } =
    useAppSelector((state) => state.file);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const { responsive } = useWindowSize();
  const adminListMode = views.admin.list;
  const parentId = data?.parent?.id;
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);

  const switchModeContainerClass = classNames({
    "me-2": !responsive.md,
  });

  const handleCreate = () => {
    setModalCreateVisible(true);
  };

  const handleDownload = () => {
    downloadFiles(selectedFiles, dispatch);
  };

  const handleDelete = async () => {
    await dispatch(deleteFiles(selectedFiles));
    setModalDeleteVisible(false);
  };

  const isFileManagement = () => {
    const inFileManagement =
      _.isEqual(location.pathname, "/admin/file-management") ||
      _.isEqual(location.pathname, "/admin/file-management/");
    const isResults =
      _.isEqual(location.pathname, "/admin/file-management/results") ||
      _.isEqual(location.pathname, "/admin/file-management/results/");
    if (!folder?.id && !(inFileManagement || isResults)) return false;
    return true;
  };

  const renderList = () =>
    adminListMode ? (
      // Re add class me-3 when uncommenting Order & Dots
      <SwitchMode module="admin">
        <div className={`ms-1 d-flex ${switchModeContainerClass}`}>
          <SwitchMode.Icon mode="grid" type="mobile" />
        </div>
      </SwitchMode>
    ) : (
      // Re add class me-3 when uncommenting Order & Dots
      <SwitchMode module="admin">
        <div className={`ms-1 d-flex ${switchModeContainerClass}`}>
          <SwitchMode.Icon mode="list" type="mobile" />
        </div>
      </SwitchMode>
    );

  return (
    <>
      <AddFolderModal
        folderId={data.id}
        isVisible={modalCreateVisible}
        setIsVisible={setModalCreateVisible}
      />
      <ActionModal
        isLoading={pendingDelete}
        show={modalDeleteVisible}
        title="Delete File"
        subtitle={
          <>
            Are you sure you want to delete{" "}
            <b>
              {selectedFiles.length}{" "}
              {selectedFiles.length > 1 ? "Files" : "File"}
            </b>{" "}
            from File Management?
          </>
        }
        buttonLeftText="Cancel"
        buttonRightText="Continue"
        onClickLeftButton={() => setModalDeleteVisible(false)}
        onClickRightButton={handleDelete}
      />
      <AdminHeaderModule {...{ actionList, folder, title }} />
      <div>
        {!responsive.md && isFileManagement() && (
          <div className="mb-3">
            <AdminSearchBar />
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-start w-100">
          {responsive.md && (
            <div className="me-2">
              <FolderFilter />
            </div>
          )}
          {handleFileLoad &&
            uploadButtonRef &&
            user?.permissions.Document?.includes("write") && (
              <UploadFile
                downloading={downloading}
                handleFileLoad={handleFileLoad}
                uploadButtonRef={uploadButtonRef}
              />
            )}
          {handleUploadFolder &&
            user?.permissions.Document?.includes("write") && (
              <FileUpload
                disabled={downloading}
                className="me-2"
                handleFileDocument={handleUploadFolder}
                icon={{
                  icon: "upload",
                  size: 18,
                }}
                id="add-folder-header"
                value="Upload Folder"
                webkitdirectory=""
              />
            )}
          {!_.isEqual(title, "Results") &&
            user?.permissions.Document?.includes("write") && (
              <Button
                onClick={handleCreate}
                className="ms-md-1"
                icon={{
                  icon: "folder_open",
                  size: 18,
                  position: "right",
                }}
                value="Add Folder"
                disabled={downloading}
              />
            )}
          {responsive.md && !_.isEmpty(selectedFiles) && (
            <div
              className={`d-flex flex-row ${user?.permissions.Document?.includes("write") ? "border-start border-dark ms-3" : ""} `}
            >
              <Button
                onClick={handleDownload}
                className={
                  user?.permissions.Document?.includes("write") ? "ms-3" : ""
                }
                icon={{
                  icon: "download",
                  size: 18,
                  position: "right",
                }}
                value="Download"
              />
              {user?.permissions.Document?.includes("write") && (
                <Button
                  onClick={() => setModalDeleteVisible(true)}
                  className="ms-3"
                  icon={{
                    icon: "delete",
                    size: 18,
                    position: "right",
                  }}
                  value="Delete"
                />
              )}
            </div>
          )}
        </div>

        <div className="d-flex align-items-center justify-content-end">
          <div className="d-flex align-items-center">
            {responsive.md ? (
              <SwitchMode module="admin">
                <div className="ms-2 me-2 d-flex">
                  <SwitchMode.Icon mode="grid" />
                </div>
                <div className="mx-1 d-flex">
                  <SwitchMode.Icon mode="list" />
                </div>
              </SwitchMode>
            ) : (
              renderList()
            )}
            {/* {responsive.md ? (
              <SwitchMode module="admin">
                <div className="ms-2 me-2 d-flex">
                  <SwitchMode.Icon mode="grid" />
                </div>
                <div className="mx-1 d-flex">
                  <SwitchMode.Icon mode="list" />
                </div>
              </SwitchMode>
            ) : adminListMode ? (
              // Re add class me-3 when uncommenting Order & Dots
              <SwitchMode module="admin">
                <div className={`ms-1 d-flex ${switchModeContainerClass}`}>
                  <SwitchMode.Icon mode="grid" type="mobile" />
                </div>
              </SwitchMode>
            ) : (
              // Re add class me-3 when uncommenting Order & Dots
              <SwitchMode module="admin">
                <div className={`ms-1 d-flex ${switchModeContainerClass}`}>
                  <SwitchMode.Icon mode="list" type="mobile" />
                </div>
              </SwitchMode>
            )} */}
            {orderingFunction && (
              <div className="position-relative mx-md-2">
                <Sort
                  collapsible
                  sort={{ id: orderFilter, name: orderFilterName }}
                >
                  <Sort.DropdownList
                    onChange={({ sortId, label }: ISortListItem) =>
                      orderingFunction({ orderingId: sortId, parentId, label })
                    }
                  />
                </Sort>
              </div>
            )}
            {/* <div className='position-relative mx-md-2'>
              <Order collapsable={true} />
            </div> */}
            {actionList && (
              <div className="d-none d-md-block">
                <DotsMenu
                  pending={trashPending}
                  dotsDirection="vertical"
                  actionList={actionList}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {!responsive.md && (
        <div className="mt-4">
          <FolderFilter />

          <div className="d-flex gap-2 mt-4">
            {folderFilters.map((filter) => (
              <Chip
                key={filter}
                name={formatCapitalizeFirstLetter(filter)}
                onClose={() =>
                  dispatch(
                    setFilters(folderFilters.filter((f) => f !== filter)),
                  )
                }
                size="small"
              />
            ))}
            {folderFilters.length > 0 && (
              <Chip
                name="Clear All"
                onClose={() => dispatch(setFilters([]))}
                size="small"
                main
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default AdminHeader;
