/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import Table from "@components/Table";
import { colors } from "@theme/colors";
import "react-activity/dist/library.css";
import { Spinner } from "react-activity";
import { IRow } from "@models/Table.type";
import FileName from "@components/FileName";
import { IFile } from "@models/files/File.type";
import { TIcon } from "@components/MaterialIcon";
import { formatHour } from "@helpers/formatHour";
import useWindowSize from "@hooks/useWindowSize";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { formatWeight } from "@helpers/formatWeight";
import ActionsColumn from "@components/ActionsColumn";
import { formatDate } from "@helpers/formatDate";
import { getTrash } from "../../redux/trash/thunks/trashThunk";
import AdminHeaderModule from "@components/AdminHeaderModule";
import { RestoreModal } from "@components/modals/RestoreModal";
import { DeleteFilePermanentlyModal } from "@components/modals/DeleteFilePermanentlyModal";
import { setShowDragDropExistentFileModal } from "@redux/files/slices/fileSlice";
import { ExistentFileModal } from "@components/modals/ExistentFileModal";
import { TRadioButton } from "@components/RadioButton";
import { restoreFile } from "@redux/trash/thunks/trashThunk";

interface ITrashProps {}

const deleteIcon: TIcon = "delete";
const restoreIcon: TIcon = "restore";

const TrashSection: React.FC<ITrashProps> = () => {
  const dispatch = useAppDispatch();
  const { responsive } = useWindowSize();
  const {
    trash: { pending, data },
    file: { showDragDropExistentFileModal, existentFileModalData },
  } = useAppSelector((state) => state);

  const [selectedFile, setSelectedFile] = useState<IFile>();
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [restoreModalVisible, setRestoreModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(getTrash());
  }, []);

  const actionList = [
    {
      id: "restore",
      icon: restoreIcon,
      text: "Restore",
      onClick: (file: IFile) => {
        setSelectedFile(file);
        if (!_.isNull(file.parent_id))
          localStorage.setItem(
            "parent_id_from_trash",
            file?.parent_id?.toString(),
          );
        else localStorage.setItem("parent_id_from_trash", "");
        setRestoreModalVisible(true);
      },
    },
    {
      id: "delete_permanently",
      icon: deleteIcon,
      text: "Delete",
      onClick: (file: IFile) => {
        setSelectedFile(file);
        setDeleteModalVisible(true);
      },
    },
  ];

  const columns = [
    {
      header: "",
      id: "checkbox",
      colspan: { xs: "1" },
      field: "",
      fixedWidth: { xs: "42px" },
    },
    {
      header: "Name",
      id: "name",
      colspan: { xs: "3" },
      field: "name",
      body: (file: IRow) => {
        return <FileName name={file.name} type={file.type} />;
      },
    },

    {
      header: "Date",
      id: "created_at",
      colspan: { xs: "1" },
      field: "created_at",
      parsedString: ({ deleted_at }: IRow) => formatDate(deleted_at),
      fixedWidth: { xs: "100px", sm: "unset" },
    },
    {
      header: "Hour",
      id: "hour",
      colspan: { xs: "1" },
      field: "hour",
      parsedString: ({ deleted_at }: IRow) => formatHour(deleted_at),
      collapsable: true,
    },
    {
      header: "Type",
      id: "type",
      colspan: { xs: "1" },
      field: "type",
      parsedString: ({ type }: IRow) => (type ? `.${type}` : "folder"),
      collapsable: true,
    },
    {
      header: "Deleted by",
      id: "deleted_by",
      colspan: { xs: "1" },
      field: "deleted_by",
      collapsable: true,
    },
    {
      header: "Original Location",
      id: "original_location",
      colspan: { xs: "1" },
      field: "original_location",
      parsedString: ({ parent_name }: IRow) => parent_name || "File Management",
      collapsable: true,
    },
    {
      header: "Size",
      id: "size",
      colspan: { xs: "1" },
      field: "size",
      parsedString: ({ size }: IRow) => formatWeight(size, true),
      collapsable: true,
    },
    {
      header: "Actions",
      id: "actions",
      colspan: { xs: "1" },
      field: "",
      body: (file: IRow) => (
        <ActionsColumn
          {...{ file, actionList }}
          dotsShowLength={4}
          context="trash"
        />
      ),
      hiddenMobileTitle: true,
    },
  ];

  const onSubmitExistentFileModal = (opt: TRadioButton) => {
    if (_.isEqual(existentFileModalData.action, "restore"))
      dispatch(
        restoreFile({ ...existentFileModalData.parameters, action: opt.name }),
      );
  };

  return (
    <>
      <ExistentFileModal
        title={existentFileModalData.title || ""}
        onFinish={() => {
          dispatch(setShowDragDropExistentFileModal(false));
        }}
        onSubmit={onSubmitExistentFileModal}
        onCancel={() => {}}
        isVisible={showDragDropExistentFileModal}
      />
      <RestoreModal
        file={selectedFile}
        isVisible={restoreModalVisible}
        setVisible={setRestoreModalVisible}
      />
      <DeleteFilePermanentlyModal
        file={selectedFile}
        isVisible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
      />
      <div className="o-admin-screen-container w-100">
        <div className="row">
          <div className="col-12">
            {data && <AdminHeaderModule folder={data} />}
            <span>
              Elements will be permanently deleted from trash after 30 days
            </span>
            <div style={{ paddingTop: 50, paddingBottom: 100 }}>
              {pending ? (
                <div className="d-flex justify-content-center mt-5">
                  <Spinner
                    className="o-ft-xl o-ft-3xl@md"
                    color={colors.brandColorSecondary}
                  />
                </div>
              ) : (
                <Table
                  data={data?.children ? data?.children : []}
                  {...{ columns }}
                  extraClassName={`${responsive.md && "c-table--selectable mt-md-3"}`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrashSection;
