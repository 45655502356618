import { useEffect, useState } from 'react';
import _ from 'lodash';
import useWindowSize from '@hooks/useWindowSize';
import useAppDispatch from '@hooks/useAppDispatch';
import { useHighLight } from '@hooks/useHighlight';
import { setSideBar } from '@redux/slices/toggleSlice';
import OnboardingModal from '@components/OnboardingModal';
import OnboardingTooltip from '@components/OnboardingTooltip';
import { updateLoggedUser } from '@redux/users/thunks/userThunk';
import { setOnboarding } from '@redux/users/slices/userSlice';
import useAppSelector from '@hooks/useAppSelector';

const Onboarding = () => {
  const { responsive } = useWindowSize();

  const dispatch = useAppDispatch();

  const [state, setState] = useState<number>(0)
  const [sidebar, setSidebar] = useState<HTMLElement | null>()
  const [appContainer, setAppContainer] = useState<HTMLElement | null>()
  const [dashboardScreen, setDashboardScreen] = useState<HTMLElement | null>()

  const { removeHighlightElement } = useHighLight();
  const { sidebarShow } = useAppSelector((state) => state.toggle);
  const { data } = useAppSelector((state) => state.user);

  useEffect(() => {
    const sidebarElement = document.getElementById("sidebar");
    const appContainerElement = document.getElementById("appContainer");
    const dashboardScreenElement = document.getElementById("dashboardScreen");

    sidebarElement?.classList.remove("vh-100");
    appContainerElement?.classList.remove("position-fixed");

    if (dashboardScreenElement && !!data?.onboarding) {
      dashboardScreenElement.style.height = "unset"
    }

    setSidebar(sidebarElement)
    setAppContainer(appContainerElement)
    setDashboardScreen(dashboardScreenElement)
  }, [])

  useEffect(() => {
    (!responsive.md && sidebarShow) && dispatch(setSideBar(false));
    (responsive.md && !sidebarShow) && dispatch(setSideBar(true));
  }, [responsive, dispatch])

  const onHandleBack = () => {
    setState((prevState: number) => prevState - 1)
  }

  const onHandleNext = () => {
    if (_.isEqual(state, 5))
      quitOnboarding()
    else
      setState((prevState: number) => prevState + 1)
  }

  const onHandleSkip = () => {
    setState(-1)
    removeHighlightElement("lastEditedOnboarding");
    blockFullScreenScroll()
    localStorage.setItem("onboarding", "true")
    dispatch(setOnboarding(true))
  }

  const quitOnboarding = () => {
    setState(-1)
    removeHighlightElement("lastEditedOnboarding");
    blockFullScreenScroll()
    let user = {
      ...data,
      onboarding: "1"
    }
    dispatch(updateLoggedUser({ user }));
  }

  const blockFullScreenScroll = () => {
    window.scrollTo(0, 0)
    setTimeout(() => {
      sidebar?.classList.add("vh-100");
      appContainer?.classList.add("position-fixed");
      dashboardScreen?.style.removeProperty("height");
    }, 500);
  }

  const OnboardingSteps = () => {
    switch (state) {
      case 1:
        return <OnboardingTooltip  {...{ state, onHandleSkip, onHandleBack, onHandleNext }} className='c-onboarding-tooltip--search-position ' title='Search' description='Search for any files or contacts inside the platform.' button='Next' arrowMobile='top-right' arrowDesktop='top-left' btnBack={false} />
      case 2:
        return <OnboardingTooltip {...{ state, onHandleSkip, onHandleBack, onHandleNext }} className='c-onboarding-tooltip--favorites-position ' title='Favorites' description='You can favorite any files and access them directly through here.' button='Next' arrowMobile='top-right' arrowDesktop='top-left' btnBack />
      case 3:
        return <OnboardingTooltip {...{ state, onHandleSkip, onHandleBack, onHandleNext }} className='c-onboarding-tooltip--dashboard-position ' title='Dashboard' description='You are able to customize your dashboard and pin files and folders so you can have the most important items in one place at one click.
' button='Next' arrowMobile='bottom' arrowDesktop='top-left' btnBack />
      case 4:
        if (responsive.md)
          return <OnboardingTooltip {...{ state, onHandleSkip, onHandleBack, onHandleNext }} className='c-onboarding-tooltip--sidebar-position ' title='Sidebar' description='Sidebar will show all the menu options inside the platform. You can collapse or expand this sidebar at any time.' button='Next' arrowDesktop='left' btnBack />
        else
          return <OnboardingTooltip {...{ state, onHandleSkip, onHandleBack, onHandleNext }} className='c-onboarding-tooltip--menu-position ' title='Menu' description='In this menu you will have all the different sections inside the platform.' button='Next' arrowMobile='top-left' btnBack />
      case 5:
        return <OnboardingTooltip {...{ state, onHandleSkip, onHandleBack, onHandleNext }} className='c-onboarding-tooltip--latest-files-position ' title='Latest Files' description='View all the latest file editions and additions so you are always working with latest versions.' button='Got it!' arrowMobile='bottom' arrowDesktop='top-left' btnBack />
      case 6:
        return <OnboardingTooltip {...{ state, onHandleSkip, onHandleBack, onHandleNext }} className='c-onboarding-tooltip--latest-files-position ' title='Latest Files' description='View all the latest file editions and additions so you are always working with latest versions.' button='Got it!' arrowMobile='bottom' arrowDesktop='top-left' btnBack />
      default:
        return <></>
    }
  }

  return (
    <>
      <OnboardingModal onClick={onHandleNext} title={<>Welcome!</>} subtitle='It takes just a minute to learn the basics to start using this platform. Check it out!' />
      {state && <OnboardingSteps />}
    </>
  );
};

export default Onboarding;
