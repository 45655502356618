import ActionModal from "@components/ActionModal";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { deleteFiles } from "@redux/files/thunks/fileThunk";

interface IDeleteModalProps {
  file: any;
  isVisible: boolean
  setVisible: (value: boolean) => void
}

export const DeleteFileModal = ({ isVisible, file, setVisible }: IDeleteModalProps) => {

  const dispatch = useAppDispatch();
  const { file: { pendingDelete } } = useAppSelector((state) => state);
  const nameFileShortened = file?.name.length > 100 ? file?.name.substring(0, 60).concat('...') : file?.name;
  return (
    <ActionModal
      isLoading={pendingDelete}
      show={isVisible}
      title='Delete File'
      subtitle={<>Are you sure you want to delete "<b>{nameFileShortened}</b>" from File Management?</>}
      buttonLeftText="Cancel"
      buttonRightText="Continue"
      onClickLeftButton={() => setVisible(false)}
      onClickRightButton={() => file && dispatch(deleteFiles([file])).then(() => setVisible(false))}
    />
  )
};
