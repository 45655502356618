/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from "../../lib/api";
import { getCookie } from "@api/AdminHttpProvider";
import { useState } from "react";

const baseURL = process.env.REACT_APP_BACK_BASE_URL;

export const useShowDocument = () => {
  const data: any[] = [];
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

  const getData = async (id: string | number, isDonwload = false) => {
    if (isDonwload) {
      setLoadingDownload(true);
    }
    if (!isDonwload) {
      setLoading(true);
    }

    const token =
      localStorage.getItem("current_website") === "admin"
        ? getCookie("access_token")
        : localStorage.getItem("access_token");

    try {
      const response = await api.get(
        localStorage.getItem("current_website") === "admin"
          ? `${baseURL}api/admin/documents/${id}`
          : `${baseURL}api/documents/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return { status: response.status, data: { ...response.data.data } };
    } catch (error: any) {
      return { status: error.response.status };
    } finally {
      if (isDonwload) {
        setLoadingDownload(false);
      }
      if (!isDonwload) {
        setLoading(false);
      }
    }
  };

  return { loading, data, getData, loadingDownload };
};
