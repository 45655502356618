import MaterialIcon, { TIcon } from "@components/MaterialIcon";
import React, { useState, useId, useEffect } from "react";

import Button from "./Button";
import DotsMenu from "./DotsMenu";
import { IAction } from "@models/Action.type";
import { TMenuSection } from "../app/types/section/MenuSection.type";
import { images } from "@theme/images";
import { truncateTextToFit } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import useWindowSize from "@hooks/useWindowSize";

interface IProcessCardProps {
  icon?: TIcon;
  title: string;
  description?: string;
  onClick: () => void;
  children?: TMenuSection[] | null;
  background: string;
  element?: TMenuSection;
  actionList?: IAction[];
}

export const ProcessCard = ({
  title,
  description,
  children,
  icon,
  onClick,
  actionList,
  element,
  background,
}: IProcessCardProps) => {
  const navigate = useNavigate();
  const { responsive, breakpoint } = useWindowSize();
  const [hover, setHover] = useState<boolean>(false);
  const [truncatedDescription, setTruncatedDescription] = useState("");
  const defaultIcon: TIcon = "inventory_2";
  const cardId = useId();
  const viewMore: TMenuSection = {
    name: "View all",
    id: 0,
    icon: "" as TIcon,
    route: "",
    level: 0,
    position: 0,
    parent_id: 0,
    children: null,
    is_link: false,
    description: "",
    editable: false,
    sectionable_id: 0,
    is_bottom_link: false,
    background: "",
    pinned: null,
  };

  function onHandleNavigate(item: TMenuSection): void {
    if (item.route) navigate(`/${item.route}`);
    else onClick();
  }

  const createDashboardCards = () => {
    let subSections = 4;
    if (children) {
      if (!responsive.lg) subSections = 2;
      else if (!responsive.xl) subSections = 3;
      return children.length > subSections
        ? [...children.slice(0, subSections), viewMore]
        : children;
    }
    return [];
  };

  const getMaxHeight = () => {
    switch (breakpoint) {
      case "xxl":
        return 215;
      case "xl":
        return 220;
      case "md":
        return 210;
      case "sm":
        return 200;
      default:
        return 165;
    }
  };
  useEffect(() => {
    setTruncatedDescription(
      truncateTextToFit(
        description || "",
        70,
        getMaxHeight(),
        `card_${cardId}`,
      ),
    );
  }, [description, cardId]);

  return (
    <div className="c-process-card__container position-relative">
      <div
        aria-hidden
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          backgroundImage: `url(${background || images.sectionModuleBackground})`,
          backgroundSize: "cover",
        }}
        className="c-process-card__card d-flex justify-content-center o-cursor-pointer position-relative"
      >
        <div className="c-process-card__card-content d-flex align-items-center justify-content-center">
          <div className="c-process-card__card-description text-center">
            <div className="c-process-card__card-icon d-flex justify-content-center align-items-center mx-auto">
              <MaterialIcon
                className="c-process-card__hovered-icon"
                color="o-cl-white"
                icon={icon || defaultIcon}
                size={24}
              />
            </div>
            <div className="c-process-card__title-section">
              <p className="c-process-card__title o-ft-base-700 o-cl-white">
                {title}
              </p>
              <p
                className="o-cl-white c-process-card__description mt-2"
                id={`card_${cardId}`}
              >
                {truncatedDescription}
              </p>
            </div>
            {hover && responsive.md && (
              <div
                className={`${hover && "c-process-card__tab-section"} c-process-card__tab-section d-flex flex-wrap justify-content-center`}
              >
                {children &&
                  createDashboardCards().map((item) => (
                    <Button
                      key={item.id}
                      value={item.name}
                      className="o-cl-white c-process-card__button-tab m-1"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        onHandleNavigate(item);
                      }}
                      size="small"
                    />
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {actionList?.length ? (
        <div className="position-absolute top-0 end-0">
          <DotsMenu
            actionList={actionList}
            dotsDirection="vertical"
            context={element}
          />
        </div>
      ) : null}
    </div>
  );
};
