/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  createFolderFromDocumentsV2,
  getDocuments,
  showDocument,
  updateDocument,
  addFileFV2,
} from "./thunks/documentsThunk";

import { IDocument } from "@models/document/NewDocument.type";

interface IDocumentSlice {
  pending: boolean;
  pendingUpdating: boolean;
  data: IDocument[];
  current: IDocument | null;
}

const initialState: IDocumentSlice = {
  pending: false,
  pendingUpdating: false,
  data: [],
  current: null,
};

export const documents = createSlice({
  name: "documents",
  initialState,
  reducers: {
    restart: () => {
      return initialState;
    },
    setCurrentResource: (
      state,
      action: PayloadAction<{ data: IDocument | null }>,
    ) => {
      state.current = action.payload.data;
    },
    setDocumentData: (state, action: PayloadAction<{ data: IDocument[] }>) => {
      state.data = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getDocuments.fulfilled,
      (state, action: PayloadAction<{ data: IDocument[] }>) => {
        const { data } = action.payload;
        state.data = data;
        state.pending = false;
      },
    );
    builder.addCase(getDocuments.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(getDocuments.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(
      showDocument.fulfilled,
      (state, action: PayloadAction<{ data: IDocument }>) => {
        const { data } = action.payload;
        state.current = data;
        state.pending = false;
      },
    );
    builder.addCase(showDocument.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(showDocument.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(
      updateDocument.fulfilled,
      (state, action: PayloadAction<{ data: IDocument }>) => {
        const { data } = action.payload;
        state.current = data;
        state.pendingUpdating = false;
      },
    );
    builder.addCase(updateDocument.rejected, (state) => {
      state.pendingUpdating = false;
    });
    builder.addCase(updateDocument.pending, (state) => {
      state.pendingUpdating = true;
    });
    builder.addCase(
      createFolderFromDocumentsV2.fulfilled,
      (state, action: PayloadAction<IDocument>) => {
        state.data.push(action.payload);
        state.pending = false;
      },
    );
    builder.addCase(createFolderFromDocumentsV2.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(createFolderFromDocumentsV2.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(
      addFileFV2.fulfilled,
      (state, action: PayloadAction<IDocument>) => {
        state.data.push(action.payload);
        state.pending = false;
      },
    );
    builder.addCase(addFileFV2.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(addFileFV2.pending, (state) => {
      state.pending = true;
    });
  },
});

export const { restart, setCurrentResource } = documents.actions;
export default documents.reducer;
