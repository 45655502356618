import React from 'react'

type Props = {
  title: string;
  data?: string | number;
  relatedUsers?: Object[];
  dataTags?: boolean;
}

const InformationData: React.FC<Props> = ({ title, data, dataTags, relatedUsers }) => {
  return (
    <div className='c-store-information-data'>
      <p className='c-store-information-data__title o-ft-base-600 o-cl-dark-grey'>{title}</p>
      <div className='c-store-information-data__data'>
        {
          dataTags ?
            <div className='c-store-information-data__tags d-flex' >
              {
                relatedUsers?.map((relatedUser: any) => (
                  <span key={relatedUser.id} className='c-store-information-data__tag o-ft-sm mx-2'>{relatedUser.name}</span>
                ))}
            </div>
            :
            <p className='o-cl-grey-200 m-0'>{data || '-'}</p>
        }
      </div>
    </div >
  )
}

export default InformationData