import React from 'react';
import ContactTableRow from '@components/ContactTableRow';
import TContact from '@models/Contact.type';

interface IContactTableProps {
  data: TContact[];
}

const ContactTable = ({ data }: IContactTableProps) => {
  return (
    <table className='c-contact-table'>
      <thead>
        <tr className='c-contact-table__thead-row'>
          <th className='c-contact-table__title o-ft-base-400'>Name</th>
          <th className='c-contact-table__title o-ft-base-400 c-contact-table--collapsable'>Company</th>
          <th className='c-contact-table__title o-ft-base-400 c-contact-table--collapsable'>Type</th>
          <th className='c-contact-table__title o-ft-base-400 c-contact-table--collapsable'>Position</th>
          <th className='c-contact-table__title o-ft-base-400'></th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) =>
          <ContactTableRow key={item.id} item={item} />
        )}
      </tbody>
    </table>
  );
};

export default ContactTable;
