import { createToast } from "@helpers/createToast";
import useAppDispatch from "./useAppDispatch";

export interface IDownloadableFile {
  url: string;
  name: string; // name + type
}

export const useDownloadOriginalFiles = () => {
  const dispatch = useAppDispatch();

  const saveFileAs = (blob: Blob, filename: string) => {
    const element = document.createElement("a");
    element.href = URL.createObjectURL(blob);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  };

  // using an original file
  const downloadMultipleOriginalFiles = (files: Array<IDownloadableFile>) => {
    files.forEach((obj: IDownloadableFile, idx: number) => {
      fetch(obj.url)
        .then((res) => res.blob())
        .then((blob) => {
          saveFileAs(blob, obj.name);
          if (idx === 0) createToast("Downloaded", "success", dispatch);
        })
        .catch((error) => {
          console.error(error);
          const text =
            "Something went wrong while processing your request. Please try again.";
          createToast(text, "danger", dispatch);
        });
    });
  };

  return {
    downloadMultipleOriginalFiles,
  };
};
