import * as Yup from "yup";

import { ErrorMessage, Form, Formik } from "formik";
import { InputSubtitle, InputText } from "@views/WalkthroughSteps/styled-step1";
import { useLocation, useNavigate } from "react-router-dom";

import { AxiosError } from "axios";
import FormCaption from "@components/FormCaption";
import FormHeader from "@components/FormHeader";
import FormView from "@components/FormView";
import HttpProvider from "@api/HttpProvider";
import PhoneInput from "@components/PhoneInput/PhoneInput";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { createToast } from "@helpers/createToast";
import styled from "styled-components";
import useAppDispatch from "@hooks/useAppDispatch";

const api = new HttpProvider();

const validationSchema = Yup.object({
  phoneNumber: Yup.string()
    .required("The phone number is required.")
    .matches(/^[+0-9][0-9- ()_]{4,20}$/, "The field must contain only numbers.")
    .matches(
      /^([2-9]\d{2})(\d{3})(\d{4})$/,
      "Please enter a valid phone number.",
    ),
});

interface UserProp {
  email: string;
  password: string;
}

const CtaBackToLogin = styled.button`
  color: var(--brand-color-secondary);
  border: 1px solid var(--brand-color-secondary);
  width: 230px;
  border-radius: 23px;
  padding: 8px;
  font-size: 16px;
  margin: 25px auto;
  background: none;
  font-weight: 700;

  &[aria-disabled="true"] {
    color: #9fa0a0;
    border-color: #9fa0a0;
  }
`;

const CreateTwoFA: React.FC<object> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const data = useLocation().state as UserProp;
  const handleSubmit = async (values: any) => {
    const params = {
      username: data.email,
      password: data.password,
      phone: `1${values.phoneNumber}`,
    };

    try {
      await api.request("post", "api/auth/phone/verification", params);
      navigate("code", { replace: true, state: params });
    } catch (error) {
      if ((error as AxiosError).response?.status === 429) {
        createToast(
          "You've requested a code recently, please wait 2 minutes to try again.",
          "warning",
          dispatch,
        );
        return;
      }

      if ((error as AxiosError).response?.status === 409) {
        createToast("This phone number is already in use", "danger", dispatch);
        return;
      }

      createToast("Unexpected error", "danger", dispatch);
    }
  };

  const initialValues = {
    phoneNumber: "",
  };

  return (
    <FormView
      renderHeader={<FormHeader title="" subtitle="Two-Step Authentication" />}
    >
      <div className="px-4">
        <FormCaption content="Please add your phone number to setup 2FA and secure your account. You will receive a code to verify your account." />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {({ errors, touched, isValid, isSubmitting }) => (
            <Form
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <div className="d-flex gap-3 flex-column">
                <div>
                  <InputSubtitle
                    className="px-2"
                    $error={!!errors.phoneNumber && !!touched.phoneNumber}
                  >
                    Phone Number
                  </InputSubtitle>
                  <InputText $error={!!errors.phoneNumber}>
                    <PhoneInput
                      name="phoneNumber"
                      placeholder="000-0000"
                      error={!!errors.phoneNumber}
                      areaCode="1"
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      render={(error) => (
                        <div className="o-ft-xs-400 o-cl-red my-1 mx-2">
                          {error}
                        </div>
                      )}
                    />
                  </InputText>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4 o-ft-xs-400">
                By providing your mobile number, you agree to receive 2FA text
                messages from this platform.
              </div>
              <div className="d-flex justify-content-center mt-1 mb-5">
                <CtaBackToLogin
                  aria-disabled={!isValid || isSubmitting}
                  disabled={!isValid || isSubmitting}
                  type="submit"
                >
                  {isSubmitting ? "Sending Code" : "Send Code"}
                </CtaBackToLogin>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </FormView>
  );
};

export default CreateTwoFA;
