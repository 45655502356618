import SocialContactItem, { TSocialContact } from './SocialContactItem';
import _ from 'lodash';

interface IContactListProps {
  list: TSocialContact[],
  alignmentRight: boolean;
}

const ContactList = ({ list, alignmentRight }: IContactListProps) => {
  const alignment = alignmentRight && 'c-contact-list--aligned-right';

  return (
    <div className={`c-contact-list d-flex flex-grow-1 ${alignment}`}>
      {list?.map(({ icon, value }, index) =>
        (!_.isNull(value)) &&
        <SocialContactItem key={index} {...{ index, icon, value }} />
      )}
    </div>
  )
};

export default ContactList;
