/* eslint-disable @typescript-eslint/no-explicit-any */
import AdminHttpProvider from "@api/AdminHttpProvider";
import HttpProvider from "@api/HttpProvider";
import { whichProvider } from "@helpers/whichProvider";

const api = new HttpProvider();
const adminApi = new AdminHttpProvider();
export default class ProcessController {
  static index() {
    return api.request("get", "api/processes");
  }

  static getStepsByProcess(processId: number) {
    return api.request("get", `api/processes/${processId}/process-steps`);
  }

  static setStepStatus(process_step_id: number, status: number) {
    return whichProvider().request("post", `api/process-step-statuses`, {
      process_step_id,
      status,
    });
  }

  static getProcess(processId?: string) {
    return adminApi.request("get", `/api/v2/admin/processes/${processId}`);
  }

  static getAllProcessTags({
    perPage,
    queryParam,
  }: {
    perPage?: number;
    queryParam?: string;
  }) {
    return adminApi.request(
      "get",
      `/api/v2/admin/process-tags?perPage=${perPage}${queryParam}`,
    );
  }

  static getProcessTagsByProcessId(processId: string) {
    return adminApi.request(
      "get",
      `/api/v2/admin/processes/${processId}/process-tags`,
    );
  }

  static getProcessStepById(processId: string) {
    return adminApi.request(
      "get",
      `/api/v2/admin/processes/${processId}/process-steps?with=documents`,
    );
  }

  static getForms(formId?: string | number) {
    return adminApi.request(
      "get",
      `/api/v2/admin/forms${formId ? `/${formId}` : ""}`,
    );
  }

  static post(data: any) {
    return adminApi.request("post", "/api/v2/admin/processes", data);
  }

  static postTag(data: any) {
    return adminApi.request("post", "/api/v2/admin/process-tags", data);
  }

  static update({
    processId,
    data,
  }: {
    processId: string | number;
    data: any;
  }) {
    return adminApi.request(
      "put",
      `/api/v2/admin/processes/${processId}`,
      data,
    );
  }

  static postStep(data: any) {
    return adminApi.request("post", `/api/v2/admin/process-steps`, data);
  }

  static updateStep({ stepId, data }: { stepId: string; data: any }) {
    return adminApi.request(
      "put",
      `/api/v2/admin/process-steps/${stepId}`,
      data,
    );
  }

  static deleteStep(stepId: string | number) {
    return adminApi.request("delete", `/api/v2/admin/process-steps/${stepId}`);
  }
}
