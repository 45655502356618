import React from 'react'

import { TUser } from '@models/user/User.type';
import { Spinner } from 'react-activity';
import colors from '../../../../../../brand/theme/colors';
import Chip from '@components/Chip';
import defaultProfileImg from '../../../../../../assets/profile-img.png';

export type Comment = {
  id: number;
  comment: string;
  created_at: string;
  files?: File[];
  reason: 'approve' | 'reject' | null;
  process_step: {
    approval_required: boolean;
    content: any | null;
    files: File[];
    form: any | null;
    id: number;
    name: string | null;
    order: number;
  };
  user: TUser
}

type Props = {
  comments: Comment[]
  loading?: boolean;
}

type FileProps = {
  fileUrl: string;
  fileName: string
}

const File: React.FC<FileProps> = ({ fileUrl, fileName }) => {
  const handleClick = () => {
    window.open(fileUrl, '_blank', 'noreferrer')
  }

  return (
    <div onClick={handleClick} className='c-comments__file py-1 px-2' title='Preview file'>
      <span className='o-ft-xs-400' style={{ wordBreak: 'break-word' }}>{fileName}</span>
    </div>
  )
}

const Comments: React.FC<Props> = ({ comments, loading = false }) => {
  if (loading) {
    return (
      <div className='c-comments__container p-4 d-flex justify-content-center'>
        <Spinner size={18} color={colors.brandColorSecondary} />
      </div>
    )
  }

  if (comments.length === 0) {
    return (
      <div className='c-comments__container d-flex justify-content-between align-items-center w-100 p-4'>
        <div>
          <b>Comments</b>
        </div>
        <div>
          No comments yet
        </div>
      </div>
    )
  }
  const baseSrcUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL : ''

  return (
    <div className='c-comments__container d-flex flex-column w-100 p-3 p-md-4'>
      <div className='o-ft-lg-500'>Comments & Activities</div>

      <div className='d-flex flex-column w-100'>
        {(comments || []).map(comment => {

          const addZero = (num: number) => {
            return num < 10 ? '0' + num : num;
          }

          // Fecha en formato original
          const created_at = comment.created_at;

          // Crear un objeto de fecha a partir de la cadena original
          const date = new Date(created_at);

          // Obtener los componentes de la fecha
          const day = addZero(date.getUTCDate());
          const month = addZero(date.getUTCMonth() + 1); // Se suma 1 ya que los meses van de 0 a 11
          const year = date.getUTCFullYear();

          // Formatear la fecha como "dd/mm/yyyy"
          const formattedDate = day + '/' + month + '/' + year;

          return (
            <div key={comment.id} className='my-4'>

              <div className='d-flex gap-1 align-items-center'>
                <img className='c-comments__profile' src={comment.user.avatar_file?.url || baseSrcUrl + defaultProfileImg} alt="user-avatar" />
                {!!comment.reason && <span className='o-ft-xs-400 o-ft-sm-400@md'>{comment.reason === 'approve' ? 'Approved by' : 'Rejected by'}</span>}
                <span className='o-ft-xs-700 o-ft-sm-700@md'>{comment.user.first_name} {comment.user.last_name}</span>
                <span className='o-ft-xs-400 o-ft-sm-400@md'>on date</span>
                <span className='o-ft-xs-700 o-ft-sm-700@md'>{formattedDate}</span>
              </div>

              <div className='p-3 mt-2' style={{ backgroundColor: '#f9f9f9' }}>
                <div className='d-flex gap-1'>
                  <span className='o-ft-xs-700'>{comment.user.first_name} {comment.user.last_name}</span>
                  <span className='o-ft-xs-400'>has left a comment:</span>
                </div>
                <div className='mt-2'>
                  <span className='o-ft-sm-400'>{comment.comment}</span>

                  {comment.files?.length ? (
                    <div className='d-flex flex-wrap gap-2 mt-1'>
                      {comment.files.map((file) => (
                        <File
                          key={(file as any).id}
                          fileName={(file as any).original_name}
                          fileUrl={(file as any).url}
                        />
                      ))}
                    </div>
                  ) : <></>}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Comments
