/* eslint-disable @typescript-eslint/no-explicit-any */
import { colors } from "@theme/colors";

export const customStylesSelectStep = {
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused ? "none" : provided.boxShadow,
    borderColor: state.isFocused ? colors.brandColorPrimary : colors.grey100,
    "&:hover": {
      borderColor: state.isFocused ? colors.brandColorPrimary : colors.grey100,
    },
    paddingRight: "8px",
    minHeight: "45px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: state.isFocused ? "#C0F" : colors.grey100,
    padding: "0px 8px",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: "pointer",
    marginTop: "1px",
    padding: "0px",
    display: "flex",
    alignItems: "center",
    color: colors.grey100,
    backgroundColor: "transparent",
    fontWeight: state.isFocused ? "700" : provided.fontWeight,
    fontSize: "14px",
    fontFamily: '"Open Sans", sans-serif',
    fontStyle: "normal",
    lineHeight: "normal",
    width: "95%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    ":before": {
      content: '""',
      display: "inline-block",
      width: "4px",
      height: "23px",
      backgroundColor: state.isFocused
        ? colors.brandColorPrimary
        : "transparent",
      marginRight: "4px",
      marginLeft: "0",
      flexShrink: 0,
    },
  }),
  placeholder: (base: any) => ({
    ...base,
    color: colors.grey200,
    fontFamily: '"Open Sans", sans-serif',
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  }),
};
