import React from 'react';

import MaterialIcon from './MaterialIcon';
import useWindowSize from '@hooks/useWindowSize';
import TContact from '../app/types/Contact.type';
import { IFile } from '../app/types/files/File.type';
import profilePicture from '../assets/profile-img.png';


interface ISearchBarItemProps {
  item: TContact | IFile
}

const iconColors: any = {
  'xls': 'o-cl-green',
  'xlsx': 'o-cl-green',
  'pdf': 'o-cl-red',
  'doc': 'o-cl-blue',
  'docx': 'o-cl-blue'
}

const SearchBarItem = ({ item }: ISearchBarItemProps) => {
  const { responsive } = useWindowSize();

  const isFilled = !('type' in item && item.type)
  const img = ('avatar_file' in item && item.avatar_file?.url) || profilePicture;

  return (
    <div className='c-search-bar-result__file-result d-flex justify-content-center align-items-center'>
      <div className='c-search-bar-result__icon-container d-flex align-items-center justify-content-center'>
        {
          'contact_type_id' in item
            ? <img src={img} alt='' className='c-search-bar-result__img me-1' />
            : 'type' in item ? (
              <MaterialIcon
                className={`o-max-width ${isFilled && "material-symbols-filled"}`}
                size={responsive.lg ? 30 : 24}
                color={item.type ? (iconColors[item.type] || 'o-cl-brand-secondary') : 'o-cl-light-blue'}
                icon={item.type ? "insert_drive_file" : "folder"}
              />
            ) : 'store_type' in item ? (
              <MaterialIcon
                className={`o-max-width`}
                size={responsive.lg ? 30 : 24}
                color="o-cl-grey-200"
                icon="storefront"
              />
            ) : 'project_type' in item && (
              <MaterialIcon
                className={`o-max-width`}
                size={responsive.lg ? 30 : 24}
                color="o-cl-grey-200"
                icon="content_paste"
              />
            )
        }
      </div>
      <span className='c-search-bar-result__name ps-2 w-100'>{item.name}</span>
    </div>
  );
};

export default SearchBarItem;
