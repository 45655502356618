import { IFile } from "@models/files/File.type";
import { TIcon } from "@components/MaterialIcon";

const editIcon: TIcon = "edit";
const deleteIcon: TIcon = "delete";
const visibilityIcon: TIcon = "visibility";
const fileDownloadIcon: TIcon = "file_download";
const fileCopyIcon: TIcon = "file_copy";
const driveFileRenameIcon: TIcon = "drive_file_rename_outline";
const historyIcon: TIcon = "history";

export const list = [
  {
    id: "preview",
    icon: visibilityIcon,
    text: "Preview",
    onClick: (file: IFile) => console.log(`view ${file.id}`),
  },
  {
    id: "edit",
    icon: editIcon,
    text: "Edit",
    onClick: (file: IFile) => console.log(`edit ${file.id}`),
  },
  {
    id: "download",
    icon: fileDownloadIcon,
    text: "Download",
    onClick: (file: IFile) => console.log(`download ${file.id}`),
  },
  {
    id: "delete",
    icon: deleteIcon,
    text: "Delete",
    onClick: (file: IFile) => console.log(`delete ${file.id}`),
  },
  {
    id: "copyFile",
    icon: fileCopyIcon,
    text: "Copy File",
    onClick: (file: IFile) => console.log(`copy ${file.id}`),
  },
  {
    id: "rename",
    icon: driveFileRenameIcon,
    text: "Rename",
    onClick: (file: IFile) => console.log(`rename ${file.id}`),
  },
  {
    id: "history",
    icon: historyIcon,
    text: "History",
    onClick: (file: IFile) => console.log(`history ${file.id}`),
  },
];
