import useAppDispatch from '@hooks/useAppDispatch';
import { updateStepStatus } from '@redux/approval-process/thunks/processThunk';
import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import MaterialIcon, { TIcon } from './MaterialIcon'
import { whichProvider } from '@helpers/whichProvider';

const statuses = [
  { id: 0, label: "To Do", icon: "exclamation" as TIcon, iconBackground: "c-step-status__icon-container-grey" },
  { id: 1, label: "In Progress", icon: "more_horiz" as TIcon, iconBackground: "c-step-status__icon-container-orange" },
  { id: 2, label: "Done", icon: "check" as TIcon, iconBackground: "c-step-status__icon-container-green" },
];

interface ICurrentStatus {
  id: number;
  label: string;
  icon: TIcon;
  iconBackground: string;
}

interface IStepStatusProps {
  disabled?: boolean;
  currentIndex: number;
  stepId: number;
  projectProcessId?: number | null;
  projectProcessStep?: any;
}

const api = whichProvider()

export default function StepStatus({ currentIndex = 0, stepId, disabled = false, projectProcessId, projectProcessStep }: IStepStatusProps) {
  const [statusOption, setStatusOption] = useState(false);
  const dispatch = useAppDispatch();
  const [currentStatusIndex, setCurrentStatusIndex] = useState<number>(currentIndex);

  const onClick = (e: React.MouseEvent) => {
    if (disabled) return
    setStatusOption(!statusOption);
    e.stopPropagation();
  }

  const handleClickStatus = (e: React.MouseEvent, idx: number) => {
    e.stopPropagation();
    setStatusOption(!statusOption);
    setCurrentStatusIndex(idx);

    if (!_.isEqual(currentStatusIndex, idx)) {
      dispatch(updateStepStatus({ process_step_id: stepId, status: idx }));
    }

    if (projectProcessId) {
      const params = {
        project_process_id: projectProcessId,
        process_step_id: stepId,
        status: idx
      }

      if (projectProcessStep) {
        api.request('put', `api/projects/processes/steps/${projectProcessStep.id}`, params)
      } else {
        api.request('post', 'api/projects/processes/steps', params)
      }
    }
  }

  useEffect(() => {
    setCurrentStatusIndex(currentIndex)
  }, [currentIndex])

  return (
    <div className='position-relative'>
      <div className={`c-step-status d-flex align-items-center justify-content-between ${disabled ? 'c-step-status--disabled' : ''}`} onClick={onClick}>
        <div className='d-flex align-items-center'>
          <div className={`c-step-status__icon ${statuses[currentStatusIndex].iconBackground}`}>
            <MaterialIcon
              color='o-cl-white'
              icon={statuses[currentStatusIndex].icon}
              size={24}
            />
          </div>
          <span className='o-ft-xs-600'>{statuses[currentStatusIndex].label}</span>
        </div>
        <div className='d-flex'>
          <MaterialIcon
            color='o-cl-grey-100'
            icon='keyboard_arrow_down'
            size={20}
          />
        </div>
      </div>

      {
        statusOption &&
        <div className='c-step-status__option-list position-absolute'>
          <ul className='m-0 p-0'>
            {statuses.map((statusItem, idx) => {
              const { id, label, icon, iconBackground } = statusItem;
              return (
                <li key={id} className='c-step-status__option' onClick={(e) => handleClickStatus(e, idx)}>
                  <div className='d-flex'>
                    <div className={`c-step-status__icon-container ${iconBackground} d-flex justify-content-center align-items-center `}>
                      <MaterialIcon
                        color='o-cl-white'
                        icon={icon}
                        size={20}
                      />
                    </div>
                    <span className={`${_.isEqual(statuses[currentStatusIndex].id, id) && 'c-step-status__title--active'} c-step-status__title o-ft-sm-400 o-cl-grey-100`}>{label}</span>
                  </div>
                </li>
              )
            }
            )}
          </ul>
        </div>
      }
    </div>
  )
}
