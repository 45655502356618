import React, { useRef, useState } from 'react'

import useHandleClickOutside from '@hooks/useHandleClickOutside';
import { ErrorMessage, useField } from 'formik';
import styled from 'styled-components';
import MaterialIcon from '@components/MaterialIcon';

const SelectContainer = styled.div`
  width: 100%;
  padding: 6px 8px;
  border-radius: 0.25rem;
  font-size: 14px;
  border: 1px solid var(--grey-200);
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
`


const OptionsContainer = styled.div<{ $maxHeight: string }>(({ $maxHeight }) => `
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  box-shadow: 0 5px 20px -3px rgba(0, 0, 0, 0.13);
  max-height: 125px;
  overflow: auto;
  position: absolute;
  width: 100%;
  background-color: #fff;
  max-height: ${$maxHeight};
`);

const Option = styled.div`
  font-size: 14px;
  cursor: pointer;
  border-left: 0px solid transparent;
  transition: border .3s ease;
  padding: 0px 8px;

  &: hover {
    border-left: 5px solid var(--brand-color-secondary);
  }
`

type Props = {
  name: string;
  items: { id: number; label: string }[];
  placeholder?: string;
  height?: string;
}

const CustomSelect: React.FC<Props> = ({ items, name, placeholder = '', height = 'auto' }) => {
  const [field, meta, helpers] = useField(name);

  const ref = useRef(null)
  const [showOptions, setShowOptions] = useState(false)

  useHandleClickOutside(ref, () => setShowOptions(false));

  const toggleOptions = () => {
    setShowOptions(prev => !prev)
  }

  const handleChange = (value: string) => {
    helpers.setValue(value)
    toggleOptions()
  }

  return (
    <>
      <select hidden name={name} onChange={field.onChange}>
        {items.map(({ id, label }) => <option key={id} value={label}>{label}</option>)}
      </select>

      <div ref={ref} className={`position-relative`}>
        <SelectContainer onClick={toggleOptions}>
          {field.value.length ? (
            <span>{field.value}</span>
          ) : (
            <span className='o-cl-grey-200'>{placeholder}</span>
          )}
          <MaterialIcon icon={showOptions ? 'expand_less' : 'expand_more'} size={20} color='o-cl-grey-200' />
        </SelectContainer>

        {showOptions ? (
          <OptionsContainer $maxHeight={height}>
            {items.map(({ id, label }) => <Option key={id} onClick={() => handleChange(label)}>{label}</Option>)}
          </OptionsContainer>
        ) : <></>}
      </div>
      <ErrorMessage name={name} render={error => <div className="o-ft-xs-400 o-cl-red my-1 mx-2">{error}</div>} />
    </>
  )
}

export default CustomSelect
