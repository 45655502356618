/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as yup from "yup";

import { Controller, Resolver, useFieldArray, useForm } from "react-hook-form";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { DropdownIndicatorProps, components } from "react-select";
import { IFormInputs, IStep } from "./types";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { isEmptyArray, isString } from "ramda-adjunct";

import ActionModal from "@components/ActionModal";
import { Asterisk } from "./styled/Asterisk";
import ButtonUI from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import { CaptionSpan } from "./styled/CaptionSpan";
import CreatableSelect from "react-select/creatable";
import { Form } from "react-bootstrap";
import { FormContainer } from "./styled/FormContainer";
import { FormTitle } from "./styled/FormTitle";
import { InferType } from "yup";
import { InputSubtitle } from "./styled/InputSubtitle";
import { InputText } from "./styled/InputText";
import { NotDropdownIndicator } from "./styled/NotDropdownIndicator";
import { ProcessesContext } from "./context/ProcessesContext";
import { Spinner } from "react-activity";
import { Steps } from "./Steps";
import { TglBtn } from "./styled/TglBtn";
import { ToggleContainer } from "./styled/ToggleContainer";
import { colors } from "@theme/colors";
import { customStylesTags } from "./styled/customStylesTags";
import debounce from "lodash/debounce";
import { getDocuments } from "@redux/documents/thunks/documentsThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import { yupResolver } from "@hookform/resolvers/yup";

export const DropdownIndicatorChevron = (
  props: DropdownIndicatorProps<any, boolean>,
) => {
  const { selectProps, isFocused } = props;

  const handleCLick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <div
      onClick={handleCLick}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <components.DropdownIndicator {...props}>
        <div
          style={{
            transform: selectProps.menuIsOpen
              ? "rotate(180deg)"
              : "rotate(0deg)",
            transition: "transform 0.2s",
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 8L10 12L14 8"
              stroke={isFocused ? colors.brandColorPrimary : "#ccc"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </components.DropdownIndicator>
    </div>
  );
};

type SchemaType = InferType<typeof schema>;

interface StepType {
  label: string;
  value: string;
}
interface StepSchema {
  stepType: StepType;
  formType?: { label: string; value: string };
  name?: string;
  approval_required: boolean;
}

const schema: any = yup
  .object({
    title: yup
      .string()
      .required("Title is required")
      .min(2, "Title must be at least 2 characters long")
      .max(50, "Title can be no longer than 50 characters"),
    description: yup
      .string()
      .required("Description is required")
      .min(5, "Description must be at least 5 characters long")
      .max(130, "Description can be no longer than 130 characters"),
    is_published: yup.boolean(),
    tags: yup.array().of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      }),
    ),
    steps: yup.mixed().when("$stateSteps.dataSteps", {
      is: (dataSteps: StepSchema) => dataSteps && isEmptyArray(dataSteps),
      then: yup.array().nullable(),
      otherwise: yup.array().of(
        yup.object().shape({
          stepType: yup
            .mixed()
            .test(
              "is-object",
              "Type is required",
              (value) => value !== null && typeof value === "object",
            )
            .required("Type is required"),
          formType: yup.mixed().when("stepType", {
            is: (stepType: StepType) => stepType && stepType.value === "form",
            then: yup
              .mixed()
              .test(
                "is-object",
                "Form type is required",
                (value) => value !== null && typeof value === "object",
              )
              .required("Form type is required"),
            otherwise: yup.mixed().nullable(),
          }),
          name: yup.mixed().when("stepType", {
            is: (stepType: StepType) => stepType && stepType.value === "basic",
            then: yup
              .string()
              .typeError("asd")
              .required("Name is required")
              .min(2, "Name must be at least 2 characters long")
              .max(50, "Name can be no longer than 50 characters"),
            otherwise: yup.mixed().nullable(),
          }),
          content: yup.mixed().when("stepType", {
            is: (stepType: StepType) => stepType && stepType.value === "basic",
            then: yup
              .string()
              .typeError("content")
              .required("Content is required"),
            otherwise: yup.mixed().nullable(),
          }),
          approval_required: yup.mixed().when("stepType", {
            is: (stepType: StepType) =>
              stepType && stepType.value === "approval",
            then: yup
              .boolean()
              .required("Approval is required")
              .typeError("Approval is required"),
            otherwise: yup.mixed().nullable(),
          }),
        }),
      ),
    }),
  })
  .required();

const Processes = () => {
  const navigate = useNavigate();

  const [isTitleFocused, setTitleFocused] = useState<boolean>(false);
  const [isDescriptionFocused, setDescriptionFocused] =
    useState<boolean>(false);
  const [selectFocused, setSelectFocused] = useState<boolean>(false);
  const [published, setPublished] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [stepsToDelete, setStepsToDelete] = useState<number[]>([]);
  const [stateSteps, setStateSteps] = useState<any[]>([]);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const dispatch = useAppDispatch();
  const {
    showProcessById,
    updateProcess,
    postProcess,
    getProcessStepById,
    getForm,
    getAllProcessTags,
    createProcessTag,
    getProcessTagsByProcessId,
    state,
  } = useContext(ProcessesContext);

  const { process } = useParams();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema) as unknown as Resolver<IFormInputs>,
    context: { dataSteps: [] },
    defaultValues: {
      title: "",
      is_published: false,
      description: "",
      tags: [],
      steps: [],
    },
  });

  const { fields, append, remove, swap } = useFieldArray<IFormInputs, "steps">({
    control,
    name: "steps",
  });

  const handleShowProcessById = async () => {
    const id = process;
    if (isString(id)) {
      try {
        const processData = await showProcessById(id);
        setValue("title", processData.data.name);
        setValue("is_published", processData.data.is_published);
        setValue("description", processData.data.description || "");
        setPublished(processData.data.is_published);

        const tagsData = await getProcessTagsByProcessId(id);
        setValue(
          "tags",
          tagsData.data.map((tag) => ({
            label: tag.name,
            value: tag.id.toString(),
          })),
        );

        const stepsData: any = await getProcessStepById(id);
        setStateSteps(stepsData.data);

        stepsData.data.forEach(async (step: IStep) => {
          if (step.form_id) {
            const formData: any = await getForm(step.form_id);

            const newStep = {
              id: step.id,
              stepType: { label: "Form Step", value: "form" },
              name: step.name || null,
              formType: { label: formData.data.title, value: formData.data.id },
              approval_required: step.approval_required,
            };

            append(newStep);
          } else {
            const newStep = {
              id: step.id,
              stepType: { label: "Basic Step", value: "basic" },
              name: step.name || null,
              formType: null,
              content: step.content,
              approval_required: step.approval_required,
              documents: step.documents,
            };

            append(newStep);
          }
        });

        await setIsLoaded(true);
      } catch (error) {
        //   console.error("Error showing process by ID:", error);
      }
    }
  };

  const handleGetAllProcessTags = (query = "") => {
    const data = {
      perPage: 50,
      q: query,
    };
    getAllProcessTags(data);
  };

  const debouncedGetAllProcessTags = useCallback(
    debounce(handleGetAllProcessTags, 600),
    [],
  );

  const handleAddStep = () => {
    const newUUID = crypto.randomUUID();
    append({
      id: newUUID,
      approval_required: false,
      documents: [],
    });
  };

  const handleRemoveStep = (index: number) => {
    const steps = getValues("steps");

    if (steps && steps[index]) {
      setStepsToDelete([...stepsToDelete, steps[index].id]);
      remove(index);
    }
  };

  const handleCreateTag = async (inputValue: string): Promise<void> => {
    const createdTag = await createProcessTag({ name: inputValue });

    const currentTags = getValues("tags") || [];
    setValue(
      "tags",
      [
        ...currentTags,
        { label: createdTag.data.name, value: createdTag.data.id.toString() },
      ],
      {
        shouldValidate: true,
      },
    );
  };

  const filterSelectedOptions = () => {
    const selectedTags = getValues("tags") || [];
    return state.dataTags.filter(
      (tag: any) =>
        !selectedTags.some((selected) => selected.value === tag.id.toString()),
    );
  };

  const transformedTagsOptions = filterSelectedOptions().map((tag: any) => ({
    label: tag.name,
    value: tag.id.toString(),
  }));

  useEffect(() => {
    handleGetAllProcessTags();
    handleShowProcessById();
    dispatch(getDocuments({ parentId: undefined }));
  }, [process]);

  const onSubmit = () => {
    handleAddStep();
  };

  const onSubmitSave = async (data: SchemaType) => {
    let processId: number = Number(process);
    const newData = {
      name: data.title,
      description: data.description,
      is_published: published,
      tags: data.tags,
      steps: data.steps,
    };
    if (process) {
      await updateProcess(process, newData, stepsToDelete);
    } else {
      processId = await postProcess(newData);
    }
    return processId;
  };

  const handleTogglePublished = (): void => {
    setPublished(!published);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const schemaCopy = [...stateSteps];
    const [removed] = schemaCopy.splice(result.source.index, 1);
    schemaCopy.splice(result.destination.index, 0, removed);
    setStateSteps(schemaCopy);
    swap(result.source.index, result.destination.index);
  };

  const watchDescription = watch("description");

  const handleInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > 130) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.slice(0, 130);
    }
  };

  return (
    <div className="p-4">
      <ActionModal
        show={openCancelModal}
        title="Cancel Process Creation"
        subtitle={
          <>
            You are about to cancel a process creation. Remember that you
            won&apos;t be able to recover it again. Do you want to continue?
          </>
        }
        buttonLeftText="Cancel"
        buttonRightText="Continue"
        onClickLeftButton={() => setOpenCancelModal(false)}
        onClickRightButton={() => navigate(-1)}
      />
      {!isLoaded && process ? (
        <div className="d-flex justify-content-center min-vh-100 align-items-center flex-column">
          <Spinner
            className="v-contacts__spinner"
            color={colors.brandColorSecondary}
          />
        </div>
      ) : (
        <FormContainer className="p-0" id="formContainer">
          <div className="c-header-module d-flex flex-row justify-content-between">
            <div className="c-header-module__left-container">
              <div className="d-flex align-items-center">
                <span className="c-header-module__title o-cl-grey-100 o-ft-3xl-600">
                  Add Process
                </span>
              </div>
              <div className="c-breadcrumb d-flex flex-row w-100">
                <span className="c-breadcrumb__text-no-hover o-cl-grey-200 d-none d-md-inline">
                  Processes
                </span>
                <span className="c-breadcrumb__separator d-none d-md-flex">
                  /
                </span>
                <NavLink
                  className="c-breadcrumb__text o-ft-base-400 d-none d-md-inline"
                  to="#"
                  onClick={() => navigate(-1)}
                >
                  View All
                </NavLink>
                <span className="c-breadcrumb__separator d-none d-md-flex">
                  /
                </span>
                <span className="c-breadcrumb__text--active d-none d-md-inline">
                  {process ? "Edit" : "Add"} Process
                </span>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <ButtonUI
                value="Go Back"
                icon={{
                  icon: "arrow_back_ios",
                  size: 12,
                  position: "right",
                }}
                onClick={() => navigate(-1)}
              />
            </div>
          </div>
          <Form className="c-process-form">
            <FormTitle>Process Information</FormTitle>
            <Form.Group className="mb-3" controlId="formTitle">
              <InputSubtitle focused={isTitleFocused}>
                Title<Asterisk>*</Asterisk>
              </InputSubtitle>
              <Controller
                name="title"
                control={control}
                render={({ fieldState: { error } }) => (
                  <InputText $error={!!errors.title}>
                    <input
                      type="text"
                      placeholder="Enter a title"
                      className={`form-control ${errors.title ? "is-invalid" : ""}`}
                      onFocus={() => setTitleFocused(true)}
                      {...register("title", {
                        onBlur: () => setTitleFocused(false),
                      })}
                    />
                    {error && (
                      <span className="error-message">{error.message}</span>
                    )}
                  </InputText>
                )}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDescription">
              <InputSubtitle focused={isDescriptionFocused}>
                Description<Asterisk>*</Asterisk>
              </InputSubtitle>
              <Controller
                name="description"
                control={control}
                render={({ fieldState: { error } }) => (
                  <div className="position-relative">
                    <InputText $error={!!errors.description}>
                      <textarea
                        rows={2}
                        placeholder="Enter a description"
                        className={`form-control ${errors.description ? "is-invalid" : ""}`}
                        onFocus={() => setDescriptionFocused(true)}
                        onInput={handleInput}
                        {...register("description", {
                          onBlur: () => setDescriptionFocused(false),
                        })}
                      />
                      {error && (
                        <span className="error-message">{error.message}</span>
                      )}
                    </InputText>
                    <div
                      className={`c-steps-textarea-container ${watchDescription?.length === 130 && "c-steps-textarea-limit"}`}
                    >
                      <p className="mb-1">{`${watchDescription?.length || 0}/130`}</p>
                    </div>
                  </div>
                )}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTags">
              <InputSubtitle focused={selectFocused}>Tags</InputSubtitle>

              <Controller
                name="tags"
                control={control}
                render={({ field }) => (
                  <CreatableSelect
                    isMulti
                    options={transformedTagsOptions}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    classNamePrefix="react-select"
                    styles={customStylesTags}
                    placeholder="Select or create tags"
                    components={{ DropdownIndicator: NotDropdownIndicator }}
                    onCreateOption={handleCreateTag}
                    onFocus={() => setSelectFocused(true)}
                    onBlur={() => setSelectFocused(false)}
                    onInputChange={(inputValue) =>
                      debouncedGetAllProcessTags(inputValue)
                    }
                    formatCreateLabel={(inputValue) => (
                      <div className="add-tags">
                        + Add tag &quot;{inputValue}&quot;
                      </div>
                    )}
                    noOptionsMessage={() =>
                      isEmptyArray(state.dataTags)
                        ? "No tags created yet. Start by adding your first tag"
                        : "loading..."
                    }
                  />
                )}
              />
            </Form.Group>
            <ToggleContainer>
              <CaptionSpan>Published</CaptionSpan>

              <div
                className={`border border-3 rounded-pill mb-6 ${published ? "toggle-border-true" : "toggle-border-false"}`}
                style={{
                  width: "60px",
                  transform: "scale(0.5)",
                }}
              >
                <TglBtn value={published} onToggle={handleTogglePublished} />
              </div>
            </ToggleContainer>
            <FormTitle>Steps</FormTitle>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="column1">
                {(providedDroppable) => (
                  <div
                    ref={providedDroppable.innerRef}
                    {...providedDroppable.droppableProps}
                  >
                    {fields.map((field, i) => (
                      <Draggable
                        key={field.id}
                        draggableId={field.id}
                        index={i}
                      >
                        {(providedDraggable) => (
                          <div
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                            style={{
                              backgroundColor: "transparent",
                              ...providedDraggable.draggableProps.style,
                            }}
                          >
                            <Steps
                              values={field}
                              watch={watch}
                              getValues={getValues}
                              index={i}
                              setValue={setValue}
                              errors={errors}
                              onSubmit={onSubmit}
                              onClose={() => handleRemoveStep(i)}
                              DropdownIndicatorChevron={
                                DropdownIndicatorChevron
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {providedDroppable.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="mb-2">
              <ButtonUI
                type="button"
                value="New Step"
                icon={{
                  icon: "add",
                  size: 20,
                  position: "right",
                }}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </Form>
          <div className="c-process-actions d-flex justify-content-end mb-4">
            <ButtonUI
              size="medium-big"
              type="button"
              value="Cancel"
              onClick={() =>
                isDirty ? setOpenCancelModal(true) : navigate(-1)
              }
              className="process-btn-cancel"
            />
            <ButtonGroup
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              primaryAction={{
                onClick: handleSubmit(async (data) => {
                  try {
                    await onSubmitSave(data);
                    navigate(-1);
                  } catch (err) {
                    console.log(err);
                  }
                }),
                value: "Save and back",
              }}
              otherActions={[
                {
                  onClick: handleSubmit(async (data) => {
                    try {
                      const processId = await onSubmitSave(data);
                      if (!process) {
                        reset();
                        navigate(`/admin/process/${processId}/edit`, {
                          replace: true,
                        });
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  }),
                  value: "Save and edit this item",
                },
                {
                  onClick: handleSubmit(async (data) => {
                    try {
                      await onSubmitSave(data);
                      reset();
                      navigate(`/admin/process/create`, {
                        replace: true,
                      });
                    } catch (err) {
                      console.log(err);
                    }
                  }),
                  value: "Save and new item",
                },
              ]}
            />
          </div>
        </FormContainer>
      )}
    </div>
  );
};

export default Processes;
