import useWindowSize from "@hooks/useWindowSize";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

interface PrivacyTermsSkeletonProps {
  amountOfSections: number;
}

const PrivacyTermsSkeleton = ({ amountOfSections }: PrivacyTermsSkeletonProps) => {

  const { responsive } = useWindowSize();

  return (
    <SkeletonTheme baseColor="#D8D8D8" highlightColor="#FFFFFF">

      <div>
        <h2 className='v-privacy-terms__title'><Skeleton width={(responsive.md) ? "20%" : "70%"} count={1} /></h2>
        {[...Array(amountOfSections)].map((value, index) =>
          <div key={index}>
            <h3 className='v-privacy-terms__subtitle'><Skeleton width={(responsive.md) ? "25%" : "80%"} count={1} /></h3>
            <p className='v-privacy-terms__text'><Skeleton count={(responsive.md) ? 10 : 20} /></p>
            <br />
          </div>
        )
        }
      </div>
    </SkeletonTheme>
  )
}

export default PrivacyTermsSkeleton;
