import MaterialIcon from "./MaterialIcon";
import React from "react";
import { Spinner } from "react-activity";
import colors from "../brand/theme/colors";
import { useGetBrands } from "@redux/brands/api";

interface IBrand {
  is_local: number;
  home_url: string;
  name: string;
}

interface IBrandSwitcherProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const BrandSwitcher: React.FC<IBrandSwitcherProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { data: brands, isLoading } = useGetBrands(undefined, {
    skip: !isOpen,
  });

  return (
    <div className="position-relative">
      <MaterialIcon
        icon={isOpen ? "expand_less" : "expand_more"}
        color="o-cl-grey-100"
        className="o-cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div className="c-header__brand-switcher o-bg-white position-absolute">
          {isLoading ? (
            <div className="c-header__spinner-container d-flex justify-content-center align-items-center w-100 h-100">
              <Spinner
                className="o-ft-sm o-ft-md@md"
                color={colors.brandColorSecondary}
              />
            </div>
          ) : (
            brands?.map(
              (brand: IBrand) =>
                brand.is_local !== 1 && (
                  <a
                    href={brand.home_url}
                    target="_blank"
                    className="text-decoration-none"
                    rel="noreferrer"
                    key={brand.name}
                  >
                    <p className="c-header__brand-title o-ft-sm-400 o-cl-grey-100 d-flex align-items-center o-cursor-pointer my-3">
                      <MaterialIcon
                        icon="open_in_new"
                        size={18}
                        color="o-cl-grey-100"
                        className="mx-2"
                      />
                      {brand.name}
                    </p>
                  </a>
                ),
            )
          )}
        </div>
      )}
    </div>
  );
};

export default BrandSwitcher;
