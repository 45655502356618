/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ReactElement,
  createContext,
  useContext,
  useState,
} from "react";

import FilterLayout from "@components/filters/FilterLayout";
import { IFilter } from "@components/Search/shared/Results";
import Search from "@components/Search";
import SwitchMode from "@components/SwitchMode";
import { TModuleTypes } from "@models/Global.type";
import _ from "lodash";
import useAppDispatch from "@hooks/useAppDispatch";
import useWindowSize from "@hooks/useWindowSize";

interface IPanelFilterProps {
  renderFilterWrapper?: ReactElement | ReactElement[];
  renderFilterLayoutWrapper?: ReactElement | ReactElement[];
  renderTagsWrapper?: ReactElement | ReactElement[];
  module: TModuleTypes;
  optionsOnTop?: boolean;
  optionsOnTopMobile?: boolean;
  optionsOnBottomMobile?: boolean;
  borderFilter?: string;
  borderShadow?: string;

  searchModule?: boolean;
}

interface ISearch {
  show: boolean;
  query: string;
}

interface IPanelFilterLayoutProps {
  children?: ReactElement | ReactElement[];
  switch?: boolean;
}

interface IPanelFilterContextProps {
  module: TModuleTypes;
}

const filters: IFilter[] = [{ id: 1, name: "tag" }];

const PanelFilterContext = createContext({} as IPanelFilterContextProps);

const PanelFilterLayout = ({
  switch: toggle,
  children,
}: IPanelFilterLayoutProps) => {
  const { module } = useContext(PanelFilterContext);

  return (
    <FilterLayout>
      <>
        {toggle && (
          <SwitchMode module={module}>
            <div className="mx-1 d-flex">
              <SwitchMode.Icon mode="grid" />
            </div>
            <div className="mx-1 d-flex">
              <SwitchMode.Icon mode="list" />
            </div>
          </SwitchMode>
        )}
        {children}
      </>
    </FilterLayout>
  );
};

export const PanelFilter = ({
  module,
  optionsOnTop,
  optionsOnTopMobile,
  optionsOnBottomMobile,
  renderFilterWrapper,
  renderFilterLayoutWrapper,
  borderFilter,
  borderShadow,
  searchModule,
  renderTagsWrapper,
}: IPanelFilterProps) => {
  const { Provider } = PanelFilterContext;
  const { responsive } = useWindowSize();

  const [focus, setFocus] = useState<boolean>(false);
  const [value, setValue] = useState("");
  const [filter, setFilter] = useState<IFilter | null>(null);

  // const { search: { show, pending: searchPending, data: searchData } } = useAppSelector(state => state.contact);

  const clearQuery = () => {
    // dispatch(updateQuery(''));
    // dispatch(getContacts());
    // clearSearch()
    setValue("");
  };

  const renderSearchModule = searchModule && (
    <Search
      borderStyle={false}
      filters={filters}
      results={{
        contacts: {
          data: [].map((contact: any) => ({
            ...contact,
            icon: "account_circle",
          })),
          meta: {},
          links: {},
          // searchData.map((contact: any) => ({ ...contact, icon: 'account_circle', })), meta: {}, links: {}
        },
      }}
      // isFetching={searchPending}
      isFetching={false}
      onSelectFilter={setFilter}
      onClear={clearQuery}
      value={value}
      placeholder="Search and filter"
      searchResultsTitle="Filter by:"
      viewAllBtn={false}
      onFocus={(OnFocusValue: any) => setFocus(OnFocusValue)}
    />
  );

  return (
    <Provider value={{ module }}>
      <div className="c-filter-module">
        <div className="row c-filter-module__container">
          <div className="d-flex align-items-center justify-content-between">
            <div className={`${borderShadow} position-relative`}>
              {(!focus || responsive.md) && (
                <div
                  className={`c-filter-module__filter d-flex align-items-center ${borderFilter}`}
                >
                  {renderFilterWrapper}
                </div>
              )}
              <div className="d-flex align-items-center w-100">
                {renderSearchModule}
              </div>
            </div>

            {responsive.md && optionsOnTop && renderFilterLayoutWrapper}
            {!responsive.md && optionsOnTopMobile && renderFilterLayoutWrapper}
          </div>
          <div className="d-md-none">
            <div className="c-filter-module__tag-list-section">
              {/* mobile tags */}
              {renderTagsWrapper}
            </div>
          </div>
          {!responsive.md &&
            optionsOnBottomMobile &&
            _.isEqual(module, "contacts") && (
              <div className="d-flex justify-content-end">
                {renderFilterLayoutWrapper}
              </div>
            )}
        </div>

        {/* desktop tags */}
        <div className="row d-none d-md-block">{renderTagsWrapper}</div>
      </div>
    </Provider>
  );
};

PanelFilter.FilterLayout = PanelFilterLayout;
