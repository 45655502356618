import _ from 'lodash';
import Checkbox, { TCheckbox } from '@components/Checkbox';

interface IFilterDropdownListProps {
  title?: string;
  appliedFilters: IFilterDropdownItem[];
  list: IFilterDropdownItem[];
  onChange: (selectedFilter: any, nextStatus: boolean) => void;
}

export interface IFilterDropdownItem {
  id: string | number;
  name: string;
}

export const FilterDropdownList = ({ title, list, appliedFilters, onChange }: IFilterDropdownListProps) => {
  const checkFilteredValue = (obj: TCheckbox) => _.some(appliedFilters, obj);
  return (
    <div className='c-option-list'>
      <h3 className='c-option-list__title o-ft-lg-400'>{title}</h3>
      {list.map((item: IFilterDropdownItem) => (
        <Checkbox key={item.id} {...{ item, onChange }} checked={checkFilteredValue(item)} />
      ))}
    </div>
  );
};
