import styled from "styled-components";
import { colors } from "@theme/colors";

export const InputText = styled.div<{ $error?: boolean }>`
  input,
  textarea {
    width: 100%;
    padding: 6px 8px;
    border-radius: 0.25rem;
    border: 1px solid
      ${(props) => (props.$error ? "var(--red)" : colors.grey200)};
    outline: none;
    box-shadow: none;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    transition: 0.5s all;
    min-height: 45px;

    &::placeholder {
      color: #9fa0a0;
    }

    &:focus {
      border: 1px solid
        ${(props) => (props.$error ? "var(--red)" : colors.brandColorPrimary)};
      outline: none;
      box-shadow: none;
    }
  }
`;
