import { useEffect, useState } from "react";
import _ from "lodash";

import Breadcrumb from "./Breadcrumb";
import Button from "@components/Button";
import MaterialIcon from "./MaterialIcon";
import useWindowSize from "@hooks/useWindowSize";
import { useLocation, useNavigate } from "react-router-dom";
import { TMenuSection } from '../app/types/section/MenuSection.type';
import { formatCapitalizeFirstLetter } from "@helpers/formatCapitalizeFirstLetter";
import { IAction } from "@models/Action.type";
import DotsMenu from "./DotsMenu";
import profilePicture from '../assets/profile-img.png';
import NewBreadcrumb from "./NewBreadcrumb/Breadcrumb";

interface IHeaderModuleProps {
  menuSection?: TMenuSection;
  title?: string;
  parent_id?: number;
  routes?: TMenuSection[] | null;
  isRoot?: boolean;
  resultsView?: boolean;
  actionList?: IAction[];
  storeOwnerDetail?: string;
  goBack?: boolean;
  withBreadcrumb?: boolean; // provisorio, refactor header module + breadcrumb
  breadcrumbOffset?: number; // provisorio, refactor header module + breadcrumb
  breadcrumbWithIds?: boolean;
}

const HeaderModule = ({ menuSection, routes, title, parent_id, isRoot, resultsView, actionList, storeOwnerDetail, withBreadcrumb, breadcrumbOffset, goBack, breadcrumbWithIds }: IHeaderModuleProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { responsive } = useWindowSize()
  const [parentMenuSection, setParentMenuSection] = useState<TMenuSection>();
  const list = actionList?.filter(o => !_.isEqual(o.type, 'favorite'));

  useEffect(() => {
    routes &&
      setParentMenuSection(routes.find((route: TMenuSection) => _.isEqual(route.id, menuSection?.parent_id)))
  }, [location]);

  const onHandleNavigate = () => {
    if (goBack) {
      navigate(-1)
      return
    }
    if (parentMenuSection?.route)
      navigate(`/${parentMenuSection?.route}`)
    else
      navigate(`/dashboard`, { replace: true })
  }

  const setTitle = () => {
    if (title)
      return formatCapitalizeFirstLetter(title)
    else
      if (menuSection)
        return formatCapitalizeFirstLetter(menuSection.name)
      else
        return ""
  }

  return (
    <header className="c-header-module d-flex justify-content-between align-items-center">
      <div className="c-header-module__left-container">
        <div className="c-header-module__title-container">
          <div id={title?.toLocaleLowerCase() + "Onboarding"} className='d-flex align-items-center'>
              {
                (!!list?.length && !menuSection?.is_link && !menuSection?.is_bottom_link) &&
                <DotsMenu position="right" actionList={list} dotsDirection={"vertical"} context={menuSection} />
              }

            <span className="c-header-module__title o-cl-grey-100 o-ft-3xl-600">{setTitle()}</span>
          </div>
        </div>
        {routes && <Breadcrumb {...{ routes }} />}
        {withBreadcrumb && responsive.md && <NewBreadcrumb withIds={breadcrumbWithIds} offset={breadcrumbOffset}/>}
        {/* <Breadcrumb /> */}
      </div>
      <div className="d-flex align-items-center ms-3 gap-3">
        {storeOwnerDetail && responsive.md &&
          <div className="c-store-owner d-flex">
            <div className="mx-2">
              <p className="c-store-owner__info o-ft-xs-600 m-0">Store Owner</p>
              <p className="c-store-owner__info o-ft-xs-400 m-0">{storeOwnerDetail}</p>
            </div>
            <img src={profilePicture} className="c-store-owner__picture" alt="" />
          </div>
        }
        {
          (parentMenuSection || parent_id || isRoot || resultsView || goBack) &&
          (responsive.md
            ?
            <Button
              value="Go Back"
              icon={{
                icon: 'arrow_back_ios',
                size: 12,
                position: 'right',
              }}
              onClick={() => onHandleNavigate()}
            />
            :
            <label onClick={() => onHandleNavigate()} className={`c-button--circle o-bg-brand-secondary:hover o-cl-brand-secondary o-cl-white:hover o-ft-xs-700 d-flex align-items-center justify-content-center mb-0 ms-4`}>
              <MaterialIcon
                className="c-button__icon ms-1"
                icon={'arrow_back_ios'}
                size={12}
              />
            </label>)
        }
      </div>
    </header>
  );
};

export default HeaderModule;
