import React from 'react'

import { useNavigate } from 'react-router-dom'

type Props = {
  crumbs: { id: number, name: string, route: string }[]
}

const BreadCrumb: React.FC<Props> = ({ crumbs }) => {
  const navigate = useNavigate()

  return (
    <div className="c-breadcrumb d-flex flex-row">
      {crumbs.map(({ id, name, route }, index, arr) => (
        <React.Fragment key={id}>
          <span
            className={`c-breadcrumb__text ${index + 1 === arr.length ? 'c-breadcrumb__text--active' : ''}`}
            onClick={() => navigate(route)}
          >
            {name}
          </span>
          <span className="c-breadcrumb__separator">/</span>
        </React.Fragment>
      ))}
    </div>
  )
}

export default BreadCrumb