import { ReactElement, createContext, useReducer } from "react";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { createToast } from "@helpers/createToast";

const textToast = (
  link: string | number | null = "",
  text: string | null = ""
): string | any => {
  return {
    warning: `
 <div class="d-flex">
  <div class="loader"></div>
  <span>
    ${text}
  </span>
 </div>`,
    success: `<div class="d-flex gap-1"><span>Element moved successfully!</span><a class="link-light" role="button" href='${`${
      link ? `/admin/file-management/${link}` : "/admin/file-management/"
    }`}' >Find it here.</a></>`,
    danger: `<span>${text}</span>`,
  };
};

enum ToastActions {
  SHOW_TOAST = "SHOW_TOAST",
  SET_TOTAL_FILES = "SET_TOTAL_FILES",
  ADD_ONE_FINISHED = "ADD_ONE_FINISHED",
}

type ToastState = {
  toast: {
    type: "success" | "danger" | "warning" | "loading";
    link: string | number | null;
    text: string | null;
  };
  filesUploading: {
    total: number;
    finished: number;
  };
};

type ToastAction = {
  type: ToastActions;
  payload?: number | boolean | any;
};

const initialState: ToastState = {
  toast: {
    type: "success",
    link: "",
    text: "",
  },
  filesUploading: {
    total: 0,
    finished: 0,
  },
};

type ToastContextType = {
  showToast: (
    type: "success" | "danger" | "warning" | "loading",
    dispatch: ThunkDispatch<any, any, any> | any,
    link?: string | number | null,
    delay?: number,
    text?: string | null
  ) => void;
  setTotalFilesUploading: (total: number) => void;
  addOneFinish: () => void;
} & ToastState;

export const ToastContext = createContext<ToastContextType | null>(null);

const ToastReducer = (state: ToastState, action: ToastAction) => {
  switch (action.type) {
    case ToastActions.SHOW_TOAST:
      return {
        ...state,
        toast: {
          type: action.payload?.type,
          link: action.payload?.link,
          text: action.payload?.text,
        },
      };
    case ToastActions.SET_TOTAL_FILES:
      return {
        ...state,
        filesUploading: {
          total: action.payload?.total,
          finished: state.filesUploading.finished,
        },
      };

    case ToastActions.ADD_ONE_FINISHED:
      return {
        ...state,
        filesUploading: {
          total: state.filesUploading.total,
          finished: state.filesUploading.finished + 1,
        },
      };
    default:
      return state;
  }
};

const ToastsProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatchAction] = useReducer(ToastReducer, initialState);

  const showToast = (
    type: "success" | "danger" | "warning" | "loading",
    dispatch: ThunkDispatch<any, any, any>,
    link?: string | number | null,
    delay?: number,
    text?: string | null
  ) => {
    createToast(
      textToast(link, text)[type],
      type,
      dispatch,
      true,
      !(type === "warning"),
      delay
    );

    dispatchAction({
      type: ToastActions.SHOW_TOAST,
      payload: { type },
    });
  };

  const setTotalFilesUploading = (total: number) => {
    dispatchAction({
      type: ToastActions.SET_TOTAL_FILES,
      payload: { total },
    });
  };

  const addOneFinish = () => {
    dispatchAction({
      type: ToastActions.ADD_ONE_FINISHED,
    });
  };

  return (
    <ToastContext.Provider
      value={{
        ...state,
        showToast,
        setTotalFilesUploading,
        addOneFinish,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export default ToastsProvider;
