import React from 'react';

interface IMessageProps {
  text: string;
  bottom?: boolean;
  right?: boolean;
}

const Message = ({ text, bottom, right }: IMessageProps) => {
  const pY = (bottom) ? 'bottom' : 'top';
  const pX = (right) ? 'right' : 'left';

  return (
    <div className={`c-message o-ft-sm-400 c-message--${pY} c-message--${pX}`}>{text}</div>
  );
};

export default Message;
