/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
import FavoritesSection from "@sections/Favorites/FavoritesSection";
import PinUnpinProvider from "../providers/PinUnpinProvider";
import React from "react";

const Favorites = () => {
  return (
    <PinUnpinProvider>
      <FavoritesSection />
    </PinUnpinProvider>
  );
};

export default Favorites;
