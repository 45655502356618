import HttpProvider from "@api/HttpProvider";

const api = new HttpProvider();

export default class VaultsController {
  index() {
    return api.request('get', 'api/vaults/user');
  }

  getVault(id: number) {
    return api.request('get', `api/vaults/${id}`);
  }

  getDocuments(id: number) { 
    return api.request('get', `api/documents/${id}`);
  }
}
