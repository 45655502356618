import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getVaults, getVaultById, getVaultsDocumentsById } from '@redux/vaults/thunks/vaultsThunk';

type BreadCrumb = {
  name: string;
  id: number;
}
export interface IVaultsSlice {
  data: Array<any>;
  pending: boolean;
  currentVault: any;
  breadcrumbArray: BreadCrumb[];
}

const initialState: IVaultsSlice = {
  data: [],
  pending: false,
  currentVault: null,
  breadcrumbArray: []
}

export const vaultsSlice = createSlice({
  name: 'vaults',
  initialState,
  reducers: {
    setCurrentVault: (state, action) => {
      const {id, name} = action.payload;
      state.currentVault = action.payload;
      state.pending = false;
      state.breadcrumbArray = [...state.breadcrumbArray, {id, name}]
    },
    resetCurrentVault: (state) => {
      state.currentVault = null;
      state.breadcrumbArray = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVaults.fulfilled, (state: IVaultsSlice, action: PayloadAction<any>) => {
      const { data } = action.payload;
      state.data = data;
      state.pending = false;
    });
    builder.addCase(getVaults.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(getVaults.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(getVaultById.fulfilled, (state: IVaultsSlice, action: PayloadAction<any>) => {
      const { data } = action.payload;
      state.currentVault = data;
      state.pending = false;
      state.breadcrumbArray = [...state.breadcrumbArray, {id: data.id, name: data.name}]
    });
    builder.addCase(getVaultById.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(getVaultById.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(getVaultsDocumentsById.fulfilled, (state: IVaultsSlice, action: PayloadAction<any>) => {
      const { data } = action.payload;
      state.currentVault = data;
      state.pending = false;
      state.breadcrumbArray = [...data.parents, {id: data.id, name: data.name}];
    });
    builder.addCase(getVaultsDocumentsById.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(getVaultsDocumentsById.pending, (state) => {
      state.pending = true;
    });
  },
});

export const { resetCurrentVault, setCurrentVault } = vaultsSlice.actions;
export default vaultsSlice.reducer;
