import React, { FocusEvent, useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import useAppSelector from '@hooks/useAppSelector';
import { TagResults } from './TagResults';
import useHandleClickOutside from '../hooks/useHandleClickOutside';
import useAppDispatch from '@hooks/useAppDispatch';
import { addTag, setCurrentTagValue } from '@redux/tags/slices/tagSlice';

interface ITagInputProps {
  label: string;
  placeholder: string;
  name: string;
  // handleKeyDown: (val: string) => void;
}

type EventType = FocusEvent<any, Element>;

export default function TagInput(props: ITagInputProps) {
  const {
    label, placeholder, name,
    // handleKeyDown,
  } = props;

  const ref = useRef<null | HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const [focus, setFocus] = useState<boolean>(false);
  const { tags: { currentTagValue }, file: { elementToTag } } = useAppSelector((state) => state);
  const [showResults, setShowResults] = useState<boolean>(false);

  useHandleClickOutside(ref, () => setShowResults(false));

  const labelClass = classNames({
    'c-tag-input__label--active': focus,
  });

  const inputContainerClass = classNames(
    'd-flex align-content-center align-items-center',
    {
      'c-tag-input__input-container--active': focus,
    }
  );

  const onChange = (e: any) => updateCurrentTagValue(e.target.value);

  const updateCurrentTagValue = (val: string) => dispatch(setCurrentTagValue(val));

  const onKeyDown = (e: any) => {
    if (_.isEqual(e.key, 'Enter')) {
      e.preventDefault();
      updateCurrentTagValue('');
    }
  };

  return (
    <div className="c-tag-input">
      <label htmlFor={name} className={`c-tag-input__label o-ft-xs-400 ${labelClass}`}>{label}</label>

      <div className='position-relative'>
        <div className={`c-tag-input__input-container ${inputContainerClass}`}>
          <input
            name={name}
            value={currentTagValue}
            {...{ placeholder, onChange, onKeyDown }}
            type="text"
            className="c-tag-input__input o-ft-base-400"
            onFocus={(e: EventType) => {
              setFocus(true);
              setShowResults(true);
            }}
            autoFocus
            onBlur={(e: EventType) => setFocus(false)}
          />
        </div>
        {((showResults || focus) && !_.isEmpty(currentTagValue)) && (
          <div ref={ref}>
            <TagResults onClick={(tag) => {
              setShowResults(false);
              updateCurrentTagValue('');
              // if()
              dispatch(addTag({ documentId: elementToTag?.id || 0, tag }));
            }} />
          </div>
        )}
      </div>


    </div>
  )
}
