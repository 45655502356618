import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  loader: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoader: (state: any, action: PayloadAction<boolean>) => {
      state.loader = action.payload
    }
  },
});

export const { setLoader } = userSlice.actions;
export default userSlice.reducer;
