import React, { useEffect } from 'react';

interface IErrorProps {
  onMounted?: () => void;
  onUnmounted?: () => void;
  code: string;
}

export const Error = ({ onMounted, onUnmounted, code }: IErrorProps) => {
  useEffect(() => {
    (onMounted) && onMounted();
    return () => {
      (onUnmounted) && onUnmounted();
    }
  }, []);

  return (
    <div className='c-error-screen w-100'>
      <span className='c-error-screen__text'>{code}</span>
    </div>
  );
}
