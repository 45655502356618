import MaterialIcon, { TIcon } from "@components/MaterialIcon";
import { TTag } from "@models/Tag.type";

interface ITagProps {
  tag: TTag;
  className?: string;
  documentId: number;
  hideClosing?: boolean;
  iconName?: "linkedin" | TIcon;
  onClick: (tag: TTag, documentId: number) => void;
}

export const Tag = ({ onClick, tag, documentId, hideClosing, iconName, className }: ITagProps) => {
  const { name } = tag;

  const tagName = name.charAt(0).toUpperCase() + name.slice(1);

  return (
    <div className={`c-tag o-cursor-pointer d-inline-flex align-items-center ${className}`}
      onClick={(e) => onClick(tag, documentId)}>
      {
        (iconName) &&
        <div className="c-tag__icon-right-container d-flex">
          <MaterialIcon
            className='c-tag__icon'
            icon={iconName}
            size={20}
            color='o-cl-grey-100'
          />
        </div>
      }
      <span className='c-tag__text o-ft-sm-400'>{tagName}</span>
      {
        !hideClosing &&
        <div className="c-tag__icon-left-container d-flex">
          <MaterialIcon
            className='c-tag__icon'
            icon='close'
            size={20}
            color='o-cl-grey-100'
          />
        </div>
      }
    </div>
  );
}
