import _ from "lodash";
import HttpProvider from "@api/HttpProvider";
import AdminHttpProvider from "@api/AdminHttpProvider";

const api = new HttpProvider();
const adminApi = new AdminHttpProvider();

export const whichProvider = () => {
  const current_website = localStorage.getItem('current_website')
  if (_.isEqual("admin", current_website))
    return adminApi
  return api
}
