import React, { useEffect } from "react";
import { Navigate, useSearchParams, useNavigate } from "react-router-dom";
import { getLoggedUser, deleteLoggedUser } from "@redux/users/thunks/userThunk";
import { getProcesses } from "@redux/approval-process/thunks/processThunk";
import {
  getFavorites,
  getFavoritesIdsTypes,
} from "@redux/favorites/thunks/favoritesThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import AuthController from "@controllers/AuthController";
import { useIdleTimer } from "react-idle-timer";
import isEmpty from "lodash/isEmpty";
import { getVaults } from "@redux/vaults/thunks/vaultsThunk";

const LOGOUT_TIMEOUT = Number(
  process.env.REACT_APP_LOGOUT_TIMEOUT || "86400000",
);

interface PublicRouterProps {
  children?: JSX.Element | JSX.Element[];
}

const AuthRouter = ({ children }: PublicRouterProps) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const accessToken: string = searchParams.get("access_token") || "";

  if (!isEmpty(accessToken)) {
    localStorage.setItem("access_token", accessToken);
  }

  const hasPhoneNumber = localStorage.getItem("has_phone_number");
  const isLogged =
    (localStorage.getItem("access_token") && !hasPhoneNumber) ||
    (localStorage.getItem("access_token") && hasPhoneNumber === "true");
  useEffect(() => {
    if (isLogged) {
      dispatch(getLoggedUser());
      dispatch(getFavorites());
      dispatch(getFavoritesIdsTypes());
      dispatch(getProcesses());
      dispatch(getVaults());
    } else {
      navigate("/login", { replace: true });
    }
  }, [dispatch, isLogged, searchParams, accessToken]);

  const logout = async () => {
    try {
      await AuthController.logOut();
      localStorage.clear();
      dispatch(deleteLoggedUser());
      searchParams.delete("access_token");
    } catch (error) {
      console.error(error);
    }
  };

  useIdleTimer({ onIdle: logout, timeout: LOGOUT_TIMEOUT, crossTab: true });

  // Obtener la última hora activa almacenada
  const obtenerUltimaHoraActiva = () => {
    const ultimaHoraActiva = localStorage.getItem("ultimaHoraActiva") || "0";
    return parseInt(ultimaHoraActiva, 10);
  };

  // Establecer la última hora activa
  const establecerUltimaHoraActiva = () => {
    const fechaHoraActual = new Date();
    const marcaTiempo = fechaHoraActual.getTime();
    localStorage.setItem("ultimaHoraActiva", marcaTiempo.toString());
  };

  // Verificar si han pasado 24 horas desde la última hora activa
  const hanPasado24Horas = () => {
    const ultimaHoraActiva = obtenerUltimaHoraActiva();
    const fechaHoraActual = new Date();
    const fechaHoraHace24Horas = new Date(
      fechaHoraActual.getTime() - LOGOUT_TIMEOUT,
    );

    return ultimaHoraActiva < fechaHoraHace24Horas.getTime();
  };

  useEffect(() => {
    if (!obtenerUltimaHoraActiva()) return;
    if (hanPasado24Horas()) logout();
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", establecerUltimaHoraActiva);

    return () => {
      window.removeEventListener("beforeunload", establecerUltimaHoraActiva);
    };
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children || <Navigate replace to="/login" />}</>;
};

export default AuthRouter;
