interface ISvgProps {
  className?: string;
}
export const GridFile = ({ className }: ISvgProps) => (
  <div className={className}>
    <svg width="80" height="100" viewBox="0 0 94 110" fill="none" xmlns="http://www.w3.org/2000/svg" className='c-grid__image mw-100'>
      <path d="M67.6119 0H12C5.37258 0 0 5.37258 0 12V98C0 104.627 5.37258 110 12 110H82C88.6274 110 94 104.627 94 98V26.0535C94 22.8219 92.6966 19.727 90.3849 17.469L75.9968 3.41549C73.755 1.22584 70.7456 0 67.6119 0Z" fill="#BCAEDE" />
      <path d="M72.8636 0L94 21.1364V30H64V0H72.8636Z" fill="#BCAEDE" />
      <path d="M69 24V4.41421C69 3.52331 70.0771 3.07714 70.7071 3.70711L90.2929 23.2929C90.9229 23.9229 90.4767 25 89.5858 25H70C69.4477 25 69 24.5523 69 24Z" fill="white" />
    </svg>
  </div>
)

export const GridFolder = ({ className }: ISvgProps) => (
  <div className={className}>
    <svg width="120" height="96" viewBox="0 0 120 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 10C0 4.47715 4.47715 0 10 0H34.9661L35.0263 0.00428936C36.9007 0.137741 40.9941 0.429185 43.7329 3.55735L51.1155 10.2768C52.346 11.3308 55.7297 13.5048 59.421 13.5048L107.742 13.5049C114.37 13.5049 119.742 18.8775 119.742 25.5049V83.2701C119.742 89.8975 114.37 95.2701 107.742 95.2701H12C5.37259 95.2701 0 89.8975 0 83.2701V10Z" fill="#85C0FF" />
    </svg>
  </div>
)

export const GridVaultFolder = ({ className }: ISvgProps) => (
  <div className={className}>
    <svg width="120" height="96" viewBox="0 0 120 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 10C0 4.47715 4.47715 0 10 0H34.9666L35.0268 0.00428946C36.9012 0.137743 40.9948 0.429191 43.7336 3.5574L51.1163 10.2769C52.3431 11.3278 55.7104 13.4921 59.389 13.5049H107.744C114.371 13.5049 119.744 18.8775 119.744 25.5049V83.2712C119.744 89.8986 114.371 95.2712 107.744 95.2712H12C5.37258 95.2712 0 89.8986 0 83.2712V10Z" fill="#85C0FF" />
      <mask id="mask0_12056_478812" maskUnits="userSpaceOnUse" x="87" y="63" width="25" height="24">
        <rect x="87.7441" y="63" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_12056_478812)">
        <path d="M98.2441 78H101.244L100.669 74.775C101.002 74.6083 101.265 74.3667 101.457 74.05C101.648 73.7333 101.744 73.3833 101.744 73C101.744 72.45 101.548 71.9792 101.157 71.5875C100.765 71.1958 100.294 71 99.7441 71C99.1941 71 98.7233 71.1958 98.3316 71.5875C97.94 71.9792 97.7441 72.45 97.7441 73C97.7441 73.3833 97.84 73.7333 98.0316 74.05C98.2233 74.3667 98.4858 74.6083 98.8191 74.775L98.2441 78ZM99.7441 85C97.4275 84.4167 95.515 83.0875 94.0066 81.0125C92.4983 78.9375 91.7441 76.6333 91.7441 74.1V68L99.7441 65L107.744 68V74.1C107.744 76.6333 106.99 78.9375 105.482 81.0125C103.973 83.0875 102.061 84.4167 99.7441 85ZM99.7441 82.9C101.477 82.35 102.911 81.25 104.044 79.6C105.177 77.95 105.744 76.1167 105.744 74.1V69.375L99.7441 67.125L93.7441 69.375V74.1C93.7441 76.1167 94.3108 77.95 95.4441 79.6C96.5775 81.25 98.0108 82.35 99.7441 82.9Z" fill="white" />
      </g>
    </svg>
  </div>
)

export const GridTrashFile = ({ className }: ISvgProps) => (
  <div className={className}>
    <svg width="80" height="100" viewBox="0 0 94 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M67.6119 0H12C5.37258 0 0 5.37258 0 12V98C0 104.627 5.37258 110 12 110H82C88.6274 110 94 104.627 94 98V26.0535C94 22.8219 92.6966 19.727 90.3849 17.469L75.9968 3.41549C73.755 1.22584 70.7456 0 67.6119 0Z" fill="#9FA0A0" />
      <path d="M72.8636 0L94 21.1364V30H64V0H72.8636Z" fill="#9FA0A0" />
      <path d="M69 24V4.41421C69 3.52331 70.0771 3.07714 70.7071 3.70711L90.2929 23.2929C90.9229 23.9229 90.4767 25 89.5858 25H70C69.4477 25 69 24.5523 69 24Z" fill="white" />
      <circle cx="22" cy="22" r="13.5" fill="#565250" stroke="white" />
      <rect x="16.166" y="20.8334" width="11.6667" height="2.33333" fill="white" />
    </svg>
  </div>
)

export const GridTrashFolder = ({ className }: ISvgProps) => (
  <div className={className}>
    <svg width="120" height="96" viewBox="0 0 120 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 10C0 4.47715 4.47715 0 10 0H34.9661L35.0263 0.00428936C36.9007 0.137741 40.9941 0.429185 43.7329 3.55735L51.1155 10.2768C52.346 11.3308 55.7297 13.5048 59.421 13.5048L107.742 13.5049C114.37 13.5049 119.742 18.8775 119.742 25.5049V83.2701C119.742 89.8975 114.37 95.2701 107.742 95.2701H12C5.37259 95.2701 0 89.8975 0 83.2701V10Z" fill="#9FA0A0" />
      <circle cx="98" cy="73" r="13.5" fill="#565250" stroke="white" />
      <rect x="92.166" y="71.8334" width="11.6667" height="2.33333" fill="white" />
    </svg>
  </div>
)

export const TableTrashFile = ({ className }: ISvgProps) => (
  <div className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2954_438897" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2954_438897)">
        <path d="M6 22C5.45 22 4.97933 21.8043 4.588 21.413C4.196 21.021 4 20.55 4 20V4C4 3.45 4.196 2.979 4.588 2.587C4.97933 2.19567 5.45 2 6 2H14L20 8V12H18V9H13V4H6V20H12V22H6Z" fill="#9FA0A0" />
        <path d="M18 13V14L21 14V16L20 16V20C20 21.1046 19.1046 22 18 22H16C14.8954 22 14 21.1046 14 20V16H13V14L16 14V13H18ZM16 16V20H18V16H16Z" fill="#9FA0A0" />
      </g>
      <circle cx="15.5" cy="16.5" r="6" fill="#565250" stroke="white" />
      <rect x="13" y="16" width="5" height="1" fill="white" />
    </svg>
  </div>
)

export const TableTrashFolder = ({ className }: ISvgProps) => (
  <div className={className}>
    <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2.08782C0 0.934746 0.934748 0 2.08782 0H7.30025L7.31282 0.000895537C7.70416 0.0287579 8.5588 0.089606 9.13061 0.74271L10.672 2.14561C10.9289 2.36567 11.6353 2.81955 12.406 2.81955L22.4946 2.81958C23.8783 2.81958 25 3.94128 25 5.32496V17.3853C25 18.769 23.8783 19.8907 22.4946 19.8907H2.50538C1.1217 19.8907 0 18.769 0 17.3853V2.08782Z" fill="#9FA0A0" />
      <circle cx="19.5" cy="16.5" r="6" fill="#565250" stroke="white" />
      <rect x="17" y="16" width="5" height="1" fill="white" />
    </svg>
  </div>
)
