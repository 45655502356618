import Toast from '@components/Toast';
import { ToastContainer } from 'react-bootstrap';
import { IToast } from '@models/toast/Toast.type';

interface IToastsProps {
  toasts: IToast[]
}

const Toasts = ({ toasts }: IToastsProps) => {
  return (
    <>
      <ToastContainer className='c-toasts position-fixed pt-5 mt-5 px-1' position='top-center' >
        {
          toasts.map((toast) => (
            <Toast {...toast} key={toast.id} />
          ))
        }
      </ToastContainer>
    </>
  )
}

export default Toasts
