/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, ReactNode, memo } from "react";

import { Spinner } from "react-activity";
import ResultItem from "./ResultItem";
import MaterialIcon, { TIcon } from "@components/MaterialIcon";
import Filter from "./Filter";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "@hooks/useAppDispatch";
import { setResults } from "@redux/search/slice";

type FilterNamesTypes =
  | "documents"
  | "contacts"
  | "stores"
  | "projects"
  | "tag";

export type IFilter = {
  id: number;
  name: FilterNamesTypes;
  icon?: TIcon;
};

export type Props = {
  results: any; // { [key: string]: Response };
  isLoading: boolean;
  searchValue: string;
  filter: IFilter | null;
  setFilter: React.Dispatch<IFilter | null>;
  showGoToStores: boolean;
};

type IProps = {
  text: string;
  route: string;
};

const GoToBtn: React.FC<IProps> = ({ text, route }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(route)}
      className="d-flex align-items-center o-cl-brand-secondary o-cursor-pointer"
      aria-hidden
    >
      <span className="o-ft-xs-400">{text}</span>
      <MaterialIcon icon="north_east" size={18} />
    </div>
  );
};

type InfoProps = {
  hasResults: boolean;
  value: string;
  isLoading: boolean;
  filter: IFilter | null;
};

const Info: React.FC<InfoProps> = ({
  hasResults,
  value,
  isLoading,
  filter,
}) => {
  const noSearchResults = !hasResults && !isLoading;

  return (
    <div className="c-search-bar-result__title-section">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner size={12} />
          <span className="o-cl-grey-100 o-ft-sm-400 mx-2">
            Loading results...
          </span>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-between">
          <span className="c-search-bar-result__title o-cl-grey-100 o-ft-sm-400">
            {noSearchResults && "NO "}SEARCH RESULT FOR “
            <strong>{value}</strong>”
          </span>
          {noSearchResults &&
            (filter?.name === "stores" || filter?.name === "projects") && (
              <GoToBtn text="Go to My Stores" route="/stores" />
            )}
        </div>
      )}
    </div>
  );
};

type IFiltersProps = {
  onSelect: (filter: IFilter | null) => void;
  filters: IFilter[];
  searchResultsTitle: string;
};

const Filters: React.FC<IFiltersProps> = ({
  onSelect,
  filters,
  searchResultsTitle,
}) => {
  return (
    <div className="c-search-bar-results">
      <div className="c-search-bar-results__filters">
        <p className="o-ft-sm-400 o-cl-grey-100">{searchResultsTitle}</p>
        <div className="d-flex flex-wrap mt-3">
          {filters.map((filter) => {
            if (
              (filter.name === "projects" &&
                process.env.REACT_APP_ENABLED_PROJECT_MODULE === "false") ||
              (filter.name === "stores" &&
                process.env.REACT_APP_ENABLED_STORE_MODULE === "false")
            ) {
              return <></>;
            }

            return (
              <div className="mb-2" key={filter.id}>
                <Filter
                  key={filter.id}
                  filter={filter}
                  onSelect={() => onSelect(filter)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

enum IconColors {
  pdf = "o-cl-red",
  xls = "o-cl-green",
  xlsx = "o-cl-green",
  doc = "o-cl-blue",
  docx = "o-cl-blue",
}

type IListProps = {
  onSelectItem: (obj: { id: number; name: string }) => void;
  onClickViewAll: () => void;
  results: { [key: string]: { data: any[]; links: any; meta: any } };
  viewAllBtn?: boolean;
};

const List = ({
  results,
  onSelectItem,
  viewAllBtn = true,
  onClickViewAll,
}: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <>
      {Object.keys(results || {}).map((name, i) => {
        if ((results[name]?.data || []).length === 0)
          return <Fragment key={i} />;

        return (
          <Fragment key={name}>
            <div className="pb-1">
              {/* <div className='c-search-bar-result__content'> */}
              {results[name].data.map((item: any) => {
                const resultName = item.project_type ? item.code : item.name;

                const iconProps = {
                  name: item.icon,
                  filled: "file" in item && !item.file,
                  color:
                    "file" in item && item.file
                      ? IconColors[item.type as keyof typeof IconColors] ||
                        "o-cl-brand-secondary"
                      : "type" in item && !item.type
                        ? "o-cl-light-blue"
                        : "o-cl-grey-200",
                };

                return (
                  <ResultItem
                    key={item.id}
                    id={item.id}
                    name={resultName}
                    icon={iconProps}
                    route={item.route || ""}
                    isFile={item.type}
                    onSelect={onSelectItem}
                  />
                );
              }) || <></>}
            </div>
          </Fragment>
        );
      })}
      {viewAllBtn && (
        <div
          aria-hidden
          onClick={() => {
            dispatch(setResults(results));
            onClickViewAll();
            navigate("/results");
          }}
          className="py-4 px-4 o-cl-brand-secondary o-ft-sm-700 o-cursor-pointer"
        >
          View All Results
        </div>
      )}
    </>
  );
};

const Results = ({ children }: { children: ReactNode }) => {
  return (
    <div className="c-search-bar-result position-absolute">{children}</div>
  );
};

Results.Info = memo(Info);
Results.Filters = memo(Filters);
Results.List = memo(List);

export default Results;
