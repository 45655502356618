import brandColors from '../brand/theme/colors';

export const colors = {
  brandColorPrimary: brandColors.brandColorPrimary,
  brandColorSecondary: brandColors.brandColorSecondary,
  brandColorTertiary: brandColors.brandColorTertiary,
  brandColorLight: brandColors.brandColorLight,
  white: '#fff',
  black: '#000',
  blue100: '#2A84E5', // Blue 1
  grey100: '#565250', // Grey 1
  grey200: '#9FA0A0', // Grey 2
  grey300: '#F4F5F5', // Grey 3
  gray400: '#ced4da', //Gray 4
  lightBlue: "#85C0FF",
  darkBlue: '#1B4061', // Dark Blue
  darkGray: '#212529',
  green: '#6CC56B', // Green
  red: '#E24325', // Red
  blue: '#2590E2', // Blue
  yellow: '#E6CB26', // Yellow
  transparent: 'transparent',
}
