import React, { useEffect, useState } from 'react';
import Button from '@components/Button';
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import { images } from '@theme/images';

interface IErrorViewProps {
  statusCode: 400 | 401 | 403 | 404 | 500;
  onMounted?: () => void;
  onUnmounted?: () => void;
}

type TError = {
  title: string;
  subtitle: string;
  value: string;
  imageError: string;
  button?: boolean;
}

export default function ErrorView({ statusCode, onMounted, onUnmounted }: IErrorViewProps) {
  const navigate = useNavigate();
  const [error, setError] = useState<TError | undefined>();

  useEffect(() => {
    setError(getErrorDetails());
    (onMounted) && onMounted();
    return () => {
      (onUnmounted) && onUnmounted();
    }
  }, []);

  const getErrorDetails = () => {
    switch (statusCode) {
      case 400:
        return {
          title: 'This page isn’t working at the moment',
          subtitle: 'Please try again later or refresh the page.',
          value: 'Go Back',
          imageError: images.errors.error400,
          button: true,
        };
      case 401:
        return {
          title: 'You need authorization to be here ',
          subtitle: 'This page is not publicly available.\nTo access it please login first.',
          value: 'Login',
          imageError: images.errors.error401,
          button: true,
        };
      case 403:
        return {
          title: 'Access Denied',
          subtitle: 'The page you were trying to get into is forbidden for some reason.',
          value: 'Login ',
          imageError: images.errors.error403,
          button: true,
        };
      case 404:
        return {
          title: 'Something unexpected has happened',
          subtitle: 'We couldn’t find what you were looking for, please try again later.',
          value: '',
          imageError: images.errors.error404,
          button: false,
        };
      case 500:
        return {
          title: 'Something unexpected has happened',
          subtitle: 'We couldn’t find what you were looking for, please try again later.',
          value: 'Go Back',
          imageError: images.errors.error500,
          button: true,
        };
    }
  }

  return (
    <>
      {(!_.isEmpty(error) && !_.isUndefined(error))
        ? (
          <div className='v-error-view o-screen-container w-100 d-flex flex-column-reverse flex-lg-row align-items-center justify-content-around'>
            <div>
              <div className='d-flex flex-column'>
                <span className='v-error-view__title o-ft-2xl-600 o-ft-3xl@md'>{error?.title}</span>
                <span className='v-error-view__subtitle o-ft-xl-300'>{error?.subtitle}</span>
              </div>
              {
                (error?.button) &&
                <div className='mt-5'>
                  <Button
                    value={error?.value}
                    onClick={() => navigate(`/`)}
                  />
                </div>
              }
            </div>
            <div className='v-error-view__image'>
              <img src={error?.imageError} className='w-100' />
            </div>
          </div>
        )
        : <></>
      }
    </>

  );
}
