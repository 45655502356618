import useWindowSize from '@hooks/useWindowSize';
import React from 'react'

const LinkButton: React.FC<{ text: string; route: string; }> = ({ text, route }) => {
  return (
    <p
      onClick={() => window.location.pathname = route}
      className='c-backpack-header__link o-cl-brand-secondary o-ft-xs-400 o-cursor-pointer'
    >
      {text}
    </p>
  )
}

const BackpackHeader: React.FC<any> = ({ info }) => {
  const { responsive } = useWindowSize()

  return (
    <header className='c-backpack-header'>
      <div className='c-backpack-header__column gap-4'>
        <span className='text-truncate o-ft-2xl-400'>{info?.project_process?.process?.name}</span>
        <LinkButton text={ responsive.md ? '<< Back to Process Review' : '<< Back'} route={`/admin/process-review`} />
      </div>
      <div className='c-backpack-header__links gap-4'>
        <div className='d-flex'>
          <span className='o-ft-xs-600'>Store: </span>
          <LinkButton text={info?.store.name} route={`/admin/store/${info?.store.id}/show`} />
        </div>
        <div className='d-flex'>
          <span className='o-ft-xs-600'>Project: </span>
          <LinkButton text={info?.project_process.project.name || info?.project_process.project.code} route={`/admin/project/${info?.project_process.project.id}/show`} />
        </div>
      </div>
    </header>
  )
}

export default BackpackHeader