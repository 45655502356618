/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";

import { Form, Formik } from "formik";
import HttpProvider, { getClientId, getClientSecret } from "@api/HttpProvider";
import React, { useRef, useState } from "react";
import axios, { AxiosError } from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import AuthController from "@controllers/AuthController";
import { CtaBackToLogin } from "@views/Walkthrough-styled";
import TextTimer from "@components/TextTimer";
import { colors } from "@theme/colors";
import { createToast } from "@helpers/createToast";
import styled from "styled-components";
import useAppDispatch from "@hooks/useAppDispatch";

export const CodeInputContainer = styled.div<{ $error: boolean }>(
  ({ $error }) => ({
    width: 51,
    height: 62,
    padding: "0 16px 18px 16px",
    border: `1px solid ${$error ? "red" : "#565250"}`,
    borderRadius: 3,
    fontSize: 20,
    display: "flex",
    alignItems: "end",
    "@media only screen and (min-width: 1025px)": {
      width: 51,
      height: 62,
    },
  }),
);

export const CodeInput = styled.input<{ $value: string }>(({ $value }) => ({
  border: "none",
  borderBottom: $value.length ? "1px solid transparent" : "1px solid #565250",
  padding: 0,
  textAlign: "center",
  fontWeight: 700,
  width: "100%",
  outline: "none",
}));

export const GoBackBtn = styled.button({
  color: colors.brandColorSecondary,
  display: "inline-block",
  width: "fit-content",
  background: "none",
  fontWeight: "bold",
  border: "none",
  borderBottom: `2px solid${colors.brandColorSecondary}`,
  paddingBottom: 5,
});

export const AnchorBtn = styled.button({
  color: colors.brandColorSecondary,
  cursor: "pointer",
  background: "none",
  border: "none",
  padding: 0,
});

const initialValues = {
  code_1: "",
  code_2: "",
  code_3: "",
  code_4: "",
};

type Values = typeof initialValues;

const validationSchema = Yup.object({
  code_1: Yup.string().matches(/^(\d)/).required("This field is required"),
  code_2: Yup.string().matches(/^(\d)/).required("This field is required"),
  code_3: Yup.string().matches(/^(\d)/).required("This field is required"),
  code_4: Yup.string().matches(/^(\d)/).required("This field is required"),
});

const api = new HttpProvider();

/**
 * [X] - resend code
 * [X] - disable submit if not valid
 * [] - paste first four characters --optional
 * [X] - loading submit
 * [] - tfa=1 si hay que redirigir a code
 */

const Code: React.FC<{ currentValues: any; goBack: () => void }> = ({
  currentValues,
  goBack,
}) => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [retryToken, setRetryToken] = useState("");

  const [searchParams] = useSearchParams();

  const [isTimerActive, setIsTimerActive] = useState(true);

  const codeInput1Ref = useRef<HTMLInputElement>(null);
  const codeInput2Ref = useRef<HTMLInputElement>(null);
  const codeInput3Ref = useRef<HTMLInputElement>(null);
  const codeInput4Ref = useRef<HTMLInputElement>(null);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    // Enfocar el siguiente input después de completar el actual
    switch (name) {
      case "code_1":
        if (value.length === 1) {
          codeInput2Ref.current?.focus();
        }
        break;
      case "code_2":
        if (value.length === 1) {
          codeInput3Ref.current?.focus();
        } else {
          codeInput1Ref.current?.focus();
        }
        break;
      case "code_3":
        if (value.length === 1) {
          codeInput4Ref.current?.focus();
        } else {
          codeInput2Ref.current?.focus();
        }
        break;
      case "code_4":
        if (value.length === 0) {
          codeInput3Ref.current?.focus();
        }
        break;
      default:
        break;
    }
  };

  const resendCode = async () => {
    const params = {
      username: searchParams.get("email"),
      phone: `1${currentValues.phoneNumber}`,
    };

    try {
      await api.request("post", "api/auth/phone/verification", params);
      createToast("Code sent successfully", "success", dispatch);
      setIsTimerActive(true);
    } catch (error) {
      createToast("Unexpected error", "danger", dispatch);
    }
  };

  const handleSubmit = async (values: Values) => {
    const body = {
      token: retryToken || token,
      email: searchParams.get("email"),
      password: currentValues.password,
      password_confirmation: currentValues.confirmPassword,
      code: `${codeInput1Ref.current?.value}${codeInput2Ref.current?.value}${codeInput3Ref.current?.value}${codeInput4Ref.current?.value}`,
      phone: `1${currentValues.phoneNumber}`,
      firebase: searchParams.get("firebase"),
    };

    let accessToken = "";

    try {
      const { data } = await AuthController.getClientCredentialToken();
      accessToken = data.access_token;
    } catch (error) {
      if ((error as AxiosError).code === "ERR_NETWORK") {
        createToast("Network error", "danger", dispatch);
        return;
      }

      createToast("Unexpected error", "danger", dispatch);
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_BACK_BASE_URL}api/auth/password/reset`,
        body,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": `application/json`,
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Access-Control-Allow-Credentials": true,
          },
        },
      );
    } catch (error) {
      setRetryToken((error as any).response.data.data.token);
      createToast("Code does not match", "danger", dispatch);
      return;
    }

    try {
      const signInBody = {
        client_id: getClientId(),
        client_secret: getClientSecret(),
        username: searchParams.get("email"),
        password:
          currentValues.password +
          values.code_1 +
          values.code_2 +
          values.code_3 +
          values.code_4,
      };

      const loginResponse = await axios.post(
        `${process.env.REACT_APP_BACK_BASE_URL}api/auth/signin`,
        signInBody,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": `application/json`,
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Access-Control-Allow-Credentials": true,
          },
        },
      );

      localStorage.setItem("access_token", loginResponse.data.access_token);
      localStorage.setItem("refresh_token", loginResponse.data.refresh_token);

      createToast("Correct verification code", "success", dispatch);
      navigate("/dashboard", { replace: true });
    } catch (error) {
      createToast("Error while trying to login", "danger", dispatch);
    }
  };

  return (
    <>
      <div>
        <p className="o-ft-2xl-400 o-cl-grey-100 text-center">
          Enter Verification Code
        </p>
        <p className="o-ft-sm-400 o-cl-grey-100 text-center">
          Please confirm your account by entering the verification code sent to
          ***-***-{currentValues.phoneNumber.slice(-4)}:
        </p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, isSubmitting, isValid, setFieldValue, values }) => {
          return (
            <Form
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <div className="d-flex flex-column align-items-center">
                <div className="d-flex gap-1 gap-md-3 mb-2 mt-3">
                  <CodeInputContainer $error={!!Object.values(errors).length}>
                    <CodeInput
                      $value={values.code_1}
                      autoComplete="off"
                      ref={codeInput1Ref}
                      name="code_1"
                      maxLength={1}
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue("code_1", e.target.value);
                      }}
                      className="o-cl-grey-100"
                    />
                  </CodeInputContainer>
                  <CodeInputContainer $error={!!Object.values(errors).length}>
                    <CodeInput
                      $value={values.code_2}
                      autoComplete="off"
                      ref={codeInput2Ref}
                      name="code_2"
                      maxLength={1}
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue("code_2", e.target.value);
                      }}
                      className="o-cl-grey-100"
                    />
                  </CodeInputContainer>
                  <CodeInputContainer $error={!!Object.values(errors).length}>
                    <CodeInput
                      $value={values.code_3}
                      autoComplete="off"
                      ref={codeInput3Ref}
                      name="code_3"
                      maxLength={1}
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue("code_3", e.target.value);
                      }}
                      className="o-cl-grey-100"
                    />
                  </CodeInputContainer>
                  <CodeInputContainer $error={!!Object.values(errors).length}>
                    <CodeInput
                      $value={values.code_4}
                      autoComplete="off"
                      ref={codeInput4Ref}
                      name="code_4"
                      maxLength={1}
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue("code_4", e.target.value);
                      }}
                      className="o-cl-grey-100"
                    />
                  </CodeInputContainer>
                </div>
                {Object.values(errors).length ? (
                  <p className="o-ft-sm-400 o-cl-red mt-3">Wrong Code</p>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                )}
              </div>

              <div className="d-flex justify-content-center flex-column">
                <CtaBackToLogin
                  aria-disabled={!isValid || isSubmitting}
                  disabled={!isValid || isSubmitting}
                  type="submit"
                >
                  {isSubmitting ? "Sending" : "Submit"}
                </CtaBackToLogin>
                <div className="d-flex justify-content-center">
                  <GoBackBtn onClick={goBack}>Go Back</GoBackBtn>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  {isTimerActive ? (
                    <p>
                      Haven’t received it?{" "}
                      <span className="o-cl-grey-200">
                        Resend a new code in{" "}
                        <TextTimer
                          onTimeOut={() => setIsTimerActive(false)}
                          seconds={120}
                        />
                      </span>
                    </p>
                  ) : (
                    <p>
                      Haven’t received it?{" "}
                      <AnchorBtn type="button" onClick={resendCode}>
                        Resend New Code
                      </AnchorBtn>
                    </p>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Code;
