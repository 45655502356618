import React from 'react'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Spinner } from 'react-activity';

import MaterialIcon from '@components/MaterialIcon';
import { ProcessCard } from '@components/ProcessCard';

import emptyState from '../../../../assets/no-results.svg';

import useWindowSize from '@hooks/useWindowSize';
import { colors } from '@theme/colors';
import useAppDispatch from '@hooks/useAppDispatch';
import { setSelectedProcess } from '@redux/my-stores/slice';
import { OutletContext } from '@views/MyStores/Layout';

const Project: React.FC = () => {
	const { responsive } = useWindowSize()

	const dispatch = useAppDispatch()

	const navigate = useNavigate()
	const { pathname } = useLocation()

	const { processes: { data, isLoading } } = useOutletContext<OutletContext>();

	const goToProcess = (process: any) => {
		dispatch(setSelectedProcess(process))
		const route = `${pathname}/${process.name.replaceAll(' ', '-')}-${process.id}`
		navigate(route)
	}

	// email details project information
	const emailTo = 'stores@franly.com';
	const subject = 'Access Request to [Project ID]';
	const body =
		'Please enter below the information needed for this request.' +
		'%0D%0A%0D%0A' +
		'Project ID: [complete project id here]' +
		'%0D%0A%0D%0A' +
		'Access requested for the following users:' +
		'%0D%0A%0D%0A' +
		'• [user email 1]:' +
		'%0D%0A' +
		'• [user email 2]:' +
		'%0D%0A' +
		'• ...'

	return (
		<>
			<div className='mb-4'>
				<a className='email-button o-cl-brand-secondary text-decoration-none' href={`mailto:${emailTo}?subject=${subject}&body=${body}`}>
					<MaterialIcon icon='forward_to_inbox' />
					<span className='request-text'>Request Access</span>
				</a>
			</div>

			<>
				{isLoading ? (
					<div className='d-flex justify-content-center mt-5'>
						<Spinner className='v-contacts__spinner' color={colors.brandColorSecondary} />
					</div>
				) : !data || data.length === 0 ? (
					<div className='d-flex flex-column justify-content-center align-items-center w-100'>
						<img className="mt-4" src={emptyState} alt="empty state" width={responsive.md ? 350 : 250} />
						<h3 className={`o-cl-dark-grey mt-4 mb-3 ${responsive.md ? 'o-ft-2xl-600' : 'o-ft-xl-600'}`}>There are no processes here!</h3>
						<p className={`v-my-stores__empty-state-description o-cl-dark-grey text-center ${responsive.md ? 'o-ft-lg-400' : 'o-ft-sm-400'}`}>
							It seems that this project doesn't have any processses associated yet, <br />
							please contact the administrator for more information.
						</p>
					</div>
				) : (
					<div className={`c-grid c-grid--sm-layout mb-3 ${!responsive.md ? 'c-grid--no-gap' : ''}`}>
						{data && data.length > 0 && data.map(({ process }) => (
							<div className='c-grid__child o-drag-border' key={process.id}>
								<ProcessCard
									onClick={() => goToProcess(process)}
									title={process.name}
									background={process?.background}
									icon='check'
								/>
							</div>
						))}
					</div>
				)}
			</>
		</>
	)
}

export default Project;