import React from 'react';

interface IFormErrorProps {
  error: string;
}

export default function FormError({ error }: IFormErrorProps) {
  return (
    <div className="c-form-error">
      <span className="c-form-error__error o-ft-sm-700">{error}</span>
      <span className="c-form-error__message o-ft-sm">Please try again.</span>
    </div>
  );
}
