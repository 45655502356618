import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


const baseUrl = process.env.REACT_APP_BACK_BASE_URL;

export const brandsApi = createApi({
  reducerPath: 'brandsApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getBrands: builder.query<any, void>({
      query: () => ({ url: 'api/brands' }),
      transformResponse: (res: any) => res.data
    }),
  }),
});

export const {
    useGetBrandsQuery: useGetBrands
  } = brandsApi;