import styled from "styled-components";

export const CaptionSpan = styled.span`
  color: var(--Grey-1, #565250);
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 5px;
`;
