import React, { useEffect, useState } from 'react'
import AdminHeaderModule from '@components/AdminHeaderModule'
import useAppSelector from '@hooks/useAppSelector';
import { IRow } from '@models/Table.type';
import FileName from '@components/FileName';
import { formatDate } from '@helpers/formatDate';
import Button from '@components/Button';
import useWindowSize from '@hooks/useWindowSize';
import Table from '@components/Table';
import useAppDispatch from '@hooks/useAppDispatch';
import CurrentVersionButton from '@components/CurrentVersionButton';
import _ from 'lodash';
import { IFile } from '@models/files/File.type';
import DotsMenu from '@components/DotsMenu';
import { TIcon } from '@components/MaterialIcon';
import ActionModal from '@components/ActionModal';
import { getVersionsHistory, restoreVersion } from '@redux/files/thunks/fileThunk';
import { formatWeight } from '@helpers/formatWeight';
import { formatHour } from '@helpers/formatHour';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-activity';
import { colors } from '@theme/colors';

export default function VersionHistory() {
  const { responsive } = useWindowSize();
  const { file: { selectedFiles, history: { data: historyData, pending } } } = useAppSelector((state) => state);
  const { trash: { data } } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const _data = { ...data, name: 'history' }; //remove hardcoded name when functionality is ready
  const [showRestoreModal, setShowRestoreModal] = useState<boolean>(false);
  const [versionFileToRestore, setVersionFileToRestore] = useState<IFile>();

  const firstId: number = historyData[0]?.id;

  const fileStatusButton = (file: IRow): JSX.Element => {
    return (_.isEqual(firstId, file?.id))
      ? <CurrentVersionButton text='Current Version' />
      : (
        <Button
          onClick={() => {
            localStorage.setItem('version_file_to_restore', JSON.stringify(file));
            setVersionFileToRestore(file as IFile);
            setShowRestoreModal(true);
          }}
          className='v-version-history__button'
          value="Restore"
        />
      );
  }

  const isFirstId = (id: number) => _.isEqual(firstId, id);

  const actionDotsMobile = (file: IRow): JSX.Element => {
    return (!_.isEqual(firstId, file?.id))
      ? <DotsMenu dotsDirection="vertical" actionList={actionList} context={file} />
      : <></>
  }

  const _file_restore: TIcon = 'settings_backup_restore';

  const actionList = [
    {
      id: "restore", icon: _file_restore, text: 'Restore', onClick: (file: IFile) => {
        localStorage.setItem('version_file_to_restore', JSON.stringify(file));
        setVersionFileToRestore(file);
        setShowRestoreModal(true);
      }
    }
  ];

  let columns = [
    { header: 'Name', id: 'name', colspan: { xs: '3', sm: '6' }, field: 'name', body: ({ id, name, type }: IRow) => <FileName isGrayIcon={!isFirstId(id)} name={`${name}`} type={type} /> },
    { header: 'Date', id: 'updated_at', colspan: { xs: '2' }, field: 'updated_at', parsedString: ({ file: { created_at } }: IRow) => formatDate(created_at), fixedWidth: { xs: '100px', sm: '2' } },
    { header: 'Hour', id: 'hour', colspan: { xs: '2' }, field: 'hour', parsedString: ({ file: { created_at } }: IRow) => formatHour(created_at), fixedWidth: { xs: '100px', sm: '2' }, collapsable: true },
    { header: 'Type', id: 'type', colspan: { sm: '2' }, field: 'type', parsedString: ({ type }: IRow) => `.${type}`, collapsable: true },
    { header: 'Edited By', id: 'edited_by', colspan: { xs: '2', sm: '2' }, field: 'edited_by', parsedString: ({ edited_by }: IRow) => edited_by || ` `, collapsable: true },
    { header: 'Size', id: 'size', colspan: { xs: '2', sm: '2' }, field: 'size', parsedString: ({ size }: IRow) => formatWeight(size, true), collapsable: true },
    {
      header: '', id: 'restore', colspan: { xs: '2' }, field: '',
      body: (file: IRow) => fileStatusButton(file)
      , collapsable: true, hiddenMobileTitle: true, fixedWidth: { xs: '40px', lg: '212px' }
    },
    {
      header: '', id: 'action', colspan: { xs: '2' }, field: '',
      body: (file: IRow) => actionDotsMobile(file)
      , collapsableDesktop: true, hiddenMobileTitle: true, fixedWidth: { xs: '40px', lg: '0px' }
    },
  ];

  useEffect(() => {
    const file: IFile = JSON.parse(localStorage.getItem('current_file_history')!);
    dispatch(getVersionsHistory(file.id));
  }, []);

  const handleSubmit = () => {
    const versionFileToRestore: IFile = JSON.parse(localStorage.getItem('version_file_to_restore')!);
    dispatch(restoreVersion(versionFileToRestore)).then(() => {
      setShowRestoreModal(false);
      navigate(`/admin/file-management/${versionFileToRestore.parent_id || ''}`, { replace: true });
    });
  }

  return (
    <>
      <ActionModal
        show={showRestoreModal}
        onHide={() => setShowRestoreModal(false)}
        title='Restore Version'
        subtitle={`“${versionFileToRestore?.name}.${versionFileToRestore?.type}” saved on ${formatDate(versionFileToRestore?.file.created_at!)} - at ${formatHour(versionFileToRestore?.file.created_at!)}hs will be restored. It will replace the current version.`}
        buttonLeftText="Cancel"
        buttonRightText="Continue"
        onClickLeftButton={() => setShowRestoreModal(false)}
        onClickRightButton={handleSubmit}
      />
      <div className='v-version-history o-admin-screen-container o-padding-module w-100' >
        {
          data &&
          <AdminHeaderModule
            folder={_data}
            hideBreadcrumb
          />
        }

        <p className='o-ft-lg o-cl-grey-100'>Restore any available version of this file. All versions will keep saved.</p>
        <div>
          {
            (pending)
              ? <div className='d-flex justify-content-center mt-5'><Spinner className='o-ft-xl o-ft-3xl@md' color={colors.brandColorSecondary} /></div>
              : (
                <Table
                  dynamicRowClassName={
                    ({ id }: IRow) => (responsive.md)
                      ? (
                        selectedFiles.some((selectedFile) => _.isEqual(selectedFile.id, id)))
                        ? "o-bg-grey-300 c-table__row--selected"
                        : "o-bg-grey-300:hover"

                      : "o-bg-transparent"
                  }
                  data={historyData}
                  // data={data?.children ? data?.children : []}
                  {...{ columns }}
                  extraClassName={`${responsive.md && "c-table--selectable mt-md-3"}`}
                />
              )
          }
        </div>
      </div>
    </>
  )
}
