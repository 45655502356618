import { EToastTypes, IToastBody } from "@models/toast/Toast.type";
import { addToast } from "@redux/toasts/slices/toastsSlice";

export const errorRequest = (err: { response: { data: any; } } | any, rejectWithValue: (arg0: any) => any, dispatch: (arg0: { payload: IToastBody; type: string; }) => void) => {
  if (window.Noty) {
    new window.Noty({
      type: "danger",
      text: 'Something went wrong while processing your request. Please try again.',
    }).show();
  } else {
    let toast = {
      id: Date.now(),
      text: 'Something went wrong while processing your request. Please try again.',
      type: EToastTypes.DANGER,
      autohide: true
    }
    dispatch(addToast(toast));
  }
  return rejectWithValue(err.response.data);
}
