/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISlice {
  results: {
    [key: string]: any;
  };
}

const initialState: ISlice = {
  results: {
    documents: { data: [] },
    contacts: { data: [] },
    stores: { data: [] },
    projects: { data: [] },
  },
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setResults: (state, action: PayloadAction<any>) => {
      state.results = action.payload;
    },
    addNextPageResults: (
      state: any,
      action: PayloadAction<{ type: string; data: any }>,
    ) => {
      state.results[action.payload.type] = {
        ...state.results[action.payload.type],
        ...action.payload.data,
        data: [
          ...state.results[action.payload.type].data,
          ...action.payload.data.data,
        ],
      };
    },
    clearResults: (state) => {
      state = initialState;
    },
    setPinnedData: (
      state,
      action: PayloadAction<{
        documentId: number;
        pinAttribute: "is_dashboard_pinned" | "is_bottom_bar_pinned";
        isPinned: boolean;
      }>,
    ) => {
      const { documentId, pinAttribute, isPinned } = action.payload;
      state.results.documents.data = state.results.documents.data.map(
        (document: any) => {
          if (documentId === document.id) {
            return { ...document, [pinAttribute]: isPinned };
          }
          return document;
        },
      );
    },
  },
});

export const { setResults, clearResults, addNextPageResults, setPinnedData } =
  searchSlice.actions;
export default searchSlice.reducer;
