import React from 'react';
import { TFavoriteRelation, updateFavorite } from '@redux/favorites/slices/favoriteSlice';
import _ from 'lodash';
import { v4 } from 'uuid';
import useAppDispatch from '@hooks/useAppDispatch';
import { addFavorites, removeFavorites } from '@redux/favorites/thunks/favoritesThunk';
import { IFile } from '@models/files/File.type';
import useAppSelector from '@hooks/useAppSelector';

export const useHandleFavorite = () => {
  const dispatch = useAppDispatch();
  const { favorites: { saved: favorites } } = useAppSelector((state) => state);

  const includes = (fileId: number) => favorites?.some((o: TFavoriteRelation) => _.isEqual(o.document_id, fileId));
  const saved = (data: IFile) => includes(data.id) || false;

  const handleFavorite = (data: IFile) => {
    let favoriteId: any = 0;
    if (saved(data)) {
      favoriteId = _.find(favorites, (o: TFavoriteRelation) => _.isEqual(o.document_id, data.id))?.favorite_id || 0;
    }

    const added = saved(data);
    const _favoriteId = v4();
    if (favoriteId) {
      dispatch(updateFavorite({ data, added, favoriteId }));
      if (added)
        dispatch(removeFavorites({ data, id: favoriteId }));
    }
    else {
      dispatch(updateFavorite({ data, added, favoriteId: _favoriteId }));
      dispatch(addFavorites(data));
    }
  }

  return {
    handleFavorite,
    saved,
    // saved: saved(data),
  };
}
