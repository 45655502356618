import HeaderModule from "@components/HeaderModule";
import { ProcessCard } from "@components/ProcessCard";
import React from "react";
import { Spinner } from "react-activity";
import { colors } from "@theme/colors";
import useAppSelector from "@hooks/useAppSelector";
import { useNavigate } from "react-router-dom";

const ApprovalProcess = () => {
  const { data, pending } = useAppSelector((state) => state.process);
  const navigate = useNavigate();

  const renderProcessItems = data.map((item) => (
    <ProcessCard
      key={item.id}
      onClick={() => navigate(`${item.id}`)}
      title={item.name}
      description={item.description}
      background={item?.background}
    />
  ));

  return (
    <div className="o-screen-container">
      <HeaderModule title="Processes" withBreadcrumb />

      {pending ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner
            className="v-contacts__spinner"
            color={colors.brandColorSecondary}
          />
        </div>
      ) : (
        <>
          <div className="py-1" />
          <div className="c-grid mb-3 c-grid--sm-layout">
            {renderProcessItems}
          </div>
        </>
      )}
    </div>
  );
};

export default ApprovalProcess;
