import _ from 'lodash';
import React, { useState } from 'react';
import Icon from '@components/IconMoon';
import { colors } from '@theme/colors';
import ClipboardCopy from '@components/ClipboardCopy'
import Tooltip from './Tooltip';
import MaterialIcon, { TIcon } from './MaterialIcon';

export type TSocialContact = {
  icon: TIcon | 'linkedin';
  value: null | string;
}

interface ISocialContactItemProps {
  icon: TSocialContact['icon'];
  value: TSocialContact['value'];
  index: number;
}

export default function SocialContactItem({ icon, value }: ISocialContactItemProps) {
  const [show, setShow] = useState(false);
  let hover = false;

  const onMouseEnter = () => {
    hover = true;
    setShow(true);
  }

  const onMouseLeave = () => {
    hover = false;
    setTimeout(() => (!hover) && setShow(false), 200);
  }

  return (
    <div
      {...{ onMouseEnter, onMouseLeave }}
      className='o-max-width position-relative mx-2'
    >
      {(show && !_.isNull(value)) && <Tooltip render={<ClipboardCopy text={value} />} />}

      <div className='c-card__link d-flex align-items-center justify-content-center'>
        {
          _.isEqual(icon, 'linkedin') ?
            <Icon
              icon='linkedin'
              size={16}
              color={colors.white}
            />
            :
            <MaterialIcon
              className='o-max-width'
              size={18}
              color='o-cl-white'
              icon={icon}
            />

        } </div>
    </div>
  );
}
