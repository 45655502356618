import React from 'react';
import Tag, { TFilterTag, TFilterTagClear } from '@components/FilterTag';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

interface ITagListProps {
  list: TFilterTag[];
  onClick: (obj: TFilterTag) => void;
}

const FilterTagList = ({ list, onClick }: ITagListProps) => {
  const tagClear: TFilterTagClear = { id: uuidv4(), name: "Clear All" };

  return (
    <div className='c-filter-tag-list position-relative pt-md-2'>
      {list.map((item) =>
        <Tag key={item.id} {...{ item, onClick }} />
      )}
      {!_.isEmpty(list) && <Tag item={tagClear} onClick={onClick} />}
    </div>
  );
};

export default FilterTagList;
