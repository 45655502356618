import React, { useContext } from 'react';
import TabButton from './TabButton';
import { PanelTabsContext } from './PanelTabs';

export interface ITabsProps {
  id: number;
  title: string;
}

export default function Tabs() {

  const { tabs } = useContext(PanelTabsContext)

  return (
    <div className='d-flex'>
      {tabs.map((tab) =>
        <TabButton key={tab.id} tab={tab} />
      )}
    </div>
  )
}
