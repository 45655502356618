/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

export const api = axios.create({
  timeout: 0,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods":
      "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Expose-Headers": "*",
  },
});
