import { NavLink, useLocation } from "react-router-dom";

import MaterialIcon from "./MaterialIcon";
import React from "react";
import _ from "lodash";
import classNames from "classnames";
import useAppSelector from "@hooks/useAppSelector";
import useWindowSize from "@hooks/useWindowSize";

const Heart = ({ isActive }: { isActive: boolean }) =>
  isActive ? (
    <MaterialIcon
      icon="favorite"
      color="o-cl-brand-primary"
      size={24}
      className="material-symbols-filled"
    />
  ) : (
    <MaterialIcon icon="favorite_border" color="o-cl-grey-200" size={24} />
  );

const FavoriteLink = () => {
  const location = useLocation();
  const { responsive } = useWindowSize();
  const isActive = _.isEqual(location.pathname, "/favorites");
  const {
    favorites: { saved },
  } = useAppSelector((state) => state);
  const value = saved.length;
  const favoritePages = value >= 1000 ? "+1k" : value;

  const classLink = classNames({
    "c-favorite-link__link--active": isActive,
  });

  return (
    <NavLink
      id="favoritesOnboarding"
      to="favorites"
      className="text-decoration-none"
    >
      <div className="c-favorite-link__desktop-section d-none d-md-flex align-items-md-center justify-content-md-center">
        <Heart isActive={isActive} />
        <div className="c-favorite-link__link-section">
          <span className={`c-favorite-link__link o-ft-base-300 ${classLink}`}>
            Favorites ({favoritePages})
          </span>
        </div>
      </div>
      {!responsive.md && <Heart isActive={isActive} />}
    </NavLink>
  );
};

export default FavoriteLink;
