/* eslint-disable react/jsx-no-useless-fragment */
import React, { Navigate } from "react-router-dom";

interface PublicRouterProps {
  children: JSX.Element | JSX.Element[];
}

export const PublicRouter = ({ children }: PublicRouterProps) => {
  const hasPhoneNumber = localStorage.getItem("has_phone_number");

  const isLogged = localStorage.getItem("access_token") && !hasPhoneNumber;

  return <>{isLogged ? <Navigate to="/dashboard" replace /> : children}</>;
};
