import React, { ReactElement, useRef } from "react";

import { FilterDropdown } from "@components/filters/FilterDropdown";
import { FilterDropdownList } from "@components/filters/FilterDropdownList";
import MaterialIcon from "../MaterialIcon";
import { useDropdown } from "@hooks/useDropdown";

interface IFilterProps {
  children?: ReactElement | ReactElement[];
  placeholder?: string;
}

export const Filter = ({
  children,
  placeholder = "Filter by...",
}: IFilterProps) => {
  const ref = useRef(null);
  const { onClick, show } = useDropdown({ ref });

  return (
    <div ref={ref} className="position-relative">
      <div
        className="c-filter o-cursor-pointer d-flex align-items-center"
        onClick={onClick}
      >
        <span className="c-filter__text o-ft-sm-400">{placeholder}</span>
        <MaterialIcon
          className="c-button__icon"
          icon={show ? "expand_less" : "expand_more"}
          size={24}
          color="o-cl-brand-secondary"
        />
      </div>
      {show && children}
    </div>
  );
};

Filter.Dropdown = FilterDropdown;
Filter.DropdownList = FilterDropdownList;
