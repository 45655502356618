import * as Yup from "yup";

import { ErrorMessage, Form, Formik } from "formik";
import { InputSubtitle, InputText } from "@views/WalkthroughSteps/styled-step1";

import { CtaBackToLogin } from "@views/Walkthrough-styled";
import PasswordInput from "@components/PasswordInput/PasswordInput";
import React from "react";

const initialValues = {
  password: "",
  confirmPassword: "",
};

type Values = typeof initialValues;

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8)
    .required("This field is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special symbol",
    ),
  confirmPassword: Yup.string()
    .required("This field is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

/**
 * [] - tfa=1 si hay que redirigir a code
 * [] - agregar ojito al password input
 */

const PasswordForm: React.FC<{
  onNext: (values: Values) => void;
  isSubmitting: boolean;
}> = ({ onNext, isSubmitting }) => {
  const handleSubmit = (values: Values) => {
    onNext(values);
  };

  return (
    <>
      <div>
        <p className="o-ft-2xl-400 text-center">Create Your Password</p>
        <p className="o-ft-sm-400 text-center">
          Create a password to finish your account setup.
        </p>
      </div>
      <div className="mt-5">
        <p className="o-ft-xs-400">
          Please create a secure password that includes the following:
        </p>
        <ul>
          <li className="o-ft-xs-400">At least 8 characters in length</li>
          <li className="o-ft-xs-400">
            A combination of lowercase and uppercase letters
          </li>
          <li className="o-ft-xs-400">At least one number (0-9)</li>
          <li className="o-ft-xs-400">
            At least one special symbol (e.g., !, @, #, $, %)
          </li>
        </ul>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {({ errors, touched, isValid }) => (
          <Form
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <div className="d-flex gap-3 flex-column">
              <div>
                <InputSubtitle $error={!!errors.password && !!touched.password}>
                  Password
                </InputSubtitle>
                <InputText $error={!!errors.password}>
                  <PasswordInput name="password" placeholder="Enter Password" />
                  <ErrorMessage
                    name="password"
                    render={(error) => (
                      <div className="o-ft-xs-400 o-cl-red my-1 mx-2">
                        {error}
                      </div>
                    )}
                  />
                </InputText>
              </div>

              <div>
                <InputSubtitle
                  $error={!!errors.confirmPassword && !!touched.confirmPassword}
                >
                  Confirm Password
                </InputSubtitle>
                <InputText $error={!!errors.confirmPassword}>
                  <PasswordInput
                    name="confirmPassword"
                    placeholder="Enter Password"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    render={(error) => (
                      <div className="o-ft-xs-400 o-cl-red my-1 mx-2">
                        {error}
                      </div>
                    )}
                  />
                </InputText>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <CtaBackToLogin
                aria-disabled={isSubmitting || !isValid}
                disabled={isSubmitting || !isValid}
                type="submit"
              >
                Log In
              </CtaBackToLogin>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PasswordForm;
