import React, { useEffect, useState } from 'react';
import TContact from '@models/Contact.type';
import { TSocialContact } from './SocialContactItem';
import ContactList from './SocialContactList';
import profilePicture from '../assets/profile-img.png';
import { TIcon } from './MaterialIcon';

interface IContactTableRowProps {
  item: TContact;
}

export default function ContactTableRow({ item }: IContactTableRowProps) {
  const {
    name, company,
    position, email, phone,
    website, linkedin, contact_type_id,
    avatar_file
  } = item;

  const [socialContacts, setSocialContacts] = useState<TSocialContact[]>([]);
  const img = avatar_file?.url || profilePicture;

  const _email: TIcon = 'email';
  const _phone: TIcon = 'phone';
  const _linkedin: TIcon | 'linkedin' = 'linkedin';
  const _language: TIcon = 'language';

  const getSocialContactsArray = () => [
    { icon: _phone, value: phone },
    { icon: _email, value: email },
    { icon: _linkedin, value: linkedin },
    { icon: _language, value: website },
  ];

  useEffect(() => {
    setSocialContacts(getSocialContactsArray());
  }, []);

  const renderTypeOfContact = (contact_type_id: number) => {
    switch (contact_type_id) {
      case 1:
        return 'Internal';
      case 2:
        return 'External';
      case 3:
        return 'Company';
      default:
        return ' ';
    }
  }
  return (
    <tr className='c-contact-table-row'>
      <td className="c-contact-table-row__col">
        <div className='d-flex flex-row'>
          <div className="c-contact-table-row__img-container d-flex flex-shrink-0">
            <img src={img} alt={name} className='c-contact-table-row__img' />
          </div>
          <div className='d-flex align-items-center'>
            {(name.length > 100) ? <span className='c-contact-table-row__name o-ft-sm-700 ms-2'>{name.substring(0, 40).concat('...')}</span> : <span className='c-contact-table-row__name o-ft-sm-700 ms-2'>{name}</span>}
          </div>
        </div>
      </td>
      <td className="c-contact-table-row__col c-contact-table-row--collapsable">
        <span className="c-contact-table-row__text o-ft-sm-400">{company?.name}</span>
      </td>
      <td className="c-contact-table-row__col c-contact-table-row--collapsable">
        <span className="c-contact-table-row__text o-ft-sm-400">{renderTypeOfContact(contact_type_id)}</span>
      </td>
      <td className="c-contact-table-row__col c-contact-table-row--collapsable">
        <span className="c-contact-table-row__text o-ft-sm-400">{position}</span>
      </td>
      <td className="c-contact-table-row__col">
        <ContactList list={socialContacts} alignmentRight />
      </td>
    </tr >
  )
}
