import React, { useRef } from 'react'

import MaterialIcon from '@components/MaterialIcon'
import { Card } from '../types';
import { useFormContext } from 'react-hook-form';
import { Values } from '../Create';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities'
import Button from '@components/Button';
import { useDropdown } from '@hooks/useDropdown';
import useWindowSize from '@hooks/useWindowSize';

type Props = {
	card: Card;
	index: number;
	copy: (c: any) => void;
	update: (id: number, c: any) => void;
	remove: () => void;
	warning: boolean;
	setWarning: React.Dispatch<Card | undefined>;
	onMove?: (oldIndex: number, newIndex: number) => void;
}

const FormCard: React.FC<Props> = ({ index, copy, remove, update, card, warning, setWarning, onMove }) => {
	const { responsive } = useWindowSize()
	const { attributes, setNodeRef, transform, transition, listeners } = useSortable({ id: card.id })
	const { register, formState: { errors }, watch } = useFormContext<Values>()

	const values = watch()

  const ref = useRef(null);
  const { onClick, show } = useDropdown({ ref });

	const handleCopy = () => {
		if (card.inputs.length === 0) {
			setWarning(card)
			return
		}

		copy({ ...values.cards[index] })
	}

  const toggleRequired = () => {
    update(index, { ...values.cards[index], required: !card.required })
  }

  const selectInputs = (inputs: { id?: number; title: string; description: string; code: string }[]) => {
    update(index, { ...values.cards[index], inputs })
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div ref={setNodeRef} style={style} className={`c-form-card__container position-relative my-4 ${!responsive.md ? 'p-4' : ''} ${warning ? 'c-form-card--warn' : ''}`}>
      <div {...attributes} {...listeners}>
        <MaterialIcon {...attributes} {...listeners} icon='open_with' className='c-form-card__drag-icon' />
      </div>

			<div className='c-form-card__corner-actions'>
				{/* flecha arriba */}
				{index > 0 && (
					<div className='c-form-card__corner-actions__btn' onClick={() => onMove?.(index, index - 1)}>
						<MaterialIcon icon='expand_less' size={20} />
					</div>
				)}
				{/* flecha abajo */}
				{index >= 0 && index !== values.cards.length - 1 && (
					<div className='c-form-card__corner-actions__btn' onClick={() => onMove?.(index, index + 1)}>
						<MaterialIcon icon='expand_more' size={20} />
					</div>
				)}
			</div>


      <div className='d-flex flex-column w-100'>
        <div className='d-flex flex-column mb-4'>
          <input {...register(`cards.${index}.title`)} placeholder='Enter a title or question' defaultValue={card.title} />
					<div className={`d-flex ${errors.cards?.[index]?.title ? 'justify-content-between' : 'justify-content-end'}`}>
          	{errors.cards?.[index]?.title && <span className='o-cl-red'>{errors.cards?.[index]?.title?.message}</span>}
						<span className='o-cl-grey-200 o-ft-sm-400'>Characters: {values.cards?.[index].title?.length}/255</span>
					</div>
        </div>
				<div className='d-flex flex-column'> 
        	<input placeholder='Enter a description (optional)' defaultValue={card.description} {...register(`cards.${index}.description`)} />
					<div className='d-flex justify-content-end'>
						<span className='o-cl-grey-200 o-ft-sm-400'>Characters: {values.cards?.[index].description?.length}/255</span>
					</div>
				</div>
      </div>

      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))', gap: '2rem' }} className='mt-4'>
        {card.inputs.map((input, i) => {
          if (input.code === 'file_multiple') {
            return (
              <div key={i} className='d-flex flex-column mt-4'>
                <Button
                  className='c-form-card__upload-button'
                  size='medium'
                  icon={{ icon: 'upload', size: 24, position: 'left' }}
                  value={input.title}
                  disabled
                />
                <span className='o-cl-grey-200 mt-2'>{input.description}</span>
              </div>
            )
          }

          if (input.code === 'textarea') {
            return (
              <div key={i} className='d-flex flex-column w-100 mb-3'>
                <label className='o-cl-grey-200 o-ft-sm-400 mb-1 mx-2'>{input.title}</label>
                <textarea rows={4} disabled placeholder={`${input.description.replace('(optional)', '')} ${card.required ? '' : '(optional)'}`}></textarea>
              </div>
            )
          }

          if (input.code === 'text') {
            return (
              <div key={i} className='d-flex flex-column w-100 mb-3'>
                <label className='mx-2 o-cl-grey-200 o-ft-sm-400 mb-1'>{input.title}</label>
                <input type="text" placeholder={input.description} disabled />
              </div>
            )
          }
        })}
      </div>

			<div className={`d-flex justify-content-between mt-5 ${!responsive.md ? 'flex-column gap-4' : ''}`}>
				<div className={`${card.inputs.length === 0 ? 'visible' : !responsive.md ? 'd-none' : 'invisible'}`}>
					<span className={`o-ft-sm-400 ${warning ? 'o-cl-brand-secondary' : ''}`}>Tap the three dots on the right-hand side to finish the configuration.</span>
				</div>
				<div className='c-form-card__actions o-user-select-none'>
					<div className='c-form-card__action c-form-card__action--toggle' onClick={toggleRequired}>
						<span className={`${responsive.md ? 'fw-bold' : 'o-ft-xs-400'}`}>Required</span>
						<MaterialIcon size={!responsive.md ? 24 : 30} color={card.required ? 'o-cl-brand-secondary' : ''} icon={card.required ? 'toggle_on' : 'toggle_off'} />
					</div>
					<MaterialIcon size={!responsive.md ? 20 : 24} icon='content_copy' className='c-form-card__action' onClick={handleCopy} />
					<MaterialIcon size={!responsive.md ? 20 : 24} icon='delete' className='c-form-card__action' onClick={remove} />
					<div className='position-relative' ref={ref}>
						<div>
							<MaterialIcon size={!responsive.md ? 20 : 24} icon='more_vert' className='c-form-card__action' color={(show || warning) ? 'o-cl-brand-secondary' : ''} onClick={onClick} />
						</div>
						{show && (
							<ul className='c-form-card__dropdown'>
								<li
									className={card.inputs.some(i => i.code === 'textarea') ? 'selected' : ''}
									onClick={() => selectInputs([{ id: card.inputs[0]?.id || undefined, code: 'textarea', title: 'Text Area for Users', description: 'Add a comment' }])}
								>
									Select Text Input
								</li>
								<li
									className={card.inputs.some(i => i.code === 'file_multiple') ? 'selected' : ''}
									onClick={() => selectInputs([{ id: card.inputs[0]?.id || undefined, code: 'file_multiple', title: 'Upload File', description: 'Maximum of weight: 512MB' }])}
								>
									Select Upload Input
								</li>
								<li
									className={card.inputs.some(i => i.code === 'text') ? 'selected' : ''}
									onClick={() => selectInputs([
										{ id: card.inputs[0]?.id || undefined, code: 'text', title: "User's First Name", description: 'Enter First Name' },
										{ id: card.inputs[1]?.id || undefined, code: 'text', title: "User's Last Name", description: 'Enter Last Name' }
									])}
								>
									Select Signature Input
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default React.memo(FormCard)
