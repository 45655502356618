import React from 'react';
import useWindowSize from '@hooks/useWindowSize';

interface IFormViewProps {
  children: JSX.Element | JSX.Element[];
  renderHeader: JSX.Element;
  renderFooter?: JSX.Element;
}

const FormView = ({ children, renderHeader, renderFooter }: IFormViewProps) => {
  const { responsive } = useWindowSize();

  return (
    <div className="c-form-view d-flex justify-content-center align-items-center">
      <div className="c-form-view__layer">
        <div className="c-form-view__content row justify-content-center align-items-center min-vh-100">
          <div className="col-12 col-md-6 col-xl-4 col-xxl-4">
            <div className="c-form-view__form-container d-flex flex-column">
              <div className='d-flex flex-column flex-grow-1'>
                {renderHeader}
                {children}
              </div>
              <div className='d-flex justify-content-center'>
                {(responsive.md) && renderFooter}
              </div>
            </div>
          </div>
          {(!responsive.md) && renderFooter}
        </div>
      </div>
    </div>
  );
};

export default FormView;
