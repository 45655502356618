import React, { useRef, useState } from 'react'
import MaterialIcon from './MaterialIcon'
import useHandleClickOutside from '@hooks/useHandleClickOutside';
import useAppSelector from '@hooks/useAppSelector';
import { downloadFiles } from '@helpers/downloadFiles';
import useAppDispatch from '@hooks/useAppDispatch';
import { trackInteractions } from '@redux/files/thunks/fileThunk';
import { IFile } from '@models/files/File.type';
interface IFileActionButtonProps {
  text?: string,
}
export default function FileActionButton({ text }: IFileActionButtonProps) {
  const [show, setShow] = useState(false);

  const onClick = () => setShow(!show);

  const ref = useRef<null | HTMLDivElement>(null)
  useHandleClickOutside(ref, () => setShow(false));
  const dispatch = useAppDispatch();

  const { file: { selectedFiles } } = useAppSelector((state) => state);

  const handleDownload = () => {
    downloadFiles(selectedFiles, dispatch);
    const ids = selectedFiles.map((i: IFile) => i?.id);
    dispatch(trackInteractions({ data: ids, type: 'download' }));
  }

  return (
    <div ref={ref}>
      <div className='c-file-action-button d-flex align-items-center o-cursor-pointer' onClick={onClick}>
        <MaterialIcon
          icon='more_vert'
          color='o-cl-grey-200'
          size={24}
        />
      </div>

      {(show && selectedFiles.length > 0) &&
        <div
          className='c-file-action-button__action o-cursor-pointer mt-1'
          onClick={handleDownload}>
          <div className='c-file-action-button__section d-flex'>
            <MaterialIcon
              icon='download'
              color='o-cl-grey-200'
              size={24}
            />
            <span className='c-file-action-button__text o-cl-grey-100 o-ft-sm-400'>{text}</span>
          </div>
        </div>
      }
    </div >
  )
}
