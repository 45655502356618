import React from "react";
import { images } from "@theme/images";

interface IHeaderProps {
  title: string;
  subtitle: string;
}

const Header = (props: IHeaderProps) => {
  const { title, subtitle } = props;

  return (
    <div className="c-sso-header d-flex flex-column">
      {images.tenantLogo && (
        <div className="c-form-header__portal d-flex">
          <img
            className="c-form-header__portal-logo-img"
            src={images.tenantLogo}
            alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
          />
        </div>
      )}
      <div className="c-sso-header__logo d-flex">
        <img
          className="c-sso-header__logo-img"
          src={images.verticalBrandLogo}
          alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
        />
      </div>
      <span className="c-sso-header__title o-ft-xl-300 o-ft-2xl@lg text-center d-block">
        {title}
      </span>
      <span className="c-sso-header__subtitle o-ft-2xl-600 o-ft-3xl@md text-center d-block">
        {subtitle}
      </span>
    </div>
  );
};

export default Header;
