import { Tag } from './Tag';
import { TIcon } from './MaterialIcon';

interface IAdminSearchBarFiltersProps {
  addFilter: (filter: string) => void
}

interface TagsDataProps {
  id: string;
  name: string;
  iconName: TIcon;
}

export default function AdminSearchBarFilters({ addFilter }: IAdminSearchBarFiltersProps) {

  const tagsData: TagsDataProps[] = [
    {
      id: "1",
      name: "Name",
      iconName: 'drive_file_rename_outline'
    },
    {
      id: "2",
      name: "Tag",
      iconName: 'tag'
    },
  ]

  return (
    <div className='c-search-bar-results position-absolute'>
      <div className='c-search-bar-results__filters'>
        <p className='o-ft-sm-400 o-cl-grey-100'>Filter by:</p>
        <div className='d-flex flex-wrap my-3'>
          {
            tagsData.map(({ id, name, iconName }) =>
              <div
                key={id}
                className='mb-2 me-2'>
                <Tag
                  tag={{
                    id,
                    name
                  }} documentId={0} onClick={() => addFilter(name)}
                  hideClosing iconName={iconName} />
              </div>
            )
          }
        </div>
      </div>
      {/* <div className='c-search-bar-results__latest-files'>
        <p className='o-ft-sm-400 o-cl-grey-100'>Recent:</p>
        <SearchBarItem iconName='history' text='2022_05_05_Architecture & Engineering' />
      </div> */}
    </div>
  )
}
