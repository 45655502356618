import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

interface ITextLinkProps {
  text: string;
  onClick?: () => void;
  type: 'element' | 'route' | 'href';
  route?: string;
  smallFontSize?: boolean;
  openInNewTab?: boolean;
}

const TextLink = ({ text, onClick, route, type, smallFontSize, openInNewTab }: ITextLinkProps) => {
  const defaultClassName = 'c-text-link o-cursor-pointer';
  const className = smallFontSize ? `${defaultClassName}` : `${defaultClassName} o-ft-lg-400`;

  const InNewTab = {
    ...(openInNewTab && { target: '_blank' }), // Adds target='_blank' if openInNewTab is true
  };

  return (
    <>
      {(_.isEqual(type, 'element') && !_.isUndefined(onClick))
        && <span {...{ onClick, className }}>{text}</span>}
      {(_.isEqual(type, 'route') && !_.isUndefined(route))
        && <Link to={route} className={className}>{text}</Link>}
      {(_.isEqual(type, 'href') && !_.isUndefined(route))
        && <a href={route} className={className} {...InNewTab}>{text}</a>}
    </>
  );
};

export default TextLink;
