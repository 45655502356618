/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";

// shared components
import Input from "./shared/Inputold";
import MaterialIcon from "@components/MaterialIcon";
import classNames from "classnames";
// @hooks
import { useDebounce } from "@hooks/useDebounce";
import useHandleClickOutside from "@hooks/useHandleClickOutside";

type Props = {
  onEnter?: () => void;
  results: any;
  isFetching: boolean;
  onType: any;
  value: string;
  borderStyle?: boolean;
  placeholder?: string;
  onFocus?: (value: boolean) => void;
  onClear?: () => void;
};

const SimpleSearch: React.FC<Props> = ({
  onEnter,
  // results,
  isFetching,
  onType,
  value,
  borderStyle = true,
  placeholder,
  onFocus,
  onClear,
}) => {
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const searchValue = useDebounce(value, 333);
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    onFocus?.(focus);
  }, [focus, onFocus]);

  useHandleClickOutside(ref, () => setFocus(false));

  // const hasResults = Object.entries(results).some((value: any) => value[1]?.data.length > 0)

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && focus && value) {
      setFocus(false);
      onEnter?.();
    }
    setFocus(event.key !== "Enter");
  };

  const iconSearchClasses = classNames({
    "c-search__colored-icon": focus || hover,
    // 'text-danger': (!hasResults && !isFetching && searchValue && (focus || hover))
    "text-danger": !isFetching && searchValue && (focus || hover),
  });

  const searchBarClasses = classNames({
    "c-search--colored-active": focus || hover,
    // 'border-danger': (!hasResults && !isFetching && searchValue && (focus || hover)),
    "border-danger": !isFetching && searchValue && (focus || hover),
    "c-search__border": borderStyle,
  });

  return (
    <div className={`position-relative ${!borderStyle && "w-100"}`} ref={ref}>
      {/* Search Input */}
      <div
        className={`c-search ${borderStyle && searchBarClasses}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onFocus={() => setFocus(true)}
        onClick={() => setFocus(true)}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <div className="c-search__container d-flex align-items-center position-relative">
          <div className="c-search__search-icon-container d-flex">
            <MaterialIcon
              color={focus ? "o-cl-brand-secondary" : "o-cl-grey-200"}
              icon="search"
              className={iconSearchClasses}
            />
          </div>
          <Input
            ref={inputRef}
            placeholder={placeholder}
            getValue={onType}
            onClear={() => {
              onClear?.();
              inputRef.current?.focus();
            }}
            value={value}
          />
        </div>
      </div>
    </div>
  );
};

export default SimpleSearch;
