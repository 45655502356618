import { Tag } from "@components/Tag";
import { TTag, TTagData } from "@models/Tag.type";

interface ITagListProps {
  list: TTagData;
  documentId: number;
  onClick: (tag: TTag, documentId: number) => void;
}

export const TagList = ({ list, onClick, documentId }: ITagListProps) => {
  const mapList = list[documentId].map((tag: TTag, index) => <div key={tag.id} className="mb-2 me-2"><Tag {...{ tag, documentId, onClick }} /></div>);

  return (
    <div className='c-tag-list d-flex flex-wrap position-relative'>
      {mapList}
    </div>
  );
}
