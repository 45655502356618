import Button from '@components/Button';
import { Modal, ModalProps } from 'react-bootstrap';

interface IBuildButtons {
  isLoading?: boolean;
  buttonLeftText?: string;
  buttonRightText?: string;
  onClickLeftButton?: () => void;
  onClickRightButton?: () => void;
}

interface ICustomButtons {
  customButtons: JSX.Element;
}

export enum EModalSizes {
  sm = 'sm',
  md = 'md',
}

type IActionModalProps =
  ModalProps
  & (ICustomButtons | IBuildButtons)
  & {
    title: string | JSX.Element;
    customSize?: EModalSizes;
    subtitle?: string | JSX.Element;
    includesForm?: boolean;
    renderContent?: JSX.Element | JSX.Element[];
  }

const ActionModal = ({
  isLoading, customButtons, title, subtitle, customSize = EModalSizes.sm,
  show, buttonLeftText = 'Cancel', buttonRightText = 'Accept',
  onClickLeftButton = () => { },
  onClickRightButton = () => { }, renderContent,
  includesForm, onExited, closeButton, onHide,
}: IActionModalProps) => {
  return (
    <Modal onExited={onExited} onHide={onHide} contentClassName='border-0 c-action-modal__content' show={show} className='d-flex align-items-center justify-content-center'>
      {(closeButton) &&
        <Modal.Header className='c-action-modal__header border-0 pb-0' closeButton>
          <div>
            <span className="o-ft-3xl-500 o-cl-grey-100">{title}</span>
          </div>
        </Modal.Header>
      }
      <div className={`c-action-modal${customSize ? ` c-action-modal--${customSize}` : ""}`}>
        {(!closeButton) &&
          <h3 className='c-action-modal__title o-ft-2xl-600 mb-3'>{title}</h3>


        }
        {(subtitle) && <p className='c-action-modal__file-name o-ft-base-400 o-cl-grey-100 mb-4 pb-2'>{subtitle}</p>}
        {renderContent}
        {(!includesForm) &&
          <div className='d-flex w-100 align-items-center justify-content-between pt-3'>
            {
              customButtons
                ? customButtons
                : <>
                  <Button
                    className="w-100 me-2 me-md-3"
                    onClick={onClickLeftButton}
                    size="medium-big"
                    value={buttonLeftText}
                  />
                  <Button
                    disabled={isLoading ? true : false}
                    isLoading={isLoading}
                    className="w-100 ms-2 ms-md-3"
                    onClick={onClickRightButton}
                    size="medium-big"
                    value={buttonRightText}
                  />
                </>
            }
          </div>
        }
      </div>
    </Modal>
  );
};

export default ActionModal;
