import React, { useState, useLayoutEffect } from 'react';

export const useGetDimensionsOfElement = (ref: any) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current?.clientHeight);
    setWidth(ref.current?.clientWidth);
  }, []);

  return {
    height,
    width,
  }
}
