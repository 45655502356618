import React, { useEffect, useState } from 'react';
import { useUsersnapApi } from '../UsersnapContext';

interface PrivateRouterProps {
  children: JSX.Element;
}

const Usersnap = ({ children }: PrivateRouterProps) => {
  const [contador, setContador] = useState(0)
  const usersnapApi: any = useUsersnapApi()

  useEffect(() => {
    if (usersnapApi && (contador < 1)) {
      setContador(contador + 1)
      usersnapApi.show(process.env.REACT_APP_USERSNAP_PROJECT_API_KEY);
    }
  }, [usersnapApi])

  return (
    <>
      {children}
    </>
  )
}
export default Usersnap;
