export const filterRouteByOrdering = (orderingId: number | string, route: string, attr: string = 'id') => {
  if (route.includes("?"))
    route = route.concat("&")
  else
    route = route.concat("?")
  const orderById = `orderBy=${attr}&orderDirection=`;
  const orderByName = 'orderBy=name&orderDirection=';
  const getStrQuery = (query: string, order: 'desc' | 'asc') => route.concat(`${query}${order}`);

  const queries: { [key: number | string]: string } = {
    1: getStrQuery(orderById, 'desc'),
    2: getStrQuery(orderById, 'asc'),
    3: getStrQuery(orderByName, 'asc'),
    4: getStrQuery(orderByName, 'desc'),
  }

  return queries[orderingId] || route;
}
