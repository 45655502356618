import classNames from "classnames";
export interface IMaterialIconProps {
  onClick?: () => void;
  icon: TIcon | 'linkedin';
  className?: string;
  size?: 12 | 18 | 20 | 24 | 30 | 40 | 50 | 60;
  color?: string;
}

export type TIcon =
  "search" |
  "home" |
  "account_circle" |
  "settings" |
  "done" |
  "info" |
  "check_circle" |
  "delete" |
  "visibility" |
  "shopping_cart" |
  "favorite" |
  "description" |
  "logout" |
  "favorite_border" |
  "lock" |
  "schedule" |
  "language" |
  "help_outline" |
  "face" |
  "manage_accounts" |
  "filter_alt" |
  "event" |
  "verified" |
  "thumb_up" |
  "fingerprint" |
  "dashboard" |
  "login" |
  "calendar_today" |
  "list" |
  "visibility_off" |
  "check_circle_outline" |
  "highlight_off" |
  "help" |
  "question_answer" |
  "date_range" |
  "paid" |
  "task_alt" |
  "article" |
  "shopping_bag" |
  "lightbulb" |
  "open_in_new" |
  "perm_identity" |
  "trending_up" |
  "history" |
  "credit_card" |
  "account_balance" |
  "delete_outline" |
  "report_problem" |
  "fact_check" |
  "assignment" |
  "verified_user" |
  "arrow_right_alt" |
  "star_rate" |
  "account_balance_wallet" |
  "autorenew" |
  "analytics" |
  "view_list" |
  "build" |
  "work" |
  "print" |
  "store" |
  "today" |
  "delete_forever" |
  "admin_panel_settings" |
  "lock_open" |
  "code" |
  "grade" |
  "savings" |
  "room" |
  "watch_later" |
  "receipt" |
  "update" |
  "add_shopping_cart" |
  "contact_support" |
  "power_settings_new" |
  "pets" |
  "calendar_month" |
  "done_all" |
  "explore" |
  "bookmark" |
  "bookmark_border" |
  "note_add" |
  "reorder" |
  "account_box" |
  "pending_actions" |
  "shopping_basket" |
  "drag_indicator" |
  "payment" |
  "launch" |
  "supervisor_account" |
  "pending" |
  "zoom_in" |
  "touch_app" |
  "assessment" |
  "thumb_up_off_alt" |
  "open_in_full" |
  "leaderboard" |
  "exit_to_app" |
  "done_outline" |
  "preview" |
  "assignment_ind" |
  "card_giftcard" |
  "view_in_ar" |
  "published_with_changes" |
  "work_outline" |
  "feedback" |
  "timeline" |
  "swap_horiz" |
  "dns" |
  "assignment_turned_in" |
  "sync_alt" |
  "flight_takeoff" |
  "stars" |
  "label" |
  "book" |
  "bug_report" |
  "contact_page" |
  "pan_tool" |
  "space_dashboard" |
  "gavel" |
  "translate" |
  "alarm" |
  "cached" |
  "tips_and_updates" |
  "edit_calendar" |
  "supervised_user_circle" |
  "android" |
  "accessibility" |
  "minimize" |
  "extension" |
  "get_app" |
  "record_voice_over" |
  "add_task" |
  "trending_flat" |
  "hourglass_empty" |
  "help_center" |
  "accessibility_new" |
  "thumb_down" |
  "rule" |
  "sticky_note_2" |
  "flutter_dash" |
  "source" |
  "dashboard_customize" |
  "support" |
  "rocket_launch" |
  "find_in_page" |
  "settings_applications" |
  "close_fullscreen" |
  "question_mark" |
  "redeem" |
  "announcement" |
  "ads_click" |
  "view_headline" |
  "loyalty" |
  "swap_vert" |
  "group_work" |
  "restore" |
  "arrow_circle_right" |
  "dangerous" |
  "euro_symbol" |
  "sensors" |
  "compare_arrows" |
  "nightlight_round" |
  "subject" |
  "privacy_tip" |
  "disabled_by_default" |
  "table_view" |
  "https" |
  "track_changes" |
  "toc" |
  "copyright" |
  "arrow_circle_up" |
  "grading" |
  "bookmarks" |
  "api" |
  "query_builder" |
  "perm_media" |
  "build_circle" |
  "input" |
  "zoom_out" |
  "view_module" |
  "perm_contact_calendar" |
  "backup" |
  "book_online" |
  "open_with" |
  "settings_phone" |
  "circle_notifications" |
  "speaker_notes" |
  "card_membership" |
  "label_important" |
  "file_present" |
  "perm_phone_msg" |
  "arrow_circle_down" |
  "3d_rotation" |
  "wysiwyg" |
  "pageview" |
  "integration_instructions" |
  "upgrade" |
  "swipe" |
  "trending_down" |
  "change_history" |
  "class" |
  "accessible" |
  "g_translate" |
  "settings_accessibility" |
  "offline_bolt" |
  "expand" |
  "currency_exchange" |
  "production_quantity_limits" |
  "model_training" |
  "settings_backup_restore" |
  "aspect_ratio" |
  "donut_large" |
  "segment" |
  "percent" |
  "calendar_view_month" |
  "view_column" |
  "arrow_circle_left" |
  "schedule_send" |
  "maximize" |
  "bookmark_add" |
  "alarm_on" |
  "thumbs_up_down" |
  "settings_ethernet" |
  "view_agenda" |
  "theaters" |
  "thumb_down_off_alt" |
  "important_devices" |
  "invert_colors" |
  "unpublished" |
  "open_in_browser" |
  "addchart" |
  "no_accounts" |
  "opacity" |
  "commute" |
  "youtube_searched_for" |
  "history_toggle_off" |
  "view_week" |
  "tour" |
  "system_update_alt" |
  "settings_input_antenna" |
  "turned_in" |
  "shop" |
  "mark_as_unread" |
  "hide_source" |
  "bookmark_added" |
  "plagiarism" |
  "search_off" |
  "flight_land" |
  "not_started" |
  "saved_search" |
  "donut_small" |
  "assignment_late" |
  "contactless" |
  "highlight_alt" |
  "view_carousel" |
  "anchor" |
  "turned_in_not" |
  "settings_input_component" |
  "mediation" |
  "assignment_return" |
  "view_quilt" |
  "fit_screen" |
  "all_inbox" |
  "edit_off" |
  "toll" |
  "settings_remote" |
  "flaky" |
  "remove_shopping_cart" |
  "hourglass_full" |
  "hotel_class" |
  "lock_clock" |
  "shopping_cart_checkout" |
  "settings_voice" |
  "balance" |
  "swap_horizontal_circle" |
  "online_prediction" |
  "filter_alt_off" |
  "pregnant_woman" |
  "view_sidebar" |
  "event_seat" |
  "next_plan" |
  "vertical_split" |
  "camera_enhance" |
  "restore_from_trash" |
  "markunread_mailbox" |
  "data_exploration" |
  "dynamic_form" |
  "calendar_view_week" |
  "accessible_forward" |
  "tab" |
  "rocket" |
  "view_stream" |
  "add_to_drive" |
  "remove_done" |
  "settings_power" |
  "request_page" |
  "smart_button" |
  "token" |
  "card_travel" |
  "outbox" |
  "try" |
  "offline_pin" |
  "calendar_view_day" |
  "terminal" |
  "rowing" |
  "lock_reset" |
  "outlet" |
  "find_replace" |
  "compress" |
  "chrome_reader_mode" |
  "settings_brightness" |
  "http" |
  "assignment_returned" |
  "alarm_add" |
  "spellcheck" |
  "backup_table" |
  "play_for_work" |
  "restore_page" |
  "credit_card_off" |
  "new_label" |
  "fax" |
  "gif" |
  "wifi_protected_setup" |
  "settings_overscan" |
  "generating_tokens" |
  "settings_input_composite" |
  "quickreply" |
  "density_medium" |
  "view_day" |
  "comment_bank" |
  "swap_vertical_circle" |
  "outbound" |
  "free_cancellation" |
  "cancel_schedule_send" |
  "line_weight" |
  "polymer" |
  "horizontal_split" |
  "add_card" |
  "batch_prediction" |
  "disabled_visible" |
  "send_and_archive" |
  "switch_access_shortcut" |
  "picture_in_picture" |
  "bookmark_remove" |
  "code_off" |
  "settings_bluetooth" |
  "view_timeline" |
  "satellite_alt" |
  "123" |
  "flip_to_front" |
  "shop_two" |
  "assured_workload" |
  "gif_box" |
  "display_settings" |
  "event_repeat" |
  "work_history" |
  "speaker_notes_off" |
  "webhook" |
  "eject" |
  "settings_input_hdmi" |
  "perm_data_setting" |
  "alarm_off" |
  "perm_scan_wifi" |
  "html" |
  "hourglass_disabled" |
  "settings_cell" |
  "app_blocking" |
  "work_off" |
  "line_style" |
  "sensors_off" |
  "output" |
  "switch_access_shortcut_add" |
  "picture_in_picture_alt" |
  "pan_tool_alt" |
  "perm_device_information" |
  "noise_control_off" |
  "subtitles_off" |
  "update_disabled" |
  "manage_history" |
  "voice_over_off" |
  "settings_input_svideo" |
  "app_shortcut" |
  "javascript" |
  "swipe_left" |
  "join_full" |
  "flip_to_back" |
  "view_array" |
  "private_connectivity" |
  "open_in_new_off" |
  "abc" |
  "all_out" |
  "label_off" |
  "rounded_corner" |
  "swipe_right" |
  "shop_2" |
  "install_desktop" |
  "tab_unselected" |
  "swipe_up" |
  "text_rotate_vertical" |
  "join_inner" |
  "explore_off" |
  "lightbulb_circle" |
  "text_rotation_none" |
  "density_small" |
  "not_accessible" |
  "data_thresholding" |
  "pin_invoke" |
  "commit" |
  "install_mobile" |
  "perm_camera_mic" |
  "extension_off" |
  "troubleshoot" |
  "swipe_down" |
  "view_kanban" |
  "css" |
  "pin_end" |
  "join_left" |
  "pinch" |
  "swipe_vertical" |
  "text_rotate_up" |
  "view_cozy" |
  "text_rotation_down" |
  "text_rotation_angledown" |
  "text_rotation_angleup" |
  "join_right" |
  "spatial_audio_off" |
  "swipe_right_alt" |
  "lock_person" |
  "noise_aware" |
  "php" |
  "view_comfy_alt" |
  "browse_gallery" |
  "view_compact_alt" |
  "arrow_outward" |
  "swipe_down_alt" |
  "network_ping" |
  "swipe_left_alt" |
  "density_large" |
  "on_device_training" |
  "swipe_up_alt" |
  "spatial_tracking" |
  "spatial_audio" |
  "add_home" |
  "unfold_more_double" |
  "hls" |
  "hls_off" |
  "width_full" |
  "width_normal" |
  "face_unlock" |
  "width_wide" |
  "unfold_less_double" |
  "repartition" |
  "transcribe" |
  "close" |
  "menu" |
  "expand_more" |
  "arrow_back" |
  "chevron_right" |
  "arrow_forward_ios" |
  "arrow_back_ios" |
  "cancel" |
  "arrow_drop_down" |
  "more_vert" |
  "chevron_left" |
  "arrow_forward" |
  "check" |
  "expand_less" |
  "more_horiz" |
  "refresh" |
  "apps" |
  "payments" |
  "arrow_upward" |
  "east" |
  "campaign" |
  "arrow_back_ios_new" |
  "arrow_downward" |
  "arrow_right" |
  "menu_open" |
  "fullscreen" |
  "double_arrow" |
  "arrow_drop_up" |
  "unfold_more" |
  "maps_home_work" |
  "west" |
  "arrow_left" |
  "south" |
  "expand_circle_down" |
  "north" |
  "north_east" |
  "home_work" |
  "first_page" |
  "fullscreen_exit" |
  "arrow_drop_down_circle" |
  "last_page" |
  "unfold_less" |
  "subdirectory_arrow_right" |
  "legend_toggle" |
  "south_east" |
  "app_settings_alt" |
  "assistant_direction" |
  "subdirectory_arrow_left" |
  "north_west" |
  "switch_left" |
  "waterfall_chart" |
  "south_west" |
  "switch_right" |
  "pivot_table_chart" |
  "apps_outage" |
  "offline_share" |
  "add_home_work" |
  "person" |
  "notifications" |
  "groups" |
  "people" |
  "share" |
  "person_outline" |
  "school" |
  "person_add" |
  "public" |
  "emoji_events" |
  "group" |
  "notifications_active" |
  "engineering" |
  "construction" |
  "people_alt" |
  "group_add" |
  "psychology" |
  "health_and_safety" |
  "travel_explore" |
  "water_drop" |
  "thumb_up_alt" |
  "notifications_none" |
  "emoji_emotions" |
  "sports_esports" |
  "sentiment_satisfied" |
  "location_city" |
  "ios_share" |
  "person_add_alt" |
  "sentiment_very_satisfied" |
  "precision_manufacturing" |
  "emoji_objects" |
  "science" |
  "military_tech" |
  "history_edu" |
  "cake" |
  "sentiment_dissatisfied" |
  "workspace_premium" |
  "coronavirus" |
  "sentiment_very_dissatisfied" |
  "emoji_people" |
  "self_improvement" |
  "person_remove" |
  "poll" |
  "handshake" |
  "sports_soccer" |
  "whatshot" |
  "domain" |
  "female" |
  "mood" |
  "people_outline" |
  "recommend" |
  "male" |
  "person_off" |
  "recycling" |
  "sentiment_neutral" |
  "person_add_alt_1" |
  "masks" |
  "connect_without_contact" |
  "back_hand" |
  "hiking" |
  "architecture" |
  "notifications_off" |
  "waving_hand" |
  "luggage" |
  "thumb_down_alt" |
  "front_hand" |
  "mood_bad" |
  "real_estate_agent" |
  "emoji_nature" |
  "vaccines" |
  "switch_account" |
  "catching_pokemon" |
  "nights_stay" |
  "king_bed" |
  "interests" |
  "sports_basketball" |
  "notification_add" |
  "emoji_symbols" |
  "sports_kabaddi" |
  "compost" |
  "sports" |
  "reduce_capacity" |
  "emoji_food_beverage" |
  "sick" |
  "emoji_transportation" |
  "sports_tennis" |
  "man" |
  "cookie" |
  "transgender" |
  "cruelty_free" |
  "outdoor_grill" |
  "elderly" |
  "deck" |
  "social_distance" |
  "add_moderator" |
  "heart_broken" |
  "follow_the_signs" |
  "woman" |
  "plus_one" |
  "clean_hands" |
  "piano" |
  "fireplace" |
  "add_reaction" |
  "sanitizer" |
  "personal_injury" |
  "surfing" |
  "sports_motorsports" |
  "sports_handball" |
  "pix" |
  "sports_baseball" |
  "edit_notifications" |
  "scale" |
  "sports_volleyball" |
  "pages" |
  "sports_football" |
  "hive" |
  "downhill_skiing" |
  "kayaking" |
  "wallet" |
  "diversity_3" |
  "public_off" |
  "single_bed" |
  "skateboarding" |
  "remove_moderator" |
  "notifications_paused" |
  "safety_divider" |
  "group_remove" |
  "person_remove_alt_1" |
  "co2" |
  "sports_cricket" |
  "boy" |
  "fitbit" |
  "sports_mma" |
  "group_off" |
  "nordic_walking" |
  "sports_golf" |
  "domain_add" |
  "6_ft_apart" |
  "paragliding" |
  "girl" |
  "sports_martial_arts" |
  "diversity_1" |
  "snowboarding" |
  "party_mode" |
  "kitesurfing" |
  "snowshoeing" |
  "sports_hockey" |
  "ice_skating" |
  "sports_rugby" |
  "groups_2" |
  "sports_gymnastics" |
  "south_america" |
  "thunderstorm" |
  "sledding" |
  "psychology_alt" |
  "diversity_2" |
  "elderly_woman" |
  "scoreboard" |
  "no_luggage" |
  "groups_3" |
  "face_6" |
  "piano_off" |
  "face_3" |
  "sign_language" |
  "cyclone" |
  "person_2" |
  "scuba_diving" |
  "flood" |
  "face_2" |
  "face_4" |
  "tsunami" |
  "roller_skating" |
  "severe_cold" |
  "face_5" |
  "person_4" |
  "tornado" |
  "landslide" |
  "person_3" |
  "volcano" |
  "18_up_rating" |
  "no_adult_content" |
  "woman_2" |
  "assist_walker" |
  "man_2" |
  "blind" |
  "man_4" |
  "man_3" |
  "add" |
  "add_circle_outline" |
  "content_copy" |
  "add_circle" |
  "send" |
  "clear" |
  "mail" |
  "save" |
  "link" |
  "remove" |
  "filter_list" |
  "inventory_2" |
  "insights" |
  "inventory" |
  "remove_circle_outline" |
  "sort" |
  "bolt" |
  "flag" |
  "reply" |
  "add_box" |
  "remove_circle" |
  "push_pin" |
  "block" |
  "create" |
  "calculate" |
  "undo" |
  "how_to_reg" |
  "content_paste" |
  "report" |
  "file_copy" |
  "backspace" |
  "shield" |
  "archive" |
  "save_alt" |
  "policy" |
  "tag" |
  "change_circle" |
  "redo" |
  "content_cut" |
  "forward" |
  "inbox" |
  "outlined_flag" |
  "draft" |
  "drafts" |
  "link_off" |
  "ballot" |
  "biotech" |
  "report_gmailerrorred" |
  "stacked_bar_chart" |
  "delete_sweep" |
  "markunread" |
  "square_foot" |
  "stream" |
  "add_link" |
  "where_to_vote" |
  "move_to_inbox" |
  "copy_all" |
  "dynamic_feed" |
  "waves" |
  "unarchive" |
  "reply_all" |
  "select_all" |
  "content_paste_search" |
  "low_priority" |
  "text_format" |
  "weekend" |
  "font_download" |
  "how_to_vote" |
  "upcoming" |
  "gesture" |
  "save_as" |
  "attribution" |
  "content_paste_go" |
  "flag_circle" |
  "next_week" |
  "filter_list_off" |
  "content_paste_off" |
  "report_off" |
  "font_download_off" |
  "deselect" |
  "web_stories" |
  "edit" |
  "navigate_next" |
  "photo_camera" |
  "image" |
  "tune" |
  "picture_as_pdf" |
  "receipt_long" |
  "circle" |
  "timer" |
  "auto_stories" |
  "collections" |
  "navigate_before" |
  "add_a_photo" |
  "auto_awesome" |
  "palette" |
  "remove_red_eye" |
  "music_note" |
  "add_photo_alternate" |
  "wb_sunny" |
  "brush" |
  "flash_on" |
  "euro" |
  "control_point" |
  "auto_fix_high" |
  "looks_one" |
  "style" |
  "adjust" |
  "straighten" |
  "photo_library" |
  "camera" |
  "portrait" |
  "camera_alt" |
  "audiotrack" |
  "video_camera_front" |
  "rotate_right" |
  "grid_on" |
  "color_lens" |
  "crop_free" |
  "timelapse" |
  "landscape" |
  "slideshow" |
  "collections_bookmark" |
  "crop_square" |
  "currency_rupee" |
  "looks_two" |
  "panorama_fish_eye" |
  "lens" |
  "filter_vintage" |
  "filter_drama" |
  "compare" |
  "auto_awesome_motion" |
  "healing" |
  "image_search" |
  "looks_3" |
  "rotate_left" |
  "crop" |
  "blur_on" |
  "center_focus_strong" |
  "wb_incandescent" |
  "flare" |
  "wb_cloudy" |
  "face_retouching_natural" |
  "cases" |
  "colorize" |
  "filter_none" |
  "brightness_4" |
  "assistant" |
  "broken_image" |
  "filter_center_focus" |
  "dehaze" |
  "nature_people" |
  "crop_original" |
  "photo" |
  "auto_awesome_mosaic" |
  "flash_off" |
  "brightness_6" |
  "tag_faces" |
  "brightness_5" |
  "details" |
  "auto_fix_normal" |
  "flip_camera_android" |
  "grain" |
  "flip" |
  "brightness_1" |
  "loupe" |
  "flip_camera_ios" |
  "image_not_supported" |
  "movie_creation" |
  "filter_1" |
  "add_to_photos" |
  "panorama" |
  "view_comfy" |
  "animation" |
  "center_focus_weak" |
  "movie_filter" |
  "looks_4" |
  "bedtime" |
  "crop_din" |
  "filter" |
  "control_point_duplicate" |
  "leak_add" |
  "brightness_7" |
  "nature" |
  "texture" |
  "photo_size_select_actual" |
  "incomplete_circle" |
  "video_camera_back" |
  "timer_off" |
  "view_compact" |
  "transform" |
  "motion_photos_on" |
  "currency_bitcoin" |
  "photo_camera_front" |
  "rotate_90_degrees_ccw" |
  "mic_external_on" |
  "gradient" |
  "looks_5" |
  "assistant_photo" |
  "music_off" |
  "hide_image" |
  "exposure_plus_1" |
  "thermostat_auto" |
  "currency_yen" |
  "photo_album" |
  "shutter_speed" |
  "contrast" |
  "exposure" |
  "filter_2" |
  "filter_tilt_shift" |
  "currency_pound" |
  "wb_twilight" |
  "crop_16_9" |
  "hdr_strong" |
  "vrpano" |
  "looks" |
  "blur_circular" |
  "linked_camera" |
  "motion_photos_auto" |
  "flash_auto" |
  "brightness_3" |
  "looks_6" |
  "crop_portrait" |
  "tonality" |
  "brightness_2" |
  "iso" |
  "photo_filter" |
  "photo_size_select_small" |
  "crop_7_5" |
  "filter_hdr" |
  "crop_rotate" |
  "hdr_weak" |
  "photo_size_select_large" |
  "filter_3" |
  "rotate_90_degrees_cw" |
  "exposure_zero" |
  "camera_front" |
  "crop_5_4" |
  "filter_frames" |
  "burst_mode" |
  "camera_roll" |
  "currency_ruble" |
  "logo_dev" |
  "blur_linear" |
  "filter_b_and_w" |
  "crop_3_2" |
  "crop_landscape" |
  "switch_camera" |
  "switch_video" |
  "filter_7" |
  "exposure_plus_2" |
  "filter_4" |
  "filter_9_plus" |
  "motion_photos_paused" |
  "wb_iridescent" |
  "monochrome_photos" |
  "grid_off" |
  "photo_camera_back" |
  "filter_5" |
  "face_retouching_off" |
  "auto_fix_off" |
  "exposure_neg_1" |
  "panorama_photosphere" |
  "leak_remove" |
  "filter_8" |
  "filter_9" |
  "timer_10" |
  "video_stable" |
  "wb_shade" |
  "filter_6" |
  "motion_photos_off" |
  "blur_off" |
  "raw_on" |
  "hdr_on" |
  "panorama_horizontal" |
  "motion_photos_pause" |
  "vignette" |
  "dirty_lens" |
  "image_aspect_ratio" |
  "30fps_select" |
  "camera_rear" |
  "timer_3" |
  "60fps_select" |
  "panorama_wide_angle_select" |
  "24mp" |
  "exposure_neg_2" |
  "deblur" |
  "autofps_select" |
  "panorama_horizontal_select" |
  "panorama_wide_angle" |
  "panorama_photosphere_select" |
  "currency_yuan" |
  "currency_lira" |
  "wb_auto" |
  "hdr_plus" |
  "mic_external_off" |
  "panorama_vertical_select" |
  "panorama_vertical" |
  "hdr_enhanced_select" |
  "12mp" |
  "hevc" |
  "mp" |
  "18mp" |
  "23mp" |
  "hdr_off" |
  "10mp" |
  "raw_off" |
  "currency_franc" |
  "11mp" |
  "20mp" |
  "3mp" |
  "16mp" |
  "15mp" |
  "bedtime_off" |
  "2mp" |
  "22mp" |
  "5mp" |
  "13mp" |
  "17mp" |
  "21mp" |
  "8mp" |
  "9mp" |
  "19mp" |
  "4mp" |
  "6mp" |
  "7mp" |
  "14mp" |
  "email" |
  "location_on" |
  "call" |
  "phone" |
  "business" |
  "chat" |
  "mail_outline" |
  "list_alt" |
  "vpn_key" |
  "qr_code_scanner" |
  "chat_bubble_outline" |
  "alternate_email" |
  "forum" |
  "chat_bubble" |
  "textsms" |
  "contact_mail" |
  "person_search" |
  "qr_code_2" |
  "qr_code" |
  "sentiment_satisfied_alt" |
  "message" |
  "comment" |
  "contacts" |
  "import_contacts" |
  "app_registration" |
  "key" |
  "contact_phone" |
  "import_export" |
  "live_help" |
  "forward_to_inbox" |
  "hourglass_bottom" |
  "rss_feed" |
  "hourglass_top" |
  "mark_email_read" |
  "read_more" |
  "mark_email_unread" |
  "more_time" |
  "document_scanner" |
  "call_end" |
  "hub" |
  "clear_all" |
  "dialpad" |
  "phone_enabled" |
  "mark_chat_unread" |
  "3p" |
  "cancel_presentation" |
  "screen_share" |
  "call_made" |
  "call_split" |
  "unsubscribe" |
  "present_to_all" |
  "domain_verification" |
  "mark_chat_read" |
  "add_ic_call" |
  "phonelink_ring" |
  "stay_current_portrait" |
  "call_received" |
  "phonelink_lock" |
  "co_present" |
  "phone_disabled" |
  "duo" |
  "ring_volume" |
  "phonelink_setup" |
  "location_off" |
  "stay_primary_portrait" |
  "swap_calls" |
  "voicemail" |
  "cell_tower" |
  "call_merge" |
  "mobile_screen_share" |
  "phonelink_erase" |
  "person_add_disabled" |
  "call_missed_outgoing" |
  "domain_disabled" |
  "speaker_phone" |
  "cell_wifi" |
  "desktop_access_disabled" |
  "stop_screen_share" |
  "spoke" |
  "call_missed" |
  "print_disabled" |
  "pause_presentation" |
  "dialer_sip" |
  "invert_colors_off" |
  "rtt" |
  "comments_disabled" |
  "wifi_calling" |
  "portable_wifi_off" |
  "mark_unread_chat_alt" |
  "send_time_extension" |
  "stay_current_landscape" |
  "sip" |
  "nat" |
  "stay_primary_landscape" |
  "no_sim" |
  "key_off" |
  "mail_lock" |
  "vpn_key_off" |
  "contact_emergency" |
  "file_download" |
  "file_upload" |
  "download" |
  "folder" |
  "grid_view" |
  "upload_file" |
  "cloud_upload" |
  "folder_open" |
  "text_snippet" |
  "cloud" |
  "request_quote" |
  "cloud_download" |
  "drive_file_rename_outline" |
  "attachment" |
  "upload" |
  "download_for_offline" |
  "create_new_folder" |
  "newspaper" |
  "downloading" |
  "folder_shared" |
  "cloud_done" |
  "topic" |
  "workspaces" |
  "cloud_queue" |
  "download_done" |
  "approval" |
  "cloud_off" |
  "drive_file_move" |
  "drive_folder_upload" |
  "file_download_done" |
  "file_open" |
  "attach_email" |
  "cloud_sync" |
  "rule_folder" |
  "cloud_circle" |
  "folder_zip" |
  "snippet_folder" |
  "folder_copy" |
  "file_download_off" |
  "difference" |
  "drive_file_move_rtl" |
  "folder_delete" |
  "folder_off" |
  "format_overline" |
  "local_shipping" |
  "place" |
  "menu_book" |
  "local_offer" |
  "badge" |
  "map" |
  "category" |
  "restaurant" |
  "directions_car" |
  "volunteer_activism" |
  "local_fire_department" |
  "my_location" |
  "local_mall" |
  "flight" |
  "near_me" |
  "handyman" |
  "directions_run" |
  "restaurant_menu" |
  "layers" |
  "medical_services" |
  "lunch_dining" |
  "local_hospital" |
  "directions_walk" |
  "celebration" |
  "park" |
  "pin_drop" |
  "local_library" |
  "local_atm" |
  "local_activity" |
  "local_cafe" |
  "rate_review" |
  "delivery_dining" |
  "design_services" |
  "person_pin" |
  "directions_bike" |
  "directions_bus" |
  "fastfood" |
  "local_police" |
  "directions_car_filled" |
  "zoom_out_map" |
  "home_repair_service" |
  "local_phone" |
  "local_grocery_store" |
  "hotel" |
  "miscellaneous_services" |
  "cleaning_services" |
  "navigation" |
  "person_pin_circle" |
  "local_gas_station" |
  "train" |
  "local_parking" |
  "local_florist" |
  "directions" |
  "local_post_office" |
  "money" |
  "two_wheeler" |
  "diamond" |
  "electrical_services" |
  "traffic" |
  "add_business" |
  "local_bar" |
  "alt_route" |
  "pedal_bike" |
  "directions_boat" |
  "agriculture" |
  "360" |
  "beenhere" |
  "route" |
  "liquor" |
  "factory" |
  "moving" |
  "sailing" |
  "local_airport" |
  "add_location_alt" |
  "maps_ugc" |
  "local_dining" |
  "emergency" |
  "warehouse" |
  "local_taxi" |
  "ramen_dining" |
  "hail" |
  "local_drink" |
  "trip_origin" |
  "local_printshop" |
  "theater_comedy" |
  "local_laundry_service" |
  "local_pizza" |
  "not_listed_location" |
  "directions_bus_filled" |
  "add_location" |
  "dinner_dining" |
  "bakery_dining" |
  "transfer_within_a_station" |
  "terrain" |
  "multiple_stop" |
  "wine_bar" |
  "takeout_dining" |
  "icecream" |
  "store_mall_directory" |
  "museum" |
  "nightlife" |
  "local_pharmacy" |
  "forest" |
  "add_road" |
  "set_meal" |
  "hardware" |
  "departure_board" |
  "local_see" |
  "ev_station" |
  "electric_car" |
  "plumbing" |
  "festival" |
  "car_rental" |
  "attractions" |
  "edit_location" |
  "layers_clear" |
  "local_convenience_store" |
  "pest_control" |
  "edit_road" |
  "run_circle" |
  "dry_cleaning" |
  "edit_location_alt" |
  "edit_attributes" |
  "zoom_in_map" |
  "moped" |
  "church" |
  "wrong_location" |
  "car_repair" |
  "local_movies" |
  "satellite" |
  "directions_boat_filled" |
  "breakfast_dining" |
  "tram" |
  "taxi_alert" |
  "atm" |
  "soup_kitchen" |
  "transit_enterexit" |
  "subway" |
  "medical_information" |
  "brunch_dining" |
  "electric_bike" |
  "hvac" |
  "signpost" |
  "electric_scooter" |
  "directions_transit" |
  "straight" |
  "merge" |
  "bus_alert" |
  "local_car_wash" |
  "local_hotel" |
  "egg" |
  "mode_of_travel" |
  "connecting_airports" |
  "streetview" |
  "castle" |
  "directions_subway" |
  "compass_calibration" |
  "stadium" |
  "crisis_alert" |
  "mosque" |
  "electric_rickshaw" |
  "directions_railway" |
  "no_meals" |
  "airline_stops" |
  "electric_moped" |
  "railway_alert" |
  "local_play" |
  "turn_right" |
  "car_crash" |
  "bike_scooter" |
  "safety_check" |
  "snowmobile" |
  "near_me_disabled" |
  "pest_control_rodent" |
  "fork_right" |
  "directions_transit_filled" |
  "airlines" |
  "egg_alt" |
  "no_transfer" |
  "turn_left" |
  "directions_subway_filled" |
  "directions_railway_filled" |
  "u_turn_left" |
  "flight_class" |
  "temple_buddhist" |
  "fort" |
  "kebab_dining" |
  "tire_repair" |
  "no_crash" |
  "temple_hindu" |
  "emergency_share" |
  "synagogue" |
  "u_turn_right" |
  "fork_left" |
  "sos" |
  "turn_sharp_right" |
  "roundabout_right" |
  "minor_crash" |
  "turn_slight_left" |
  "remove_road" |
  "turn_slight_right" |
  "screen_rotation_alt" |
  "turn_sharp_left" |
  "roundabout_left" |
  "emergency_recording" |
  "ramp_left" |
  "ramp_right" |
  "fire_truck" |
  "fire_hydrant_alt" |
  "play_arrow" |
  "play_circle_filled" |
  "videocam" |
  "play_circle" |
  "mic" |
  "volume_up" |
  "pause" |
  "play_circle_outline" |
  "volume_off" |
  "replay" |
  "library_books" |
  "skip_next" |
  "speed" |
  "stop" |
  "fiber_manual_record" |
  "movie" |
  "skip_previous" |
  "new_releases" |
  "playlist_add" |
  "equalizer" |
  "loop" |
  "fast_forward" |
  "web" |
  "video_library" |
  "playlist_add_check" |
  "library_add" |
  "mic_off" |
  "pause_circle" |
  "video_call" |
  "subscriptions" |
  "repeat" |
  "stop_circle" |
  "volume_mute" |
  "shuffle" |
  "not_interested" |
  "sort_by_alpha" |
  "mic_none" |
  "library_music" |
  "fast_rewind" |
  "videocam_off" |
  "volume_down" |
  "recent_actors" |
  "queue_music" |
  "web_asset" |
  "hearing" |
  "pause_circle_filled" |
  "library_add_check" |
  "subtitles" |
  "pause_circle_outline" |
  "fiber_new" |
  "album" |
  "note" |
  "av_timer" |
  "playlist_play" |
  "games" |
  "radio" |
  "queue" |
  "replay_circle_filled" |
  "branding_watermark" |
  "forward_10" |
  "replay_10" |
  "closed_caption" |
  "video_settings" |
  "featured_play_list" |
  "control_camera" |
  "playlist_add_check_circle" |
  "slow_motion_video" |
  "add_to_queue" |
  "airplay" |
  "repeat_one" |
  "high_quality" |
  "call_to_action" |
  "snooze" |
  "playlist_remove" |
  "hd" |
  "closed_caption_off" |
  "repeat_on" |
  "replay_30" |
  "featured_video" |
  "shuffle_on" |
  "5g" |
  "forward_30" |
  "music_video" |
  "replay_5" |
  "queue_play_next" |
  "playlist_add_circle" |
  "art_track" |
  "hearing_disabled" |
  "forward_5" |
  "audio_file" |
  "interpreter_mode" |
  "explicit" |
  "video_file" |
  "4k" |
  "fiber_smart_record" |
  "video_label" |
  "repeat_one_on" |
  "closed_caption_disabled" |
  "remove_from_queue" |
  "surround_sound" |
  "play_disabled" |
  "web_asset_off" |
  "sd" |
  "missed_video_call" |
  "10k" |
  "fiber_pin" |
  "fiber_dvr" |
  "lyrics" |
  "4k_plus" |
  "1k" |
  "2k" |
  "1k_plus" |
  "8k" |
  "9k_plus" |
  "8k_plus" |
  "5k" |
  "3k" |
  "5k_plus" |
  "2k_plus" |
  "6k_plus" |
  "9k" |
  "3k_plus" |
  "7k_plus" |
  "7k" |
  "6k" |
  "star" |
  "check_box" |
  "check_box_outline_blank" |
  "radio_button_unchecked" |
  "radio_button_checked" |
  "star_border" |
  "toggle_on" |
  "star_outline" |
  "toggle_off" |
  "star_half" |
  "indeterminate_check_box" |
  "star_purple500" |
  "star_border_purple500" |
  "warning" |
  "error" |
  "error_outline" |
  "warning_amber" |
  "notification_important" |
  "add_alert" |
  "auto_delete" |
  "attach_money" |
  "format_list_bulleted" |
  "edit_note" |
  "mode_edit" |
  "monetization_on" |
  "attach_file" |
  "attach_file_add" |
  "post_add" |
  "bar_chart" |
  "checklist" |
  "drag_handle" |
  "insert_drive_file" |
  "show_chart" |
  "format_quote" |
  "format_list_numbered" |
  "border_color" |
  "table_chart" |
  "query_stats" |
  "pie_chart" |
  "notes" |
  "format_bold" |
  "text_fields" |
  "insert_photo" |
  "table_rows" |
  "insert_emoticon" |
  "title" |
  "publish" |
  "auto_graph" |
  "checklist_rtl" |
  "mode" |
  "insert_chart_outlined" |
  "mode_comment" |
  "draw" |
  "insert_link" |
  "functions" |
  "insert_invitation" |
  "mode_edit_outline" |
  "format_italic" |
  "format_align_left" |
  "horizontal_rule" |
  "format_color_fill" |
  "add_comment" |
  "insert_chart" |
  "linear_scale" |
  "format_size" |
  "stacked_line_chart" |
  "format_underlined" |
  "bubble_chart" |
  "insert_comment" |
  "money_off" |
  "vertical_align_bottom" |
  "height" |
  "vertical_align_top" |
  "format_align_center" |
  "schema" |
  "format_paint" |
  "area_chart" |
  "format_color_text" |
  "format_align_right" |
  "scatter_plot" |
  "format_list_numbered_rtl" |
  "square" |
  "highlight" |
  "format_align_justify" |
  "merge_type" |
  "add_chart" |
  "format_shapes" |
  "data_object" |
  "numbers" |
  "money_off_csred" |
  "pie_chart_outline" |
  "short_text" |
  "format_indent_increase" |
  "strikethrough_s" |
  "align_horizontal_left" |
  "vertical_align_center" |
  "format_color_reset" |
  "multiline_chart" |
  "move_up" |
  "hexagon" |
  "score" |
  "border_all" |
  "rectangle" |
  "format_indent_decrease" |
  "space_bar" |
  "format_clear" |
  "move_down" |
  "format_line_spacing" |
  "align_vertical_bottom" |
  "horizontal_distribute" |
  "align_horizontal_center" |
  "text_increase" |
  "candlestick_chart" |
  "format_strikethrough" |
  "align_horizontal_right" |
  "vertical_distribute" |
  "superscript" |
  "margin" |
  "polyline" |
  "wrap_text" |
  "border_clear" |
  "subscript" |
  "align_vertical_center" |
  "align_vertical_top" |
  "data_array" |
  "pentagon" |
  "border_style" |
  "padding" |
  "border_outer" |
  "text_decrease" |
  "line_axis" |
  "insert_page_break" |
  "border_inner" |
  "border_left" |
  "border_bottom" |
  "format_textdirection_l_to_r" |
  "border_vertical" |
  "border_horizontal" |
  "format_textdirection_r_to_l" |
  "border_top" |
  "border_right" |
  "shape_line" |
  "type_specimen" |
  "support_agent" |
  "wifi" |
  "account_tree" |
  "sync" |
  "priority_high" |
  "event_available" |
  "phone_in_talk" |
  "event_note" |
  "confirmation_number" |
  "sms" |
  "live_tv" |
  "ondemand_video" |
  "wifi_off" |
  "drive_eta" |
  "event_busy" |
  "do_not_disturb_on" |
  "wc" |
  "more" |
  "power" |
  "do_not_disturb" |
  "sync_problem" |
  "time_to_leave" |
  "do_disturb_on" |
  "vpn_lock" |
  "running_with_errors" |
  "folder_special" |
  "enhanced_encryption" |
  "encrypted" | 
  "do_disturb" |
  "network_check" |
  "sms_failed" |
  "phone_callback" |
  "adb" |
  "system_update" |
  "power_off" |
  "phone_forwarded" |
  "personal_video" |
  "voice_chat" |
  "vibration" |
  "do_disturb_alt" |
  "airline_seat_recline_normal" |
  "do_not_disturb_alt" |
  "sync_disabled" |
  "tap_and_play" |
  "no_encryption" |
  "sd_card" |
  "phone_missed" |
  "airline_seat_recline_extra" |
  "imagesearch_roller" |
  "mms" |
  "disc_full" |
  "bluetooth_audio" |
  "no_encryption_gmailerrorred" |
  "sd_card_alert" |
  "sim_card_alert" |
  "do_not_disturb_off" |
  "airline_seat_individual_suite" |
  "phone_paused" |
  "do_disturb_off" |
  "sync_lock" |
  "phone_locked" |
  "airline_seat_flat" |
  "network_locked" |
  "airline_seat_flat_angled" |
  "tv_off" |
  "phone_bluetooth_speaker" |
  "directions_off" |
  "airline_seat_legroom_normal" |
  "airline_seat_legroom_extra" |
  "airline_seat_legroom_reduced" |
  "video_chat" |
  "keyboard_arrow_down" |
  "phone_iphone" |
  "smartphone" |
  "keyboard_arrow_right" |
  "computer" |
  "security" |
  "desktop_windows" |
  "smart_display" |
  "keyboard_backspace" |
  "phone_android" |
  "keyboard_arrow_up" |
  "laptop" |
  "keyboard_arrow_left" |
  "smart_toy" |
  "keyboard_double_arrow_right" |
  "keyboard_return" |
  "headphones" |
  "keyboard" |
  "memory" |
  "headset_mic" |
  "point_of_sale" |
  "tv" |
  "keyboard_double_arrow_left" |
  "mouse" |
  "keyboard_voice" |
  "keyboard_double_arrow_down" |
  "developer_board" |
  "router" |
  "videogame_asset" |
  "cast_for_education" |
  "device_hub" |
  "keyboard_double_arrow_up" |
  "headset" |
  "laptop_mac" |
  "start" |
  "cast" |
  "watch" |
  "tablet_mac" |
  "devices_other" |
  "desktop_mac" |
  "keyboard_tab" |
  "laptop_chromebook" |
  "phonelink" |
  "monitor" |
  "speaker" |
  "toys" |
  "gamepad" |
  "keyboard_alt" |
  "sim_card" |
  "connected_tv" |
  "tablet_android" |
  "cast_connected" |
  "laptop_windows" |
  "device_unknown" |
  "keyboard_hide" |
  "browser_updated" |
  "scanner" |
  "tablet" |
  "speaker_group" |
  "keyboard_capslock" |
  "earbuds" |
  "headset_off" |
  "home_max" |
  "headphones_battery" |
  "keyboard_command_key" |
  "smart_screen" |
  "power_input" |
  "dock" |
  "browser_not_supported" |
  "earbuds_battery" |
  "home_mini" |
  "phonelink_off" |
  "developer_board_off" |
  "adf_scanner" |
  "videogame_asset_off" |
  "keyboard_control_key" |
  "watch_off" |
  "keyboard_option_key" |
  "light_mode" |
  "restart_alt" |
  "dark_mode" |
  "task" |
  "summarize" |
  "password" |
  "sell" |
  "signal_cellular_alt" |
  "devices" |
  "settings_suggest" |
  "quiz" |
  "widgets" |
  "storage" |
  "thermostat" |
  "credit_score" |
  "battery_full" |
  "gps_fixed" |
  "price_check" |
  "medication" |
  "pin" |
  "gpp_good" |
  "price_change" |
  "tungsten" |
  "battery_charging_full" |
  "fmd_good" |
  "reviews" |
  "note_alt" |
  "air" |
  "graphic_eq" |
  "bluetooth" |
  "dvr" |
  "nightlight" |
  "sports_score" |
  "access_time" |
  "monitor_heart" |
  "water" |
  "share_location" |
  "gpp_maybe" |
  "location_searching" |
  "cable" |
  "cameraswitch" |
  "shortcut" |
  "airplane_ticket" |
  "device_thermostat" |
  "wallpaper" |
  "radar" |
  "data_usage" |
  "signal_wifi_4_bar" |
  "monitor_weight" |
  "gpp_bad" |
  "developer_mode" |
  "battery_std" |
  "mode_night" |
  "wifi_tethering" |
  "bloodtype" |
  "signal_cellular_4_bar" |
  "discount" |
  "flashlight_on" |
  "splitscreen" |
  "fmd_bad" |
  "network_wifi" |
  "access_time_filled" |
  "sim_card_download" |
  "airplanemode_active" |
  "mobile_friendly" |
  "send_to_mobile" |
  "battery_alert" |
  "usb" |
  "screen_search_desktop" |
  "lens_blur" |
  "signal_wifi_statusbar_4_bar" |
  "lan" |
  "screen_rotation" |
  "signal_wifi_statusbar_connected_no_internet_4" |
  "system_security_update_good" |
  "gps_not_fixed" |
  "bluetooth_connected" |
  "remember_me" |
  "pattern" |
  "nfc" |
  "mode_standby" |
  "brightness_high" |
  "battery_saver" |
  "play_lesson" |
  "storm" |
  "data_saver_off" |
  "brightness_medium" |
  "signal_wifi_0_bar" |
  "brightness_low" |
  "network_cell" |
  "screenshot" |
  "data_saver_on" |
  "bluetooth_searching" |
  "grid_4x4" |
  "mobiledata_off" |
  "ad_units" |
  "battery_5_bar" |
  "bluetooth_disabled" |
  "hdr_auto" |
  "security_update_good" |
  "battery_unknown" |
  "signal_wifi_off" |
  "settings_system_daydream" |
  "signal_wifi_bad" |
  "wifi_calling_3" |
  "ssid_chart" |
  "access_alarms" |
  "battery_4_bar" |
  "signal_wifi_connected_no_internet_4" |
  "battery_0_bar" |
  "aod" |
  "add_to_home_screen" |
  "battery_6_bar" |
  "signal_cellular_0_bar" |
  "flashlight_off" |
  "security_update" |
  "access_alarm" |
  "gps_off" |
  "4g_mobiledata" |
  "signal_wifi_statusbar_null" |
  "grid_3x3" |
  "sd_storage" |
  "battery_3_bar" |
  "reset_tv" |
  "brightness_auto" |
  "wifi_lock" |
  "signal_cellular_connected_no_internet_4_bar" |
  "do_not_disturb_on_total_silence" |
  "airplanemode_inactive" |
  "1x_mobiledata" |
  "nearby_error" |
  "security_update_warning" |
  "battery_1_bar" |
  "punch_clock" |
  "signal_cellular_connected_no_internet_0_bar" |
  "screen_lock_portrait" |
  "medication_liquid" |
  "system_security_update" |
  "system_security_update_warning" |
  "wifi_tethering_off" |
  "battery_2_bar" |
  "edgesensor_high" |
  "wifi_password" |
  "4g_plus_mobiledata" |
  "signal_cellular_off" |
  "signal_wifi_4_bar_lock" |
  "signal_cellular_nodata" |
  "lte_mobiledata" |
  "add_alarm" |
  "location_disabled" |
  "bluetooth_drive" |
  "phishing" |
  "media_bluetooth_on" |
  "mobile_off" |
  "signal_cellular_null" |
  "30fps" |
  "screenshot_monitor" |
  "rsvp" |
  "grid_goldenratio" |
  "3g_mobiledata" |
  "edgesensor_low" |
  "screen_lock_rotation" |
  "60fps" |
  "lte_plus_mobiledata" |
  "screen_lock_landscape" |
  "usb_off" |
  "network_wifi_1_bar" |
  "timer_10_select" |
  "wifi_find" |
  "network_wifi_3_bar" |
  "signal_cellular_no_sim" |
  "dataset" |
  "g_mobiledata" |
  "timer_3_select" |
  "wifi_tethering_error" |
  "network_wifi_2_bar" |
  "wifi_2_bar" |
  "h_mobiledata" |
  "signal_cellular_alt_2_bar" |
  "media_bluetooth_off" |
  "wifi_channel" |
  "e_mobiledata" |
  "r_mobiledata" |
  "signal_cellular_alt_1_bar" |
  "h_plus_mobiledata" |
  "wifi_1_bar" |
  "hdr_on_select" |
  "hdr_auto_select" |
  "nearby_off" |
  "hdr_off_select" |
  "dataset_linked" |
  "devices_fold" |
  "fluorescent" |
  "macro_off" |
  "storefront" |
  "apartment" |
  "fitness_center" |
  "business_center" |
  "spa" |
  "house" |
  "meeting_room" |
  "corporate_fare" |
  "ac_unit" |
  "cottage" |
  "family_restroom" |
  "other_houses" |
  "checkroom" |
  "grass" |
  "all_inclusive" |
  "airport_shuttle" |
  "child_care" |
  "beach_access" |
  "pool" |
  "kitchen" |
  "casino" |
  "holiday_village" |
  "roofing" |
  "room_service" |
  "room_preferences" |
  "sports_bar" |
  "free_breakfast" |
  "bathtub" |
  "escalator_warning" |
  "child_friendly" |
  "foundation" |
  "food_bank" |
  "gite" |
  "villa" |
  "night_shelter" |
  "golf_course" |
  "stairs" |
  "no_photography" |
  "microwave" |
  "backpack" |
  "elevator" |
  "smoking_rooms" |
  "wash" |
  "cabin" |
  "house_siding" |
  "hot_tub" |
  "countertops" |
  "water_damage" |
  "fire_extinguisher" |
  "carpenter" |
  "charging_station" |
  "soap" |
  "baby_changing_station" |
  "rv_hookup" |
  "umbrella" |
  "smoke_free" |
  "tapas" |
  "rice_bowl" |
  "do_not_touch" |
  "balcony" |
  "no_food" |
  "fence" |
  "houseboat" |
  "tty" |
  "bento" |
  "wheelchair_pickup" |
  "iron" |
  "bungalow" |
  "chalet" |
  "crib" |
  "do_not_step" |
  "no_meeting_room" |
  "escalator" |
  "dry" |
  "stroller" |
  "no_drinks" |
  "no_cell" |
  "no_flash" |
  "no_backpack" |
  "no_stroller" |
  "vaping_rooms" |
  "vape_free" |
  "desk" |
  "manage_search" |
  "feed" |
  "chair" |
  "bed" |
  "podcasts" |
  "coffee" |
  "shower" |
  "yard" |
  "window" |
  "door_front" |
  "light" |
  "garage" |
  "dining" |
  "flatware" |
  "coffee_maker" |
  "blender" |
  "camera_indoor" |
  "bedroom_parent" |
  "table_restaurant" |
  "bathroom" |
  "bedroom_baby" |
  "chair_alt" |
  "door_sliding" |
  "door_back" |
  "camera_outdoor" |
  "living" |
  "doorbell" |
  "bedroom_child" |
  "table_bar" |
  "sensor_door" |
  "sensor_window" |
  "electric_bolt" |
  "shield_moon" |
  "energy_savings_leaf" |
  "solar_power" |
  "auto_mode" |
  "wind_power" |
  "oil_barrel" |
  "electric_meter" |
  "heat_pump" |
  "sensor_occupied" |
  "gas_meter" |
  "propane_tank" |
  "mode_fan_off" |
  "roller_shades" |
  "propane" |
  "broadcast_on_personal" |
  "roller_shades_closed" |
  "blinds" |
  "blinds_closed" |
  "curtains" |
  "broadcast_on_home" |
  "vertical_shades_closed" |
  "curtains_closed" |
  "vertical_shades" |
  "heart_minus" |
  "heart_plus" |
  "exclamation" |
  "nest_cam_wired_stand" |
  // new
  "rebase" |
  "encrypted" |
  "settings_b_roll" ;

const MaterialIcon = (props: IMaterialIconProps) => {

  const { icon, className, onClick, size, color }: IMaterialIconProps = props;
  const classSizes = {
    12: 'xs',
    18: 'lg',
    20: 'xl',
    24: '2xl',
    30: '3xl',
    40: '4xl',
    50: '5xl',
    60: '6xl'
  };

  const iconClass = classNames({ [`${className}`]: className });

  return (
    <i className={`material-symbols-outlined ${iconClass} ${color} ${size && `o-ft-${classSizes[size]}`}`} {...{ onClick }}>{icon}</i>
  );
}

export default MaterialIcon;
