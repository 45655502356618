import React, { useState } from "react";

import MaterialIcon from "./MaterialIcon";
import Message from "@components/Message";

interface IListItemProps {
  text: string;
}

const ClipboardCopy = ({ text }: IListItemProps) => {
  const [showMessage, setShowMessage] = useState(false);

  const onClick = () => {
    navigator.clipboard.writeText(text);
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 1000);
  };

  return (
    <div className='c-clipboard-copy o-cursor-pointer position-relative d-flex align-items-center  w-100"'>
      <div
        className="c-clipboard-copy__content o-ft-base-400 d-flex align-items-center d-flex justify-content-between w-100"
        onClick={onClick}
        aria-hidden
      >
        <span className="c-clipboard-copy__content-text">{text}</span>
        <div className="d-inline m-2">
          {/* <Icon
            icon="copy"
            size={18}
            color={colors.grey100}
          /> */}
          <MaterialIcon
            className="c-button__icon"
            icon="content_copy"
            size={20}
            color="o-cl-grey-100"
          />
        </div>
      </div>
      {showMessage && <Message right text="Copied" />}
    </div>
  );
};

export default ClipboardCopy;
