/* eslint-disable @typescript-eslint/no-explicit-any */
import { createToast } from "./createToast";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { IFile, IFolder } from "@models/files/File.type";

export const isAlreadyASection = (
  folder: IFolder | IFile,
  dispatch: ThunkDispatch<any, any, any>,
) => {
  const text = `<strong>Section already added.<br>"${folder.name}" is already a section.<br/>
  <a style="color: white" href="${process.env.REACT_APP_BACK_BASE_URL}admin/new-section" target="_blank">Find it here</a>`;
  createToast(text, "warning", dispatch);
};

export const isSectionCantDelete = (dispatch: ThunkDispatch<any, any, any>) => {
  const text = `Folder not deleted.<br>To delete this folder, it must be removed as section from all menus first.`;
  createToast(text, "warning", dispatch);
};
