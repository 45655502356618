import { useState } from "react";
import _ from "lodash";
import useAppDispatch from "./useAppDispatch";
import useAppSelector from "./useAppSelector";
import { IFile } from "@models/files/File.type";
import { renameResultElement } from "@redux/results/slices/adminResultSlice";
import { getExistentFilesValuesByFolder, updateElementName } from "@redux/files/thunks/fileThunk";
import { renameElement, setDragDropExistentFileData, setElementToRename, setShowDragDropExistentFileModal } from "@redux/files/slices/fileSlice";

export const useRenameElementActive = (initialValue?: string) => {
  const { file: { elementToRename } } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<string | undefined>(initialValue);

  const { data: resultsData } = useAppSelector(state => state.adminResults);
  const setElement = (el: IFile | null) => dispatch(setElementToRename(el));

  const clearRenameActive = () => {
    dispatch(setElementToRename(null));
  }

  const updateElementsName = () => {
    let name: string | undefined = value || elementToRename?.name;
    const element: any = { ...elementToRename, name };
    if (!_.isEqual(value, elementToRename?.name) && !!value) {
      dispatch(getExistentFilesValuesByFolder({ parentId: element.parent_id, values: [`${name}.${element.type}`] })).then((resp) => {
        const respExistentFilesValues = resp.payload;
        if (!respExistentFilesValues.includes(true)) {
          dispatch(renameElement(element));
          resultsData && dispatch(renameResultElement(element));
          dispatch(updateElementName({ file: element }));
        } else {
          dispatch(setDragDropExistentFileData({
            title: 'Rename File',
            action: 'rename',
            parameters: element
          }))
          dispatch(setShowDragDropExistentFileModal(true));
        }
      });
    }
  }

  const onKeyDown = (e: any) => {
    if (_.isEqual(e.key, 'Enter')) {
      clearRenameActive();
      updateElementsName();
    }
  }

  const onClick = (e: any) => {
    e.stopPropagation()
  }

  const onBlur = () => {
    clearRenameActive();
    updateElementsName();
  }

  return {
    value,
    setValue,
    elementToRename,
    setElement,
    onClick,
    onKeyDown,
    onBlur,
    clearRenameActive,
    updateElementsName,
  }
}
