import React from "react";

interface IFormCaptionProps {
  content: string | JSX.Element;
  link?: string;
}

const FormCaption = ({ content, link }: IFormCaptionProps) => {
  return (
    <div className="c-form-caption text-center mb-4">
      <span className="c-form-caption__text o-ft-xl-400">{content}</span>
      <a href={link} className="c-form-caption__link o-ft-xl-400">
        {link}
      </a>
    </div>
  );
};

export default FormCaption;
