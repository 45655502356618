import { Toast as BSToast, Fade } from "react-bootstrap";
import { useEffect, useState } from "react";

import { IToast } from "@models/toast/Toast.type";
import MaterialIcon from "@components/MaterialIcon";
import { removeToast } from "@redux/toasts/slices/toastsSlice";
import useAppDispatch from "@hooks/useAppDispatch";

const Toast = ({
  autohide = true,
  id,
  text,
  type,
  withClose = true,
  delay,
}: IToast) => {
  let [show, setShow] = useState<boolean>(false);
  let dispatch: any = useAppDispatch();

  useEffect(() => {
    setShow(true);
  }, []);

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      dispatch(removeToast(id));
    }, 100000);
  };

  return (
    <BSToast
      animation
      onClose={handleClose}
      className={`c-toast c-toast--${type} d-flex justify-content-between align-items-center o-ft-base-700 o-cl-white mb-2 mx-auto border-0 `}
      show={show}
      delay={delay}
      autohide={autohide}
      key={id}
      transition={Fade}
    >
      <BSToast.Body className="py-2 px-3">
        <div
          className="toast-file-name-je"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </BSToast.Body>
      {withClose && (
        <button
          type="button"
          className="c-toast__button px-3 border-0 d-flex align-items-center"
          aria-label="Close"
          data-dismiss="toast"
          onClick={handleClose}
        >
          <MaterialIcon size={20} icon="close" className="o-cl-white" />
        </button>
      )}
    </BSToast>
  );
};

export default Toast;
