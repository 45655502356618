import React, { useEffect, useState } from 'react'
import { images } from '@theme/images';
import PrivacyTermsSkeleton from '@components/PrivacyTermsSkeleton';
import PrivacyTermsController from '@controllers/PrivacyTermsController';

const privacyTermsController = new PrivacyTermsController();

export default function PrivacyPolicy() {

  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [privacyPolicy, setPrivacyPolicy] = useState("")

  useEffect(() => {
    privacyTermsController.getPrivacyPolicy(onSuccess, onError);
  }, []);

  const onSuccess = (response: string) => {
    setPrivacyPolicy(response)
    setIsLoading(false)
  }

  const onError = (error: any) => {
    setHasError(true)
  }

  return (
    <div className="o-image-background">
      <div className="o-image-background-layer">
        <div className="v-privacy-terms o-screen-scroll">
          <div className="v-privacy-terms__content">
            <div className="o-logo">
              <img className="o-logo-img" src={images.brandLogo} alt={`${process.env.REACT_APP_BRAND_NAME} logo`} />
            </div>
            {
              isLoading ?
                <PrivacyTermsSkeleton amountOfSections={5} />
                :
                <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
            }
          </div>
        </div>
      </div>
    </div>
  )
}
