import _ from 'lodash';
import { v4 } from 'uuid';
import { IFile } from '@models/files/File.type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFavoritesResponse } from '@models/favorites/Favorites.type';
import { applyFavoriteOrderFilters, applyFavoritesFilters, getFavorites, getFavoritesIdsTypes, nextPage } from '@redux/favorites/thunks/favoritesThunk';

export interface IFavoritesSlice {
  pagination: {
    links: any,
    meta: any,
    pending: boolean;
  }
  pending: boolean;
  saved: TFavoriteRelation[];
  data: IFile[];
  types: IFavoriteFilters[];
  filters: IFavoriteFilters[];
  orderFilter: number | null;
  orderFilterName: string | null;
}

const initialState: IFavoritesSlice = {
  pagination: {
    links: [],
    meta: {},
    pending: false
  },
  data: [],
  saved: [],
  pending: false,
  types: [],
  filters: [],
  orderFilter: 1,
  orderFilterName: 'Newest',
}

export interface IFavoriteFilters {
  id: string | number;
  name: string;
}

export type TFavoriteRelation = {
  favorite_id: number | string;
  document_id: number;
}

const checkFilteredValue = (arr: any, obj: any) => _.some(arr, obj);

export const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    updateFavorite: (state: IFavoritesSlice, action: PayloadAction<{ data: IFile, added: boolean, favoriteId: number | string }>) => {
      const { data, added, favoriteId } = action.payload;
      const { id } = data;

      const removeFavorite = (o: TFavoriteRelation) => !_.isEqual(o.document_id, id);

      state.data = (added) ? state.data.filter((o: IFile) => !_.isEqual(o.id, id)) : [...state.data, data];
      state.saved = (added)
        ? state.saved.filter(removeFavorite)
        : [...state.saved, { favorite_id: favoriteId, document_id: id }];
    },
    setFavoritesFilters: (state: IFavoritesSlice, action: PayloadAction<IFavoriteFilters>) => {
      if (_.isEqual(action.payload.name, "Clear All")) {
        state.filters = initialState.filters;
      }
      else {
        const filters = (checkFilteredValue(state.filters, action.payload))
          ? state.filters.filter((prop: any) => !_.isEqual(prop, action.payload))
          : [...state.filters, action.payload];

        state.filters = filters;
      }
    },
    setFavoriteOrderFilters: (state, action: PayloadAction<any>) => {
      const { orderingId, label } = action.payload;
      state.orderFilter = orderingId;
      state.orderFilterName = label;
    },
    setPinnedData: (state, action: PayloadAction<{ document_id: number; pin_to_dashboard: unknown }>) => {
      const { document_id, pin_to_dashboard } = action.payload;
      state.data = state.data.map(o => {
        if (_.isEqual(document_id, o.id)) {
          return {
            ...o, pin_to_dashboard,
          }
        }
        else return o;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFavorites.fulfilled, (state, action: PayloadAction<IFavoritesResponse>) => {
      const { data, meta, links } = action.payload;
      const { orderFilter, orderFilterName, filters } = initialState;
      state.filters = filters;
      state.orderFilter = orderFilter;
      state.orderFilterName = orderFilterName;
      state.data = data.map((f: any) => f?.document);
      state.pagination = {
        meta: meta,
        links: links,
        pending: false
      };
      state.pending = false
    });
    builder.addCase(getFavorites.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(getFavorites.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(getFavoritesIdsTypes.fulfilled, (state, action: PayloadAction<{ ids: any[], types: string[] }>) => {
      const { ids, types } = action.payload;
      state.saved = ids;
      state.types = [
        ...types.map((i: string, idx: number) => {
          return {
            id: v4(),
            name: i
          }
        })
      ];
    });
    builder.addCase(applyFavoritesFilters.fulfilled, (state, action: PayloadAction<any>) => {
      const { data } = action.payload;
      const { orderFilter, orderFilterName } = initialState;
      state.data = data.map((f: any) => f?.document);
      state.pending = false;
      state.orderFilter = orderFilter;
      state.orderFilterName = orderFilterName;
    });
    builder.addCase(applyFavoritesFilters.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(applyFavoritesFilters.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(applyFavoriteOrderFilters.fulfilled, (state, action: PayloadAction<any>) => {
      const { data, links, meta } = action.payload;
      state.data = data.map((f: any) => f?.document);
      state.pagination = {
        meta: meta,
        links: links,
        pending: false
      }
      state.pending = false;
    });
    builder.addCase(applyFavoriteOrderFilters.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(applyFavoriteOrderFilters.pending, (state) => {
      state.pending = true;
    });
    // pagination
    builder.addCase(nextPage.fulfilled, (state, action: PayloadAction<IFavoritesResponse>) => {
      const { data, links, meta } = action.payload;
      state.data = state.data.concat(data.map((f: any) => f?.document));
      state.pagination = {
        meta: meta,
        links: links,
        pending: false
      }
    });
    builder.addCase(nextPage.pending, (state) => {
      state.pagination = {
        ...state.pagination,
        pending: true
      }
    });
    builder.addCase(nextPage.rejected, (state) => {
      state.pending = false;
    });
  }
});

export const { updateFavorite, setFavoritesFilters, setFavoriteOrderFilters, setPinnedData } = favoriteSlice.actions;
export default favoriteSlice.reducer;
