import React from 'react'

interface ICurrentVersionButtonProps {
  text: string;
}


export default function CurrentVersionButton({ text }: ICurrentVersionButtonProps) {
  return (
    <button className='c-current-version-button d-flex align-items-center justify-content-center'>
      <span className='o-ft-xs-700'>{text}</span>
    </button>
  )
}
