import HttpProvider from "@api/HttpProvider";
import AdminHttpProvider from "@api/AdminHttpProvider";

const api = new HttpProvider();
const adminApi = new AdminHttpProvider();

export default class FavoritesController {
  index() {
    return api.request('get', 'api/favorites');
  }

  getIdsTypes() {
    return api.request('get', 'api/favorites/idstypes');
  }

  add({ document_id }: { document_id: number }) {
    return api.request('post', 'api/favorites', { document_id });
  }

  delete(id: number) {
    return api.request('delete', `api/favorites/${id}`);
  }

  filterBy(query: string) {
    return api.request('get', `api/favorites${query}`);
  }

  getNextPage(query: string) {
    return api.request('get', query);
  }
}
