import React from 'react'

import VaultBreadcrumb from '@components/NewBreadcrumb/VaultBreadcrumb'
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@components/Button';
import { formatCapitalizeFirstLetter } from '@helpers/formatCapitalizeFirstLetter';
import useAppSelector from "@hooks/useAppSelector";
const SectionHeader = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { currentVault } = useAppSelector((state) => state.vaults);

  return (
    <header className="c-header-module d-flex align-items-center justify-content-between">
      <div style={{ maxWidth: '82%' }}>
        <span className="c-header-module__title o-cl-grey-100 o-ft-3xl-600">{formatCapitalizeFirstLetter(currentVault?.name || "Vaults")}</span>
        <VaultBreadcrumb />
      </div>

      {location.pathname.split('/').length > 2 && (
        <div>
          <Button
            value="Go Back"
            icon={{ icon: 'arrow_back_ios', size: 12, position: 'right' }}
            onClick={() => navigate(-1)}
          />
        </div>
      )}
    </header>
  )
}

export default SectionHeader