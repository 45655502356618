import PinUnpinProvider from "../providers/PinUnpinProvider";
import VaultsSection from "../sections/Vaults/VaultsSection";
import React from "react";
import { useParams } from "react-router-dom";

const Vault = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <PinUnpinProvider vaultId={Number(id)}>
      <VaultsSection />
    </PinUnpinProvider>
  );
};

export default Vault;
