/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";

import Button from "@components/Button";
import ActionModal from "./ActionModal";
import { RadioSwitch } from "./RadioSwitch";
import { TRadioButton } from "@components/RadioButton";
import _ from "lodash";
import {
  useGetDashboardSections,
  useGetMobileMenuSections,
} from "@redux/sections/api";
import {
  usePinSectionBottomBar,
  usePinSectionDashboard,
} from "@redux/documents/api";
import {
  usePinUnpinContext,
  EModulesToPin,
} from "../providers/PinUnpinProvider";
import useAppDispatch from "@hooks/useAppDispatch";
import { createToast } from "@helpers/createToast";
import { setPinnedData } from "@redux/search/slice";

interface IPinModalProps {
  title?: string;
  subtitle?: string;
  pinModal: boolean;
  setPinModal: (value: boolean) => void;
}

const PinModal = ({
  title = "Replace",
  subtitle = "Please check which of the following pinned sections you want to replace:",
  pinModal,
  setPinModal,
}: IPinModalProps) => {
  const { data: dashboardSections, refetch: refetchDashboardSections } =
    useGetDashboardSections();
  const { data: mobileMenuSections, refetch: refetchMobileMenuSections } =
    useGetMobileMenuSections();
  const [pinSectionDashboard] = usePinSectionDashboard();
  const [pinSectionBottomBar] = usePinSectionBottomBar();
  const dispatch = useAppDispatch();
  const { document, module, vaultId, context, endpoint } = usePinUnpinContext();
  const initialActiveIndex = -1;
  const defaultRadio: TRadioButton = {
    id: 0,
    name: "",
    value: "",
  };
  const [currentIndex, setCurrentIndex] = useState<number>(initialActiveIndex);
  const [selectedOption, setSelectedOption] =
    useState<TRadioButton>(defaultRadio);

  const handleChangeRadioSwitch = (opt: TRadioButton) => {
    setSelectedOption(opt);
  };

  const pinOptions = (
    module === "navigation" ? mobileMenuSections : dashboardSections
  )?.data?.map((section: any, index: number) => {
    const { pinned } = section;
    return {
      id: section.id,
      name: "replace",
      value: pinned?.name || section.name,
      icon: section.icon,
      pin_to_dashboard: section.pin_to_dashboard || null,
      position: index + 1,
    };
  });

  return (
    <div>
      <ActionModal
        show={pinModal}
        title={title}
        subtitle={subtitle}
        includesForm
        closeButton
        onHide={() => {
          setPinModal(false);
          setCurrentIndex(-1);
        }}
        renderContent={
          <>
            <RadioSwitch
              variant="block"
              {...{ currentIndex, setCurrentIndex }}
              values={pinOptions}
              onChange={handleChangeRadioSwitch}
            />
            <div className="d-flex w-100 mt-4 align-items-center justify-content-center pt-1 ">
              <Button
                className="w-75 mx-2 mx-md-4"
                disabled={selectedOption.id === 0}
                onClick={() => {
                  setPinModal(false);
                  if (document) {
                    if (_.isEqual(module, EModulesToPin.Dashboard)) {
                      pinSectionDashboard({
                        dashboardPosition: selectedOption.position || 1,
                        pineableId: document.id || 0,
                        parentId: document.parent_id || 0,
                        vaultId,
                        endpoint,
                      })
                        .unwrap()
                        .then(() => {
                          if (context === "results") {
                            dispatch(
                              setPinnedData({
                                documentId: document.id || 0,
                                isPinned: true,
                                pinAttribute: "is_dashboard_pinned",
                              }),
                            );
                          }
                          refetchDashboardSections();
                          createToast(
                            `Folder added to dashboard successfully`,
                            "success",
                            dispatch,
                          );
                        });
                    } else {
                      pinSectionBottomBar({
                        bottomBarPosition: selectedOption.position || 1,
                        pineableId: document.id || 0,
                        parentId: document.parent_id || 0,
                        vaultId,
                        endpoint,
                      })
                        .unwrap()
                        .then(() => {
                          refetchMobileMenuSections();
                          createToast(
                            `Folder added to dashboard successfully`,
                            "success",
                            dispatch,
                          );
                        });
                    }
                  }
                }}
                size="medium-big"
                value="Replace"
              />
            </div>
          </>
        }
      />
    </div>
  );
};

export default PinModal;
