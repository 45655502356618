import React from "react";
import { images } from "@theme/images";

interface IFormHeaderProps {
  title: string;
  subtitle?: string;
}

const FormHeader = (props: IFormHeaderProps) => {
  const { title, subtitle } = props;

  return (
    <div className="c-form-header d-flex flex-column">
      {images.tenantLogo && (
        <div className="c-form-header__portal d-flex">
          <img
            className="c-form-header__portal-logo-img"
            src={images.tenantLogo}
            alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
          />
        </div>
      )}
      <div className="c-form-header__logo d-flex">
        <img
          className="c-form-header__logo-img"
          src={images.verticalBrandLogo}
          alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
        />
      </div>
      <span className="c-form-header__title o-ft-xl-400 o-ft-xl@lg text-center d-block">
        {title}
      </span>
      <span className="c-form-header__subtitle o-ft-2xl-600 o-ft-2xl@md text-center d-block">
        {subtitle}
      </span>
      {/* <span className="c-form-header__description"></span> */}
    </div>
  );
};

export default FormHeader;
