import useWindowSize from '@hooks/useWindowSize';

interface ILoginContainerProps {
  children: JSX.Element | JSX.Element[];
  renderHeader: JSX.Element;
  renderFooter: JSX.Element;
}

const LoginContainer = ({ children, renderHeader, renderFooter }: ILoginContainerProps) => {
  const { responsive } = useWindowSize();

  return (
    <div className="c-login-container d-flex justify-content-center align-items-center o-screen-scroll">
      <div className="c-login-container__layer">
        <div className="c-login-container__content row justify-content-center align-items-center min-vh-100">
          <div className="col-12 col-md-6 col-xl-4 col-xxl-4">
            <div className="c-login-container__form-container d-flex flex-column">
              <div className='d-flex flex-column flex-grow-1 justify-content-center'>
                {renderHeader}
                {children}
              </div>
              <div className='d-flex justify-content-center'>
                {(responsive.md) && renderFooter}
              </div>
            </div>
          </div>
          {(!responsive.md) && renderFooter}
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;
