import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTags, getTagsByDocuments, postTag } from '@redux/tags/thunks/tagThunk';
import { TTag, TTagData } from '@models/Tag.type';
import _ from 'lodash';

export interface ITagSlice {
  data: TTag[]; // all tags (suggestions)
  pending: boolean;
  tagsByDocuments: TTagData; // all tags by document
  tagsByDocumentsEditable: TTagData; // all tags by document
  currentTagValue: string;
}

type TDocumentTag = {
  document_id: number;
  tags: TTag[];
}

const initialState: ITagSlice = {
  data: [],
  pending: false,
  tagsByDocuments: {},
  tagsByDocumentsEditable: {},
  currentTagValue: '',
}

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    setCurrentTagValue: (state: ITagSlice, action: PayloadAction<string>) => {
      state.currentTagValue = action.payload;
    },
    removeTag: (state: ITagSlice, action: PayloadAction<{ documentId: number, tag: TTag }>) => {
      const { documentId, tag } = action.payload;
      state.tagsByDocumentsEditable[documentId] = state.tagsByDocumentsEditable[documentId].filter((o) => !_.isEqual(o.id, tag.id));
    },
    addTag: (state: ITagSlice, action: PayloadAction<{ documentId: number, tag: TTag }>) => {
      const { documentId, tag } = action.payload;
      state.tagsByDocumentsEditable[documentId] = [...state.tagsByDocumentsEditable[documentId], tag]
    },
    clearTagsByDocumentsEditable: (state: ITagSlice, action: PayloadAction<{ documentId: number }>) => {
      const { documentId } = action.payload;
      state.tagsByDocumentsEditable[documentId] = [...state.tagsByDocuments[documentId]];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTags.fulfilled, (state, action: PayloadAction<any>) => {
      let { data } = action.payload;
      state.data = data;
      state.pending = false;
    });
    builder.addCase(getTags.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(getTags.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(postTag.fulfilled, (state, action: PayloadAction<any>) => {
      state.pending = false;
    });
    builder.addCase(postTag.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(postTag.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(getTagsByDocuments.fulfilled, (state, action: PayloadAction<TDocumentTag[]>) => {
      const data = Object.fromEntries(action.payload.map((o: TDocumentTag) => [o.document_id, o.tags]));
      state.tagsByDocuments = data;
      state.tagsByDocumentsEditable = data;
    });
  }
});

export const { setCurrentTagValue, removeTag, addTag, clearTagsByDocumentsEditable } = tagSlice.actions;
export default tagSlice.reducer;
