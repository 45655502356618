import { baseURL } from "@api/HttpProvider";
import { whichProvider } from "@helpers/whichProvider";
import { TUser } from "@models/user/User.type";
import axios from "axios";

export default class UserController {
  static getUser() {
    return whichProvider().request("get", "api/auth/user");
  }

  static updateUser(user: TUser) {
    const token = localStorage.getItem("access_token");
    return axios.post(`${baseURL}api/users/${user.id}?_method=PUT`, user, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": `multipart/form-data`,
      },
    });
  }
}
