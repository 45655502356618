/* eslint-disable @typescript-eslint/no-explicit-any */
import { colors } from "@theme/colors";

export const customStylesTags = {
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused ? "none" : provided.boxShadow,
    borderColor: state.isFocused ? colors.brandColorPrimary : colors.grey100,
    outline: "none",
    "&:hover": {
      borderColor: state.isFocused ? colors.brandColorPrimary : colors.grey100,
    },
    minHeight: "45px",

    zIndex: 2,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "0px",
    color: colors.grey100,
    backgroundColor: "transparent",
    fontWeight: state.isFocused ? "700" : provided.fontWeight,
    fontSize: "14px",
    fontFamily: '"Open Sans", sans-serif',
    fontStyle: "normal",
    lineHeight: "normal",
    ":before": {
      content: '""',
      display: "inline-block",
      width: "4px",
      height: "23px",
      backgroundColor: state.isFocused
        ? colors.brandColorPrimary
        : "transparent",
      marginRight: "4px",
      marginLeft: "0",
      flexShrink: 0,
    },
  }),
  multiValue: (base: any) => ({
    ...base,
    display: "inline-flex",
    padding: "0px 4px",
    alignItems: "center",
    gap: "0px",
  }),
  placeholder: (base: any) => ({
    ...base,
    color: "#9FA0A0",
    fontFamily: '"Open Sans", sans-serif',
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    ":hover": {
      backgroundColor: "transparent",
      color: colors.red,
    },
    paddingLeft: "0px",
  }),
};
