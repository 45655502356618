/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import styled from "styled-components";

const BarContainer = styled.div`
  background: var(--rewards-light-green, #bdffeb);
  border: 1px solid var(--rewards-green, #32cfc2);
  height: 8px;
  border-radius: 10px;
  overflow: hidden;
`;

const BarValue = styled("div")<{ max: number; value: number }>`
  height: 9px;
  background: var(--rewards-green, #32cfc2);
  opacity: 0.5;
  width: ${(props: any) => (props.value / props.max) * 100}%;
  transition: 0.5s all;
  border-radius: 3px;
`;

const ProgressBar = ({ max, value }: { max: number; value: number }) => {
  return (
    <div>
      <BarContainer>
        <BarValue max={max} value={value} />
      </BarContainer>
      <span className="d-flex justify-content-end o-ft-xs-400 mt-1">
        {value + 1}/{max}
      </span>
    </div>
  );
};

export default ProgressBar;
