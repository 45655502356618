/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import "./styles.css";

import "./LexicalWysWyg.css";

import { $getRoot, $insertNodes } from "lexical";

import { $generateNodesFromDOM } from "@lexical/html";
import Editor from "./Editor";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";
import PlaygroundNodes from "./nodes/PlaygroundNodes";
import React from "react";
import { SharedHistoryContext } from "./context/SharedHistoryContext";

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: Error): void {
  console.error(error);
}

const LexicalWysWyg = ({ onChange, initialValue }: any) => {
  const initialConfig = {
    editorState: (editor: any) => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialValue, "text/html");
      const nodes = $generateNodesFromDOM(editor, dom);
      const root = $getRoot();
      root.clear();

      $insertNodes(nodes);
    },
    namespace: "Lexical",
    theme: PlaygroundEditorTheme,
    onError,
    nodes: [...PlaygroundNodes],
  };

  return (
    <div className="editorWrapper">
      <LexicalComposer initialConfig={initialConfig}>
        <SharedHistoryContext>
          <div className="editor-shell">
            <Editor onChange={onChange} />
          </div>
        </SharedHistoryContext>
      </LexicalComposer>
    </div>
  );
};

export default LexicalWysWyg;
