import { useCallback, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom';

import PanelTabs from '@components/PanelTabs'
import { ITabsProps } from '@components/Tabs';
import MaterialIcon from '@components/MaterialIcon';

import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';

import { setSelectedProject } from '@redux/my-stores/slice';

import Projects from './shared/Projects/Projects';
import Information from './shared/Information/Information';

import { OutletContext } from '@views/MyStores/Layout';

const tabs: ITabsProps[] = [
  { id: 1, title: 'Projects' },
  { id: 2, title: 'Store Information' }
];

const Store: React.FC = () => {
  const [activeTab, setActiveTab] = useState<ITabsProps>(tabs[0]);

  const { projects: { data } } = useOutletContext<OutletContext>()
  const { data: user } = useAppSelector(state => state.user)
  const { selectedStore } = useAppSelector(state => state.myStores)
  // const dispatch = useAppDispatch()

  // useEffect(() => {
  //   dispatch(setSelectedProject(null))
  // }, [dispatch])

  const handleToggleTab = (tab: ITabsProps) => {
    setActiveTab(tab)
  }

  const SwitchTab = useCallback(() => {
    if (activeTab.id === 1) return <Projects />
    if (activeTab.id === 2) return <Information />
    return <></>
  }, [activeTab.id])

  // request new project email template
  const R_emailTo = 'stores@franly.com';
  const R_subject = 'New Project Request for ' + selectedStore?.name;
  const R_body =
    'Please enter below the information needed for this request.' +
    '%0D%0A%0D%0A' +
    'Project Type:' +
    '%0D%0A%0D%0A' +
    'Related users:' +
    '%0D%0A' +
    selectedStore?.users?.map(user => `• ${user.email}:`).join('%0D%0A') +
    '%0D%0A%0D%0A' +
    'Project Estimated Start Date:' +
    '%0D%0A%0D%0A' +
    'Project Estimated Completion Date:' +
    '%0D%0A%0D%0A'

  // email details project information
  const emailTo = 'stores@franly.com';
  const subject = 'Store Information Update Request';
  const body = `Please enter below this line the update you are requesting.%0D%0A%0D%0A - ${user?.first_name} ${user?.last_name}`

  const request_new_project = `mailto:${R_emailTo}?subject=${R_subject}&body=${R_body}`;
  const request_store_information = `mailto:${emailTo}?subject=${subject}&body=${body}`;

  return (
    <>
      <div className='mb-4'>
        {activeTab?.id === 1 && (data && data.length > 0) && (
          <a className='email-button o-cl-brand-secondary text-decoration-none' href={request_new_project}>
            <MaterialIcon icon='forward_to_inbox' />
            <span className='request-text'>Request New Project</span>
          </a>
        )}

        {activeTab?.id === 2 && (
          <a className='email-button o-cl-brand-secondary text-decoration-none' href={request_store_information}>
            <MaterialIcon icon='forward_to_inbox' />
            <span className='request-text'>Request Store Information Update</span>
          </a>
        )}
      </div>

      <PanelTabs {...{ tabs, activeTab }} onChange={handleToggleTab} />
      <>{SwitchTab()}</>
    </>
  )
}

export default Store
