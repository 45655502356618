/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
import MaterialIcon, { TIcon } from "@components/MaterialIcon";
import { useHandleFavorite } from "@hooks/useHandleFavorite";
import classNames from "classnames";
import _ from "lodash";
import React from "react";

interface IActionProps {
  icon: TIcon;
  onClick: () => void;
  text: string;
  lastOne: boolean;
  type?: "favorite" | "pin";
  file: any;
  hidden?: boolean;
}

const Action = ({
  icon,
  onClick,
  lastOne,
  text,
  type,
  file,
  hidden = false,
}: IActionProps) => {
  const { saved } = useHandleFavorite();

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick();
  };

  const buttonClass = classNames({
    "me-0": _.isEqual(lastOne, true),
    "me-3": _.isEqual(lastOne, false),
    "border-0 o-bg-transparent": _.isEqual(type, "favorite"),
    "c-action": !_.isEqual(type, "favorite"),
    "c-action--no-file": !file.hasOwnProperty("type"),
    "c-action--pinned": _.isEqual(type, "pin") && !!file.is_dashboard_pinned,
    "d-none": hidden,
  });

  const classFavoriteIcon = saved(file) ? "filled" : "outline";

  return (
    <button
      className={`${buttonClass} o-max-width position-relative d-flex align-items-center justify-content-center`}
      onClick={clickHandler}
      title={text}
      type="button"
    >
      {_.isEqual(type, "favorite") ? (
        <MaterialIcon
          icon="favorite"
          color="o-cl-brand-primary"
          size={20}
          className={`material-symbols-${classFavoriteIcon}`}
        />
      ) : (
        <MaterialIcon
          className="o-max-width"
          color={!file.hasOwnProperty("type") ? "o-cl-grey-200" : "o-cl-white"}
          icon={icon}
          size={18}
        />
      )}
    </button>
  );
};

export default Action;
