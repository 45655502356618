import React from "react";
import FileManagementHome from "../../sections/filesManagement/FilesManagement";
import DocumentProvider from "../../providers/DocumentsProvider";
import PinUnpinProvider from "../../providers/PinUnpinProvider";

const FileManagementView = () => {
  return (
    <PinUnpinProvider context="file-management">
      <DocumentProvider>
        <FileManagementHome />
      </DocumentProvider>
    </PinUnpinProvider>
  );
};

export default FileManagementView;
