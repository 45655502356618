/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EToastTypes, IToast } from "@models/toast/Toast.type";
import { addToast, updateToast } from "@redux/toasts/slices/toastsSlice";

import { IFile } from "@models/files/File.type";
import { ThunkDispatch } from "@reduxjs/toolkit";
import _ from "lodash";
import { createToast } from "./createToast";
import { getCookie } from "@api/AdminHttpProvider";
import { setDownloading } from "@redux/files/slices/fileSlice";

export const downloadFiles = async (
  files: IFile[],
  dispatch: ThunkDispatch<any, any, any>,
) => {
  const currentWebsite = localStorage.getItem("current_website");

  const addHeaders = (file: IFile) => {
    if (file.type) return {};
    return {
      method: "GET",
      headers: {
        Authorization: _.isEqual(currentWebsite, "admin")
          ? `Bearer ${getCookie("access_token")}`
          : `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
  };

  const getURL = (file: IFile) => {
    if (file.type) {
      return file.file.url;
    }
    if (process.env.REACT_APP_BACK_BASE_URL) {
      return `${process.env.REACT_APP_BACK_BASE_URL}api/admin/documents/${file.id}/download`;
    }
    return "";
  };

  const createDownloadObject = (blob: Blob, file: IFile) => {
    const element = document.createElement("a");
    const url = URL.createObjectURL(blob);
    element.href = url;
    element.download = `${file.name}${file.type ? `.${file.type}` : ".zip"}`;
    element.click();
    URL.revokeObjectURL(url);
  };

  const createSuccessMessage = (
    dispatch: ThunkDispatch<any, any, any>,
    file: IFile,
  ) => {
    let text = "";

    if (window.Noty) {
      text = `<strong>File Downloaded</strong><br>File ${file.name} downloaded`;
      new window.Noty({ type: "success", text }).show();
    } else {
      // text = 'Download completed sucessfully!'
      // const toast = {
      //   id: Date.now(),
      //   text,
      //   type: EToastTypes.PRIMARY,
      //   autohide: true
      // }
      // dispatch(addToast(toast));
    }
  };

  files.forEach(async (file) => {
    try {
      const loadingToast: IToast = {
        id: file.id,
        text: "Download completed successfully!",
        type: EToastTypes.PRIMARY,
      };

      dispatch(setDownloading(true));

      const response = await fetch(getURL(file), addHeaders(file));
      const data = await response.blob();
      const blob = new Blob([data]);
      createDownloadObject(blob, file);

      dispatch(addToast(loadingToast));

      dispatch(
        updateToast({
          ...loadingToast,
          type: EToastTypes.PRIMARY,
          text: "Download completed successfully!",
          withClose: true,
          autohide: true,
        }),
      );

      dispatch(setDownloading(false));

      createSuccessMessage(dispatch, file);
    } catch (error) {
      console.log(error);
      createToast(
        "Something went wrong while processing your request. Please try again.",
        "danger",
        dispatch,
      );
    }
  });
};
