import { RootState } from '@redux/store';
import { useSelector } from 'react-redux';
import useWindowSize from '@hooks/useWindowSize';
import profileImg from '../assets/profile-img.png';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

interface IAvatarProps {
  onClick?: () => void;
}

const Avatar = ({onClick}:IAvatarProps) => {
  const { responsive } = useWindowSize();
  const { data, pending } = useSelector((state: RootState) => state.user);

  const name = data?.name;
  const email = data?.email;
  const avatar_file = data?.avatar_file;

  const loadingImageSize = (responsive.xs ? (responsive.md ? (responsive.lg ? "56px" : "51px") : "51px") : "56px")

  if (pending) {
    return (
      <div className='c-avatar--loading'>
        <SkeletonTheme baseColor="#D8D8D8" highlightColor="#FFFFFF">
          <div className='d-flex justify-content-center align-items-center'>
            <Skeleton circle={true} height={loadingImageSize} width={loadingImageSize} />
          </div>
          <div className='c-avatar__user-section align-self-center'>
            <span className='c-avatar__name o-ft-base-300 p-0'><Skeleton width={"80%"} /></span>
            <span className='c-avatar__email o-ft-xs-300 m-0'><Skeleton /></span>
          </div>
        </SkeletonTheme>
      </div>
    )
  }

  return (
    <div className='c-avatar__desktop-section d-flex align-items-md-center' onClick={onClick}>
      <div className='d-flex'>
        <div className='c-avatar__user-picture'>
          <img className="header__logo c-avatar__picture" src={(avatar_file ? avatar_file.url : profileImg)} alt="profile" />
        </div>
        {!(responsive.md && !responsive.lg) &&
          <div className='c-avatar__desktop-section d-flex flex-column justify-content-center'>
            <span className='c-avatar__name o-ft-base-300 p-0'>{name}</span>
            <span className='c-avatar__email o-ft-xs-300 m-0'>{email}</span>
          </div>
        }
      </div>
    </div>
  )
};

export default Avatar;

