import { ErrorMessage, Field } from "formik";
import MaterialIcon, { TIcon } from "./MaterialIcon";
import React, { FocusEvent, useEffect, useState } from "react";

import _ from "lodash";
import classNames from "classnames";

interface ITextFieldProps {
  error?: string;
  label: string;
  placeholder: string;
  type: string;
  name: string;
  handleBlur: (e: FocusEvent<any, Element>) => void;
  renderError?: JSX.Element;
  errorHandler?: boolean;
  iconName?: TIcon;
  onChange?: any;
  renderRight?: JSX.Element | JSX.Element[];
  isfocusOnRender?: boolean;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  disabled?: boolean;
}

type EventType = FocusEvent<any, Element>;

export default function TextField(props: ITextFieldProps) {
  const {
    isfocusOnRender = false,
    error,
    label,
    type,
    placeholder,
    name,
    handleBlur,
    renderError,
    errorHandler,
    iconName,
    renderRight,
    onKeyPress,
    disabled,
  } = props;
  const [focus, setFocus] = useState<boolean>(false);
  const [id, setId] = useState<string | null>(null);
  const [passwordType, setPasswordType] =
    useState<"text" | "password">("password");

  const isPasswordType = (v: string) => _.isEqual(v, "password");

  const labelClass = classNames({
    "c-text-field__label--active": focus,
    "c-text-field__label--error": errorHandler,
  });

  const inputContainerClass = classNames(
    "d-flex align-content-center align-items-center",
    {
      "c-text-field__input-container--active": focus,
      "c-text-field__input-container--error": errorHandler,
    }
  );

  useEffect(() => {
    const newId = crypto.randomUUID();
    setId(newId);
  }, []);

  useEffect(() => {
    if (isfocusOnRender && id) {
      document.getElementById(id)!.focus();
    }
  }, [id, isfocusOnRender]);

  const TextInput = () => (
    <div className={`c-text-field__input-container ${inputContainerClass}`}>
      {!_.isUndefined(iconName) && (
        <MaterialIcon icon={iconName} size={24} color="o-cl-grey-200" />
      )}
      <Field
        id={id}
        name={name}
        type={!isPasswordType(type) ? type : passwordType}
        className={`c-text-field__input o-ft-base-400 ${
          disabled && "bg-transparent"
        }`}
        placeholder={placeholder}
        // autoFocus
        onFocus={(e: EventType) => setFocus(true)}
        onBlur={(e: EventType) => {
          handleBlur(e);
          setFocus(false);
        }}
        onKeyDown={onKeyPress}
        disabled={disabled}
      />

      {isPasswordType(type) && !errorHandler && (
        <div className="d-flex o-cursor-pointer">
          <MaterialIcon
            onClick={() =>
              setPasswordType(
                isPasswordType(passwordType) ? "text" : "password"
              )
            }
            icon={
              isPasswordType(passwordType) ? "visibility" : "visibility_off"
            }
            size={24}
            color="o-cl-grey-200"
          />
        </div>
      )}

      {errorHandler && (
        <div className="position-relative d-flex">
          {/* <Icon
            icon="error"
            size={20}
            color={colors.red}
          /> */}
          <MaterialIcon icon="error" size={24} color="o-cl-red" />
          {renderError || null}
        </div>
      )}
    </div>
  );

  return (
    <div className="c-text-field">
      <label
        htmlFor={name}
        className={`c-text-field__label o-ft-xs-400 ${labelClass}`}
      >
        {label}
      </label>
      {renderRight ? (
        <div className="d-flex justify-content-between align-items-center">
          {TextInput()}
          {renderRight}
        </div>
      ) : (
        TextInput()
      )}

      {error && (
        <span className="c-text-field__error-message">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
}
