import { IFile } from '@models/files/File.type';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import MaterialIcon, { TIcon } from '@components/MaterialIcon';
import { copyFile, getExistentFilesValuesByFolder, pasteFile } from '@redux/files/thunks/fileThunk';
import { setDragDropExistentFileData, setShowDragDropExistentFileModal } from '@redux/files/slices/fileSlice';

interface IContextMenuProps {
  data?: IFile;
  folder_id: number;
  contextMenu: any
}

const ContextMenu = ({ data, folder_id, contextMenu }: IContextMenuProps) => {

  const dispatch = useAppDispatch();
  const { file: { copiedFile } } = useAppSelector((state) => state);

  const { file, xPos, yPos } = contextMenu;

  const actions = [
    { id: 1, icon: "content_copy", name: "Copy", onClick: () => dispatch(copyFile(file)) },
    { id: 2, icon: "content_paste", name: "Paste", onClick: () => handlePaste() },
  ];

  const handlePaste = async () => {
    if (copiedFile) {
      try {
        if (copiedFile?.parent_id === folder_id || (!copiedFile?.parent_id && !folder_id)) {
          dispatch(pasteFile({ file: copiedFile, parent_id: folder_id ? folder_id : null }))
        } else {
          dispatch(getExistentFilesValuesByFolder({ parentId: folder_id, values: [`${copiedFile.name}.${copiedFile.type}`] })).then((resp) => {
            const respExistentFilesValues = resp.payload;
            if (!respExistentFilesValues.includes(true)) {
              dispatch(pasteFile({ file: copiedFile, parent_id: folder_id ? folder_id : null }))
            } else {
              dispatch(setDragDropExistentFileData({
                title: 'Paste File',
                action: 'paste',
                parameters: { file: copiedFile, parent_id: folder_id ? folder_id : null }
              }))
              dispatch(setShowDragDropExistentFileModal(true));
            }
          });
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  const RenderOptions = ({ id, children }: { id: number, children: JSX.Element }) => {
    switch (id) {
      case 1:
        if (data)
          return (
            children
          )
        else
          return <></>
      case 2:
        if (!data)
          return (
            children
          )
        else
          return <></>
      default:
        return (
          children
        )
    }
  }

  return (
    <div style={{
      top: yPos,
      left: xPos
    }}
      className="c-context-menu position-absolute">
      {
        <ul className='p-0 m-0'>
          {actions.map((action, index) => {
            const { id, icon, name, onClick } = action;
            return <RenderOptions key={id} id={id}>
              <li onClick={onClick} className={`c-context-menu__item o-cl-grey o-ft-sm o-cursor-pointer`}>
                <MaterialIcon
                  className={`o-max-width mx-2`}
                  size={24}
                  color={"o-cl-grey-100"}
                  icon={icon as TIcon}
                />
                {name}
              </li>
            </RenderOptions>
          })}
        </ul>
      }
    </div>
  );
};

export default ContextMenu;
