export const formatDateDDmmmYYYY = (date: Date) => {
  let shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const dateToParse = new Date(date);
  const formattedDate = `${dateToParse.getDate().toString().padStart(2, "0")} ${shortMonths[dateToParse.getMonth()]} ${dateToParse.getFullYear()}`;
  return isNaN(dateToParse.getTime()) ? "" : formattedDate;
}

export const formatDate = (date: Date) => {
  const dateToParse = new Date(date);
  let monthOfYear = dateToParse.getMonth() + 1
  let getDayWith2Digits = `${dateToParse.getDate() < 10 ? '0' : ''}${dateToParse.getDate()}`;
  let getMonthWith2Digits = `${monthOfYear < 10 ? '0' : ''}${monthOfYear}`;
  return `${getMonthWith2Digits}/${getDayWith2Digits}/${dateToParse.getFullYear()}`;
}
