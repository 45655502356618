import React, { useEffect } from "react";
import AuthController from "@controllers/AuthController";
import useAppDispatch from "@hooks/useAppDispatch";
import { deleteLoggedUser } from "@redux/users/thunks/userThunk";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    AuthController.logOut();
    localStorage.clear();
    dispatch(deleteLoggedUser());
    navigate("/login");
  }, [dispatch, navigate]);

  return <div />;
};

export default Logout;
