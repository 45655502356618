import { useEffect, useState } from "react";

type Props = {
  seconds: number;
  onTimeOut: () => void;
}

const TextTimer: React.FC<Props> = ({ seconds, onTimeOut, }) => {
  const [timer, setTimer] = useState(seconds)

  useEffect(() => {
    const timer = setInterval(() => {
      setTimer(p => p > 1 ? p - 1 : 0);
    }, 1000);

    // Limpiar el intervalo cuando el componente se desmonta o cuando el tiempo llega a cero
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (timer === 0) onTimeOut()
  }, [timer, onTimeOut])

  let minutos: any = Math.floor(timer / 60);
  let segundos: any = timer % 60;

  minutos = minutos < 10 ? '0' + minutos : minutos;
  segundos = segundos < 10 ? '0' + segundos : segundos;

  return <span>{minutos}:{segundos}</span>
}

export default TextTimer