import _ from "lodash";
import DotsMenu from "./DotsMenu";
import classNames from "classnames";
import Button from "@components/Button";
import MaterialIcon from "./MaterialIcon";
import AdminSearchBar from "./AdminSearchBar";
import AdminBreadcrumb from "./AdminBreadcrumb";
import useWindowSize from '../hooks/useWindowSize';
import { IFile, IFileResponse } from "@models/files/File.type";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCapitalizeFirstLetter } from "@helpers/formatCapitalizeFirstLetter";

interface IHeaderModuleProps {
  folder?: IFileResponse;
  type?: 'dashboard';
  actionList?: any;
  title?: string;
  hideBreadcrumb?: boolean;
}

const AdminHeaderModule = ({ folder, actionList, title, hideBreadcrumb }: IHeaderModuleProps) => {

  const location = useLocation();
  const navigate = useNavigate();
  const { responsive } = useWindowSize();

  const file: IFile = JSON.parse(localStorage.getItem('current_file_history')!);

  const onHandleNavigate = () => {
    // if (!folder?.parents?.length && (!folder?.id || isTrash))
    if (!folder?.parents?.length && !folder?.parent_id)
      navigate(`/admin/file-management`, { replace: true })
    else
      navigate(`/admin/file-management/${folder?.parent_id}`, { replace: true });
  }

  const chooseTitle = () => {
    if (folder?.name)
      return formatCapitalizeFirstLetter(folder.name)
    else if (title)
      return title
    else
      return "File Management";
  }

  const isFileManagement = () => {
    const isFileManagement = _.isEqual(location.pathname, "/admin/file-management") || _.isEqual(location.pathname, "/admin/file-management/")
    const isResults = _.isEqual(location.pathname, "/admin/file-management/results") || _.isEqual(location.pathname, "/admin/file-management/results/")
    if (!folder?.id && !(isFileManagement || isResults))
      return false
    return true
  }

  const buttonClasses = classNames({
    // "d-none": !(folder?.parents?.length || folder?.id || isTrash)
    "d-none": (_.isEqual(location.pathname, "/admin/file-management"))
  });

  return (
    <div className="c-header-module d-flex flex-row justify-content-between ">
      <div className="c-header-module__left-container">
        <div className="d-flex align-items-center">
          {(!responsive.md && actionList) &&
            <DotsMenu actionList={actionList} dotsDirection={"vertical"} />
          }
          {
            (_.isEqual(folder?.name, 'history'))
              ? <span className="c-header-module__title o-ft-3xl-600">Version History for: <span className="o-cl-grey-200">{file.name}</span></span>
              : <span className="c-header-module__title o-cl-grey-100 o-ft-3xl-600">{chooseTitle()}</span>
          }
        </div>
        {(folder && !hideBreadcrumb) && <AdminBreadcrumb folder={folder} />}
      </div>

      <div className="d-flex align-items-center">
        {
          (responsive.md) ?
            <>
              {
                isFileManagement() &&
                <div className="ms-4 me-5">
                  <AdminSearchBar />
                </div>
              }
              <Button
                className={buttonClasses}
                value="Go Back"
                icon={{
                  icon: 'arrow_back_ios',
                  size: 12,
                  position: 'right',
                }}
                onClick={() => onHandleNavigate()}
              />
            </>
            :
            <label onClick={() => onHandleNavigate()} className={`c-button--circle o-bg-brand-secondary:hover o-cl-brand-secondary o-cl-white:hover o-ft-xs-700 d-flex align-items-center justify-content-center mb-0 ms-4 ${buttonClasses}`}>
              <MaterialIcon
                className="c-button__icon ms-1"
                icon='arrow_back_ios'
                size={12}
              />
            </label>
        }
      </div>
    </div>
  );
};

export default AdminHeaderModule;
