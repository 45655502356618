import React from 'react';
import MaterialIcon from './MaterialIcon';
// import { TContactSpecialty } from '@models/ContactsaByType.type';
import { TContactCategory } from '@models/ContactCategory.type';
import { IFilterDropdownItem } from '@components/filters/FilterDropdownList';

export type TFilterTagProps = {
  item: TFilterTag;
  onClick: (obj: TFilterTag) => void;
}

export type TFilterTag = TContactCategory | TFilterTagClear | IFilterDropdownItem;

export type TFilterTagClear = {
  id: string;
  name: "Clear All";
};

const FilterTag = ({ item, onClick }: TFilterTagProps) => {
  const { name } = item;

  return (
    <div onClick={(e) => onClick(item)} className='c-filter-tag o-cursor-pointer d-inline-flex align-items-center'>
      <span className='c-filter-tag__text o-ft-sm-400'>{name}</span>
      <div className=''>
        <MaterialIcon
          className='c-filter-tag__icon'
          icon='close'
          size={12}
          color='o-cl-grey-200'
        />
      </div>
    </div>
  );
};

export default FilterTag;
