/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import Results, { IFilter } from "./shared/Results";

import Filter from "./shared/Filter";
// shared components
import Input from "./shared/Input";
import MaterialIcon from "@components/MaterialIcon";
import classNames from "classnames";
import { isNotNilOrEmpty } from "ramda-adjunct";
import useHandleClickOutside from "@hooks/useHandleClickOutside";
import { useNavigate } from "react-router-dom";

// @hooks

type Props = {
  filters: IFilter[];
  results: { [key: string]: { data: any; meta: any; links: any } };
  isFetching: boolean;
  onSelectFilter: (filter: IFilter | null) => void;
  viewAllBtn?: boolean;
  borderStyle?: boolean;
  placeholder?: string;
  searchResultsTitle: string;
  onSelectItem?: (obj: { id: number; name: string }) => void;
  onFocus?: (value: boolean) => void;
  onClear?: () => void;
  onChange?: any;
  value: string;
};

const Search: React.FC<Props> = ({
  filters,
  results,
  isFetching,
  onChange,
  onSelectFilter,
  value,
  viewAllBtn,
  borderStyle = true,
  placeholder,
  searchResultsTitle,
  onSelectItem,
  onFocus,
  onClear,
}) => {
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const [filter, setFilter] = useState<IFilter | null>(null);

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    onFocus?.(focus);
  }, [focus, onFocus]);

  useHandleClickOutside(ref, () => setFocus(false));

  const hasResults = Object.entries(results).some(
    (valuee: any) => valuee[1]?.data.length > 0,
  );

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && !isFetching && isNotNilOrEmpty(value)) {
      navigate("/results");
      setFocus(false);
    }
    if (event.key !== "Enter") {
      setFocus(true);
    }
  };

  const iconSearchClasses = classNames({
    "c-search__colored-icon": focus || hover,
    "text-danger": !hasResults && !isFetching && value && (focus || hover),
  });

  const searchBarClasses = classNames({
    "c-search--colored-active": focus || hover,
    "border-danger": !hasResults && !isFetching && value && (focus || hover),
    "c-search__border": borderStyle,
  });

  const handleChangeFilter = (filterValue: IFilter | null) => {
    setFilter(filterValue);
    setFocus(false);
    onSelectFilter(filterValue);
  };

  return (
    <div className={`position-relative ${!borderStyle && "w-100"}`} ref={ref}>
      {/* Search Input */}
      <div
        className={`c-search d-flex align-items-center position-relative ${borderStyle && searchBarClasses}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onFocus={() => setFocus(true)}
        onClick={() => setFocus(true)}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <div className="c-search__search-icon-container d-flex">
          <MaterialIcon
            color={focus ? "o-cl-brand-secondary" : "o-cl-grey-200"}
            icon="search"
            className={iconSearchClasses}
          />
        </div>
        {filter && (
          <Filter
            filter={filter}
            onClose={() => {
              setFilter(null);
              onSelectFilter(null);
            }}
            noIcon
          />
        )}
        <Input
          ref={inputRef}
          placeholder={placeholder}
          onChange={onChange}
          onClickClear={onClear}
        />
      </div>

      {/* Search Panel */}
      {focus && (!filter || value || hasResults) && (
        <div className="c-search-bar-result position-absolute">
          {!filter && (
            <Results.Filters
              searchResultsTitle={searchResultsTitle}
              onSelect={handleChangeFilter}
              filters={filters}
            />
          )}
          <div className="c-search-bar-result__list">
            {value && (
              <Results.Info
                hasResults={hasResults}
                isLoading={isFetching}
                value={value}
                filter={filter}
              />
            )}
            {value && hasResults && !isFetching && (
              <Results.List
                onClickViewAll={() => setFocus(false)}
                viewAllBtn={viewAllBtn}
                results={results}
                onSelectItem={(item) => {
                  setFocus(false);
                  onSelectItem?.(item);
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;
