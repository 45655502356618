import React, { useRef, useState } from 'react'

import MaterialIcon from '@components/MaterialIcon'
import useHandleClickOutside from '@hooks/useHandleClickOutside';

export type IOption = {
  id: number;
  name: string;
}

export type Props = {
  options: IOption[];
  selected: IOption[];
  onSelect: (arg: IOption) => void;
}

const Types: React.FC<Props> = ({ options, onSelect, selected }) => {
  const ref = useRef<null | HTMLDivElement>(null)
  const [open, setIsOpen] = useState(false)

  useHandleClickOutside(ref, () => {
    setIsOpen(false)
  });

  return (
    <div className='position-relative' ref={ref}>
      <div className='c-filter o-cursor-pointer d-flex align-items-center o-user-select-none' onClick={() => setIsOpen(prev => !prev)}>
        <span className='c-filter__text o-ft-sm-400'>Filter by...</span>
        <MaterialIcon size={20} color='o-cl-brand-secondary' icon={open ? 'expand_more' : 'expand_less'} />
      </div>

      {open && (
        <div data-testid="options" className='c-floating-filter-view'>
          <span className='c-floating-filter-view__title o-ft-2xl-400'>Filters</span>
          <div className='c-option-list'>
            <span className='c-option-list__title o-ft-lg-400'>Type</span>
            {options.map((option) => (
              <Option
                isSelected={!!selected.find(item => item.id === option.id)}
                key={option.id}
                option={option}
                onSelect={onSelect}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

type OptionProps = {
  option: IOption;
  onSelect: (arg: IOption) => void;
  isSelected: boolean;
}

const Option: React.FC<OptionProps> = ({ option, onSelect, isSelected }) => {
  const handleSelect = () => {
    onSelect(option)
  }

  return (
    <div key={option.id} className='d-flex align-items-center gap-2 o-cursor-pointer my-2 o-user-select-none' onClick={handleSelect}>
      <MaterialIcon
        size={20}
        icon={isSelected ? 'check_box' : 'check_box_outline_blank'}
        color={isSelected ? 'o-cl-brand-secondary' : 'o-cl-grey-200'}
      />
      <span className='o-ft-sm-400 o-cl-grey-200'>{option.name}</span>
    </div>
  )
}

export default Types