/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import UserController from "@controllers/UserController";
import { initializeUser } from "@redux/users/slices/userSlice";

export const getLoggedUser = createAsyncThunk(
  "user/index",
  async (args: void, { rejectWithValue }) => {
    try {
      const response = await UserController.getUser();

      const permissions: any = {};

      response.data.data.permissions.forEach((item: any) => {
        const { action } = item;

        const entity = item.entity.replace(/App\\Models\\/gi, "");

        if (!permissions[entity]) {
          permissions[entity] = [];
        }

        permissions[entity] = permissions[entity].concat(action);
      });

      return {
        data: {
          ...response.data.data,
          permissions,
        },
      };
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateLoggedUser = createAsyncThunk(
  "user/update",
  async (args: any, { rejectWithValue }) => {
    const { onSuccess, onError, user } = args;
    try {
      const response: any = await UserController.updateUser(user);
      if (onSuccess) onSuccess();
      return response?.data;
    } catch (err: any) {
      if (onError) onError(err);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteLoggedUser = createAsyncThunk(
  "user/delete",
  async (args: void, { rejectWithValue, dispatch }) => {
    try {
      dispatch(initializeUser());
      return null;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);
