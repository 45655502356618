import useAppSelector from '@hooks/useAppSelector';
import { RootState } from '@redux/store';
import React from 'react';

interface ITooltipProps {
  render: JSX.Element;
  position?: 'top' | 'left' | 'bottom' | 'right';
}

const Tooltip = ({ render, position }: ITooltipProps) => {

  const { toggle } = useAppSelector((state: RootState) => state);
  const { views } = toggle;
  const module = 'contacts';

  return (
    <div className={`c-tooltip c-tooltip--${position} ${(views[module].grid) ? "c-tooltip--top" : "c-tooltip--left"} `} >
      {render}
    </div>
  );
}

export default Tooltip;
