/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from "lodash";
import { IRow } from "@models/Table.type";
import { IFile } from "@models/files/File.type";
import { deleteFiles } from "@redux/files/thunks/fileThunk";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  applyFileOrderFilters,
  getResultsWithQuery,
} from "../thunks/adminResultThunk";

interface IAdminResultSlice {
  data: IFile[];
  query: string;
  pending: boolean;
  orderFilter: number | null;
  orderFilterName: string | null;
}

const initialState: IAdminResultSlice = {
  data: [],
  query: "",
  pending: false,
  orderFilter: 1,
  orderFilterName: "Newest",
};

export const adminResultSlice = createSlice({
  name: "results",
  initialState,
  reducers: {
    addQuery: (state: any, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    setFileOrderFilters: (state, action: PayloadAction<any>) => {
      const { orderingId, label } = action.payload;
      state.orderFilter = orderingId;
      state.orderFilterName = label;
    },
    renameResultElement: (
      state: IAdminResultSlice,
      action: PayloadAction<IFile | null>,
    ) => {
      const file = action.payload;
      state.data = state.data.map((o: IFile) => {
        if (_.isEqual(o.id, file?.id))
          return { ...o, name: file?.name || o.name };
        return o;
      });
    },
    removeChildren: (
      state: IAdminResultSlice,
      action: PayloadAction<{ id: number }>,
    ) => {
      const childrenId = action.payload.id;
      state.data = state.data.filter((child) => child.id !== childrenId);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getResultsWithQuery.fulfilled,
      (state, action: PayloadAction<{ data: any }>) => {
        const { data } = action.payload;
        state.data = data;
        state.pending = false;
      },
    );
    builder.addCase(getResultsWithQuery.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(getResultsWithQuery.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(
      applyFileOrderFilters.fulfilled,
      (state, action: PayloadAction<{ data: any }>) => {
        const { data } = action.payload;
        state.data = data;
        state.pending = false;
      },
    );
    builder.addCase(applyFileOrderFilters.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(applyFileOrderFilters.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(
      deleteFiles.fulfilled,
      (state, action: PayloadAction<IFile[] | IRow[] | any[]>) => {
        const deletedFiles = action.payload;
        state.data = state.data.filter(
          (savedFile: IFile) =>
            !deletedFiles.find((deletedFile) =>
              _.isEqual(deletedFile.id, savedFile.id),
            ),
        );
      },
    );
  },
});

export const {
  addQuery,
  setFileOrderFilters,
  renameResultElement,
  removeChildren,
} = adminResultSlice.actions;
export default adminResultSlice.reducer;
