import React, { forwardRef, memo } from "react";

import MaterialIcon from "@components/MaterialIcon";

type Props = {
  getValue?: (value: string) => void;
  onClear?: () => void;
  placeholder?: string;
  value: string;
};

const InputOld = forwardRef<HTMLInputElement | null, Props>(
  ({ getValue, onClear, placeholder = "Search", value }, ref) => {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      getValue?.(e.target.value || "");
    };

    const clear = () => {
      onClear?.();
    };

    return (
      <div className="d-flex w-100 align-items-center justify-content-between">
        <input
          ref={ref}
          type="text"
          value={value}
          onChange={handleOnChange}
          placeholder={placeholder}
          className="c-search__input o-ft-sm-300 bg-transparent"
        />
        {value && (
          <MaterialIcon
            icon="close"
            color="o-cl-grey-200"
            onClick={clear}
            className="c-search__search-icon-container d-flex o-cursor-pointer"
          />
        )}
      </div>
    );
  },
);

export default memo(InputOld);
