import React from "react";
import AppRouter from "@routers/AppRouter";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import Toasts from "@components/Toasts";
import useAppSelector from "@hooks/useAppSelector";
import history from "@routers/History";
import ScrollToTop from "@components/ScrollToTop";
import ToastsProvider from "./providers/ToastsProvider";

const App = () => {
  const { toasts } = useAppSelector((state) => state.toasts);

  return (
    <HistoryRouter history={history}>
      <ToastsProvider>
        <div className="front-app">
          <ScrollToTop />
          {!!toasts.length && <Toasts {...{ toasts }} />}
          <AppRouter />
        </div>
      </ToastsProvider>
    </HistoryRouter>
  );
};

export default App;
