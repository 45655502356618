/* eslint-disable @typescript-eslint/no-explicit-any */
import MaterialIcon, { TIcon } from "@components/MaterialIcon";
/* eslint-disable react/require-default-props */
import React, { RefObject, forwardRef } from "react";

import _ from "lodash";
import classNames from "classnames";

interface IIconProp {
  icon: TIcon;
  size: 12 | 18 | 20 | 24 | 40 | 60;
}

interface IFileUploadProps {
  className?: string;
  disabled?: boolean;
  handleFileDocument: any;
  icon?: IIconProp;
  id: string;
  // eslint-disable-next-line react/no-unused-prop-types
  ref: RefObject<HTMLInputElement | null>;
  size?: "small" | "medium" | "medium-big" | "big";
  supportedFormats?: string[];
  value: string;
  webkitdirectory?: string;
  multiple?: boolean;
  hasIcon?: boolean;
}

const FileUpload = forwardRef<HTMLInputElement | null, IFileUploadProps>(
  (
    {
      hasIcon = true,
      className,
      disabled = false,
      icon,
      id,
      size = "medium",
      supportedFormats = [""],
      value = "Upload Files",
      handleFileDocument,
      webkitdirectory,
      multiple = true,
    },
    ref,
  ) => {
    const onChange = (e: React.ChangeEvent) => {
      handleFileDocument(e);
      const target = e.target as HTMLInputElement;
      target.value = "";
    };

    const labelClass = classNames({
      "c-file-upload--disabled": disabled,
      "o-ft-2xs-600": _.isEqual(size, "small"),
      "o-ft-xs-700": _.isEqual(size, "medium"),
      "o-ft-base-700 o-ft-xl-700@md": _.isEqual(size, "medium-big"),
      "o-ft-xl-700": _.isEqual(size, "big"),
      [`c-file-upload--${size}`]: size,
      [`${className}`]: className,
    });

    return (
      <label
        htmlFor={id}
        className={`c-file-upload o-bg-brand-secondary:hover o-cl-brand-secondary o-cl-white:hover o-ft-xs-700 d-flex align-items-center justify-content-center mb-0 ${labelClass}`}
      >
        {value}
        {hasIcon && icon && (
          <MaterialIcon
            className="c-file-upload__icon ms-2"
            color={disabled ? "o-cl-grey-200" : "o-cl-brand-secondary"}
            icon={icon.icon}
            size={icon.size}
          />
        )}
        <input
          {...{ id, onChange, disabled, ref }}
          type="file"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...({
            webkitdirectory,
          } as React.InputHTMLAttributes<HTMLInputElement>)}
          accept={supportedFormats.join(",")}
          multiple={multiple}
        />
      </label>
    );
  },
);

export default FileUpload;
