import { Outlet, useParams } from "react-router-dom";
import SectionHeader from "@components/SectionHeader";
import { useGetVault } from "@redux/vaults/api";
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ShowSkeletonLoader = () => (
  <SkeletonTheme baseColor="#D8D8D8" highlightColor="#FFFFFF">
    <div className="c-header-module d-flex justify-content-between align-items-center">
      <div className="c-header-module__left-container">
        <div className="c-header-module__title-container">
          <h3 className="c-header-module__title o-cl-grey-100">
            <Skeleton width={200} />
          </h3>
          <h5 className="c-breadcrumb__text d-none d-md-block">
            <Skeleton width={100} />
          </h5>
        </div>
      </div>
    </div>
  </SkeletonTheme>
);

const Layout = () => {
  const { id } = useParams();

  const { isFetching } = useGetVault({ id: Number(id) });

  return (
    <div className="o-screen-container">
      {isFetching ? <ShowSkeletonLoader /> : <SectionHeader />}
      <div className="py-1" />
      <Outlet />
    </div>
  );
};

export default Layout;
