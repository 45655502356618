import React, { PropsWithChildren } from 'react';
import Button from '@components/Button';
import MaterialIcon from '@components/MaterialIcon';
import useWindowSize from '@hooks/useWindowSize';

interface ModalProps {
  title: string;
  isOpen: boolean;
  width?: number;
  withCloseIcon?: boolean;
  disableSubmit?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmText?: string,
  cancelText?: string,
  withFooter?: boolean;
  centerButtons?: boolean;
  className?: string;
}

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  title,
  isOpen,
  onClose,
  withCloseIcon = true,
  disableSubmit = false,
  onConfirm,
  cancelText = 'Cancel',
  confirmText = 'Continue',
  children,
  withFooter = true,
  centerButtons,
  className
}) => {
  const { responsive } = useWindowSize()

  if (!isOpen) {
    return null;
  }

  return (
    <div className='c-modal__container'>
      <div className={`c-modal ${className}`}>
        <header className='d-flex justify-content-between align-items-center gap-5'>
          <h5 className={responsive.md ? 'o-ft-2xl-600' : 'o-ft-base-600'}>{title}</h5>
          {withCloseIcon && <MaterialIcon className='o-cursor-pointer' size={30} icon='close' onClick={onClose} />}
        </header>
        {children}
        {withFooter && (
          <div className={`${centerButtons ? 'd-flex justify-content-center' : ''}`}>
            <div className={`c-modal__buttons gap-2`}>
              <Button value={cancelText} onClick={onClose} size={responsive.md ? 'medium-big' : 'medium'} />
              <Button value={confirmText} onClick={onConfirm} size={responsive.md ? 'medium-big' : 'medium'}  disabled={disableSubmit} />
            </div>
          </div>
        )}
      </div>
      <div className="c-modal__overlay d-flex justify-content-center" onClick={onClose} />
    </div>
  );
};

export default Modal;
