import TagController from "@controllers/TagController";
import { errorRequest } from "@helpers/errorRequest";
import { formatNotificationNoty } from "@helpers/formatNotificationNoty";
import { EToastTypes } from "@models/toast/Toast.type";
import { addToast } from "@redux/toasts/slices/toastsSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";

const tagController = new TagController();

export const getTags = createAsyncThunk(
  'tag/index',
  async (args, { rejectWithValue }) => {
    try {
      let response: any = await tagController.index();
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postTag = createAsyncThunk(
  'tag/post',
  async ({ tags: _tags, documentId }: { tags: Array<string>; documentId: number; }, { rejectWithValue, dispatch }) => {
    try {
      const tags: Array<string> = Array.from(new Set<string>(_tags));

      let response: any = await tagController.add(tags, documentId);
      const notification = {
        text: "Tags updated",
        description: 'Tags have been updated successfully.',
      };

      if (window.Noty) {
        new window.Noty({
          type: "success",
          text: formatNotificationNoty(notification),
        }).show();
      } else {
        let toast = {
          id: Date.now(),
          text: notification.description,
          type: EToastTypes.PRIMARY,
          autohide: true
        }
        dispatch(addToast(toast));
      }
      dispatch(getTags());
      dispatch(getTagsByDocuments());
      return response?.data;
    } catch (err: any) {
      return errorRequest(err, rejectWithValue, dispatch)
    }
  }
);

export const getTagsByDocuments = createAsyncThunk(
  'tag/documents',
  async (args, { rejectWithValue }) => {
    try {
      let response: any = await tagController.getTagsByDocuments();
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
