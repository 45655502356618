import { whichProvider } from '@helpers/whichProvider';

export default class TagController {
  index() {
    return whichProvider().request('get', 'api/tags');
  }

  getTagsByDocuments() {
    return whichProvider().request('get', 'api/admin/documents/idstags');
  }

  add(tags: Array<string>, document_id: number) {
    return whichProvider().request('post', `api/admin/documents/${document_id}/tags`, JSON.stringify({ tags }));
  }

}
