import { FC } from 'react';

// core styles shared by all of react-notion-x (required)
import 'react-notion-x/src/styles.css'

import { Spinner } from 'react-activity';
import { Link, useParams } from 'react-router-dom';

// react-notion-x
import { NotionRenderer } from 'react-notion-x';
import { Collection } from 'react-notion-x/build/third-party/collection';

// utils
import useFetch from '@hooks/useFetch';

const BASE_URL = process.env.REACT_APP_NOTION_API_BASE_URL
const HOME_ID = process.env.REACT_APP_NOTION_HOME_ID
const HOME_ADMIN_ID = process.env.REACT_APP_NOTION_HOME_ADMIN_ID
const NOTION_PAGE_COVER = process.env.REACT_APP_NOTION_PAGE_COVER
const NOTION_ADMIN_PAGE_COVER = process.env.REACT_APP_NOTION_ADMIN_PAGE_COVER

const NotionPage: FC<{ isAdmin?: boolean }> = ({ isAdmin = false }) => {
  const { id } = useParams();

  const { data, error } = useFetch<any>(`${BASE_URL}/notion/${id || (isAdmin ? HOME_ADMIN_ID : HOME_ID)}`)

  if (error) {
    return (
      <div className='d-flex align-items-center justify-content-center full-height'>
        {error.message}
      </div>
    )
  }

  if (!data) {
    return (
      <div className='d-flex align-items-center justify-content-center full-height'>
        <Spinner size={18} />
      </div>
    );
  }

  return (
    <div className='o-screen-scroll'>
      <NotionRenderer
        recordMap={data.body}
        fullPage
        disableHeader
        defaultPageCover={isAdmin ? NOTION_ADMIN_PAGE_COVER : NOTION_PAGE_COVER}
        mapPageUrl={(route: string) => `${route}`}
        components={{
          PageLink: (route: any) => <Link to={route.href}>{route.children}</Link>,
          Collection,
        }}
      />
    </div>
  );
}

export default NotionPage;