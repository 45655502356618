import DocumentsProvider from "../../providers/DocumentsProvider";
import ProcessesProvider from "@sections/Process/context/ProcessesContext";
import ProcessesView from "@sections/Process";
import React from "react";

const ProcessView = () => {
  return (
    <ProcessesProvider>
      <DocumentsProvider>
        <ProcessesView />
      </DocumentsProvider>
    </ProcessesProvider>
  );
};

export default ProcessView;
