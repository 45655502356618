import { IFile } from "@models/files/File.type";
import { IRow } from "@models/Table.type";

export const previewFile = (file: IFile | IRow) => {
  // `${window.location.origin}/documents/${selectId}`

  const selectId = file?.id;

  window.open(
    localStorage.getItem("current_website") === "admin"
      ? `/admin/documents/${selectId}`
      : `/document/${selectId}`,
    "_blank",
  );
};
