/* eslint-disable @typescript-eslint/no-explicit-any */
import { useGetDocument, useGetDocuments } from "@redux/documents/api";

import Grid from "@components/Grid";
import Header from "@views/Section/shared/Header";
import { IAction } from "@models/Action.type";
import { IFile } from "@models/files/File.type";
import PaginationLoader from "@components/PaginationLoader";
import PinModal from "@components/PinModal";
import React from "react";
import { Spinner } from "react-activity";
import { TIcon } from "@components/MaterialIcon";
import { colors } from "@theme/colors";
import { createToast } from "@helpers/createToast";
import { trackInteractions } from "@redux/files/thunks/fileThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import { useHandleFavorite } from "@hooks/useHandleFavorite";
import { useParams } from "react-router-dom";
import { useShowDocument } from "@hooks/fetchs/useShowDocument";
import { usePinUnpinContext } from "../../providers/PinUnpinProvider";
import useBreadcrumb from "@hooks/useBreadcrumb";
import { usePinActionList } from "@hooks/usePinActionList";

const DocumentsListSection: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const { getData } = useShowDocument();
  const { data: documentSection, isFetching: isFetchingDocument } =
    useGetDocument({
      id: Number(id),
    });
  const { data: documents, isFetching } = useGetDocuments({
    parentId: Number(id),
  });

  const { breadcrumb, generatingBreadcrumb } = useBreadcrumb(Number(id));
  const {
    isOpenPinModal,
    setIsOpenPinModal,
    tooglePinDashboard,
    tooglePinBottomBar,
  } = usePinUnpinContext();

  const { actionList: pinActionList } = usePinActionList({
    document: documentSection?.data,
    endpoint: "getDocument",
    tooglePinDashboard,
    tooglePinBottomBar,
  });
  const { handleFavorite } = useHandleFavorite();

  const handleDownload = (fileId: string) => {
    getData(fileId).then((e) => {
      const { url } = e.data.file;
      const { name, type } = e.data;
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const objectUrl = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = objectUrl;
          link.download = `${name}.${type}`;
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(objectUrl);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error("Error fetching the file:", error);
        });
    });
  };

  const actionList: IAction[] = [
    {
      id: "favorite",
      icon: "favorite" as TIcon,
      text: "",
      type: "favorite",
      onClick: (file: IFile) => {
        handleFavorite(file);
      },
    },
    {
      id: "copy-link",
      icon: "file_copy",
      text: "Copy Link",
      onClick: (file: IFile) => {
        const selectId = file?.id;
        if (selectId) {
          navigator.clipboard.writeText(
            `${window.location.origin}/document/${selectId}`,
          );
          createToast("Copied", "success", dispatch);
        }
        dispatch(trackInteractions({ data: [file.id], type: "share" }));
      },
    },
    {
      id: "download",
      icon: "file_download",
      text: "Download",
      onClick: (file: IFile) => {
        const selectId = file?.id;
        handleDownload(`${selectId}`);
      },
    },
  ];

  return (
    <>
      <PinModal pinModal={isOpenPinModal} setPinModal={setIsOpenPinModal} />
      <div className="o-screen-container">
        <Header
          title={documentSection?.data?.name ?? ""}
          actions={pinActionList}
          withGoBack={!!documentSection?.data?.parent_id}
          isLoading={isFetchingDocument || generatingBreadcrumb}
          breadcrumb={breadcrumb}
        />

        {isFetchingDocument ? (
          <div className="d-flex justify-content-center mt-5">
            <Spinner
              className="v-contacts__spinner"
              color={colors.brandColorSecondary}
            />
          </div>
        ) : (
          <Grid
            data={documents?.data || []}
            layout="c-grid--sm-layout"
            actionList={actionList}
            context="documents"
          />
        )}

        {isFetching && <PaginationLoader placeholder="Loading documents..." />}
      </div>
    </>
  );
};

export default DocumentsListSection;
