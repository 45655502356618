import _ from 'lodash';
import { IRow } from '@models/Table.type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFile, IFileResponse } from '@models/files/File.type';
import { deleteTrashFiles, restoreFile, getTrash, getFolderTrash } from '@redux/trash/thunks/trashThunk';

interface IInitialState {
  pending: boolean;
  pendingDelete: boolean;
  data: IFileResponse;
  selectedFiles: IFile[];
  folderTrashFiles: IFile[];
}

const initialState: IInitialState = {
  pending: false,
  pendingDelete: false,
  data: { id: 0, name: "", parent_id: 0, parent: null, parents: [], children: [] },
  selectedFiles: [],
  folderTrashFiles: []
}

export const trashSlice = createSlice({
  name: 'trash',
  initialState,
  reducers: {
    addTrash: (state: any, action: PayloadAction<IFile>) => {
      const file = { ...action.payload, deleted_at: new Date().toISOString() }
      state.data.children.push(file)
      state.folderTrashFiles.push(file)
    },
    setPending: (state: any, action: PayloadAction<boolean>) => {
      const pending = action.payload;
      state.pending = pending
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTrash.fulfilled, (state, action: PayloadAction<{ data: IFileResponse }>) => {
      let { data } = action.payload;
      state.data = data
      state.pending = false;
    });
    builder.addCase(getTrash.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(getTrash.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(getFolderTrash.fulfilled, (state, action: PayloadAction<IFile[]>) => {
      state.folderTrashFiles = action.payload
      state.pending = false;
    });
    builder.addCase(getFolderTrash.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(getFolderTrash.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(deleteTrashFiles.fulfilled, (state, action: PayloadAction<any[]>) => {
      const deletedFiles = action.payload;
      state.pendingDelete = false;
      state.folderTrashFiles = state.folderTrashFiles.filter((trashFiles) => !deletedFiles.find((deletedFile) => _.isEqual(deletedFile.id, trashFiles.id)));
      state.selectedFiles = state.selectedFiles.filter((selectedFile) => !deletedFiles.find((deletedFile) => _.isEqual(deletedFile.id, selectedFile.id)));
      state.data.children = state.data.children.filter((savedFile) => !deletedFiles.find((deletedFile) => _.isEqual(deletedFile.id, savedFile.id)))
    });
    builder.addCase(deleteTrashFiles.rejected, (state, action) => {
      state.pendingDelete = false;
    });
    builder.addCase(deleteTrashFiles.pending, (state) => {
      state.pendingDelete = true;
    });
    builder.addCase(restoreFile.fulfilled, (state, action: PayloadAction<IFile[] | IRow[]>) => {
      const restoreFiles = action.payload;
      state.pendingDelete = false;
      state.folderTrashFiles = state.folderTrashFiles.filter((trashFiles) => !restoreFiles.find((restoreFile) => _.isEqual(restoreFile.id, trashFiles.id)));
      state.selectedFiles = state.selectedFiles.filter((selectedFile) => !restoreFiles.find((restoreFile) => _.isEqual(restoreFile.id, selectedFile.id)));
      state.data.children = state.data.children.filter((savedFile) => !restoreFiles.find((restoreFile) => _.isEqual(restoreFile.id, savedFile.id)))
    });
    builder.addCase(restoreFile.rejected, (state, action) => {
      state.pendingDelete = false;
    });
    builder.addCase(restoreFile.pending, (state) => {
      state.pendingDelete = true;
    });
  },
});

export const { setPending, addTrash } = trashSlice.actions;
export default trashSlice.reducer;
