import _ from "lodash";
import Button from "./Button";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowSize from "@hooks/useWindowSize";
import MaterialIcon, { TIcon } from "@components/MaterialIcon";
import { TMenuSection } from "../app/types/section/MenuSection.type";
import { images } from "@theme/images";
import DotsMenu from "./DotsMenu";
import { IAction } from "@models/Action.type";

interface ISectionModuleItemProps {
  icon?: TIcon;
  title: string;
  onClick: () => void;
  children?: TMenuSection[] | null;
  background: string;
  element?: TMenuSection;
  actionList?: IAction[];
  context?: "dashboard" | "main";
}

export const SectionModuleItem = ({
  title,
  children,
  icon,
  onClick,
  background,
  actionList,
  element,
  context = "main",
}: ISectionModuleItemProps) => {
  const navigate = useNavigate();
  const { responsive } = useWindowSize();
  const [hover, setHover] = useState<boolean>(false);
  const defaultIcon: TIcon = "inventory_2";
  const viewMore: TMenuSection = {
    name: "View all",
    id: 0,
    icon: "" as TIcon,
    route: "",
    level: 0,
    position: 0,
    parent_id: 0,
    children: null,
    is_link: false,
    description: "",
    editable: false,
    sectionable_id: 0,
    is_bottom_link: false,
    background: "",
    pinned: null,
  };

  function onHandleNavigate(item: TMenuSection): void {
    if (item.route) navigate(`/${item.route}`);
    else onClick();
  }

  const createDashboardCards = () => {
    let subSections = 4;
    if (children) {
      if (!responsive.lg) subSections = 2;
      else if (!responsive.xl) subSections = 3;
      return children.length > subSections
        ? [...children.slice(0, subSections), viewMore]
        : children;
    }
    return [];
  };

  return (
    <div className="c-section-module-item__container position-relative">
      <div
        aria-hidden
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          backgroundImage: `url(${background || images.sectionModuleBackground})`,
        }}
        className="c-section-module-item__card d-flex justify-content-center o-cursor-pointer position-relative"
      >
        <div className="c-section-module-item__card-content d-flex align-items-center justify-content-center">
          <div className="c-section-module-item__card-description text-center">
            <div className="c-section-module-item__card-icon d-flex justify-content-center align-items-center mx-auto ">
              <MaterialIcon
                className="c-section-module-item__hovered-icon"
                color="o-cl-white"
                icon={icon || defaultIcon}
                size={24}
              />
            </div>
            <div className="c-section-module-item__title-section">
              <p className="c-section-module-item__title o-ft-base-700 o-cl-white m-0">
                {title}
              </p>
            </div>
            {hover && responsive.md && (
              <div
                className={`${hover && "c-section-module-item__tab-section"} c-section-module-item__tab-section d-flex flex-wrap justify-content-center`}
              >
                {children &&
                  createDashboardCards().map((item) => (
                    <Button
                      key={item.id}
                      value={item.name}
                      className="o-cl-white c-section-module-item__button-tab m-1"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        onHandleNavigate(item);
                      }}
                      size="small"
                    />
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {!!actionList?.length &&
      (_.isEqual(context, "main") ||
        (_.isEqual(context, "dashboard") &&
          (element?.pin_to_dashboard || element?.pinned))) ? (
        <div className="position-absolute top-0 end-0 mt-3 mx-2">
          <DotsMenu
            actionList={actionList}
            dotsDirection="vertical"
            context={element}
          />
        </div>
      ) : null}
    </div>
  );
};
