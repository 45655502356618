import { TSwitchModeTypes, TModuleTypes } from '@models/Global.type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TToggle = {
  grid: boolean;
  list: boolean;
}
export type TActionToggle = {
  mode: TSwitchModeTypes;
  module: TModuleTypes;
}

const defaultToggle: TToggle = {
  grid: true,
  list: false,
}
export interface IToggle {
  sidebarShow: boolean;
  views: {
    admin: TToggle;
    contacts: TToggle;
    favorites: TToggle;
  };
}

const initialState: IToggle = {
  sidebarShow: true,
  views: {
    admin: defaultToggle,
    contacts: defaultToggle,
    favorites: defaultToggle,
  }
}

export const toggleSlice = createSlice({
  name: 'toggle',
  initialState,
  reducers: {
    setSideBar: (state: IToggle, action: PayloadAction<boolean>) => {
      state.sidebarShow = action.payload;
    },
    setViewMode: (state: IToggle, action: PayloadAction<TActionToggle>) => {
      const { mode, module } = action.payload;
      state.views[module].grid = false;
      state.views[module].list = false;
      state.views[module][mode] = true;
    },
  },
});

export const { setSideBar, setViewMode } = toggleSlice.actions;
export default toggleSlice.reducer;
