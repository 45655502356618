import { TContactCategory } from '@models/ContactCategory.type';
// import { TContactSpecialty } from '@models/ContactsaByType.type';
import { IFile } from '@models/files/File.type';
import { v4 as uuidv4 } from 'uuid';
import { IFilterDropdownItem } from '@components/filters/FilterDropdownList';
import _ from 'lodash';
import MaterialIcon from './MaterialIcon';
import classNames from 'classnames';

export type TCheckbox = TContactCategory | IFile | IFilterDropdownItem;

interface ICheckboxProps {
  item: TCheckbox;
  onChange: (obj: TCheckbox, nextStatus: boolean) => void;
  checked: boolean;
  className?: string;
  showName?: boolean;
  variant?: 'icon';
}

const Checkbox = ({ item, onChange, checked, className, showName = true, variant }: ICheckboxProps) => {
  const { id, name } = item;
  const key = id + uuidv4();

  const checkboxClass = classNames({
    'c-checkbox--variant-icon': _.isEqual(variant, 'icon'),
  });

  return (
    <div className={`c-checkbox ${className} ${checkboxClass}`}>
      <div className="c-checkbox__check-container">
        <input onChange={() => onChange(item, !checked)} className="c-checkbox__input o-cursor-pointer" type="checkbox" id={key} name="" checked={checked} />
        <div className="c-checkbox__check">
          {(checked && _.isEqual(variant, 'icon')) &&
            <MaterialIcon icon='check' size={18} />
          }
        </div>
      </div>
      {showName && <label className="c-checkbox__name o-ft-sm-400 o-cursor-pointer d-inline" htmlFor={key}>{name}</label>}
    </div>
    // <>
    //   {(_.isEqual(variant, 'icon')) ? (
    //     <div className={`c-checkbox ${className} c-checkbox--variant-icon`}>
    //       <div className="c-checkbox__check-container">
    //         <input onChange={() => onChange(item, !checked)} className="c-checkbox__input o-cursor-pointer" type="checkbox" id={key} name="" checked={checked} />
    //         {(checked && _.isEqual(variant, 'icon')) &&
    //           <div className="c-checkbox__check">
    //             <MaterialIcon icon='check' size={18} />
    //           </div>
    //         }
    //       </div>
    //       {showName && <label className="c-checkbox__name o-ft-sm-400 o-cursor-pointer d-inline" htmlFor={key}>{name}</label>}
    //     </div>
    //   ) : (
    //     <div className={`c-checkbox ${className}`}>
    //       <div className="c-checkbox__check-container">
    //         <input onChange={() => onChange(item, !checked)} className="c-checkbox__input o-cursor-pointer" type="checkbox" id={key} name="" checked={checked} />
    //         <div className="c-checkbox__check"></div>
    //       </div>
    //       {showName && <label className="c-checkbox__name o-ft-sm-400 o-cursor-pointer d-inline" htmlFor={key}>{name}</label>}
    //     </div>
    //   )
    //   }
    // </>
  );
};

export default Checkbox;
