import styled from "styled-components";

export const StepContainer = styled.div`
  z-index: 1;
  margin-bottom: 42px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  padding: 8px 12px;
  padding-left: 30px;
  min-height: 133px;
  border-radius: 0.25rem;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.15);
`;
