import { GridFile, GridFolder, GridVaultFolder } from "@components/svg";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { setCurrentResource } from "@redux/documents/documentsSlice";

import Checkbox from "@components/Checkbox";
import { IDocument } from "@models/document/NewDocument.type";
import { IFile } from "@models/files/File.type";
import { Spinner } from "react-activity";
import classNames from "classnames";
import colors from "../../brand/theme/colors";
import emptyState from "../../assets/no-results.svg";
import { formatExtension } from "@helpers/formatExtension";
import { getDocuments } from "@redux/documents/thunks/documentsThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import useDocumentsContext from "@hooks/useDocumentsContext";
import useWindowSize from "@hooks/useWindowSize";

const NoResult = () => {
  const baseSrcUrl =
    process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL : "";
  const { responsive } = useWindowSize();
  return (
    <div className="d-flex justify-content-center flex-column align-items-center h-100">
      <img
        src={`${baseSrcUrl}${emptyState}`}
        alt="empty state"
        width={responsive.md ? 350 : 250}
      />
      <h2>No results found</h2>
      <div>We couldn&apos;t find any matches for your search</div>
      <div className="mb-5">Please try again</div>
    </div>
  );
};
const FileItem = ({
  item,
  show,
  withCheckbox,
  onClickCheckbox,
  documentsSelected,
}: {
  item: any;
  show?: "files" | "folders" | "both";
  withCheckbox: boolean;
  onClickCheckbox?: (document: { id: number; name: string }) => void;
  documentsSelected: { id: number; name: string }[];
}) => {
  const { elementToMove } = useDocumentsContext();

  const isFile = item.type;
  const isDisabled =
    elementToMove?.id === item.id ||
    (show === "files" && !isFile) ||
    (show === "folders" && isFile);
  const dispatch = useAppDispatch();
  const gridChildClass = classNames({
    "o-bg-grey-300": documentsSelected.some((doc) => doc.id === item.id),
  });
  const gridNameTextClass = classNames({
    "c-grid__name-text": item?.name?.length > 25,
    "d-inline-block": item?.name?.length <= 25,
  });

  const [isHovered, setIsHovered] = useState(false);

  const renderIcon = (itemRender: IDocument) => {
    if (itemRender.is_vault) {
      return <GridVaultFolder className={`${isDisabled && "opacity-25"}`} />;
    }
    let icon;
    if (itemRender.type) {
      icon = <GridFile className={`${isDisabled && "opacity-25"}`} />;
    } else {
      icon = <GridFolder className={`${isDisabled && "opacity-25"}`} />;
    }
    return icon;
  };

  const renderInput = () => {
    if (withCheckbox && isFile) {
      return (
        <Checkbox
          item={item as IFile}
          onChange={() =>
            onClickCheckbox && onClickCheckbox({ id: item.id, name: item.name })
          }
          checked={documentsSelected.some((doc) => doc.id === item.id)}
          showName={false}
        />
      );
    }
    if (!withCheckbox) {
      return (
        <input
          type="radio"
          value={item.id}
          disabled={isDisabled}
          name="move_to"
          onChange={() => {
            dispatch(
              setCurrentResource({
                data: item,
              }),
            );
          }}
        />
      );
    }

    return null;
  };

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`c-grid__child--admin o-cursor-pointer ${gridChildClass}`}
    >
      <div
        className="c-grid__actions px-1 position-absolute align-items-center"
        style={{
          height: "45px",
        }}
      >
        {!isDisabled && (
          <div
            className={`${isHovered || documentsSelected.some((doc) => doc.id === item.id) ? "visible" : "invisible"} ps-2`}
          >
            {renderInput()}
          </div>
        )}
      </div>
      <div
        aria-hidden
        onClick={() => {
          if (!isDisabled && !isFile) {
            dispatch(
              setCurrentResource({
                data: item,
              }),
            );
            dispatch(getDocuments({ parentId: item?.id || undefined }));
          }
        }}
        className="c-grid__content d-flex align-items-center flex-column"
        title={`${item?.name}${item.type ? formatExtension(item.type) : ""}`}
      >
        <div className="position-relative">
          {renderIcon(item)}
          {item.type ? (
            <p className="c-grid__type position-absolute o-cl-white o-ft-lg-400 m-0 pe-1 pb-1 pb-md-2">
              {formatExtension(item.type)}
            </p>
          ) : null}
        </div>
        <p className="c-grid__name o-cl-grey-100 o-ft-sm-400 o-ft-lg-400@md text-center pt-3 m-0">
          <span className={`${gridNameTextClass}`}>{item.name}</span>
        </p>
      </div>
    </div>
  );
};

const DisplayData = ({
  data,
  searchDocument,
  show,
  withCheckbox,
  onClickCheckbox,
  documentsSelected,
}: {
  data: any;
  searchDocument: any;
  show: "files" | "folders" | "both";
  withCheckbox: boolean;
  onClickCheckbox?: (document: { id: number; name: string }) => void;
  documentsSelected: { id: number; name: string }[];
}) =>
  data && data.length === 0 && searchDocument !== "" ? (
    <NoResult />
  ) : (
    <div className="c-grid--documents mx-5">
      {data?.map((child: any) => (
        <FileItem
          item={child}
          key={child.id}
          show={show}
          withCheckbox={withCheckbox}
          onClickCheckbox={onClickCheckbox}
          documentsSelected={documentsSelected}
        />
      ))}
    </div>
  );

const DocumentsSection = ({
  show = "folders",
  withCheckbox = false,
  onClickCheckbox,
  documentsSelected = [],
}: {
  show?: "files" | "folders" | "both";
  withCheckbox?: boolean;
  onClickCheckbox?: (document: { id: number; name: string }) => void;
  documentsSelected?: { id: number; name: string }[];
}) => {
  const {
    documents: { data, pending },
  } = useAppSelector((state) => state);
  const { searchDocument } = useDocumentsContext();

  return (
    <>
      {searchDocument !== "" && data.length > 0 && (
        <span className="px-3 pt-3 d-block">
          Results for: <span className="fw-bold">{searchDocument}</span>
        </span>
      )}
      {pending ? (
        <div className="d-flex justify-content-center flex-column align-items-center gap-4 h-100">
          <Spinner
            className="o-ft-xl o-ft-3xl@md"
            color={colors.brandColorSecondary}
          />
        </div>
      ) : (
        <DisplayData
          data={data}
          searchDocument={searchDocument}
          show={show}
          withCheckbox={withCheckbox}
          onClickCheckbox={onClickCheckbox}
          documentsSelected={documentsSelected}
        />
      )}
    </>
  );
};

export default DocumentsSection;
