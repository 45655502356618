import useWindowSize from '@hooks/useWindowSize';
import React from 'react'

export type Props = {
  src: string;
  title: string;
  description: string;
}

const Error: React.FC<Props> = ({ src, title, description }) => {
  const { responsive } = useWindowSize()

  return (
    <div className='d-flex flex-column justify-content-center align-items-center w-100'>
      <img className="mt-4" src={src} alt="empty state" width={responsive.md ? 350 : 250} />
      <h3 className={`o-cl-dark-grey text-center mt-4 mb-3 ${responsive.md ? 'o-ft-2xl-600' : 'o-ft-xl-600'}`}>{title}</h3>
      <p className={`v-my-stores__empty-state-description o-cl-dark-grey text-center ${responsive.md ? 'o-ft-lg-400' : 'o-ft-sm-400'}`}>
        {description}
      </p>
    </div>
  )
}

export default Error