/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { getCookie } from "@api/AdminHttpProvider";

const getHeaders = () => {
  const token =
    getCookie("access_token") || localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json`,
  };
};

const baseUrl = process.env.REACT_APP_BACK_BASE_URL;

export const myVaultsApi = createApi({
  reducerPath: "myVaultsApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getVault: builder.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `api/v2/vaults/${id}`,
        headers: getHeaders(),
      }),
    }),
    getVaultsData: builder.query<any, { nextUrl?: string }>({
      query: ({ nextUrl }) => ({
        url: nextUrl || "api/vaults",
        headers: getHeaders(),
      }),
    }),
    getDocumentVaults: builder.query<any, number>({
      query: (id) => ({
        url: `api/admin/documents/${id}`,
        headers: getHeaders(),
      }),
      transformResponse: (res: any) => res.data,
    }),
    getNextVaults: builder.query<any, string>({
      query: (url) => ({ url, headers: getHeaders() }),
    }),
  }),
});

export const {
  useGetVaultsDataQuery: useGetVaultsData,
  useGetDocumentVaultsQuery: useGetDocumentVaults,
  useGetNextVaultsQuery: useGetNextVaults,
  useGetVaultQuery: useGetVault,
} = myVaultsApi;
