import DotsMenu from "@components/DotsMenu";
import React from "react";
import { IAction } from "@models/Action.type";

import BreadCrumb from "./shared/BreadCrumb";
import GoBack from "./shared/GoBack";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

type Props = {
  title: string;
  actions: IAction[];
  withGoBack: boolean;
  isLoading: boolean;
  breadcrumb: Array<{ name: string; id: number; route: string }>;
};

const Header: React.FC<Props> = ({
  isLoading,
  title,
  withGoBack,
  actions,
  breadcrumb,
}) => {
  if (isLoading) {
    return (
      <SkeletonTheme baseColor="#D8D8D8" highlightColor="#FFFFFF">
        <div className="c-header-module d-flex justify-content-between align-items-center">
          <div className="c-header-module__left-container">
            <div className="c-header-module__title-container">
              <h3 className="c-header-module__title o-cl-grey-100">
                <Skeleton width={200} />
              </h3>
              <h5 className="c-breadcrumb__text d-none d-md-block">
                <Skeleton width={100} />
              </h5>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    );
  }

  return (
    <div className="c-header-module d-flex justify-content-between align-items-center">
      <div className="c-header-module__left-container">
        <div className="c-header-module__title-container">
          <div
            id={`${title?.toLocaleLowerCase()}Onboarding`}
            className="d-flex align-items-center"
          >
            {!!actions?.length && (
              <DotsMenu
                position="right"
                actionList={actions}
                dotsDirection="vertical"
              />
            )}
            <span className="c-header-module__title o-cl-grey-100 o-ft-3xl-600">
              {title}
            </span>
          </div>
        </div>

        <div className="d-none d-md-block">
          <BreadCrumb crumbs={breadcrumb} />
        </div>
      </div>
      <div className="d-flex align-items-center ms-3 gap-3">
        {withGoBack && <GoBack />}
      </div>
    </div>
  );
};

export default Header;
