import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getHeaders = () => {
  const token = localStorage.getItem('access_token');
  return {
    "Authorization": `Bearer ${token}`,
    'Content-Type': `application/json`,
  }
}

const baseUrl = process.env.REACT_APP_BACK_BASE_URL;

export const contactsApi = createApi({
  reducerPath: 'contactsApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getContacts: builder.query<any, { url: string }>({
      query: ({ url }) => ({ url, headers: getHeaders() }),
    }),
    getContactsBySearch: builder.query<any, { url: string }>({
      query: ({ url }) => ({ url, headers: getHeaders() }),
      transformResponse: (res: any) => res.data
    }),
    getTypes: builder.query<any, void>({
      query: () => ({ url: 'api/contact-types', headers: getHeaders() }),
      transformResponse: (res: any) => res.data
    }),
    getNextPage: builder.query<any, { url: string }>({
      query: ({ url }) => ({ url, headers: getHeaders() }),
      transformResponse: (res: any) => ({data: res.data, links: res.links})
    })
  }),
});

export const {
  useGetContactsQuery: useGetContacts,
  useGetContactsBySearchQuery: useGetContactsBySearch,
  useGetTypesQuery: useGetTypes,
  useGetNextPageQuery: useGetNextPage
} = contactsApi;
