import useAppSelector from "@hooks/useAppSelector";
import { IFile } from "@models/files/File.type";
import { getExistentFilesValuesByFolder, updateHierarchy } from "@redux/files/thunks/fileThunk";
import { setDragElement, setDraggingId, setDropElementId, setShowDragDropExistentFileModal, setDragDropExistentFileData } from "@redux/files/slices/fileSlice";
import useAppDispatch from "@hooks/useAppDispatch";
import _ from "lodash";
import { IRow } from '../app/types/Table.type';

export type TDropZone = {
  id: number;
  type: 'dropzone'
}

export const useDragAndDrop = () => {
  const { file: { dragElement } } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const handleDrag = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  }

  const handleDragStart = (event: any, data?: any) => {
    event.stopPropagation();
    dispatch(setDragElement(data));
  }

  const handleDragEnter = (event: any, data: IFile | IRow | TDropZone) => {
    event.stopPropagation();
    event.preventDefault();

    if (_.isEqual(data.type, 'dropzone') || !_.isNull(data.type)) {
      dispatch(setDraggingId(0));
    } else {
      dispatch(setDraggingId(data.id));
    }
  }

  const handleDragOver = (event: any, data?: any) => {
    event.stopPropagation();
    event.preventDefault();
  }

  const handleDragLeave = (event: any, data: any) => {
    event.stopPropagation();
    event.preventDefault();

    if (event.currentTarget.contains(event.relatedTarget)) return;

    if (_.isEmpty(data)) { // grid/table (dropzone)
      dispatch(setDraggingId(-1));
    } else {
      dispatch(setDraggingId(0));
    }
  }

  const handleDrop = (event: any, data?: any, func?: (e: any, data: any) => void) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(setDraggingId(-1));
    let dropId = 0;

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const files = event.dataTransfer.files;
      event.dataTransfer.clearData();
      if (func) func(event, files);
    }

    if (!_.isEqual(data?.id, dragElement?.id) && _.isNull(data?.type)) {
      const dropFolderId = data?.id;
      const dragElementName = `${dragElement?.name}.${dragElement?.type}`.toLowerCase();
      const uploadNameFiles = [dragElementName];

      if (!event.dataTransfer.files || !event.dataTransfer.files.length) {
        dispatch(getExistentFilesValuesByFolder({
          parentId: dropFolderId,
          values: uploadNameFiles,
        })).then((resp) => {
          const respExistentFilesValues = resp.payload;
          if (!respExistentFilesValues.includes(true)) {
            dispatch(updateHierarchy({ parent: data, element: dragElement || null }));
          } else {
            dispatch(setDragDropExistentFileData({
              title: `Existent File: "${uploadNameFiles[0]}"`,
              action: 'drag_drop',
              parameters: { parent: data, element: dragElement || null }
            }));
            dispatch(setShowDragDropExistentFileModal(true));
          }
        });
      } else {
        dispatch(updateHierarchy({ parent: data, element: dragElement || null }));
      }
    }

    /*
      3 cases:
        - Dropping in folder (get folder id)
        - Dropping in file (get parent_id)
        - Dropping in parent (get current route id)
    */

    if (_.isNull(data?.type)) { // folder
      dropId = data.id;
    }
    else { // current parent or file
      dropId = data.parent_id;
    }

    dispatch(setDropElementId(dropId));
    dispatch(setDragElement(null));
  }

  const handleDragEnd = (event: any) => { }

  return {
    handleDrag,
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    handleDrop,
    handleDragOver,
    handleDragLeave,
  }
}
