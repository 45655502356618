import { useEffect, FC, memo } from 'react';

// libraries
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

// @redux
import { setSideBar } from '@redux/slices/toggleSlice';
import { RootState } from '@redux/store';

// @hooks
import useWindowSize from '@hooks/useWindowSize';

// @components
import Menu from '@components/Menu';
import LogOut from '@components/LogOut';
import ProfileSettingsModal from '@components/ProfileSettingsModal';
import MaterialIcon from '@components/MaterialIcon';
import NewSidebar from './Sidebar/Sidebar';

const Sidebar: FC = () => {
  const dispatch = useDispatch();
  const { sidebarShow } = useSelector((state: RootState) => state.toggle);
  const { responsive } = useWindowSize();

  const sidebarClass = classNames({
    'd-flex': sidebarShow,
    'c-sidebar__closed': !sidebarShow,
  });

  const iconClass = classNames({
    'c-sidebar__icon--rotate': !sidebarShow
  });

  const onClickToggle = () => {
    dispatch(setSideBar(!sidebarShow));
  }

  useEffect(() => {
    dispatch(setSideBar(responsive.md));
  }, [])

  return (
    <aside id="sidebar" className={`c-sidebar justify-content-between ${sidebarClass} `}>
      {/* {sidebarShow && <Menu />} */}
      {sidebarShow && <NewSidebar />}
      <div className='c-sidebar__icon-container' onClick={onClickToggle}>
        <MaterialIcon
          icon='arrow_back_ios'
          size={24}
          color={`c-sidebar__icon o-cl-brand-primary ${iconClass}`}
        />
      </div>
      {(sidebarShow && !responsive.md) &&
        <div className='c-sidebar__user-section d-md-flex justify-content-between align-items-center p-3'>
          <ProfileSettingsModal />
        </div>
      }
    </aside>
  );
};

export default memo(Sidebar);
