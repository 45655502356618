/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import StoreCard from "@components/StoreCard";
import emptyState from "../../assets/empty-state.svg";

// @redux/my-stores
import { IMyStore } from "@redux/my-stores/types";
import { setSelectedStore } from "@redux/my-stores/slice";
import { Spinner } from "react-activity";
import { colors } from "@theme/colors";

// @hooks
import useWindowSize from "@hooks/useWindowSize";
import useAppDispatch from "@hooks/useAppDispatch";
import { OutletContext } from "./Layout";

const MyStores: React.FC = () => {
  const { responsive } = useWindowSize();

  const {
    stores: { data, isLoading },
  } = useOutletContext<OutletContext>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goToStore = useCallback(
    (store: IMyStore) => {
      dispatch(setSelectedStore(store));
      navigate(`/stores/${store.name.replaceAll(" ", "-")}-${store.id}`);
    },
    [dispatch, navigate],
  );

  const renderer = useCallback(() => {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-center w-100 mt-5">
          <div>
            <Spinner
              className="v-contacts__spinner"
              color={colors.brandColorSecondary}
            />
          </div>
        </div>
      );
    }

    if (data && data.length === 0) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
          <img
            className="mt-4"
            src={emptyState}
            alt="empty state"
            width={responsive.md ? 350 : 250}
          />
          <h3
            className={`o-cl-dark-grey mt-4 mb-3 ${responsive.md ? "o-ft-2xl-600" : "o-ft-xl-600"}`}
          >
            There’s no stores yet!
          </h3>
          <p
            className={`v-my-stores__empty-state-description o-cl-dark-grey text-center ${responsive.md ? "o-ft-lg-400" : "o-ft-sm-400"}`}
          >
            Please contact the administrator to have access to the corresponding
            store.
          </p>
        </div>
      );
    }

    if (data) {
      return data.map((store: any) => (
        <StoreCard
          key={store.id}
          store={store}
          onClick={() => goToStore(store)}
        />
      ));
    }
  }, [data, isLoading, goToStore, responsive.md]);

  // useEffect(() => {
  //   dispatch(setSelectedStore(null))
  //   dispatch(setSelectedProject(null))
  // }, [dispatch])

  return <div className="v-my-stores d-flex flex-wrap">{renderer()}</div>;
};

export default MyStores;
