import { Formik, Form as FormikForm } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AuthController from "@controllers/AuthController";
import Button from "@components/Button";
import Footer from "@components/sso/Footer";
import Header from "@components/sso/Header";
import LoginContainer from "@components/sso/LoginContainer";
import { baseURL } from "@api/HttpProvider";
import { useIubenda } from "@hooks/useIubenda";

const initialValues = {
  email: "",
};

const LoginSSO = () => {
  useIubenda();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.authProvider !== "okta" && params.authProvider !== "saml2") {
      navigate("/error-404");
    }

    AuthController.authWithSingleSignOn(params.authProvider || "");
  }, [params, navigate]);

  const urlLogin = `${baseURL}auth/platform/${params.authProvider}/redirect`;

  const onSubmit = () => {
    setIsLoading(true);
  };

  return (
    <LoginContainer
      renderHeader={
        <Header
          title="Welcome"
          subtitle={process.env.REACT_APP_LOGIN_SSO_TITLE!}
        />
      }
      renderFooter={
        <Footer link="https://inspirebrands.identitynow.com/ui/d/request-center/request-access" />
      }
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {() => (
          <FormikForm
            className="c-login__form-container d-flex flex-column"
            noValidate
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <div className="bottom-button-container d-flex justify-content-center">
              <Button
                route={urlLogin}
                type="submit"
                isLoading={isLoading}
                size="big"
                typeStyle="block"
                value="Login"
                disabled
              />
            </div>
          </FormikForm>
        )}
      </Formik>
    </LoginContainer>
  );
};

export default LoginSSO;
