import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/* eslint-disable @typescript-eslint/no-explicit-any */
import { TIcon } from "@components/MaterialIcon";
import { TUser } from "@models/user/User.type";

const baseUrl = process.env.REACT_APP_BACK_BASE_URL;

type Params = { user?: TUser | null; filter?: string; search: string };
export type Response = { id: number; name: string; icon?: TIcon }[];

const getHeaders = () => {
  const token = localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json`,
  };
};

export const searchApi = createApi({
  reducerPath: "searchApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    searchDocuments: builder.query<any, Params>({
      query: ({ search }) => ({
        url: `api/v2/documents?filters[q]=${search}&perPage=10`,
        headers: getHeaders(),
      }),
      transformResponse: ({ data, links, meta }: any) => {
        return {
          links,
          meta,
          data: data.map(
            ({ type, section, parent, id, route, ...rest }: any) => {
              return {
                icon: type ? "insert_drive_file" : "folder",
                route: type ? `/document/${id}` : `documents/${id}`,
                type,
                section,
                id,
                ...rest,
              };
            },
          ),
        };
      },
    }),
    searchContacts: builder.query<any, Params>({
      query: ({ search }) => ({
        url: `api/search/contacts?filters[q]=${search}&perPage=10`,
        headers: getHeaders(),
      }),
      transformResponse: ({ data, links, meta }: any) => {
        return {
          links,
          meta,
          data: data.map(({ id, name, ...rest }: any) => ({
            id,
            name,
            icon: "account_circle",
            route: "/results?tab=1",
            ...rest,
          })),
        };
      },
    }),
    searchStores: builder.query<any, Params>({
      query: ({ search }) => ({
        url: `api/search/stores?filters[q]=${search}&perPage=10`,
        headers: getHeaders(),
      }),
      transformResponse: ({ data, links, meta }: any, _, { user }) => {
        return {
          links,
          meta,
          data: data
            .filter(
              (store: any) =>
                store.users?.find((u: any) => u.name === user!.name) ||
                store.owner_user.name === user!.name,
            )
            .map(({ id, name, ...rest }: any) => ({
              id,
              name,
              icon: "storefront",
              route: `stores/${name.replaceAll(" ", "-")}-${id}`,
              ...rest,
            })),
        };
      },
    }),
    searchProjects: builder.query<any, Params>({
      query: ({ search }) => ({
        url: `api/search/projects?filters[q]=${search}&perPage=10`,
        headers: getHeaders(),
      }),
      transformResponse: ({ data, links, meta }: any, _, { user }) => {
        return {
          links,
          meta,
          data: data
            .filter(
              (project: any) =>
                project.store.users?.find((u: any) => u.name === user!.name) ||
                project.store.owner_user.name === user!.name,
            )
            .map(({ id, name, code, store, ...rest }: any) => {
              return {
                id,
                name,
                store,
                code,
                ...rest,
                icon: "content_paste",
                route: `stores/${store.name.replaceAll(" ", "-")}-${
                  store.id
                }/${(name || code).replaceAll(" ", "-")}-${id}`,
              };
            }),
        };
      },
    }),
    getNextPage: builder.query<any, { query: string; tab: string }>({
      query: ({ query }) => ({ url: query, headers: getHeaders() }),
      transformResponse: (response: any, _, { query, tab }) => {
        return query ? { ...response, tab } : { links: { next: null } };
      },
    }),
  }),
});

export const {
  useSearchDocumentsQuery: useSearchDocuments,
  useSearchContactsQuery: useSearchContacts,
  useSearchStoresQuery: useSearchStores,
  useSearchProjectsQuery: useSearchProjects,
  useGetNextPageQuery: useGetNextPage,
} = searchApi;
