import Button from "@components/Button";
import { TTag } from "@models/Tag.type";
import TagInput from "@components/TagInput";
import { TagList } from "@components/TagList";
import ActionModal from "@components/ActionModal";
import useAppSelector from "@hooks/useAppSelector";
import useAppDispatch from "@hooks/useAppDispatch";
import { postTag } from "@redux/tags/thunks/tagThunk";
import { clearTagsByDocumentsEditable, removeTag, setCurrentTagValue } from "@redux/tags/slices/tagSlice";

interface ITagModalProps {
  isVisible: boolean
  setVisible: (value: boolean) => void
}

export const TagModal = ({ isVisible, setVisible }: ITagModalProps) => {

  const {
    file: { elementToTag },
    tags: { tagsByDocumentsEditable, pending: tagPending }
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const elementToTagId = elementToTag?.id || 0;

  const handlePostTags = () => {
    const tags = tagsByDocumentsEditable[elementToTagId]?.map((o: TTag) => o.name.toLowerCase());
    dispatch(postTag({ documentId: elementToTagId, tags })).then(() => setVisible(false));
  }

  return (
    <ActionModal
      show={isVisible}
      title={<span>Tags for: <span className='o-cl-grey-200'>{elementToTag?.name}</span></span>}
      includesForm
      renderContent={
        <>
          <TagInput label='Tag' placeholder='# Tag' name='' />
          <TagList list={tagsByDocumentsEditable} onClick={(tag, documentId) => dispatch(removeTag({ tag, documentId }))} documentId={elementToTagId} />
          <div className='d-flex w-100 mt-4 align-items-center justify-content-between pt-1'>
            <Button
              className={`w-100 mx-2 mx-md-4`}
              onClick={() => {
                setVisible(false);
                dispatch(setCurrentTagValue(''));
                dispatch(clearTagsByDocumentsEditable({ documentId: elementToTagId }));
              }}
              size="medium-big"
              value={"Cancel"}
            />
            <Button
              isLoading={tagPending}
              disabled={tagPending ? true : false}
              className={`w-100 mx-2 mx-md-4`}
              onClick={handlePostTags}
              size="medium-big"
              value={"Continue"}
            />
          </div>
        </>
      }
    />
  )
};
