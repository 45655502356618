/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import _ from "lodash";
import GridItem from "./GridItem";
import classNames from "classnames";
import { IRow } from "@models/Table.type";
import { IAction } from "@models/Action.type";
import { useLocation } from "react-router-dom";
import { IFile } from "@models/files/File.type";
import useAppSelector from "@hooks/useAppSelector";
import { TDropZone, useDragAndDrop } from "@hooks/useDragAndDrop";
import useWindowSize from "@hooks/useWindowSize";

interface IGridProps {
  data: IFile[];
  dynamicCardClassName?: (arg: IRow) => string; // Add a class string to a row based on a condition
  layout: string;
  context?:
    | "documents"
    | "results"
    | "file-management"
    | "vaults"
    | "favorites";
  contextMenu?: any;
  draggable?: boolean;
  checkboxes?: boolean;
  actionList?: IAction[];
  trashActionList?: IAction[];
  handleFileLoad?: (e: React.ChangeEvent) => void;
  storeInfo?: boolean;
}

const Grid = ({
  data,
  dynamicCardClassName,
  actionList,
  trashActionList,
  handleFileLoad,
  layout,
  contextMenu,
  draggable,
  checkboxes,
  storeInfo,
  context,
}: IGridProps) => {
  const location = useLocation();

  const {
    handleDrop,
    handleDrag,
    handleDragEnter,
    handleDragOver,
    handleDragLeave,
  } = useDragAndDrop();
  const {
    file: { draggingId },
  } = useAppSelector((state) => state);
  const folderId = location.pathname.split("/")[3];
  const isInFileManagement = location.pathname.includes(
    `/admin/file-management`,
  );
  const { responsive } = useWindowSize();

  const gridParentClass = classNames({
    // 'o-drag-border--color': dragging,
    "o-drag-border--color": _.isEqual(draggingId, 0),
    "o-min-height-dropzone": draggable,
  });

  const gridClass = classNames({
    "c-grid--no-gap": !responsive.md && !isInFileManagement,
  });

  const onChange = (e: any, files: any) => {
    e.stopPropagation();
    e.preventDefault();

    const target: any = {
      target: {
        files,
      },
    };

    if (handleFileLoad) handleFileLoad(target);
  };

  return (
    <div
      className={`py-2 ${gridParentClass}`}
      draggable={draggable}
      onDrag={handleDrag}
      onDrop={(e) => handleDrop(e, { parent_id: folderId }, onChange)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) =>
        handleDragEnter(e, { id: 0, type: "dropzone" } as TDropZone)
      }
      onDragLeave={(e) => handleDragLeave(e, {})}
      onDragStart={(e) => e.preventDefault()}
    >
      <div className={`c-grid ${gridClass} mb-3 ${layout}`}>
        {/* <div className={`c-grid ${gridClass} mx-md-3 px-md-2 mb-3 ${layout}`}> */}
        {(data || []).map((file: IFile) => (
          <div
            key={file.id}
            onContextMenu={(e) => {
              if (contextMenu && file.type) {
                const { setPosition, setFile, setIsVisible } = contextMenu;
                e.preventDefault();
                e.stopPropagation();
                setFile(file);
                setPosition({ x: e.pageX, y: e.pageY });
                setIsVisible(true);
              } else {
                e.stopPropagation();
              }
            }}
          >
            <GridItem
              draggable={draggable}
              key={file.id}
              data={file}
              {...{
                context,
                actionList,
                trashActionList,
                dynamicCardClassName,
                handleFileLoad,
                checkboxes,
                storeInfo,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Grid;
