import { DocumentsContext } from "../providers/DocumentsProvider";
import { useContext } from "react";

const useDocumentsContext = () => {
  const context = useContext(DocumentsContext);

  if (context === null) {
    throw new Error("moveToContext must be used within a DocumentsProvider");
  }

  return context;
};

export default useDocumentsContext;
