import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import React, { useEffect } from "react";

import AdminRouter from "./AdminRouter";
import AuthRouter from "./AuthRouter";
import CreatePassword from "@views/CreatePassword";
import CreateTwoFA from "@views/Create2Fa/CreateTwoFA";
import ErrorView from "@views/ErrorView";
import ForgotPassword from "@views/ForgotPassword";
import { HomeRouter } from "./HomeRouter";
import Login from "@views/Login";
import LoginSSO from "@views/LoginSSO";
import Logout from "@views/Logout";
import PrivacyPolicy from "@views/PrivacyPolicy";
import { PublicRouter } from "./PublicRouter";
import ResetPassword from "@views/ResetPassword";
import { Spinner } from "react-activity";
import TermsConditions from "@views/TermsConditions";
import TraditionalLogin from "@views/TraditionalLogin";
import TwoFAValidCode from "@views/Create2Fa/ValidateTwoFACode";
import Walkthrough from "@views/Walkthrough";
import { colors } from "@theme/colors";
import useAppSelector from "@hooks/useAppSelector";

const AppRouter = () => {
  const { loader } = useAppSelector((state) => state.loader);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const origin = searchParams.get("origin");

    if (origin) {
      localStorage.setItem("origin", origin);
    }
  }, [searchParams]);

  const routes = [
    { path: "forgot-password", element: <ForgotPassword /> },
    { path: "logout", element: <Logout /> },
    { path: "privacy-policy", element: <PrivacyPolicy /> },
    { path: "terms-conditions", element: <TermsConditions /> },
    { path: "/login/:authProvider", element: <LoginSSO /> },
    { path: "external", element: <TraditionalLogin /> },
    { path: "external/enter-code", element: <TraditionalLogin enterCode /> },
    { path: "/traditional", element: <TraditionalLogin traditional /> },
    {
      path: "/login",
      element:
        process.env.REACT_APP_ENABLE_LOGIN_ONBOARDING === "true" ? (
          <Login />
        ) : (
          <TraditionalLogin traditional />
        ),
    },
    { path: "error-400", element: <ErrorView statusCode={400} /> },
    { path: "error-401", element: <ErrorView statusCode={401} /> },
    { path: "error-403", element: <ErrorView statusCode={403} /> },
    { path: "error-404", element: <ErrorView statusCode={404} /> },
    { path: "error-500", element: <ErrorView statusCode={500} /> },
    { path: "*", element: <Login /> },
  ];

  if (loader) {
    return (
      <div
        style={{
          background: "#fff",
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Spinner size={28} color={colors.brandColorSecondary} />
        <span>Redirecting to {process.env.REACT_APP_BACK_BASE_URL}</span>
      </div>
    );
  }

  return (
    <Routes>
      {/* These routes require authentication */}
      <Route
        path="/*"
        element={
          <AuthRouter>
            <HomeRouter />
          </AuthRouter>
        }
      />

      <Route path="/admin/*" element={<AdminRouter />} />

      {process.env.REACT_APP_ENABLE_REQUEST_ACCESS === "true" && (
        <Route path="request-access" element={<Walkthrough />} />
      )}

      {/* These routes are public */}
      {routes.map(({ path, element }, key) => (
        <Route
          {...{ key, path }}
          element={<PublicRouter>{element}</PublicRouter>}
        />
      ))}

      <Route path="external/create-2fa" element={<CreateTwoFA />} />
      <Route path="external/create-2fa/code" element={<TwoFAValidCode />} />
      {/* These routes are public and have children */}
      <Route path="reset-password">
        <Route
          path=""
          element={
            <PublicRouter>
              <ResetPassword />
            </PublicRouter>
          }
        />
        <Route
          path=":token"
          element={
            <PublicRouter>
              <ResetPassword />
            </PublicRouter>
          }
        />
      </Route>
      <Route path="create-password">
        <Route
          path=":token"
          element={
            <PublicRouter>
              <CreatePassword />
            </PublicRouter>
          }
        />
        <Route path="" element={<Navigate replace to="/error-404" />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
