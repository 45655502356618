import React from 'react';
import TextLink from '@components/TextLink';

export type TFormTextLinkProps = {
  text: string;
  linkText: string;
  onClick: () => void;
}

const FormTextLink = ({ text, linkText, onClick }: TFormTextLinkProps) => {
  return (
    <div className='c-form-text-link text-center d-flex justify-content-center'>
      <span className='c-form-text-link__text o-ft-lg-400'>{text}</span>
      <TextLink type='element' text={linkText} onClick={onClick} />
    </div>
  );
};

export default FormTextLink;
