import Action from '@components/Action';
import { IAction } from '@models/Action.type';
import _ from 'lodash';
interface IActionsProps {
  extraClassName?: string;
  file: any;
  list?: IAction[];
}

const sectionsExcludedActions: string[] = ['history', 'copy-link'];
const sectionExcludedActionsOnPlatform: string[] = ['download'];
const currentWebsite = localStorage.getItem('current_website');

let excludedActionsForSections = [...sectionsExcludedActions, ...(!_.isEqual(currentWebsite, 'admin') ? sectionExcludedActionsOnPlatform : [])];

const Actions = ({ extraClassName, file, list }: IActionsProps) => {
  let actionList: IAction[] = list ?? [];

  if(!file.type)
    actionList = list?.filter(action => !excludedActionsForSections.includes(action.id)) ?? [];

  return (
    <>
      <div className={`position-relative d-flex justify-content-start ${extraClassName}`}>
        {actionList?.map(({ id, icon, onClick, text, type, hidden }, index) => (
            <Action
              {...{ file, icon, type, text }}
              key={id}
              lastOne={index === (actionList?.length)! - 1}
              onClick={() => onClick(file)}
              hidden={hidden}
            />
          )
        )}
      </div>
    </>
  )
};

export default Actions;
