import TContact from '@models/Contact.type';
import profilePicture from '../assets/profile-img.png';
import { TIcon } from './MaterialIcon';
import { TSocialContact } from './SocialContactItem';
import ContactList from './SocialContactList';

const _phone: TIcon = 'phone';
const _email: TIcon = 'email';
const _linkedin: TIcon | 'linkedin' = 'linkedin';
const _language: TIcon = 'language';

type ICardProps = {
  item: TContact;
}

const Contact = ({ item }: ICardProps) => {
  const {
    name, company,
    position, email, phone,
    website, linkedin,
    avatar_file
  } = item;

  const socialContacts: TSocialContact[] = [
    { icon: _phone, value: phone },
    { icon: _email, value: email },
    { icon: _linkedin, value: linkedin },
    { icon: _language, value: website },
  ];

  let className: string = '';

  const getClassNameByType = () => {
    let str: string = '';
    switch (item.contact_type_id) {
      case 1:
        str = 'o-bg-brand-secondary:before';
        break;
      case 2:
        str = 'o-bg-grey-200:before';
        break;
      case 3:
        str = 'o-bg-grey-300:before';
        break;
      default:
        str = '';
        break;
    }
    return str;
  }

  className = getClassNameByType();
  const nameShortened = name.length > 50 ? name.substring(0, 42).concat('...') : name;
  
  return (
    <div className={`c-card d-flex flex-grow-1 ${className}`}>
      <div className='c-card__content d-flex flex-column flex-grow-1'>
        <div className='c-card__profile d-flex align-self-center flex-shrink-0'>
          <img src={avatar_file?.url || profilePicture} alt={name} className='c-card__profile-picture' />
        </div>
        <div className='d-flex flex-column align-items-center flex-grow-1'>
          <h3 className='c-card__name o-ft-base-700 text-center'>{nameShortened}</h3>
          <span className='c-card__position o-ft-xs-700 text-center flex-grow-1'>{position}</span>
          <span className='c-card__company o-ft-xs-600 text-center'>{company?.name}</span>
        </div>
        <div className='d-flex'>
          <ContactList list={socialContacts} alignmentRight={false} />
        </div>
      </div>
    </div >
  );
};

export default Contact;
