import React, { useState } from 'react'
import Button from '@components/Button';
import { Modal } from 'react-bootstrap';
import { RootState } from '@redux/store';
import { useSelector } from 'react-redux';
import useAppDispatch from '@hooks/useAppDispatch';
import { updateLoggedUser } from '@redux/users/thunks/userThunk';

interface ISmallModalProps {
  title: any;
  subtitle: string;
  onClick: () => void;
}
export default function OnboardingModal({ title, subtitle, onClick }: ISmallModalProps) {

  const dispatch = useAppDispatch();
  const [show, setShow] = useState(true);  //true to function

  const { data } = useSelector((state: RootState) => state.user);

  const quitOnboarding = (temporary: boolean) => {
    const sidebarElement = document.getElementById("sidebar");
    const appContainerElement = document.getElementById("appContainer");
    const dashboardScreenElement = document.getElementById("dashboardScreen");
    setShow(false)
    if (temporary)
      localStorage.setItem("onboarding", "true")
    else {
      let user = {
        ...data,
        onboarding: "1"
      }
      dispatch(updateLoggedUser({ user }));
    }
    sidebarElement?.classList.add("vh-100");
    appContainerElement?.classList.add("position-fixed");
    dashboardScreenElement?.style.removeProperty("height");
  }

  return (
    <div className='c-small-modal h-100'>
      <Modal
        dialogClassName='c-small-modal__container'
        show={show}
        centered
        backdrop="static"
        keyboard={false}
      >
        <div className='p-4'>
          {/* remove the closeButton to center the title, the X is not in the design */}
          <Modal.Header className='d-flex justify-content-center py-3 border-0'>
            <h2 className='c-small-modal__title o-ft-xl-700 m-0'>{title}</h2>
          </Modal.Header>
          <Modal.Body className='py-3 px-0'>
            <span className='o-ft-sm-400 d-block text-center'>{subtitle} </span>
            <div className='c-small-modal__button-container d-flex justify-content-center'>
              <Button
                size="big"
                typeStyle="block"
                value="Begin"
                className='c-button--colored'
                onClick={() => { setShow(false); onClick() }}
              />
            </div>

          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-center border-0'>
            <span className='c-small-modal__link o-ft-xs' onClick={() => quitOnboarding(true)}>Maybe Later</span>
            <span className='c-small-modal__link o-ft-xs' onClick={() => quitOnboarding(false)}>Skip</span>
          </Modal.Footer></div>
      </Modal >
    </div >
  );
}
