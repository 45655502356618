import useAppSelector from '@hooks/useAppSelector';
import MaterialIcon, { TIcon } from './MaterialIcon'
import { IProject } from '@redux/my-stores/types';
interface IFolderItemProps {
  project?: IProject;
  onClick?: () => void;
  isNew?: boolean;
}

const FolderItem: React.FC<IFolderItemProps> = ({ project, onClick, isNew }) => {
  const { name, code, icon } = project || {};
  const defaultIcon: TIcon = 'inventory_2';

  const { selectedStore } = useAppSelector(state => state.myStores)

  // request new project email template
  const R_emailTo = 'stores@franly.com';
  const R_subject = 'New Project Request for ' + selectedStore?.name;
  const R_body = 
  'Please enter below the information needed for this request.' +
  '%0D%0A%0D%0A' +
  'Project Type:' +
  '%0D%0A%0D%0A' +
  'Related users:' +
  '%0D%0A' + 
  selectedStore?.users?.map(user => `• ${user.email}:`).join('%0D%0A') +
  '%0D%0A%0D%0A' +
  'Project Estimated Start Date:' +
  '%0D%0A%0D%0A' +
  'Project Estimated Completion Date:' +
  '%0D%0A%0D%0A'

  if (isNew) {
    return (
      <a href={`mailto:${R_emailTo}?subject=${R_subject}&body=${R_body}`} className='c-folder-item__container position-relative'>
        <div className='c-folder-item__card d-flex justify-content-center o-cursor-pointer position-relative'>
          <div className='c-folder-item__card-content c-folder-item__card-content--new d-flex align-items-center justify-content-center'>
            <div className='c-folder-item__card-description text-center'>
              <div className='c-folder-item__card-icon d-flex justify-content-center align-items-center mx-auto '>
                <MaterialIcon
                  className='c-folder-item__hovered-icon'
                  color='o-cl-white'
                  icon='forward_to_inbox'
                  size={24}
                />
              </div>
              <div className='c-folder-item__title-section'>
                <p className='c-folder-item__title o-ft-base-700 o-cl-white m-0'>
                  Request New Project
                </p>
              </div>
            </div>
          </div>

        </div>
      </a>
    )
  }

  return (
    <div className='c-folder-item__container position-relative'>
      <div
        onClick={onClick}
        className='c-folder-item__card d-flex justify-content-center o-cursor-pointer position-relative'
      >
        <div className='c-folder-item__card-content d-flex align-items-center justify-content-center'>
          <div className='c-folder-item__card-description text-center'>
            <div className='c-folder-item__card-icon d-flex justify-content-center align-items-center mx-auto '>
              <MaterialIcon
                className='c-folder-item__hovered-icon'
                color='o-cl-white'
                icon={icon || defaultIcon}
                size={24}
              />
            </div>
            <div className='c-folder-item__title-section'>
              <p className='c-folder-item__title o-ft-base-700 o-cl-white m-0'>
                {name}
              </p>
              <p className={`o-cl-white m-0 px-1 ${!name ? 'o-ft-base-700' : 'o-ft-sm-400'}`}>{code}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default FolderItem
