import React, { createContext } from 'react'
import Tabs, { ITabsProps } from './Tabs';

interface IPanelTabsProps {
  children?: JSX.Element[] | JSX.Element;
  tabs: ITabsProps[];
  activeTab: { id: number, title: string } | null;
  onChange: ({ id, title }: { id: number, title: string }) => void;
}

interface IPanelTabsContextProps extends IPanelTabsProps {
}

export const PanelTabsContext = createContext({} as IPanelTabsContextProps);

export default function PanelTabs({ children, tabs, onChange, activeTab }: IPanelTabsProps) {
  return (
    <PanelTabsContext.Provider value={{ onChange, tabs, activeTab }}>
      <div className='c-panel-tabs d-flex my-2'>
        <Tabs />
        <div className='c-panel-tabs__white-space'></div>
      </div>
      {children}
    </PanelTabsContext.Provider>
  )
}
