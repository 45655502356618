import React from 'react'

import InformationData from './InformationData'
import useAppSelector from '@hooks/useAppSelector';

const Information: React.FC = () => {
  const { selectedStore } = useAppSelector((state) => state.myStores);

  const {
    name,
    id,
    store_number,
    owner_user,
    country,
    zip_code,
    address_1,
    address_2,
    capacity,
    store_type,
    users,
    state
  } = selectedStore!;

  return (
    <div className='c-store-information w-100'>
      <div className='d-md-flex gap-3'>
        <InformationData title='Store Name' data={name} />
        <InformationData title='Store Type' data={store_type.name} />
      </div>
      <div className='d-md-flex gap-3'>
        <InformationData title='Site ID' data={id} />
        <InformationData title='Store Number' data={store_number} />
      </div>
      <div className='d-md-flex c-store-information__section'>
        <InformationData title='Owner User' data={owner_user.name} />
      </div>
      <InformationData title='Related Users' dataTags={true} relatedUsers={users} />
      <div className='d-md-flex c-store-information__section'>
        <InformationData title='Capacity' data={capacity} />
      </div>
      <div className='d-md-flex gap-3'>
        <InformationData title='Address 1' data={address_1} />
        <InformationData title='Address 2' data={address_2} />
        <InformationData title='Zip Code' data={zip_code} />
      </div>
      <div className='d-md-flex gap-3'>
        <InformationData title='Country' data={country.name} />
        <InformationData title='State' data={state?.name} />
      </div>
    </div>
  )
}

export default Information
