import { useState, useEffect } from "react";
import { useGetDocument } from "@redux/documents/api";

const useBreadcrumb = (documentId: number) => {
  const [breadcrumb, setBreadcrumb] = useState<
    Array<{ name: string; id: number; route: string }>
  >([]);

  const [docId, setDocId] = useState<number | null>(documentId);
  const {
    data: document,
    isFetching: isFetchingDocument,
    isSuccess,
  } = useGetDocument(
    {
      id: docId,
    },
    {
      skip: docId === null,
    },
  );
  useEffect(() => {
    setDocId(documentId);
    setBreadcrumb([]);
  }, [documentId]);

  if (isSuccess) {
    setBreadcrumb([
      {
        name: document.data.name,
        id: document.data.id,
        route: `/documents/${document.data.id}`,
      },
      ...breadcrumb,
    ]);
    if (document.data.parent_id) {
      setDocId(document.data.parent_id);
    } else {
      setDocId(null);
    }
  }

  return {
    breadcrumb,
    generatingBreadcrumb: isFetchingDocument,
  };
};

export default useBreadcrumb;
