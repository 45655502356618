import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// import HeaderModule from '@components/HeaderModule';
import NewBreadcrumb from "@components/NewBreadcrumb/Breadcrumb";
import { formatCapitalizeFirstLetter } from '@helpers/formatCapitalizeFirstLetter';
import Button from '@components/Button';
import MaterialIcon from '@components/MaterialIcon';
import useWindowSize from '@hooks/useWindowSize';

export type OutletContext = any

const rootPath = 'all-forms'

const FormsLayout: React.FC = () => {
	const { responsive } = useWindowSize()

	const location = useLocation()
	const navigate = useNavigate()
	const context: OutletContext = {}

	const currentPath = location.pathname.split('/')[location.pathname.split('/').length - 1]

	const goBack = () => {
		navigate(-1)
	}

	return (
		<div className='o-admin-screen-container w-100'>
			<header className='c-header-module d-flex align-items-center justify-content-between'>
				<div>
					<span className="c-header-module__title o-cl-grey-100 o-ft-3xl-600">
						{formatCapitalizeFirstLetter(currentPath.replaceAll('-', ' '))}
					</span>
					{responsive.md && <NewBreadcrumb offset={1} />}
				</div>
				<div>
					{
						(responsive.md && currentPath !== rootPath
							?
							<Button
								value="Go Back"
								icon={{
									icon: 'arrow_back_ios',
									size: 12,
									position: 'right',
								}}
								onClick={goBack}
							/>
							: !responsive.md && currentPath !== rootPath &&
							<div onClick={goBack} className={`c-button--circle o-bg-brand-secondary:hover o-cl-brand-secondary o-cl-white:hover o-ft-xs-700 d-flex align-items-center justify-content-center mb-0 ms-4`}>
								<MaterialIcon
									className="c-button__icon ms-1"
									icon={'arrow_back_ios'}
									size={12}
								/>
							</div>)
					}
				</div>
			</header>

			<Outlet context={context} />
		</div>
	)
}

export default FormsLayout;
