import React from 'react'
import { useOutletContext } from 'react-router-dom';

import { Spinner } from 'react-activity';

import { ProcessStep } from '@components/ProcessStep'
import useAppSelector from '@hooks/useAppSelector'
import { OutletContext } from '@views/MyStores/Layout';
import { colors } from '@theme/colors';
import Comments from './shared/Comments';
import { useGetComments } from '@redux/approval-process/api';

const Process: React.FC = () => {
	const { processes: { isLoading } } = useOutletContext<OutletContext>();
	const { selectedProcess, projectProcessId } = useAppSelector(state => state.myStores);

	const {
		data: comments,
		isLoading: loadingComments,
		isFetching
	} = useGetComments({ projectProcessId }, { skip: !projectProcessId })

	if (isLoading) {
		return (
			<div className='d-flex justify-content-center mt-5'>
				<Spinner className='v-contacts__spinner' color={colors.brandColorSecondary} />
			</div>
		)
	}

	return (
		<>
			{selectedProcess?.process_steps.map((step, index) => (
				<ProcessStep
					key={step.id}
					index={index + 1}
					step={step}
				/>
			))}
			<Comments comments={comments || []} loading={loadingComments || isFetching} />
		</>
	)
}

export default Process