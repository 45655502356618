import React, { useEffect, useRef, useState } from "react";
import { useGetNdaContract, usePostNdaSigned } from "@redux/nda-modal/api";

import Button from "@components/Button";
import { Spinner } from "react-activity";

const NdaModal = ({ onClose }: { onClose: () => void }) => {
  const { data, isLoading } = useGetNdaContract();
  const [postNdaSigned] = usePostNdaSigned();
  const [isChecked, setChecked] = useState(false);
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const bodyRef = useRef<HTMLDivElement>(null);

  function decodeHtml(html: string) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  const decodedHtml = decodeHtml(data?.body);
  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };
  const handleButtonClick = () => {
    if (isChecked) {
      onClose();
      postNdaSigned();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (bodyRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = bodyRef.current;
        if (Math.round(scrollTop) + clientHeight >= scrollHeight - 50) {
          setIsCheckboxEnabled(true);
        }
      }
    };

    const bodyElement = bodyRef.current;
    if (bodyElement) {
      bodyElement.addEventListener("scroll", handleScroll);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (bodyElement) {
        bodyElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  return (
    <div className="c-nda-modal">
      <div className="c-nda-modal__header">
        <h2 className="o-ft-2xl-600 o-ft-3xl-600@md o-cl-grey-100">
          Non-Disclosure Agreement
        </h2>
      </div>
      <div className="c-nda-modal__content" ref={bodyRef}>
        {isLoading ? (
          <div className="d-flex justify-content-center my-5">
            <Spinner className="c-button__spinner" />
          </div>
        ) : (
          <div>
            <div className="o-ft-base-400">
              <div dangerouslySetInnerHTML={{ __html: decodedHtml }} />
            </div>
            <div>
              <hr className="dropdown-divider" />
              <div className="mt-4">
                <span className="o-ft-base-700">BUSINESS INFORMATION</span>
              </div>
              <div className="row mt-3">
                <div className="col-xs-12 col-md-4 mb-2">
                  <div>
                    <span className="o-ft-base-700">Effective Date</span>
                  </div>
                  <div className="mt-2">
                    {data.rendered_tokens.ContractDate}
                  </div>
                </div>
                <div className="col-xs-12 col-md-4 mb-2">
                  <div>
                    <span className="o-ft-base-700">Business Name</span>
                  </div>
                  <div className="mt-2">
                    {data.rendered_tokens.CounterpartyName}
                  </div>
                </div>
                <div className="col-xs-12 col-md-4 mb-2">
                  <div>
                    <span className="o-ft-base-700">Business Address</span>
                  </div>
                  <div className="mt-2">
                    {data.rendered_tokens.CounterpartyAddress}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="c-nda-modal__footer d-flex justify-content-between">
        <div className="c-nda-modal__disclosure">
          <label
            className="o-ft-xs-600 o-ft-base-400@md d-flex"
            htmlFor="agree-checkbox"
          >
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="mx-3"
              disabled={!isCheckboxEnabled}
              id="agree-checkbox"
            />
            <span>
              {" "}
              I am an authorized signer for Business, and on behalf of Business,
              I have read and agree to Inspire Brand&apos;s{" "}
              <span className="o-ft-base-600@md">
                Non-Disclosure Agreement.
              </span>
            </span>
          </label>
        </div>
        <div className="d-flex flex-row justify-content-end">
          <div className="c-nda-modal__footer-submit-btn">
            <Button
              onClick={handleButtonClick}
              disabled={!isChecked}
              size="big"
              typeStyle="block"
              value="Continue"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default NdaModal;
