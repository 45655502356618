import React, { forwardRef, useRef, useState, useImperativeHandle, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAppSelector from '@hooks/useAppSelector';
import { useGetFileByIdQuery } from '@redux/files/slices/fileSlice';
import ArrowIcon from './shared/ArrowIcon';
import { IFile, IFileResponse } from '@models/files/File.type';

export type Props = {
	id: number;
	name: string;
	parent: IFile;
	close?: boolean;
	onSelect?: (id: number) => void;
	ref?: any;
}

const Folder: React.FC<Props> = forwardRef(({ id, name, parent, onSelect, close }, ref) => {
	const { file: { data: fileData, pending } } = useAppSelector((state) => state)

	const childRef = useRef<{ toggleOpen: () => void }>()

	const [isOpen, setIsOpen] = useState(false)
	const [subFolders, setSubFolders] = useState<{ [key: number]: IFileResponse }>()
	const [getById, setGetById] = useState<number | null>(null)

	const navigate = useNavigate();

	const { data: parentData } = useGetFileByIdQuery<{ data: IFileResponse }>(getById, { skip: !getById })

	useEffect(() => {
		if (close && isOpen || !fileData.id) {
			setIsOpen(false)
		}
	}, [close])

	useEffect(() => {
		let foundId = fileData.parents.find(parent => parent.id === id)

		// Pido recuperar los datos de el padre y abro al padre
		if (!!foundId) {
			setIsOpen(true)
			setGetById(foundId.id)
		}

		if (!fileData.id) { // cuando es /admin/file-management/
			setIsOpen(false)
		}

		if (fileData.id === id) { // cuando es /admin/file-management/123
			setIsOpen(true) // si ya está abierta la mantengo abierta sino no
			childRef.current?.toggleOpen() // cierro a sus hijos
			setSubFolders((prev) => ({ ...prev, [id]: fileData })) // guardo sus subcarpetas
		}
	}, [fileData])

	// si hay info del padre agrego la subcarpeta
	useEffect(() => {
		if (parentData) {
			setSubFolders((prev) => ({ ...prev, [id]: parentData }))
		}
	}, [parentData])

	// Paso la función toogleOpen para cerrar dinamicamente a los hijos
	useImperativeHandle(ref, () => ({
		toggleOpen() {
			setIsOpen(false)
		}
	}))

	const handleSelect = () => {
		if (pending) return
		setIsOpen(!isOpen)
		navigate(`/admin/file-management/${isOpen ? (parent ? parent.id : '') : id}`)
		onSelect?.(id)
	}

	return (
		<>
			<div className='c-folder__father' onClick={handleSelect}>
				<ArrowIcon isArrowOpen={isOpen} />
				<span className={`c-folder__name ${isOpen ? 'o-cl-grey-100' : 'o-cl-grey-200'}`}>
					{name}
				</span>
			</div>

			{isOpen && subFolders && (
				subFolders[id].parents.length <= 3 && subFolders[id].children
					.filter((f: IFile) => f.type === null)
					.map(({ id, name, parent }: IFile) => {
						return (
							<div key={id} className='c-folder__sub-folder-container'>
								<Folder
									id={id}
									name={name}
									parent={parent}
									close={fileData.id !== id && parent.id === fileData.parent_id}
									ref={childRef}
								/>
							</div>
						)
					})
			)}
		</>
	)
});

export default React.memo(Folder)