/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, memo } from "react";

import MaterialIcon from "@components/MaterialIcon";

type Props = {
  onChange?: any;
  onClickClear?: () => void;
  placeholder?: string;
};

const Input = forwardRef<HTMLInputElement | null, Props>(
  ({ onChange, onClickClear, placeholder = "Search" }, ref) => {
    const hasValue = (inputRef: typeof ref): boolean => {
      if (inputRef && "current" in inputRef && inputRef.current) {
        return inputRef.current.value.length > 0;
      }
      return false;
    };

    return (
      <div className="d-flex w-100 align-items-center justify-content-between">
        <input
          id="search-input"
          ref={ref}
          type="text"
          onChange={onChange}
          placeholder={placeholder}
          className="c-search__input o-ft-sm-300 bg-transparent"
        />
        {hasValue(ref) && (
          <MaterialIcon
            icon="close"
            color="o-cl-grey-200"
            onClick={onClickClear}
            className="c-search__search-icon-container d-flex o-cursor-pointer"
          />
        )}
      </div>
    );
  },
);

export default memo(Input);
