import VaultsController from "@controllers/VaultsController";
import { createAsyncThunk } from "@reduxjs/toolkit";

const vaultsController = new VaultsController();

export const getVaults = createAsyncThunk(
  'vaults/index',
  async (args, { rejectWithValue }) => {
    try {
      let response: any = await vaultsController.index();
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getVaultById = createAsyncThunk(
  'vaults/current',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      let response: any = await vaultsController.getVault(id);
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getVaultsDocumentsById = createAsyncThunk(
  'vaults/documents',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      let response: any = await vaultsController.getDocuments(id);
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);