/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataForm, IProcesses, ISteps, ITags } from "../types";
import React, {
  Dispatch,
  ReactNode,
  createContext,
  useMemo,
  useReducer,
} from "react";
import { isEmptyArray, isString } from "ramda-adjunct";

import ProcessController from "@controllers/ProcessController";
import { createToast } from "@helpers/createToast";
import useAppDispatch from "@hooks/useAppDispatch";

enum ProcessActions {
  SET_DATA_TAGS = "SET_DATA_TAGS",
  SET_DATA_FORMS = "SET_DATA_FORMS",
}
type ProcessAction = {
  type: ProcessActions;
  payload?: number | boolean | ITags[] | DataForm[] | any;
};

interface ProcessesState {
  dataTags: ITags[];
  dataForms: DataForm[];
}

const initialState: ProcessesState = {
  dataForms: [],
  dataTags: [],
};

export const ProcessesContext = createContext<{
  state: ProcessesState;
  dispatch: Dispatch<ProcessAction>;
  showProcessById: (id: string) => Promise<IProcesses>;
  updateProcess: (id: string | number, data: any, toDelete: number[]) => void;
  postProcess: (data: any) => Promise<number>;
  getProcessStepById: (stepId: string) => Promise<ISteps>;
  deleteProcessStep: (stepId: string) => void;
  showAllForms: () => void;
  getForm: (formId: string | number) => void;
  getAllProcessTags: (data: any) => void;
  createProcessTag: (data: any) => any;
  getProcessTagsByProcessId: (processId: string) => Promise<ITags>;
}>({
  state: initialState,
  dispatch: () => null,
  showProcessById: () => Promise.resolve({ data: {} } as IProcesses),
  updateProcess: () => {},
  postProcess: () => Promise.resolve(0),
  getProcessStepById: () => Promise.resolve({ data: [] } as ISteps),
  deleteProcessStep: () => {},
  showAllForms: () => {},
  getForm: () => {},
  getAllProcessTags: () => {},
  createProcessTag: () => {},
  getProcessTagsByProcessId: () => Promise.resolve({ data: [] } as ITags),
});

const processesReducer = (
  state: ProcessesState,
  action: ProcessAction,
): ProcessesState => {
  switch (action.type) {
    case ProcessActions.SET_DATA_TAGS:
      return { ...state, dataTags: action?.payload?.data };
    case ProcessActions.SET_DATA_FORMS:
      return { ...state, dataForms: action?.payload?.data };
    default:
      return state;
  }
};

const ProcessesProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(processesReducer, initialState);
  const appDispatch = useAppDispatch();

  const showProcessById = async (id: string): Promise<IProcesses> => {
    try {
      const response = await ProcessController.getProcess(id);
      return response.data;
    } catch (error) {
      createToast("Error on load tag.", "danger", appDispatch);
      throw error;
    }
  };

  const deleteProcessStep = async (stepId: string | number) => {
    try {
      await ProcessController.deleteStep(stepId);
    } catch (error) {
      createToast("Error on delete step.", "danger", appDispatch);
      throw error;
    }
  };

  const updateProcess = async (
    id: string | number,
    data: any,
    stepsToDelete: number[],
  ) => {
    try {
      const newData = {
        name: data.name,
        description: data.description,
        is_published: data.is_published === true ? 1 : 0,
        process_tags: isEmptyArray(data.tags)
          ? null
          : data.tags.map((tag: any) => tag.value),
      };

      await ProcessController.update({
        processId: id,
        data: newData,
      });

      const stepPromises = data.steps.map((step: any, index: number) => {
        const commonData = {
          process_id: id,
          type: step.stepType.value,
          order: index + 1,
        };

        const formData = {
          ...commonData,
          ...(step.stepType.value === "form"
            ? {
                form_id: step.formType.value,
                approval_required: step.approval_required ? 1 : 0,
              }
            : {
                name: step.name,
                content: step.content,
                documents: isEmptyArray(step.documents)
                  ? null
                  : step.documents.map((doc: any) => doc.id),
              }),
        };

        const dataStep = {
          ...commonData,
          ...formData,
        };

        return isString(step.id)
          ? ProcessController.postStep(dataStep)
          : ProcessController.updateStep({
              stepId: step.id,
              data: dataStep,
            });
      });

      Promise.all(stepPromises)
        .then(() => {
          createToast("Process updated successfully!", "success", appDispatch);
        })
        .catch(() => {
          createToast("Error updating the step", "danger", appDispatch);
        });

      stepsToDelete.map((stepId: number) => deleteProcessStep(stepId));
    } catch (error) {
      createToast("Error to create the process", "danger", appDispatch);
      throw error;
    }
  };

  const postProcess = async (data: any) => {
    try {
      const newData = {
        name: data.name,
        description: data.description,
        is_published: data.is_published === true ? 1 : 0,
        process_tags: isEmptyArray(data.tags)
          ? null
          : data.tags.map((tag: any) => tag.value),
      };

      const response = await ProcessController.post(newData);

      const stepPromises = data.steps.map((step: any, index: number) => {
        const commonData = {
          process_id: response.data.data.id,
          type: step.stepType.value,
          order: index + 1,
        };

        const formData = {
          ...commonData,
          ...(step.stepType.value === "form"
            ? {
                form_id: step.formType.value,
                approval_required: step.approval_required ? 1 : 0,
              }
            : {
                name: step.name,
                content: step.content,
                documents: isEmptyArray(step.documents)
                  ? null
                  : step.documents.map((doc: any) => doc.id),
              }),
        };

        return ProcessController.postStep(formData);
      });

      Promise.all(stepPromises)
        .then(() => {
          createToast("Process created successfully!", "success", appDispatch);
        })
        .catch(() => {
          createToast("Error creating the step", "danger", appDispatch);
        });
      return response.data.data.id;
    } catch (error) {
      createToast("Error to create the process", "danger", appDispatch);
      throw error;
    }
  };

  const getProcessStepById: (processId: string) => Promise<ISteps> = async (
    processId: string,
  ) => {
    try {
      const response = await ProcessController.getProcessStepById(processId);
      return response.data;
    } catch (error) {
      createToast("Error on load steps.", "danger", appDispatch);
      return error;
    }
  };

  const getForm = async (formId: string | number) => {
    try {
      const response = await ProcessController.getForms(formId);
      return response.data;
    } catch (error) {
      createToast("Error on load form.", "danger", appDispatch);
      return error;
    }
  };

  const showAllForms = async () => {
    try {
      const response = await ProcessController.getForms();
      dispatch({
        type: ProcessActions.SET_DATA_FORMS,
        payload: response.data,
      });
    } catch (error) {
      createToast("Error on load forms.", "danger", appDispatch);
      throw error;
    }
  };

  const getAllProcessTags = async (data: { perPage: number; q: string }) => {
    const { perPage, q } = data;
    const queryParam = typeof q === "string" ? `&filters[q]=${q}` : "";

    const response = await ProcessController.getAllProcessTags({
      perPage,
      queryParam,
    });

    dispatch({
      type: ProcessActions.SET_DATA_TAGS,
      payload: response.data,
    });
  };

  const createProcessTag = async (data: any) => {
    try {
      const response = await ProcessController.postTag(data);
      createToast("Tag add successfully!", "success", appDispatch);
      return response.data;
    } catch (error) {
      createToast("Error adding tag", "danger", appDispatch);
      return error;
    }
  };

  const getProcessTagsByProcessId: (
    processId: string,
  ) => Promise<ITags> = async (processId: string) => {
    try {
      const response =
        await ProcessController.getProcessTagsByProcessId(processId);
      return response.data;
    } catch (error) {
      createToast("Error getting tag", "danger", appDispatch);
      return { data: {} } as ITags;
    }
  };

  const value = useMemo(
    () => ({
      state,
      dispatch,
      showProcessById,
      updateProcess,
      postProcess,
      getProcessStepById,
      deleteProcessStep,
      getForm,
      showAllForms,
      getAllProcessTags,
      createProcessTag,
      getProcessTagsByProcessId,
    }),
    [state, dispatch],
  );

  return (
    <ProcessesContext.Provider value={value}>
      {children}
    </ProcessesContext.Provider>
  );
};

export default ProcessesProvider;
