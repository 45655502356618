/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";

import {
  CodeInput,
  CodeInputContainer,
} from "@views/CreatePassword/shared/forms/Code";
import { Form, Formik } from "formik";
import HttpProvider, { getClientId, getClientSecret } from "@api/HttpProvider";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AxiosError } from "axios";
import Button from "@components/Button";
import FormView from "@components/FormView";
import TextLink from "@components/TextLink";
import TextTimer from "@components/TextTimer";
import { createToast } from "@helpers/createToast";
import { images } from "@theme/images";
import useAppDispatch from "@hooks/useAppDispatch";

const api = new HttpProvider();

const initialValues = {
  code_1: "",
  code_2: "",
  code_3: "",
  code_4: "",
};

type Values = typeof initialValues;

const validationSchema = Yup.object({
  code_1: Yup.string().matches(/^(\d)/).required("This field is required"),
  code_2: Yup.string().matches(/^(\d)/).required("This field is required"),
  code_3: Yup.string().matches(/^(\d)/).required("This field is required"),
  code_4: Yup.string().matches(/^(\d)/).required("This field is required"),
});

const EnterCode: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isTimerActive, setIsTimerActive] = useState(true);

  useEffect(() => {
    if (!location.state) {
      navigate("/external", { replace: true });
    }
  }, [location.state, navigate]);

  const codeInput1Ref = useRef<HTMLInputElement>(null);
  const codeInput2Ref = useRef<HTMLInputElement>(null);
  const codeInput3Ref = useRef<HTMLInputElement>(null);
  const codeInput4Ref = useRef<HTMLInputElement>(null);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    // Enfocar el siguiente input después de completar el actual
    switch (name) {
      case "code_1":
        if (value.length === 1) {
          codeInput2Ref.current?.focus();
        }
        break;
      case "code_2":
        if (value.length === 1) {
          codeInput3Ref.current?.focus();
        } else {
          codeInput1Ref.current?.focus();
        }
        break;
      case "code_3":
        if (value.length === 1) {
          codeInput4Ref.current?.focus();
        } else {
          codeInput2Ref.current?.focus();
        }
        break;
      case "code_4":
        if (value.length === 0) {
          codeInput3Ref.current?.focus();
        }
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      const loginResponse = await api.request("post", "api/auth/signin", {
        client_id: getClientId(),
        client_secret: getClientSecret(),
        username: (location.state as any)?.username,
        password:
          (location.state as any)?.password +
          values.code_1 +
          values.code_2 +
          values.code_3 +
          values.code_4,
      });

      localStorage.setItem("access_token", loginResponse.data.access_token);
      localStorage.setItem("refresh_token", loginResponse.data.refresh_token);

      createToast("Correct verification code", "success", dispatch);
      navigate("/dashboard", { replace: true });
    } catch (error) {
      if ((error as AxiosError).code === "ERR_NETWORK") {
        createToast("Unexpected error", "danger", dispatch);
        return;
      }

      if ((error as AxiosError).response?.status === 400) {
        createToast("Code expired", "danger", dispatch);
        return;
      }

      createToast("The code does not match", "danger", dispatch);
    }
  };

  const sendCode = async () => {
    const params = {
      username: (location.state as any)?.username,
      password: (location.state as any)?.password,
    };

    try {
      await api.request("post", "api/auth/phone/verification", params);
      createToast("Code sent successfully", "success", dispatch);
      setIsTimerActive(true);
    } catch (error) {
      createToast("Unexpected error", "danger", dispatch);
    }
  };

  const emailTo = "support@franly.com";
  const subject = "Phone number update request";
  const body =
    "Please enter below this line the update you are requesting." +
    "%0D%0A%0D%0A" +
    "[New Phone Number]";

  return (
    <FormView
      renderHeader={
        <div className="c-form-header">
          <div className="c-form-header__logo d-flex">
            <img
              className="c-form-header__logo-img"
              src={images.verticalBrandLogo}
              alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
            />
          </div>
          <span className="c-form-header__subtitle o-ft-2xl-600 o-ft-2xl@md text-center d-block">
            Enter Verification Code
          </span>
          <span className="c-form-header__title o-ft-md-400  o-ft-lg@md text-center d-block">
            We&apos;ve sent a code to
            <br />
            <b>{(location.state as any)?.phone}:</b>
          </span>
        </div>
      }
      renderFooter={
        <div>
          {isTimerActive ? (
            <div className="text-center">
              <span className="c-form-links__bottom-label">
                Didn’t receive any e-mail?
                <span className="o-cl-grey-200">
                  {" "}
                  Resend a new code in
                  <span className="o-cl-grey-200">
                    {" "}
                    <TextTimer
                      onTimeOut={() => setIsTimerActive(false)}
                      seconds={120}
                    />
                  </span>
                </span>
              </span>
            </div>
          ) : (
            <div className="d-flex flex-row justify-content-center">
              <span className="c-form-links__bottom-label text-center">
                Didn’t receive any e-mail?{" "}
                <TextLink
                  text="Resend here"
                  type="element"
                  onClick={sendCode}
                />
              </span>
            </div>
          )}
          <div className="d-flex flex-row">
            <span className="c-form-links__bottom-label text-center">
              Problems with phone number?{" "}
              <TextLink
                text="Contact the administrator"
                type="href"
                route={`mailto:${emailTo}?subject=${subject}&body=${body}`}
              />
            </span>
          </div>
        </div>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, isValid, errors, values, isSubmitting }) => (
          <Form
            className="c-login__form-container d-flex flex-column flex-grow-1"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <div className="d-flex flex-column align-items-center mt-3">
              <div className="d-flex gap-2 gap-md-4">
                <CodeInputContainer
                  $error={!!Object.values(errors).length}
                  onClick={() => codeInput1Ref.current?.focus()}
                >
                  <CodeInput
                    $value={values.code_1}
                    autoComplete="off"
                    ref={codeInput1Ref}
                    name="code_1"
                    maxLength={1}
                    onChange={(e: any) => {
                      handleChange(e);
                      setFieldValue("code_1", e.target.value);
                    }}
                  />
                </CodeInputContainer>
                <CodeInputContainer
                  $error={!!Object.values(errors).length}
                  onClick={() => codeInput2Ref.current?.focus()}
                >
                  <CodeInput
                    $value={values.code_2}
                    autoComplete="off"
                    ref={codeInput2Ref}
                    name="code_2"
                    maxLength={1}
                    onChange={(e: any) => {
                      handleChange(e);
                      setFieldValue("code_2", e.target.value);
                    }}
                  />
                </CodeInputContainer>
                <CodeInputContainer
                  $error={!!Object.values(errors).length}
                  onClick={() => codeInput3Ref.current?.focus()}
                >
                  <CodeInput
                    $value={values.code_3}
                    autoComplete="off"
                    ref={codeInput3Ref}
                    name="code_3"
                    maxLength={1}
                    onChange={(e: any) => {
                      handleChange(e);
                      setFieldValue("code_3", e.target.value);
                    }}
                  />
                </CodeInputContainer>
                <CodeInputContainer
                  $error={!!Object.values(errors).length}
                  onClick={() => codeInput4Ref.current?.focus()}
                >
                  <CodeInput
                    $value={values.code_4}
                    autoComplete="off"
                    ref={codeInput4Ref}
                    name="code_4"
                    maxLength={1}
                    onChange={(e: any) => {
                      handleChange(e);
                      setFieldValue("code_4", e.target.value);
                    }}
                  />
                </CodeInputContainer>
              </div>
              {Object.values(errors).length ? (
                <p className="o-ft-sm-400 o-cl-red mt-4">Wrong Code</p>
              ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <></>
              )}
            </div>

            <div className="bottom-button-container d-flex flex-grow-1 justify-content-center align-items-end">
              <Button
                isLoading={isSubmitting}
                type="submit"
                size="big"
                typeStyle="block"
                value="Submit"
                disabled={!isValid || isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </FormView>
  );
};

export default EnterCode;
