import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { IMyStore, IProject } from "./types";
import { IProcess } from "@redux/approval-process/slices/processSlice";
import { TUser } from "@models/user/User.type";

const getHeaders = () => {
	const token = localStorage.getItem('access_token');
	return {
		"Authorization": `Bearer ${token}`,
		'Content-Type': `application/json`,
	}
}

const baseUrl = process.env.REACT_APP_BACK_BASE_URL

export const myStoresApi = createApi({
	reducerPath: 'myStoreApi',
	baseQuery: fetchBaseQuery({ baseUrl }),
	endpoints: (builder) => ({
		getStores: builder.query<IMyStore[], { user: TUser | null }>({
			query: () => ({ url: 'api/stores', headers: getHeaders() }),
			transformResponse: (res: any, _, { user }) => {
				return res.data.filter((store: any) =>
					store.users?.find((u: any) =>
						u.name === user!.name) ||
						store.owner_user.name === user!.name
				)
			}
		}),
		getProjects: builder.query<IProject[], string>({
			query: (id) => ({ url: `api/stores/${id}/projects`, headers: getHeaders() }),
			transformResponse: (res: any) => res.data || res
		}),
		getProcesses: builder.query<{ id: number; process: IProcess; project: IProject, project_process_steps: any[] }[], string>({
			query: (id) => ({ url: `api/projects/${id}/project-processes`, headers: getHeaders() }),
			transformResponse: (res: any) => res.data || res
		})
	}),
	refetchOnFocus: true
})

export const {
	useGetStoresQuery: useGetStores,
	useGetProjectsQuery: useGetProjects,
	useGetProcessesQuery: useGetProcesses
} = myStoresApi;