/* eslint-disable @typescript-eslint/no-explicit-any */
import ProcessController from "@controllers/ProcessController";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getProcesses = createAsyncThunk(
  "approval-process/index",
  async (args, { rejectWithValue }) => {
    try {
      const response: any = await ProcessController.index();
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getStepsByProcess = createAsyncThunk(
  "steps-by-process/index",
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response: any = await ProcessController.getStepsByProcess(id);
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateStepStatus = createAsyncThunk(
  "updateStepStatus",
  async (
    { process_step_id, status }: { process_step_id: number; status: number },
    { rejectWithValue },
  ) => {
    try {
      const response: any = await ProcessController.setStepStatus(
        process_step_id,
        status,
      );
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);
