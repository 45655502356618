import { useEffect, useState } from "react";
import { Field } from "./ProcessForm";

export function useFormBuilder(cards: any[], fields: Field[]) {
  const [groups, setGroups] = useState<any>();
  const [errors, setErrors] = useState<any>({})

  const handleChange = (fieldId: number, value: any) => {
    const updated = groups.map((group: any) => ({
      ...group,
      inputs: group.inputs.map((field: any) => {
        if (field.id === fieldId) {
          if ((value === '' || (Array.isArray(value) && value.length === 0)) && field.required) {
            setErrors((prev: any) => ({ ...prev, [field.id]: 'field is required' }))
          } else {
            setErrors((prev: any) => ({ ...prev, [field.id]: '' }))
          }
          return { ...field, value }
        }
        return field
      })
    }))
    setGroups(updated)
  }

  useEffect(() => {
    if (!cards) return

    const formGroups = cards.map(card => ({
      // id: card.card_id,
      title: card.title,
      description: card.description,
      required: card.required,
      inputs: card.inputs.map((input: any) => {
        let result

        fields.forEach(field => {
          if (field.id === input.id) {  
            result = {
              ...input,
              updateId: field.updateId,
              value: field.value,
              required: field.required
            }
          }
        })

        if (result) return result

        return {
          ...input,
          value: '',
          required: card.required
        }
      })
    }))

    setGroups(formGroups)
  }, [cards, fields])

  return {
    groups,
    errors,
    setErrors,
    handleChange
  };
}