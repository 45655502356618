import React from "react";
import MaterialIcon, { TIcon } from "@components/MaterialIcon";
import { useNavigate } from "react-router-dom";

export type Props = {
  id: number;
  name: string;
  route?: string;
  isFile?: boolean;
  icon?: {
    name: TIcon;
    color: string;
    filled?: boolean;
  };
  onSelect: (obj: { id: number; name: string }) => void;
};

const ResultItem: React.FC<Props> = ({
  id,
  name,
  icon,
  route,
  isFile,
  onSelect,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (route) {
      if (isFile) {
        window.open(route, "_blank");
        return;
      }
      navigate(route);
    }

    onSelect?.({ id, name });
  };

  return (
    <div className="o-cursor-pointer" onClick={handleClick} aria-hidden>
      <div className="c-search-bar-result__file-result d-flex justify-content-center align-items-center">
        {icon && (
          <div className="c-search-bar-result__icon-container d-flex align-items-center justify-content-center">
            <MaterialIcon
              icon={icon.name}
              className={`o-max-width ${icon.filled && "material-symbols-filled"}`}
              color={icon.color}
              size={30}
            />
          </div>
        )}
        <span className="c-search-bar-result__name ps-2 w-100">{name}</span>
      </div>
    </div>
  );
};

export default ResultItem;
