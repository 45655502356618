import React from "react";
import PinUnpinProvider from "../../../../providers/PinUnpinProvider";
import AllFormSection from "@sections/Forms/AllFormsSection";

const All: React.FC = () => {
  return (
    <PinUnpinProvider>
      <AllFormSection />
    </PinUnpinProvider>
  );
};

export default All;
