import { FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SerializedError } from "@reduxjs/toolkit";
const getHeaders = () => {
  const token = localStorage.getItem('access_token');
  return {
    "Authorization": `Bearer ${token}`,
    'Content-Type': `application/json`,
  }
}
const baseUrl = process.env.REACT_APP_BACK_BASE_URL;
type AddSignerResponse = { data: any; } | { error: FetchBaseQueryError | SerializedError };
export const NdaApi = createApi({
  reducerPath: 'NdaApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getNdaContract: builder.query<any, void>({ // trae ultimo contrato
      query: () => ({
        url: 'api/ironclad/contract/latest',
        headers: getHeaders()
      }),
      transformResponse: (res: any) => res.data
    }),
    getSignedInfo: builder.query<any, void>({  // si fue firmado el ultimo contrato
      query: () => ({
        url: 'api/ironclad/contract/signed',
        headers: getHeaders()
      }),
      transformResponse: (res: any) => res.data
    }),
    getIsSigner: builder.query<any, void>({  // si el user esta habilitado para firmar un contrato
      query: () => ({
        url: 'api/ironclad/signer',
        headers: getHeaders()
      }),
      transformResponse: (res: any) => res.data
    }),
    addSigner: builder.mutation<AddSignerResponse, void>({  // para crear un usuario si no existe
      query: () => ({
        url: 'api/ironclad/signer',
        method: 'POST',
        body: {},
        headers: getHeaders()
      }),
      transformResponse: (res: any) => res.data
    }),
    addNdaSigned: builder.mutation<any, void>({ // para firmar el contrato
      query: () => ({
        url: 'api/ironclad/contract/sign',
        method: 'POST',
        body: {},
        headers: getHeaders(),
      }),
      transformResponse: (res: any) => res.data,
    }),
  }),
});
export const {
  useGetNdaContractQuery: useGetNdaContract,
  useGetSignedInfoQuery: useGetSignedInfo,
  useGetIsSignerQuery: useGetIsSigner,
  useAddNdaSignedMutation: usePostNdaSigned,
  useAddSignerMutation: usePostAddSigner,
} = NdaApi;
