import React, { useEffect, useState } from 'react'
import storeCardBg from '../assets/store-card-bg.jpg';

interface IStoreCardProps {
  onClick: () => void;
  store?: any;
}

export default function StoreCard({ store, onClick }: IStoreCardProps) {
  const { name, store_number, address_1, city, state, zip_code, cover_file } = store;

  return (
    <div className='c-store-card' onClick={onClick}>
      <div className="c-store-card__container">
        <img src={cover_file?.url || storeCardBg} className='c-store-card__bg' alt="store" />
        <div className='c-store-card__info d-flex flex-column justify-content-between'>
          <div>
            <p className='c-store-card__name m-0'>{name}</p>
            <span className='c-store-card__number d-block'>#{store_number}</span>
          </div>
          <div className='c-store-card__address-container'>
            <p className='c-store-card__address o-ft-xs m-0'>{address_1}</p>
            <div className='c-store-card__city-info'>
              <span className='c-store-card__city o-ft-xs o-ft-xs-500'>{city?.name} </span>
              <span className='c-store-card__city o-ft-xs o-ft-xs-500'>{state?.name} </span>
              <span className='c-store-card__city o-ft-xs o-ft-xs-500'>{zip_code}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
