import { useState } from "react"

import MaterialIcon from "@components/MaterialIcon"
import { Field } from "formik"

type Props = {
  name: string
  placeholder: string
}

const PasswordInput: React.FC<Props> = (props) => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShow = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="c-password-input">
      <MaterialIcon
        className="c-password-input__icon c-password-input__icon--left"
        icon='lock'
        size={18}
        color='o-cl-grey-200'
      />
      <Field
        className="c-password-input__field"
        type={showPassword ? 'text' : 'password'} 
        autoComplete='off'
        {...props}
      />
      <MaterialIcon
        className="c-password-input__icon c-password-input__icon--right o-cursor-pointer"
        size={18} icon={showPassword ? 'visibility' : 'visibility_off'}
        onClick={toggleShow}
        color='o-cl-grey-200'
      />
    </div>
  )
}

export default PasswordInput