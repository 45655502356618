/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from "react";

import Button from "@components/Button";
import FileName from "@components/FileName";
import FileUpload from "@components/FileUpload";
import { IRow } from "@models/Table.type";
import MaterialIcon from "@components/MaterialIcon";
import { Modal } from "react-bootstrap";
import Table from "@components/Table";
import { formatWeight } from "@helpers/formatWeight";
import useDocumentsContext from "@hooks/useDocumentsContext";
import useWindowSize from "@hooks/useWindowSize";

interface IFilesModal {
  handleFileLoad: (e: React.ChangeEvent) => void;
  handleFileUpload: () => void;
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  isLoading?: boolean;
  handleUploadFolder?: (e: React.ChangeEvent) => void;
}

const Status = ({ status }: { status: boolean }) => (
  <span className="o-ft-sm-400 ps-1 o-cl-grey-200 d-flex align-items-center">
    {status ? (
      <MaterialIcon icon="check_circle" color="o-cl-green" size={20} />
    ) : (
      <MaterialIcon icon="error_outline" color="o-cl-red" size={20} />
    )}
  </span>
);

const Action = ({
  id,
  name,
  removeFile,
}: {
  id: number;
  name: string;
  removeFile: (id: number) => void;
}) => (
  <button
    type="button"
    className="d-flex align-items-center justify-content-center border-0 py-3 bg-transparent"
    title={`Delete file "${name}"`}
    onClick={() => removeFile(id)}
  >
    <MaterialIcon icon="delete" color="o-cl-grey-100" size={20} />
  </button>
);

const FilesModal = ({
  handleFileLoad,
  handleFileUpload,
  setShow,
  show,
  isLoading,
  handleUploadFolder,
}: IFilesModal) => {
  const { responsive } = useWindowSize();
  const {
    filesToUpload,
    setFilesToUpload,
    foldersToUpload,
    setFoldersToUpload,
    restartDocumentsState,
  } = useDocumentsContext();
  const removeFile = (id: number) => {
    if (foldersToUpload.length > 0) {
      setFoldersToUpload(foldersToUpload.filter((folder) => folder.id !== id));
    } else {
      setFilesToUpload(filesToUpload.filter((file) => file.id !== id));
    }
  };

  const handleClose = () => {
    setShow(false);
    restartDocumentsState();
  };

  const columns = [
    {
      header: "Status",
      id: "status",
      colspan: { xs: "1" },
      field: "status",
      body: ({ status }: IRow) => <Status status={status} />,
      fixedWidth: { xs: "35px", md: "48px" },
    },
    {
      header: "Name",
      id: "name",
      colspan: { xs: "3", md: "10" },
      field: "file.name",
      body: ({ file, name }: IRow) => (
        <FileName
          name={name || file.name.split(".").slice(0, -1).join(".")}
          type={!name && file.name.split(".")[file.name.split(".").length - 1]}
        />
      ),
    },
    {
      header: "Size",
      id: "size",
      colspan: { xs: "1", md: "2" },
      field: "file.size",
      parsedString: ({ file, size }: IRow) =>
        formatWeight(size || file.size, true),
      fixedWidth: { xs: "90px", sm: "unset" },
    },
    {
      header: "Action",
      id: "action",
      colspan: { xs: "1" },
      field: "action",
      body: ({ file, id, name }: IRow) => (
        <Action id={id} name={name || file.name} removeFile={removeFile} />
      ),
      fixedWidth: { xs: "42px", md: "58px" },
    },
  ];

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="c-files-modal border-0 rounded-0"
      dialogClassName="c-files-modal__dialog"
      centered
      size="lg"
      scrollable
    >
      <Modal.Header className="p-4 mx-md-2 my-md-1 border-0">
        <h2 className="o-ft-2xl-400 o-cl-grey-100 o-ft-3xl-600@md mb-0">
          Files to Upload
        </h2>
        <button
          type="button"
          className="bg-transparent border-0 d-flex pb-2 pb-md-0 align-items-center justify-content-center"
          onClick={handleClose}
        >
          <MaterialIcon icon="close" size={24} color="o-cl-grey-100" />
        </button>
      </Modal.Header>
      <Modal.Body className="p-0 c-files-modal__body">
        {(foldersToUpload.length > 0 || filesToUpload.length > 0) && (
          <Table
            columns={columns}
            data={foldersToUpload.length > 0 ? foldersToUpload : filesToUpload}
            dynamicRowClassName={({ status }: IRow) =>
              status ? "" : " c-table__row--error"
            }
            extraClassName="c-table--no-borders"
            showHeader={false}
            canClickRow={false}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="py-4 border-0 my-md-2">
        <div className="d-flex flex-column flex-md-row align-items-center mw-100 py-md-1 w-100">
          {!!filesToUpload.find((file) => !file.status) && (
            <div className="d-flex align-items-md-center mb-4 mb-md-0">
              <MaterialIcon
                icon="warning_amber"
                color="o-cl-red"
                className="pt-2 pt-md-0"
                size={20}
              />
              <p className="o-cl-red o-ft-xs-600 mb-0 ps-2">
                Some files couldn&apos;t be uploaded, check they don&apos;t
                exceed the permitted size (1GB). Folders are currently not
                supported.
              </p>
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center justify-content-md-end ps-md-5 pe-md-3 w-100">
            <FileUpload
              handleFileDocument={
                foldersToUpload.length > 0 ? handleUploadFolder : handleFileLoad
              }
              className="me-2 me-sm-3 me-md-2"
              icon={{
                icon: "add",
                size: 18,
              }}
              id="add-folder-modal"
              size={responsive.md ? "big" : "medium-big"}
              value={foldersToUpload.length > 0 ? "Add" : "Add files"}
              webkitdirectory={foldersToUpload.length > 0 ? "" : undefined}
            />

            <Button
              isLoading={isLoading}
              className="ms-2 ms-sm-3 ms-md-1"
              disabled={
                (!filesToUpload.filter((file) => file.status).length &&
                  !foldersToUpload.filter((folder) => folder.status).length) ||
                !!isLoading
              }
              icon={{
                icon: "upload",
                position: "right",
                size: 24,
              }}
              onClick={handleFileUpload}
              size={responsive.md ? "big" : "medium-big"}
              value="Upload"
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FilesModal;
