/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import MaterialIcon, { TIcon } from "@components/MaterialIcon";

import React from "react";
import { Spinner } from "react-activity";
import classNames from "classnames";
import { formatCapitalizeFirstLetter } from "@helpers/formatCapitalizeFirstLetter";

export type Props = {
  icon?: TIcon;
  id?: any;
  size?: "small";
  main?: boolean;
  name: string;
  error?: string;
  isLoading?: boolean;
  onClose?: (id: any) => any;
  onClick?: () => any;
};

const Chip: React.FC<Props> = ({
  id,
  name,
  icon,
  onClose,
  onClick,
  size,
  error,
  isLoading,
  main,
}) => {
  const classes = classNames({
    "c-chip--error": !!error,
    "c-chip--small": size === "small",
    "c-chip--main": main,
    "with-icon": icon !== undefined,
  });

  return (
    <div
      className={`c-chip ${classes}`}
      title={error ?? ""}
      onClick={() => onClick?.()}
    >
      {icon && (
        <MaterialIcon
          icon={icon}
          className={error ? "c-chip__icon--error" : "c-chip__icon"}
          onClick={() => onClick?.()}
        />
      )}
      <span className="c-chip__name">{formatCapitalizeFirstLetter(name)}</span>
      {isLoading ? (
        <Spinner />
      ) : (
        <MaterialIcon
          icon="close"
          onClick={() => onClose?.(id)}
          className="c-chip__close"
          size={20}
        />
      )}
    </div>
  );
};

export default Chip;
