import React from 'react';
import Button from './Button';
import { Modal, ModalProps } from 'react-bootstrap'


interface IActionPopUpProps extends ModalProps {
  title: string;
  subtitle: string;
  onClickLeftButton: () => void;
  onClickRightButton: () => void;
}

const ActionPopUp = ({ title, subtitle, show, onClickLeftButton, onClickRightButton }: IActionPopUpProps) => {

  return (
    <Modal show={show} className="d-flex align-items-center">
      <div className='c-action-popup'>
        <h3 className='c-action-popup__title o-ft-2xl-600 text-center'>{title}</h3>
        <p className='c-action-popup__subtitle o-ft-sm-400'>{subtitle}</p>
        <div className='d-flex justify-content-around'>
          <Button
            value="Cancel"
            onClick={onClickLeftButton}
          />
          <Button
            value="Continue"
            onClick={onClickRightButton}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ActionPopUp;
