import React, { ReactElement } from 'react';

interface IFilterModuleProps {
  children?: ReactElement | ReactElement[];
}

const FilterLayout = ({ children }: IFilterModuleProps) => {
  return (
    <div className='c-filter-layout'>
      <div className="c-filter-module__options">
        <div className='c-filter-module__options-section d-flex align-items-center'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default FilterLayout;
