import HttpProvider from "@api/HttpProvider";

const api = new HttpProvider();

export default class PrivacyTermsController {

  getTermsAndConditions(onSuccess: (res: any) => void, onError: (error: any) => void) {
    api.request('get', "api/organization")
      .then((res) => {
        onSuccess(res?.data.data[0]?.terms_and_conditions)
        // onSuccess(res?.data.privacy_terms.terms_conditions)
      })
      .catch((err) => onError(err));
  }

  getPrivacyPolicy(onSuccess: (res: any) => void, onError: (error: any) => void) {
    api.request('get', "api/organization")
      .then((res) => {
        onSuccess(res?.data.data[0]?.privacy_policy)
        //onSuccess(res?.data.privacy_terms.privacy_policy)
      })
      .catch((err) => onError(err));
  }
}
