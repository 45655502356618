import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useOutletContext, useSearchParams } from 'react-router-dom';

import Button from '@components/Button';
import UploadButton from '@components/UploadButton';
import Modal from '@components/Modal';
import { ProcessStepContext } from '@components/ProcessStep';

import { useFormBuilder } from './useFormBuilder';
import { OutletContext } from '@views/MyStores/Layout';

import groupBy from 'lodash/groupBy';

import { whichProvider } from '@helpers/whichProvider';
import { createToast } from '@helpers/createToast';

import useAppSelector from '@hooks/useAppSelector';
import useAppDispatch from '@hooks/useAppDispatch';
import useWindowSize from '@hooks/useWindowSize';
import Alert from '@components/Alert';

import axios from 'axios';
import mime from 'mime-types'
import { baseURL } from '@api/AdminHttpProvider';

export type Field = {
  id: number;
  updateId: number;
  title: string;
  description: string;
  required: boolean;
  value: string;
}

type Props = {
  cards: any[]
}

const ProcessForm: React.FC<Props> = ({ cards }) => {
  const { responsive } = useWindowSize()

  const [searchParams] = useSearchParams()
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [loadingFiles, setLoadingFiles] = useState<{ [key: string]: boolean }>();
  const [removedFiles, setRemovedFiles] = useState([])
  const [errorFiles, setErrorFiles] = useState<any[]>([])

  const outlet = useOutletContext<OutletContext>()
  const { step: { id, approval_required, form }, review, projectProcessStep } = useContext(ProcessStepContext);
  const { projectProcessId } = useAppSelector(state => state.myStores);

  const [fields, setFields] = useState<Field[]>([])

  const { groups, handleChange, errors, setErrors } = useFormBuilder(cards, fields)

  const diffMinutes = () => {
    if (!projectProcessStep?.updated_at) return 31

    const updated_time = new Date(projectProcessStep?.updated_at!).getTime()
    const actual_time = new Date().getTime()
    const difference = actual_time - updated_time
    const resultInMinutes = Math.round((difference / 1000) / 60);
    return resultInMinutes
  }

  useEffect(() => {
    const fieldId = projectProcessStep?.id || searchParams.get('id')
    if (!fieldId) return

    whichProvider().request('get', `api/projects/processes/steps/${fieldId}/fields`).then(({ data }) => {
      const response = data.data

      const fileInputs = response
        .filter((f: any) => f.form_field.field.code === 'file_multiple')
        .map((field: any) => ({ form_field: field.form_field, id: field.id, value: field.value }))

      const fileInputsValues = groupBy(fileInputs, 'form_field.id')

      const mappedResponse: any = [];

      response.forEach((item: any) => {
        if (item.form_field.id === 114) {
          const encontrado = mappedResponse.some((i: any) => i.form_field.id === 114);
          if (!encontrado) {
            mappedResponse.push(item);
          }
        } else {
          mappedResponse.push(item);
        }
      });

      const inputs = mappedResponse.map((input: any) => ({
        updateId: input.id,
        id: input.form_field.id,
        title: input.form_field.title,
        description: input.form_field.description,
        required: input.form_field.is_required,
        value: fileInputsValues[input.form_field.id] || input.value
      }))

      setFields(inputs)
    })
  }, [projectProcessStep, searchParams])

  const dispatch = useAppDispatch()

  const [comment, setComment] = useState('')
  const [submitModal, setSubmitModal] = useState(false)

  const handleSubmit = async () => {
    setSubmitModal(false)
    setLoadingSubmit(true)

    const baseUrl = 'api/projects/processes'
    const method = fields.length ? 'put' : 'post'

    for await (const id of removedFiles) {
      await whichProvider().request('delete', `api/projects/processes/steps/fields/${id}`)
    }

    if (method === 'post') {
      try {
        const { data } = await whichProvider().request(method, `${baseUrl}/steps`, {
          project_process_id: projectProcessId,
          process_step_id: id, // id del step original
          status: approval_required ? 1 : 2
        })

        for await (const group of groups) {
          for await (const input of group.inputs) {
            if (input.value.length > 0) {

              if (Array.isArray(input.value)) {
                const token = localStorage.getItem("access_token");

                for await (const file of input.value) {
                  const file_extension = file.value.name.split('.')[file.value.name.split('.').length - 1]
                  const mime_type = mime.lookup(file_extension)

                  try {
                    const { data: signedUrl } = await axios.post(`${baseURL}/api/get-signed-url?extension=${file_extension}&mime_type=${mime_type}&folder=step_fields`)

                    await axios.put(signedUrl.data.file.url, file.value)

                    const bodyFormData = new FormData();

                    bodyFormData.append('value[name]', signedUrl.data.path.split('/')[1])
                    bodyFormData.append('value[path]', signedUrl.data.path)
                    bodyFormData.append('value[original_name]', file.value.name.split('.').slice(0, file.value.name.split('.').length - 1).join('.'))
                    bodyFormData.append('value[type]', file.value.type || file.value.name.split('.')[1])
                    bodyFormData.append('value[size]', file.value.size.toString())
                    bodyFormData.append('value[extension]', signedUrl.data.path.split('.')[1])
                    bodyFormData.append('project_process_step_id', data.data.id.toString())
                    bodyFormData.append('form_field_id', input.id.toString())

                    await axios.post(`${baseURL}/api/projects/processes/steps/fields`, bodyFormData, {
                      timeout: 360000,
                      headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': `multipart/form-data`,
                      },
                    })
                  } catch (error) {
                    createToast("Upload Failed. Specific files couldn't upload, tap edit to retry.", 'danger', dispatch)
                    setErrorFiles(prev => [...prev, file.value])
                  }
                }
              } else {
                await whichProvider().request(method, `${baseUrl}/steps/fields`, {
                  project_process_step_id: data.data.id,
                  form_field_id: input.id,
                  value: input.value
                })
              }
            }
          }
        }

        if (comment) {
          await whichProvider().request('post', `${baseUrl}/comments`, {
            project_process_id: projectProcessId,
            project_process_step_id: data.data.id,
            comment
          })
        }

        if (outlet) {
          outlet.refetchProcesses()
        }

        createToast('Process submitted successfully', 'success', dispatch)
        setLoadingSubmit(false)
      } catch (error) {
        createToast('An error has ocurred while trying to send the process', 'danger', dispatch)
        setLoadingSubmit(false)
      }
    }

    if (method === 'put') {
      try {
        const { data } = await whichProvider().request(method, `${baseUrl}/steps/${projectProcessStep.id}`, {
          process_step_id: id,
          project_process_id: projectProcessId,
          status: 1,
          review_status: 0
        })

        for await (const group of groups) {
          for await (const input of group.inputs) {
            if (input.value.length > 0) {
              if (Array.isArray(input.value)) {
                const token = localStorage.getItem("access_token");

                for await (const file of input.value) {
                  if (!file.form_field) {
                    const file_extension = file.value.name.split('.')[file.value.name.split('.').length - 1]
                    const mime_type = mime.lookup(file_extension)

                    try {
                      const { data: signedUrl } = await axios.post(`${baseURL}/api/get-signed-url?extension=${file_extension}&mime_type=${mime_type}&folder=step_fields`)

                      await axios.put(signedUrl.data.file.url, file.value)

                      const bodyFormData = new FormData();

                      bodyFormData.append('value[name]', signedUrl.data.path.split('/')[1])
                      bodyFormData.append('value[path]', signedUrl.data.path)
                      bodyFormData.append('value[original_name]', file.value.name.split('.').slice(0, file.value.name.split('.').length - 1).join('.'))
                      bodyFormData.append('value[type]', file.value.type || file.value.name.split('.')[1])
                      bodyFormData.append('value[size]', file.value.size.toString())
                      bodyFormData.append('value[extension]', signedUrl.data.path.split('.')[1])
                      bodyFormData.append('project_process_step_id', data.data.id.toString())
                      bodyFormData.append('form_field_id', input.id.toString())

                      await axios.post(`${baseURL}/api/projects/processes/steps/fields`, bodyFormData, {
                        timeout: 360000,
                        headers: {
                          'Authorization': `Bearer ${token}`,
                          'Accept': 'application/json',
                          'Content-Type': `multipart/form-data`,
                        },
                      })
                    } catch (error) {
                      createToast("Upload Failed. Specific files couldn't upload, tap edit to retry.", 'danger', dispatch)
                      setErrorFiles(prev => [...prev, file.value])
                    }
                  }
                }
              } else {
                if (input.updateId) {
                  await whichProvider().request(method, `${baseUrl}/steps/fields/${input.updateId}`, {
                    project_process_step_id: data.data.id,
                    form_field_id: input.id,
                    value: input.value
                  })
                } else {
                  await whichProvider().request('post', `${baseUrl}/steps/fields`, {
                    project_process_step_id: data.data.id,
                    form_field_id: input.id,
                    value: input.value
                  })
                }

              }
            }
          }
        }

        if (comment) {
          await whichProvider().request('post', `${baseUrl}/comments`, {
            project_process_id: projectProcessId,
            project_process_step_id: data.data.id,
            comment
          })
        }

        if (outlet) {
          outlet.refetchProcesses()
        }

        createToast('Process updated and submitted successfully', 'success', dispatch)
        setLoadingSubmit(false)
      } catch (error) {
        createToast('An error has ocurred while trying to update the process', 'danger', dispatch)
        setLoadingSubmit(false)
      }
    }
  }

  const renderFields = useMemo(() => {
    return groups?.map((group: any, i: number) => (
      <React.Fragment key={i}>
        {i > 0 && <div className='pt-4 pb-2'><hr className='my-4' /></div>}

        <div className='mt-4 mb-2'>
          <h3 className={`${group.required ? 'text-required' : ''}`}>{group.title}</h3>
          <p>{group.description}</p>
        </div>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))', gap: '2rem' }}>
          {group.inputs.map((field: any) => {
            if (field.code === 'textarea') {
              return (
                <div key={field.id} className='d-flex flex-column w-100 mt-4'>
                  <textarea
                    rows={4}
                    placeholder={`${field.description.replace('(optional)', '')} ${group.required ? '' : '(optional)'}`}
                    style={{ resize: 'none' }}
                    className='p-2'
                    maxLength={255}
                    defaultValue={field.value}
                    onChange={(e) => handleChange(field.id, e.target.value)}
                    disabled={(projectProcessStep?.status > 0 && projectProcessStep?.review_status !== 'Rejected' && diffMinutes() > 30) || review || loadingSubmit || projectProcessStep?.review_status === 'Approved'}
                  />
                  <p>{errors[field.id] || ''}</p>
                </div>
              )
            }

            if (field.code === 'text') {
              return (
                <div key={field.id} className='d-flex flex-column w-100 mb-3'>
                  <label className='o-cl-grey-200 o-ft-sm-400 mb-1 mx-2'>{field.title}</label>
                  <input
                    type="text"
                    placeholder={field.description}
                    className='p-2'
                    maxLength={255}
                    onChange={(e) => handleChange(field.id, e.target.value)}
                    defaultValue={field.value}
                    disabled={(projectProcessStep?.status > 0 && projectProcessStep?.review_status !== 'Rejected' && diffMinutes() > 30) || review || loadingSubmit || projectProcessStep?.review_status === 'Approved'}
                  />
                  <p>{errors[field.id] || ''}</p>
                </div>
              )
            }

            if (field.code === 'file_multiple') {
              return (
                <div key={field.id} className='d-flex flex-column mt-4'>
                  <UploadButton
                    value={field.title}
                    fieldId={field.id}
                    required={field.required}
                    setErrors={setErrors}
                    onChange={handleChange}
                    setRemovedFiles={setRemovedFiles}
                    errorFiles={errorFiles}
                    initialFiles={field.value || []}
                    disabled={(projectProcessStep?.status > 0 && projectProcessStep?.review_status !== 'Rejected' && diffMinutes() > 30) || review || loadingSubmit || projectProcessStep?.review_status === 'Approved'}
                    loadingFiles={loadingFiles}
                    setLoadingFiles={setLoadingFiles}
                  />
                  <span className='o-cl-grey-200 mt-2'>{field.description}</span>
                </div>
              )
            }

            return <></>
          })}
        </div>
      </React.Fragment>
    ))
  }, [groups, diffMinutes])

  return (
    <>
      <form className='w-100'>
        <div>
          {form?.description ?? ''}
        </div>
        {renderFields}
        <div className='d-flex justify-content-between align-items-center mt-4 gap-4'>
          <div className='w-100'>
            {diffMinutes() <= 30 && (projectProcessStep?.status > 0 && (projectProcessStep?.review_status !== 'Rejected' && projectProcessStep?.review_status !== 'Approved')) && (
              <Alert
                type='info'
                title="This form will be available for editing for the next 30 minutes. After that time, you won't be able to edit."
              />
            )}
          </div>
          {!review ? (
            <Button
              value={(projectProcessStep?.status > 0 && (projectProcessStep?.review_status !== 'Rejected' && projectProcessStep?.review_status !== 'Approved')) ? 'Edit' : 'Submit'}
              size='big'
              isLoading={loadingSubmit}
              onClick={() => setSubmitModal(true)}
              disabled={
                (projectProcessStep?.review_status === 'To Review' && diffMinutes() > 30) ||
                groups?.some((group: any) => group.inputs.some((i: any) => i.value.length === 0 && i.required)) ||
                Object.values(errors).some((err) => err) ||
                Object.values(loadingFiles || {}).some((isLoading) => isLoading) ||
                projectProcessStep?.review_status === 'Approved' ||
                loadingSubmit
              }
            />
          ) : (
            <></>
          )}
        </div>
      </form>
      <Modal
        title='Please confirm you are submitting this step'
        onConfirm={handleSubmit}
        onClose={() => setSubmitModal(false)}
        isOpen={submitModal}
        withCloseIcon={false}
        confirmText='Proceed'
      >
        <p className={`mt-2 ${responsive.md ? 'o-ft-md-400' : 'o-ft-xs-400'}`}>Please provide additional information in the field below you may consider relevant for this document</p>
        <textarea
          rows={4}
          className={`p-2 ${responsive.md ? 'mt-4' : 'o-ft-xs-400'}`}
          style={{ resize: 'none' }}
          placeholder='Add a comment (optional)'
          maxLength={255}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Modal>
    </>
  )
}

export default ProcessForm