import React, { useEffect, useRef, useState } from 'react'
import Results, { IFilter } from './shared/Results';

import Filter from './shared/Filter';
// shared components
import Input from './shared/Inputold'
import MaterialIcon from '@components/MaterialIcon';
import classNames from 'classnames';
// @hooks
import { useDebounce } from '@hooks/useDebounce';
import useHandleClickOutside from '@hooks/useHandleClickOutside';

type Props = {
  filters: IFilter[];
  onEnter?: () => void;
  results: { [key: string]: { data: any; meta: any; links: any } };
  isFetching: boolean;
  onType: any;
  onSelectFilter: (filter: IFilter | null) => void
  value: string;
  viewAllBtn?: boolean;
  borderStyle?: boolean;
  placeholder?: string;
  searchResultsTitle: string;
  onSelectItem?: (obj: { id: number; name: string }) => void;
  onFocus?: (value: boolean) => void;
  onClear?: () => void;
}

const SearchOld: React.FC<Props> = ({
  filters,
  onEnter,
  results,
  isFetching,
  onType,
  onSelectFilter,
  value,
  viewAllBtn,
  borderStyle = true,
  placeholder,
  searchResultsTitle,
  onSelectItem,
  onFocus,
  onClear
}) => {
  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const [filter, setFilter] = useState<IFilter | null>(null)
  const searchValue = useDebounce(value, 333)
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    onFocus?.(focus)
  }, [focus, onFocus])

  useHandleClickOutside(ref, () => setFocus(false));

  const hasResults = Object.entries(results).some((value: any) => value[1]?.data.length > 0)

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && focus && value) {
      setFocus(false)
      onEnter?.()
    }
    setFocus(event.key !== 'Enter')
  };

  const iconSearchClasses = classNames({
    'c-search__colored-icon': (focus || hover),
    'text-danger': (!hasResults && !isFetching && searchValue && (focus || hover))
  });

  const searchBarClasses = classNames({
    'c-search--colored-active': (focus || hover),
    'border-danger': (!hasResults && !isFetching && searchValue && (focus || hover)),
    'c-search__border': borderStyle,
  });

  const handleChangeFilter = (filter: IFilter | null) => {
    setFilter(filter);
    setFocus(false);
    onSelectFilter(filter);
  }

  return (
    <div className={`position-relative ${!borderStyle && 'w-100'}`} ref={ref}>
      {/* Search Input */}
      <div
        className={`c-search d-flex align-items-center position-relative ${borderStyle && searchBarClasses}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onFocus={() => setFocus(true)}
        onClick={() => setFocus(true)}
        onKeyDown={handleKeyDown}
      >
        <div className='c-search__search-icon-container d-flex'>
          <MaterialIcon
            color={focus ? 'o-cl-brand-secondary' : 'o-cl-grey-200'}
            icon='search'
            className={iconSearchClasses}
          />
        </div>
        {filter && <Filter filter={filter} onClose={() => { setFilter(null); onSelectFilter(null) }} noIcon />}
        <Input
          ref={inputRef}
          placeholder={placeholder}
          getValue={onType}
          onClear={() => {
            onClear?.()
            inputRef.current?.focus()
          }}
          value={value} />
      </div>

      {/* Search Panel */}
      {focus && (!filter || value || hasResults) && (
        <div className='c-search-bar-result position-absolute'>
          {!filter && <Results.Filters searchResultsTitle={searchResultsTitle} onSelect={handleChangeFilter} filters={filters} />}
          <div className='c-search-bar-result__list'>
            {value && <Results.Info hasResults={hasResults} isLoading={isFetching} value={value} filter={filter} />}
            {value && hasResults && !isFetching && (
              <Results.List
                onClickViewAll={() => setFocus(false)}
                viewAllBtn={viewAllBtn}
                results={results}
                onSelectItem={(item) => {
                  setFocus(false);
                  onSelectItem?.(item);
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchOld