import { Outlet, useParams } from "react-router-dom";
import { useGetVault } from "@redux/vaults/api";
import { images } from "@theme/images";
import useWindowSize from "@hooks/useWindowSize";
import React, { useEffect } from "react";
import { Spinner } from "react-activity";
import { colors } from "@theme/colors";
import useAppDispatch from "@hooks/useAppDispatch";
import { setCurrentVault } from "@redux/vaults/slices/vaultsSlice";

const VaultsLayout = () => {
  const { id } = useParams<{ id: string }>();
  const { responsive } = useWindowSize();
  const dispatch = useAppDispatch();
  const { data: vault, error, isFetching } = useGetVault({ id: Number(id) });

  useEffect(() => {
    if (vault) {
      dispatch(setCurrentVault({ id: vault.data.id, name: vault.data.name }));
    }
  }, [vault]);

  if (error) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center w-100">
        <img
          className="mt-4"
          src={images.errors.error403}
          alt="empty state"
          width={responsive.md ? 350 : 250}
        />
        <h3
          className={`o-cl-dark-grey mt-4 mb-3 ${responsive.md ? "o-ft-2xl-600" : "o-ft-xl-600"}`}
        >
          Access Denied
        </h3>
        <p
          className={`v-my-stores__empty-state-description o-cl-dark-grey text-center ${responsive.md ? "o-ft-lg-400" : "o-ft-sm-400"}`}
        >
          You don&apos;t have access to this vault.
        </p>
      </div>
    );
  }

  if (isFetching) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <Spinner
          className="v-contacts__spinner"
          color={colors.brandColorSecondary}
        />
      </div>
    );
  }

  return <Outlet />;
};

export default VaultsLayout;
