import { CSSProperties, useRef } from 'react';
import useWindowSize from '@hooks/useWindowSize';
import { formatWeight } from '@helpers/formatWeight';
import { TableTrashFile, TableTrashFolder } from './svg';
import { formatDate } from '@helpers/formatDate';
import MaterialIcon, { TIcon } from '@components/MaterialIcon';
import { useRenameElementActive } from '@hooks/useRenameElementActive';
import { useGetDimensionsOfElement } from '@hooks/useGetDimensionsOfElement';
import { formatExtension } from '@helpers/formatExtension';

interface IFileNameProps {
  name: string;
  type?: string;
  size?: number;
  filled?: boolean;
  created_at?: Date;
  rename?: boolean;
  deleted_at?: string;
  isGrayIcon?: boolean;
  icon?: TIcon;
}

const FileName = ({ name, type, created_at, size, filled = false, rename, deleted_at, isGrayIcon, icon }: IFileNameProps) => {

  const isFilled = (!type || filled)
  const _file: TIcon = icon || 'insert_drive_file';
  const { responsive } = useWindowSize();
  const ref = useRef(null);
  const renameInputRef = useRef(null);
  const { width } = useGetDimensionsOfElement(ref);
  const { onKeyDown, onBlur, onClick, value, setValue } = useRenameElementActive(name);

  const iconColors: any = {
    'xls': 'o-cl-green',
    'xlsx': 'o-cl-green',
    'pdf': 'o-cl-red',
    'doc': 'o-cl-blue',
    'docx': 'o-cl-blue',
    'png': 'o-cl-orange',
  }

  const fileNameStyle: CSSProperties = {
    height: 0,
    display: 'inline-block',
    position: 'absolute'
  };

  return (
    <span className='c-file-name d-flex flex-row align-items-center'>
      {deleted_at ?
        type
          ? <TableTrashFile className='o-max-width me-3' />
          : <TableTrashFolder className='o-max-width me-3' />
        :
        <MaterialIcon
          className={`o-max-width me-3 ${isFilled && "material-symbols-filled"}`}
          size={24}
          color={isGrayIcon ? 'o-cl-grey-100 ' : (type ? (iconColors[type] || 'o-cl-purple') : 'o-cl-light-blue')}
          icon={type ? _file : icon || "folder"}
        />
      }
      <span style={fileNameStyle} ref={ref} className='c-file-name__text o-ft-sm-700 o-cl-grey-100'>{name}</span>
      <div className='c-file-name d-flex flex-column'>
        {(rename && responsive.md)
          ? (
            <div className='o-bg-white ps-2 pe-4 o-ft-sm-700 o-cl-grey-100'>
              <input
                ref={renameInputRef}
                {...{ onKeyDown, onClick, onBlur, value }}
                style={{ width: ((width + 5)) + 'px' }}
                className="c-file-name__input o-ft-sm-700 o-cl-grey-100"
                onChange={(f) => setValue(f.target.value)}
                type="text"
                autoFocus
              />
              {(type) ? formatExtension(type) : ""}
            </div>
          )
          : <span className='c-file-name__text o-ft-sm-700 o-cl-grey-100'>
            {`${name}${(type) ? formatExtension(type) : ""}`}
          </span>
        }
        <div className='d-flex o-cl-grey-100 o-ft-sm-400'>
          {size &&
            <span className='c-file-name__text'>
              {formatWeight(size, true)}
              &nbsp;{"-"}&nbsp;
            </span>}
          {created_at &&
            <span className='c-file-name__text'>
              {formatDate(created_at)}
            </span>}
        </div>
      </div>
    </span>
  );
}

export default FileName;
