import React from "react";
import ToggleButton from "react-toggle-button";
import { TglBtnProps } from "../types";
import { colors } from "@theme/colors";

export const TglBtn: React.FC<TglBtnProps> = ({ value, onToggle }) => {
  return (
    <ToggleButton
      inactiveLabel=""
      activeLabel=""
      colors={{
        activeThumb: {
          base: colors.brandColorPrimary,
        },
        inactiveThumb: {
          base: "#565250",
        },
        active: {
          base: "#FFF",
          hover: "#FFF",
        },
        inactive: {
          base: "#FFF",
          hover: "#FFF",
        },
      }}
      value={value}
      onToggle={onToggle}
    />
  );
};
