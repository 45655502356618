import { createJSONStorage, persist } from "zustand/middleware";

import { create } from "zustand";

interface DocumentUploadingProps {
  isUploading: boolean;
}

const initialState = {
  isUploading: false,
};

type DocumentUploadingActions = {
  setUploading: (value: boolean) => void;
  reset: () => void;
};

export const useDocumentUploadingStore = create<
  DocumentUploadingProps & DocumentUploadingActions
>()((set) => ({
  ...initialState,
  setUploading: (value) => set({ isUploading: value }),
  reset: () => set(initialState),
}));
