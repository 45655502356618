import React, { FocusEvent, useEffect, useRef, useState } from 'react'
import _ from 'lodash';
import { Tag } from './Tag';
import classNames from 'classnames';
import Button from '@components/Button'
import MaterialIcon from './MaterialIcon'
import { useNavigate } from 'react-router-dom';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import AdminSearchBarResults from './AdminSearchBarResults';
import { resetSearch } from '@redux/files/slices/fileSlice';
import AdminSearchBarFilters from './AdminSearchBarFilters';
import useHandleClickOutside from '@hooks/useHandleClickOutside';
import { addQuery } from '@redux/results/slices/adminResultSlice';
import { getFilesWithQuery, updateQuery, updateFilter } from '@redux/files/thunks/fileThunk';


type EventType = FocusEvent<any, Element>;

export default function AdminSearchBar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [show, setShow] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [toSearchValue, setToSearchValue] = useState<string>("")
  const [searchedValue, setSearchedValue] = useState<string>("")
  const ref = useRef<null | HTMLDivElement>(null)
  const inputRef = useRef<null | HTMLInputElement>(null)
  const { filter, data, pending } = useAppSelector(state => state.file.search);

  useEffect(() => {
    //Time before starts searching
    if (toSearchValue !== '') {
      const timer = setTimeout(() => setSearchedValue(toSearchValue), 333);
      return () => clearTimeout(timer);
    }
    else {
      setSearchedValue("")
      dispatch(resetSearch())
    }
  }, [toSearchValue])

  useEffect(() => {
    if (searchedValue !== '') {
      dispatch(updateQuery(searchedValue));
      dispatch(getFilesWithQuery({ query: searchedValue, filter: filter ? filter : "q" }));
    }
  }, [searchedValue]);


  useHandleClickOutside(ref, () => {
    setFocus(false)
    setShow(false)
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setToSearchValue(value)
  };

  const clearQuery = () => {
    dispatch(resetSearch())
    setToSearchValue("")
    inputRef.current?.focus()
  }

  const addFilter = (filter?: string) => {
    inputRef.current?.focus()
    dispatch(updateFilter(filter))
    dispatch(updateQuery(searchedValue));
    dispatch(getFilesWithQuery({ query: searchedValue, filter: filter ? filter : "q" }));
  }

  const handleSubmit = (e: any) => {
    if (e.key === 'Enter' && !pending && data.length != 0) {
      dispatch(addQuery(`api/admin/documents/search?filters[${(filter ? filter : "q").toLocaleLowerCase()}]=${searchedValue}`))
      navigate(`/admin/file-management/results`, { replace: true })
    }
  }

  const selectResult = (id: number) => {
    dispatch(addQuery(`api/admin/documents/search?filters[id]=${id}`))
    navigate(`/admin/file-management/results`, { replace: true })
  }

  const buttonClearClasses = classNames({
    'text-danger border-danger': (_.isEmpty(data) && !pending && (toSearchValue && searchedValue) && focus),
  });

  const iconSearchClasses = classNames({
    'text-danger': (_.isEmpty(data) && !pending && (toSearchValue && searchedValue) && focus),
    'c-search__colored-icon': (focus)
  });

  const searchBarClasses = classNames({
    'c-admin-search-bar__search-bar-container--active': (focus),
    'border-danger': (_.isEmpty(data) && !pending && (toSearchValue && searchedValue) && focus),
  });

  return (
    <div ref={ref} className={"position-relative"}>
      <div className={`c-admin-search-bar`} onFocus={(e: EventType) => setFocus(true)} onClick={() => setShow(true)}>
        {/* c-admin-search-bar__search-bar-container--error class when there is no results */}
        <div className={`c-admin-search-bar__search-bar-container d-flex align-items-center ${searchBarClasses}`}>
          <div className='c-admin-search-bar__search-icon d-flex align-items-center'>
            <MaterialIcon
              className={`${iconSearchClasses}`}
              icon='search'
              size={24}
              color='o-cl-grey-200'
            />
          </div>
          {filter && (
            <Tag
              tag={{ id: filter, name: filter }}
              className={"me-2"}
              documentId={0} onClick={() => { addFilter() }}
            />
          )}
          <input
            className="c-admin-search-bar__text-input o-ft-sm-300" type="text"
            value={toSearchValue}
            placeholder='Search'
            onChange={onChange}
            onKeyDown={handleSubmit}
            ref={inputRef}
          />
          {(focus || toSearchValue) &&
            <div className='c-admin-search-bar__delete-button'>
              <Button
                size='small'
                value='Clear'
                onClick={clearQuery}
                className={buttonClearClasses}
                {...(!toSearchValue && { disabled: true })}
              />
            </div>
          }
        </div>
      </div>
      {focus && show && (
        <>
          <AdminSearchBarFilters addFilter={addFilter} />
          {toSearchValue && <AdminSearchBarResults searchedText={toSearchValue} onClick={selectResult} />}
        </>
      )}
    </div>
  )
}


