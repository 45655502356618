import React from 'react'
interface IFormDescriptionProps {
  text: string;
  className?: string;
}

export default function FormDescription({ text, className }: IFormDescriptionProps) {
  return (
    <div className={`c-form-description text-center ${className}`}>
      <span className='c-form-description__text o-ft-sm-400'>{text}</span>
    </div>
  )
}
