import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const getHeaders = () => {
  const token = localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json`,
  };
};
const baseUrl = process.env.REACT_APP_BACK_BASE_URL;
export const OnboardingCompaniesApi = createApi({
  reducerPath: "OnboardingCompaniesApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getCompanies: builder.query<any, any>({
      query: ({ search }) => ({
        url: `api/companies?filters[q]=${search}&perPage=10`,
        headers: getHeaders(),
      }),
      transformResponse: (res: any) => res.data,
    }),
    addCompanies: builder.mutation<any, void>({
      query: () => ({
        url: "api/companies",
        method: "POST",
        body: {},
        headers: getHeaders(),
      }),
      transformResponse: (res: any) => res.data,
    }),
  }),
});
export const {
  useGetCompaniesQuery: useGetCompanies,
  useAddCompaniesMutation: usePostCompanies,
} = OnboardingCompaniesApi;
