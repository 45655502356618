import React, { useEffect, useRef, useState } from 'react'

import Button from '@components/Button'
import useWindowSize from '@hooks/useWindowSize'
import { Card } from './types'
import FormCard from './shared/FormCard'

import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { DndContext, closestCenter } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {
  useDeleteField,
  useDeleteGroup,
  useGetForm,
  usePostField,
  usePostForm,
  usePostGroup,
  useUpdateField,
  useUpdateForm,
  useUpdateGroup
} from '@redux/forms/api'
import { Spinner } from 'react-activity'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { createToast } from '@helpers/createToast'
import useAppDispatch from '@hooks/useAppDispatch'

export type Values = {
  title: string;
  description: string;
  cards: Card[]
}

const schema = yup
  .object<any>({
    title: yup.string().required().max(255),
    description: yup.string().max(255),
    cards: yup.array()
      .of(
        yup.object().shape({
          id: yup.number(),
          card_id: yup.number(),
          title: yup.string().required().max(255),
          description: yup.string().max(255),
          order: yup.number(),
          inputs: yup.array().of(
            yup.object({
              id: yup.number(),
              code: yup.string(),
              title: yup.string(),
              description: yup.string()
            })).min(1).required(),
          required: yup.boolean().required()
        })
      )
  })
  .required()

const generateId = () => Math.floor(Math.random() * Date.now())

const Create: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { responsive } = useWindowSize()

  const dispatch = useAppDispatch()

  const { form, isLoading }: any = useGetForm(searchParams.get('id'))
  // const { form, isLoading }: any = useGetForm(params.id || '')

  const [saving, setSaving] = useState(false)

  const methods = useForm<Values>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    values: {
      title: form?.title,
      description: form?.description ?? '',
      cards: form?.cards
    }
  })

  const bottomEl = useRef<HTMLDivElement>(null);
  
  const { register, handleSubmit, reset, getValues, formState, control, watch } = methods
  const { fields, append, remove, update, move } = useFieldArray({ name: "cards", control })
  const { errors, isValid, isSubmitting, isDirty, isSubmitted, isSubmitSuccessful } = formState

  const [addForm] = usePostForm()
  const [addGroup] = usePostGroup()
  const [addField] = usePostField()

  const [updateForm] = useUpdateForm()
  const [updateGroup] = useUpdateGroup()
  const [updateField] = useUpdateField()

  const [deleteGroup] = useDeleteGroup()
  const [deleteField] = useDeleteField()

  const [warning, setWarning] = useState<Card | undefined>()
  const [removedIds, setRemovedIds] = useState<number[]>([])

  useEffect(() => {
    reset(getValues())
  }, [isSubmitSuccessful])

  const add = () => {
    if (fields.some(field => field.inputs.length === 0)) {
      setWarning(fields.find(field => field.inputs.length === 0))
      return
    }

    append({
      id: generateId(),
      title: '',
      description: '',
      required: false,
      inputs: [],
      order: fields.length + 1
    },
    {
      shouldFocus: responsive.md
    })
  }

  const copy = (card: Card) => {
    append({ ...card, card_id: generateId(), order: fields.length + 1 })
  }

  const handleRemove = (index: number, id?: number) => {
    if (id) setRemovedIds(prev => [...prev, id])
    remove(index)
  }

  const onSubmit = async ({ title, description, cards }: Values) => {
    setSaving(true)

    try {
      if (form) {
        if (form.title !== title || form.description !== description) {
          await updateForm({ id: form.id, title, description })
        }

        form.cards.forEach(async (prevCard: Card) => {
          const existentCardIndex = cards.findIndex(c => c.card_id === prevCard.card_id)
          const existentCard = cards.find(c => c.card_id === prevCard.card_id)

          if (existentCard) { // existe entonces actualizo
            if (JSON.stringify(prevCard) !== JSON.stringify(existentCard)) {
              await updateGroup({
                id: prevCard.card_id,
                title: existentCard.title,
                description: existentCard.description,
                order: existentCardIndex
              })

              prevCard.inputs.forEach(async (input, i) => {
                if (i > 0 && existentCard.inputs.length === 1) {
                  await deleteField(input.id)
                  return
                }

                await updateField({
                  id: existentCard.inputs[i].id,
                  title: existentCard.inputs[i].title,
                  description: existentCard.inputs[i].description,
                  field_code: existentCard.inputs[i].code,
                  is_required: existentCard.required
                })
              })

              existentCard.inputs.forEach(async (input: any, i) => {
                if (!input.id) {
                  await addField({
                    form_group_id: prevCard.card_id,
                    title: input.title,
                    description: input.description,
                    field_code: input.code,
                    is_required: existentCard.required
                  })
                }
              })
            }

            return
          }

          if (removedIds.includes(prevCard.card_id!)) {
            await deleteGroup(prevCard.card_id)
            prevCard.inputs.forEach(async (input) => await deleteField(input.id))
          }
        })

        const prevCardsId: number[] = form.cards.map((c: Card) => c.card_id)
        const newCards = cards.filter(c => !prevCardsId.includes(c.card_id!))

        if (newCards.length) {
          // fue creado
          newCards.forEach(async (newCard, i) => {
            const group = await addGroup({
              form_id: form.id,
              title: newCard.title,
              description: newCard.description,
              order: newCard.order
            }).unwrap()

            for await (const input of newCard.inputs) {
              await addField({
                form_group_id: group.id,
                title: input.title,
                description: input.description,
                field_code: input.code,
                is_required: newCard.required
              })
            }
          })
        }

        setSaving(false)

        const text = `<strong>Form saved</strong><br>The form created has been saved successfully!`
        createToast(text, "success", dispatch)

        return
      }

      const submitedForm: any = await addForm({ title, description }).unwrap()

      for await (const [i, card] of cards.entries()) {
        const group: any = await addGroup({
          form_id: submitedForm.id,
          title: card.title,
          description: card.description,
          order: i
        }).unwrap()

        for await (const input of card.inputs) {
          await addField({
            form_group_id: group.id,
            title: input.title,
            description: input.description,
            field_code: input.code,
            is_required: card.required
          })
        }
      }
      setSaving(false)

      const text = `Form Saved <br/> The form created has been saved successfully!`
      createToast(text, "success", dispatch)

      navigate('/admin/all-forms')
    } catch (error) {
      setSaving(false)

      const text = 'Something went wrong while processing your request. Please try again.'
      createToast(text, "danger", dispatch)
    }
  };

  const onDragEnd = (e: any) => {
    const { active, over } = e

    if (active?.id === over?.id) return

    const oldIndex = fields.findIndex(field => field.id === active.id)
    const newIndex = fields.findIndex(field => field.id === over.id)
    move(oldIndex, newIndex)

    getValues().cards.forEach((_, i) => {
      update(i, { ...getValues().cards[i], order: i })
    })
  }

  const onMove = (oldIndex: number, newIndex: number) => {
    move(oldIndex, newIndex)

    getValues().cards.forEach((_, i) => {
      update(i, { ...getValues().cards[i], order: i })
    })
  }

  const values = watch()

  if (isLoading) {
    return (
      <div className='d-flex align-items-center justify-content-center mt-5'>
        <Spinner size={24} />
      </div>
    )
  }

  return (
    <>
      <p className='o-ft-2xl-400 mb-4 mt-5 o-spacing'>General Form Information</p>
      <FormProvider {...methods}>
        <form className='d-flex flex-column w-100 mb-4 admin-form o-spacing'>
          <div className='d-flex flex-column mb-3'>
            <input {...register('title')} placeholder='Enter a title' />
            <div className={`d-flex ${errors.title ? 'justify-content-between' : 'justify-content-end'}`}>
              {errors.title && <span className='o-cl-red'>{errors.title.message}</span>}
              <span className='o-cl-grey-200 o-ft-sm-400'>Characters: {values.title?.length}/255</span>
            </div>
          </div>
          <div className={`d-flex flex-column ${responsive.md ? 'mb-5' : ''}`}>
            <input {...register('description')} placeholder='Enter a description (optional)' />
            <div className="d-flex justify-content-end">
              <span className='o-cl-grey-200 o-ft-sm-400'>Characters: {values.description?.length}/255</span>
            </div>
          </div>

          <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
            <SortableContext items={fields} strategy={verticalListSortingStrategy} disabled={!responsive.md}>
              {fields.map((card: Card, index: number) => (
                  <FormCard
                    index={index}
                    card={card}
                    copy={copy}
                    remove={() => handleRemove(index, card.card_id)}
                    update={update}
                    warning={warning?.id === card.id}
                    setWarning={setWarning}
                    onMove={!responsive.md ? onMove : undefined}
                    key={card.id}
                  />
              ))}
            </SortableContext>
          </DndContext>
        </form>
      </FormProvider>

      <div ref={bottomEl}></div>

      <div className='d-flex justify-content-between align-items-center mb-5 o-spacing'>
        <Button
          value='Add New Card'
          onClick={add}
          icon={{ icon: 'add', position: 'right', size: 20 }}
        />
        <Button
          value={saving ? 'Saving' : 'Save Form'}
          onClick={handleSubmit(onSubmit)}
          size={responsive.md ? 'big' : 'medium'}
          className='admin-form__submit-button'
          disabled={!isValid || isSubmitting || !isDirty || isSubmitted}
        />
      </div>
    </>
  )
}

export default Create
