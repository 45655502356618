const arrExceptions = ["and", "of"];

export const formatCapitalizeFirstLetter = (str: string) => {
  const arrStr = str?.split(" ");

  const res = arrStr?.map((s: string) => {
    if (arrExceptions.includes(s)) return s.toLowerCase();
    else return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1);
  });
  return res?.join(' ');
}

