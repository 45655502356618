/* eslint-disable global-require */
import background from "../brand/assets/background.jpg";
import cardBackground from "../brand/assets/card-background.jpg";
import error400 from "../brand/assets/errors/error-400.svg";
import error401 from "../brand/assets/errors/error-401.svg";
import error403 from "../brand/assets/errors/error-403.svg";
import error404 from "../brand/assets/errors/error-404.svg";
import error500 from "../brand/assets/errors/error-500.svg";
import logo from "../brand/assets/logo.png";
import verticalLogo from "../brand/assets/vertical-logo.png";

let tenantLogo;

try {
  tenantLogo = require("../brand/assets/tenant_logo.png");
} catch (error) {
  tenantLogo = null;
}

export const images = {
  brandLogo: logo,
  tenantLogo,
  brandBackground: background,
  verticalBrandLogo: verticalLogo,
  sectionModuleBackground: cardBackground,
  errors: {
    error400,
    error401,
    error403,
    error404,
    error500,
  },
};
