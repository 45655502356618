import { TTagData } from "@models/Tag.type";

interface ITagsPopUpProps {
  list: TTagData;
  tags: string;
  documentId: number;
}

export const TagsPopUp = ({ list, tags, documentId }: ITagsPopUpProps) => {
  return (
    <div className='c-tags-pop-up position-relative'>
      <span className='c-tags-pop-up__tags o-ft-sm-400 o-cl-grey-200'>{tags}</span>
      <div className='c-tags-pop-up__list position-absolute'>
        {
          list[documentId].map(({ id, name }) => (
            <span key={id} className='c-tags-pop-up__item o-cl-grey-100 o-ft-sm-400'>#{name}</span>
          ))
        }
      </div>
    </div>
  );
}
