import React, { useEffect, useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import useWindowSize from "@hooks/useWindowSize";
import { IFileResponse } from "@models/files/File.type";
import { formatCapitalizeFirstLetter } from "@helpers/formatCapitalizeFirstLetter";

interface IBreadcrumb {
  folder: IFileResponse;
}

const AdminBreadcrumb = ({ folder }: IBreadcrumb) => {

  const baseURL = "/admin/file-management"
  const { responsive } = useWindowSize();

  const [breadcrumbItems, setBreadcrumbItems] = useState<any[]>([])

  useEffect(() => {
    (folder.name) ?
      folder.parents ?
        setBreadcrumbItems([{ name: "File Management" }, ...folder.parents, folder])
        :
        setBreadcrumbItems([{ name: "File Management" }, folder])
      :
      setBreadcrumbItems([{ ...folder, name: "File Management" }])
  }, [folder])

  return (
    <div className="c-breadcrumb d-flex flex-row w-100">
      {(breadcrumbItems.length < 4 || (responsive.lg && breadcrumbItems.length < 5))
        ?
        <>
          {breadcrumbItems.slice(0, breadcrumbItems.length - 1).map((item, index) => {
            let name = formatCapitalizeFirstLetter(item?.name);
            return (
              <Fragment key={index}>
                <NavLink className="c-breadcrumb__text o-ft-base-400 d-none d-md-inline" to={`${baseURL}/${item.id ? item.id : ""}`}>{name}</NavLink>
                <span className="c-breadcrumb__separator d-none d-md-flex">/</span>
              </Fragment>
            )
          })}
          <span className="c-breadcrumb__text--active d-none d-md-inline">{formatCapitalizeFirstLetter(breadcrumbItems[breadcrumbItems.length - 1]?.name)}</span>
        </>
        :
        <>
          <NavLink className="c-breadcrumb__text o-ft-base-400 d-none d-md-flex flex-shrink-0" to={`${baseURL}/`}>{formatCapitalizeFirstLetter(breadcrumbItems[0]?.name)}</NavLink>
          <div className='c-breadcrumb__pop-up d-flex position-relative flex-shrink-0'>
            <span className="c-breadcrumb__separator d-none d-md-flex o-cursor-pointer flex-shrink-0">/... /</span>
            <div className='c-breadcrumb__pop-up-list position-absolute'>
              {
                breadcrumbItems.slice(1, breadcrumbItems.length - (responsive.xl ? 2 : 1)).map((route, index) => (
                  <NavLink key={index} className="c-breadcrumb__pop-up-item o-ft-base-400 o-cl-grey-100" to={`${baseURL}/${route.id}`}>{formatCapitalizeFirstLetter(route.name)}</NavLink>
                ))
              }
            </div>
          </div>
          {responsive.xl &&
            <>
              <NavLink className="c-breadcrumb__text o-ft-base-400 d-none d-md-flex flex-shrink-0" to={`${baseURL}/${breadcrumbItems[breadcrumbItems.length - 2].id}`}>{formatCapitalizeFirstLetter(breadcrumbItems[breadcrumbItems.length - 2]?.name)}</NavLink>
              <span className="c-breadcrumb__separator d-none d-md-flex flex-shrink-0">/</span>
            </>
          }
          <span className="c-breadcrumb__text--active d-none d-md-inline flex-shrink-1">{formatCapitalizeFirstLetter(breadcrumbItems[breadcrumbItems.length - 1]?.name)}</span>
        </>
      }
    </div>
  );
};

export default AdminBreadcrumb;
