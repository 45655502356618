import styled from "styled-components";
import { colors } from "@theme/colors";

export const InputSubtitle = styled.label<{ focused?: boolean }>`
  color: ${(props) =>
    props.focused ? colors.brandColorPrimary : colors.grey200};
  font-family: Open Sans;
  margin-left: 7px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
`;
