import React from "react";
import PinUnpinProvider from "../providers/PinUnpinProvider";
import MainResultsSection from "../sections/MainResults/MainResultsSection";

const MainResults: React.FC = () => {
  return (
    <PinUnpinProvider context="results">
      <MainResultsSection />
    </PinUnpinProvider>
  );
};

export default MainResults;
