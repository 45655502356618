import React, { ReactElement } from 'react';
import { IFilterDropdownItem } from '@components/filters/FilterDropdownList';

interface IFilterDropdownProps {
  children?: ReactElement | ReactElement[];
  title?: string;
}

export interface IFilterDropdown {
  title: string;
  data: IFilterDropdownItem[];
}

export const FilterDropdown = ({ children, title = 'Filters' }: IFilterDropdownProps) => {
  return (
    <div className='c-floating-filter-view'>
      <span className='c-floating-filter-view__title o-ft-2xl-400'>{title}</span>
      {children}
    </div>
  );
}

