import { useContext } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { SortContext } from '@components/filters/Sort';

export interface ISortListProps {
  onChange: ({ sortId, label }: ISortListItem) => void;
}

export interface ISortListItem {
  sortId: number | string;
  label: string;
}

export const SortList = ({ onChange }: ISortListProps) => {
  const { sort: { id } } = useContext(SortContext);

  const orderingFilters = [
    { orderingId: 1, label: "Newest" },
    { orderingId: 2, label: "Oldest" },
    { orderingId: 3, label: "A-Z" },
    { orderingId: 4, label: "Z-A" },
  ];

  return (
    <div className='c-order-list position-absolute'>
      <ul className='p-0 m-0'>
        {orderingFilters.map((value) => {
          const { orderingId: sortId, label } = value;
          return (
            <li key={sortId} onClick={() => onChange({ sortId, label })}
              className={`c-order-list__item o-ft-sm o-cursor-pointer ${classNames({ 'c-order-list__item--active': _.isEqual(id, sortId) })}`}>{label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
