/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "@api/AdminHttpProvider";

const baseUrl = process.env.REACT_APP_BACK_BASE_URL;

export const sectionApi = createApi({
  reducerPath: "sectionApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token =
        localStorage.getItem("access_token") || getCookie("access_token");
      if (token) headers.set("Authorization", `Bearer ${token}`);
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSidebarItems: builder.query<any, void>({
      query: () => ({ url: "api/v2/sections/side-bar" }),
    }),
    getSidebarSubItem: builder.query<any, { sectionId: number | null }>({
      query: ({ sectionId }) => ({
        url: `api/v2/sections/${sectionId}/subsections`,
      }),
    }),
    getMobileMenuSections: builder.query<any, void>({
      query: () => ({ url: "api/v2/sections/bottom-bar" }),
    }),
    getDashboardSections: builder.query<any, void>({
      query: () => ({ url: "api/v2/sections/dashboard" }),
    }),
    addSectionDocument: builder.mutation<any, { sectionableId: number }>({
      query: ({ sectionableId }) => ({
        url: `api/v2/sections/documents`,
        method: "POST",
        body: {
          sectionable_id: sectionableId,
        },
      }),
    }),
  }),
});

export const {
  useGetMobileMenuSectionsQuery: useGetMobileMenuSections,
  useGetSidebarItemsQuery: useGetSidebarItems,
  useGetDashboardSectionsQuery: useGetDashboardSections,
  useGetSidebarSubItemQuery: useGetSidebarSubItem,
  useAddSectionDocumentMutation: useAddSectionDocument,
} = sectionApi;
