/* eslint-disable react/button-has-type */
import "react-activity/dist/library.css";
import React from "react";

import styled from "styled-components";
import {
  Dropdown,
  Button,
  ButtonGroup as ButtonGroupUI,
} from "react-bootstrap";
import colors from "../brand/theme/colors";
import { Spinner } from "react-activity";

const CustomDropdownItem = styled(Dropdown.Item)`
  &.dropdown-item {
    color: black;
    background-color: white;
    font-weight: 400;
    font-size: 16px;
    border-left: 3px solid white;
    &:hover {
      border-left: 3px solid ${colors.brandColorPrimary};
      background-color: white;
      font-weight: 600;
    }
  }
`;
interface IAction {
  onClick: () => void;
  value: string;
}
interface IButtonGroupProps {
  primaryAction: IAction;
  otherActions: IAction[];
  isDisabled: boolean;
  isLoading?: boolean;
}

const ButtonGroup = ({
  primaryAction,
  otherActions,
  isDisabled,
  isLoading,
}: IButtonGroupProps) => {
  return (
    <Dropdown as={ButtonGroupUI}>
      <Button
        onClick={primaryAction.onClick}
        disabled={isDisabled}
        variant="franly"
        className="d-flex align-items-center"
      >
        {primaryAction.value}{" "}
        {isLoading && (
          <span>
            <Spinner className="c-button__spinner" />
          </span>
        )}
      </Button>
      <Dropdown.Toggle
        split
        id="dropdown-split-basic"
        className="o-bg-brand-secondary:hover o-cl-brand-secondary o-cl-white:hover"
        variant="franly"
      />
      <Dropdown.Menu>
        {otherActions.map((option) => (
          <CustomDropdownItem onClick={option.onClick} disabled={isDisabled}>
            {option.value}
          </CustomDropdownItem>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ButtonGroup;
