/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import TextLink from "@components/TextLink";
import TextTimer from "@components/TextTimer";

interface ValidateTwoFALinksI {
  isTimerActive: any;
  setIsTimerActive: any;
  resendCode: any;
  emailTo: any;
  subject: any;
  emailBody: any;
}

const ValidateTwoFALinks: React.FC<ValidateTwoFALinksI> = ({
  isTimerActive,
  setIsTimerActive,
  resendCode,
  emailTo,
  subject,
  emailBody,
}) => {
  return (
    <div>
      <div className="d-flex justify-content-center mt-4">
        {isTimerActive ? (
          <div className="d-flex flex-row justify-content-center mb-2">
            <span className="c-form-links__bottom-label text-center">
              Didn’t receive anything?{" "}
              <span className="o-cl-grey-200">
                Resend a new code in{" "}
                <TextTimer
                  onTimeOut={() => setIsTimerActive(false)}
                  seconds={120}
                />
              </span>
            </span>
          </div>
        ) : (
          <div className="d-flex flex-row justify-content-center mb-2">
            <span className="c-form-links__bottom-label__2fa text-center">
              Didn’t receive anything?{" "}
              <button
                type="button"
                onClick={resendCode}
                className="c-text-button"
              >
                Resend New Code
              </button>
            </span>
          </div>
        )}
      </div>
      <div className="d-flex flex-row justify-content-center">
        <span className="c-form-links__bottom-label__2fa text-center">
          Problems with phone number?{" "}
          <TextLink
            text="Contact the administrator"
            type="href"
            route={`mailto:${emailTo}?subject=${subject}&body=${emailBody}`}
          />
        </span>
      </div>
    </div>
  );
};

export default ValidateTwoFALinks;
