import MaterialIcon, { TIcon } from "@components/MaterialIcon";
import React, { ReactElement, createContext, useContext } from "react";
import { TModuleTypes, TSwitchModeTypes } from "@models/Global.type";

import { TActionToggle } from "@redux/slices/toggleSlice";
import _ from "lodash";
import { setViewMode } from "@slices/toggleSlice";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";

interface ISwitchModeProps {
  children: ReactElement | ReactElement[];
  module: TModuleTypes;
}

interface ISwitchModeContextProps {
  module: TModuleTypes;
}

interface ISwitchModeIconProps {
  mode: TSwitchModeTypes;
  type?: "mobile";
}

const SwitchModeContext = createContext({} as ISwitchModeContextProps);

const SwitchModeIcon = ({ mode, type }: ISwitchModeIconProps) => {
  const { views } = useAppSelector((state) => state.toggle);
  const { module } = useContext(SwitchModeContext);
  const dispatch = useAppDispatch();
  const toggle: TActionToggle = { mode, module };
  let active = views[module][mode];

  if (_.isEqual(type, "mobile")) {
    active = !active;
  }

  const modeIcons = {
    grid: "grid_on" as TIcon,
    list: "format_list_bulleted" as TIcon,
  };

  const onClick = () => dispatch(setViewMode(toggle));

  return (
    <MaterialIcon
      onClick={onClick}
      className="o-cursor-pointer"
      icon={modeIcons[mode]}
      size={24}
      color={`${active ? "o-cl-brand-secondary" : "o-cl-grey-100"} `}
    />
  );
};

const SwitchMode = ({ children, module }: ISwitchModeProps) => {
  const { Provider } = SwitchModeContext;

  return <Provider value={{ module }}>{children}</Provider>;
};

SwitchMode.Icon = SwitchModeIcon;

export default SwitchMode;
