/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import { ProcessStep } from "@components/ProcessStep";
import { Spinner } from "react-activity";
import { colors } from "@theme/colors";
import { getStepsByProcess } from "@redux/approval-process/thunks/processThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import Header from "./Section/shared/Header";

const ApprovalProcessSteps = () => {
  const { id } = useParams();

  const {
    process: { data, steps, stepsPending, pending },
  } = useAppSelector((state) => state);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const [currentProcess, setCurrentProcess] = useState<any>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getStepsByProcess({ id: Number(id) }));
  }, [dispatch, location, searchParams]);

  const renderSteps = steps.map((step, i) => (
    <ProcessStep key={step.id} index={i + 1} step={step} />
  ));

  useEffect(() => {
    if (data.length > 0) {
      setCurrentProcess(data.find((process) => process.id === Number(id)));
    }
  }, [data, id]);

  return (
    <div className="o-screen-container">
      <Header
        isLoading={pending}
        actions={[]}
        title={currentProcess?.name || ""}
        withGoBack
        breadcrumb={[
          {
            name: "Processes",
            id: 0,
            route: "/processes",
          },
          {
            name: currentProcess?.name,
            id: currentProcess?.id,
            route: "/",
          },
        ]}
      />
      <div className="o-ft-base-400">{currentProcess?.description}</div>
      {stepsPending ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner
            className="v-contacts__spinner"
            color={colors.brandColorSecondary}
          />
        </div>
      ) : (
        renderSteps
      )}
    </div>
  );
};

export default ApprovalProcessSteps;
