import { DocumentsList, ShowDocument } from "@views/Documents";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Navigate,
  Route,
  Routes,
  useLocation,
  useRoutes,
} from "react-router-dom";
import { ReactElement, useEffect, useState } from "react";

import ApprovalProcess from "@views/ApprovalProcess";
import ApprovalProcessSteps from "@views/ApprovalProcessSteps";
import BottomTabNavigator from "@components/BottomTabNavigator";
import Contacts from "@views/Contacts";
import Dashboard from "@views/Dashboard";
import { Error } from "@views/Error";
import ErrorView from "@views/ErrorView";
import Favorites from "@views/Favorites";
import FolderLayout from "@views/Folder/Layout";
import Header from "@components/Header";
import Logout from "@views/Logout";
import MainResults from "@views/MainResults";
import MyStores from "@views/MyStores";
import NotionPage from "@views/Notion";
import Sidebar from "@components/Sidebar";
import Store from "@views/MyStores/shared/Store";
import StoreProcess from "@views/MyStores/shared/Process";
import StoreProject from "@views/MyStores/shared/Project";
import StoresLayout from "@views/MyStores/Layout";
import Usersnap from "@components/Usersnap";
import { UsersnapProvider } from "../UsersnapContext";
import Vault from "@views/Vault";
import VaultDocuments from "@views/VaultDocuments";
import Vaults from "@views/Vaults";
import { isDocumentPath } from "../utils/isDocumentPath";
import useWindowSize from "@hooks/useWindowSize";
import VaultsLayout from "@sections/Vaults/VaultsLayout";

export const HomeRouter = () => {
  const { responsive } = useWindowSize();
  const [showSidebar, setShowSidebar]: any[] = useState(true);
  const location = useLocation();

  useEffect((): void => {
    localStorage.setItem("current_website", "platform");
  }, []);

  const RenderErrorRoutes = () => {
    const element = useRoutes([
      {
        path: "/error-401",
        element: (
          <Error
            code="401"
            onMounted={() => setShowSidebar(false)}
            onUnmounted={() => setShowSidebar(true)}
          />
        ),
      },
    ]);
    return element;
  };

  const renderNotFoundRoute: ReactElement = (
    <Route
      path="*"
      element={
        <ErrorView
          statusCode={404}
          onMounted={() => setShowSidebar(false)}
          onUnmounted={() => setShowSidebar(true)}
        />
      }
    />
  );

  const renderRoutes = (
    <Route path="/">
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/results" element={<MainResults />} />
      <Route path="/favorites" element={<Favorites />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/processes" element={<ApprovalProcess />} />
      <Route path="/processes/:id" element={<ApprovalProcessSteps />} />
      <Route path="/document/:id" element={<ShowDocument />} />
      <Route path="/documents/:id" element={<DocumentsList />} />
      <Route path="/help-resources" element={<NotionPage />}>
        <Route path=":id" />
      </Route>
      <Route path="/stores" element={<StoresLayout />}>
        <Route index element={<MyStores />} />
        <Route path=":storeName-:storeId" element={<Store />} />
        <Route
          path=":storeName-:storeId/:projectName-:projectId"
          element={<StoreProject />}
        />
        <Route
          path=":storeName-:storeId/:projectName-:projectId/:processName-:processId"
          element={<StoreProcess />}
        />
      </Route>
      <Route path="/vaults" element={<FolderLayout />}>
        <Route index element={<Vaults />} />
        <Route path=":id" element={<VaultsLayout />}>
          <Route index element={<Vault />} />
          <Route path="documents/*" element={<VaultDocuments />} />
        </Route>
      </Route>
      <Route path="" element={<Navigate replace to="/login" />} />
    </Route>
  );

  return (
    <UsersnapProvider initParams={undefined}>
      <Usersnap>
        <div id="appContainer" className="position-fixed w-100">
          {!isDocumentPath(location.pathname) && <Header />}
          <div className="o-app-container d-flex">
            {showSidebar && !isDocumentPath(location.pathname) && <Sidebar />}
            <div className="o-app-container__views w-100">
              <RenderErrorRoutes />
              <Routes>
                <>
                  {renderNotFoundRoute}
                  {renderRoutes}
                </>
              </Routes>
            </div>
            {!responsive.md && <BottomTabNavigator />}
          </div>
        </div>
      </Usersnap>
    </UsersnapProvider>
  );
};
