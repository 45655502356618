/* eslint-disable import/no-named-as-default */
import filesSlice, { filesApi } from "@redux/files/slices/fileSlice";

import { NdaApi } from "./nda-modal/api";
import { OnboardingCompaniesApi } from "./onboarding/api";
import adminResultSlice from "@redux/results/slices/adminResultSlice";
import { brandsApi } from "./brands/api";
import { configureStore } from "@reduxjs/toolkit";
import { contactsApi } from "./contacts/api";
import { documentsApi } from "./documents/api";
import documentsSlice from "./documents/documentsSlice";
import favoriteSlice from "@redux/favorites/slices/favoriteSlice";
import { formsApi } from "./forms/api";
import loaderSlice from "./loader/loader.slice";
import { myStoresApi } from "@redux/my-stores/api";
import myStoresSlice from "@redux/my-stores/slice";
import { myVaultsApi } from "./vaults/api";
import { processReviewApi } from "./approval-process/api";
import processSlice from "@redux/approval-process/slices/processSlice";
import { searchApi } from "./search/api";
import searchSlice from "@redux/search/slice";
import { sectionApi } from "./sections/api";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import tagSlice from "@redux/tags/slices/tagSlice";
import toastsSlice from "@redux/toasts/slices/toastsSlice";
import toggleSlice from "@slices/toggleSlice";
import trashSlice from "@redux/trash/slices/trashSlice";
import userSlice from "@redux/users/slices/userSlice";
import vaultsSlice from "./vaults/slices/vaultsSlice";

export const store = configureStore({
  // the states of our application
  reducer: {
    loader: loaderSlice,
    user: userSlice,
    toggle: toggleSlice,
    toasts: toastsSlice,
    file: filesSlice,
    documents: documentsSlice,
    favorites: favoriteSlice,
    trash: trashSlice,
    tags: tagSlice,
    process: processSlice,
    adminResults: adminResultSlice,
    search: searchSlice,
    myStores: myStoresSlice,
    vaults: vaultsSlice,
    [filesApi.reducerPath]: filesApi.reducer,
    [myStoresApi.reducerPath]: myStoresApi.reducer,
    [myVaultsApi.reducerPath]: myVaultsApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [formsApi.reducerPath]: formsApi.reducer,
    [processReviewApi.reducerPath]: processReviewApi.reducer,
    [contactsApi.reducerPath]: contactsApi.reducer,
    [sectionApi.reducerPath]: sectionApi.reducer,
    [documentsApi.reducerPath]: documentsApi.reducer,
    [brandsApi.reducerPath]: brandsApi.reducer,
    [NdaApi.reducerPath]: NdaApi.reducer,
    [OnboardingCompaniesApi.reducerPath]: OnboardingCompaniesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      filesApi.middleware,
      myStoresApi.middleware,
      myVaultsApi.middleware,
      searchApi.middleware,
      formsApi.middleware,
      processReviewApi.middleware,
      contactsApi.middleware,
      sectionApi.middleware,
      brandsApi.middleware,
      NdaApi.middleware,
      documentsApi.middleware,
      OnboardingCompaniesApi.middleware,
    ]),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
