import React, { useEffect } from 'react'
import _ from 'lodash';
import useWindowSize from '@hooks/useWindowSize';
import { useHighLight } from '@hooks/useHighlight';

interface IOnboardingTooltipProps {
  title: any;
  state: 1 | 2 | 3 | 4 | 5 | 6;
  button: string;
  btnBack: boolean;
  className?: string;
  description: string;
  onHandleSkip: () => void;
  onHandleBack: () => void;
  onHandleNext: () => void;
  arrowMobile?: 'top-right' | 'top-left' | 'left' | 'right' | 'bottom';
  arrowDesktop?: 'top-right' | 'top-left' | 'left' | 'right' | 'bottom';
}
export default function OnboardingTooltip({ title, state, description, btnBack, button, className, arrowMobile, arrowDesktop, onHandleSkip, onHandleBack, onHandleNext }: IOnboardingTooltipProps) {

  const { responsive } = useWindowSize();
  const {
    highlightElement,
    getElementMeasures,
    removeHighlightElement
  } = useHighLight();

  const amountOfDots = 5;

  const activeDots = (dot: number) => {
    if (_.isEqual(dot, state - 1))
      return true
  }

  useEffect(() => {
    switch (state) {
      case 1:
        removeHighlightElement("favoritesOnboarding");
        if (responsive.md)
          highlightElement("searchBarOnboarding");
        else
          highlightElement("searchIconOnboarding");
        break;
      case 2:
        removeHighlightElement("searchIconOnboarding");
        removeHighlightElement("searchBarOnboarding");
        removeHighlightElement("dashboardOnboarding");
        removeHighlightElement("dashboardBottomTabOnboarding");
        highlightElement("favoritesOnboarding");
        break;
      case 3:
        removeHighlightElement("favoritesOnboarding");
        removeHighlightElement("sidebarOnboarding");
        removeHighlightElement("menuOnboarding");
        if (responsive.md)
          highlightElement("dashboardOnboarding");
        else
          highlightElement("dashboardBottomTabOnboarding");
        break;
      case 4:
        removeHighlightElement("dashboardOnboarding");
        removeHighlightElement("dashboardBottomTabOnboarding");
        removeHighlightElement("lastEditedOnboarding");
        if (responsive.md)
          highlightElement("sidebarOnboarding");
        else
          highlightElement("menuOnboarding");
        break;
      case 5:
        removeHighlightElement("menuOnboarding");
        removeHighlightElement("sidebarOnboarding");
        highlightElement("lastEditedOnboarding");
        break;
    }
  }, [])

  const tooltipPosition = () => {
    let position: { top: any; left: any; } = { top: 0, left: 0 };
    let measures;
    switch (state) {
      case 1:
        if (responsive.md) {
          measures = getElementMeasures("searchBarOnboarding")
          if (measures) {
            position = { top: measures.y, left: measures.x }
            position = {
              ...position,
              top: position.top + 82
            }
          }
        }
        else {
          measures = getElementMeasures("searchIconOnboarding")
          if (measures) {
            position = { top: measures.y, left: measures.x }
            position = {
              top: position.top + 60,
              left: position.left - 250
            }
          }
        }
        break;
      case 2:
        measures = getElementMeasures("favoritesOnboarding")
        if (measures) {
          position = { top: measures.y, left: measures.x }
          if (responsive.md) {
            position = {
              top: position.top + 60,
              left: position.left - 22
            }
          }
          else {
            position = {
              top: position.top + 60,
              left: position.left - 250
            }
          }
        }
        break;
      case 3:
        if (responsive.md) {
          measures = getElementMeasures("dashboardOnboarding")
          if (measures) {
            position = { top: measures.y, left: measures.x }
            position = {
              top: position.top + 80,
              left: position.left - 10
            }
          }
        }
        else {
          measures = getElementMeasures("dashboardBottomTabOnboarding")
          if (measures) {
            position = { top: measures.y, left: measures.x }
            position = {
              top: position.top - 230,
              left: (window.innerWidth * 0.5) - 138
            }
          }
        }
        break;
      case 4:
        if (responsive.md) {
          measures = getElementMeasures("sidebarOnboarding")
          if (measures) {
            position = { top: measures.y, left: measures.x }
            position = {
              top: position.top - 11,
              left: (position.left + 35) + measures.width
            }
          }
        }
        else {
          measures = getElementMeasures("menuOnboarding")
          if (measures) {
            position = { top: measures.y, left: measures.x }
            position = {
              top: position.top + 55,
              left: position.left - 20
            }
          }
        }
        break;
      case 5:
        measures = getElementMeasures("lastEditedOnboarding")
        if (measures) {
          position = { top: measures.y, left: measures.x }
          if (responsive.md)
            position = {
              top: position.top + 77,
              left: position.left
            }
          else
            position = {
              top: position.top - 199,
              left: position.left
            }
        }
        break;
    }
    const isVisible = () => ((position.top && position.left) ? "block" : "none")
    return { ...position, display: isVisible() }
  }
  return (
    <>
      <div className='overlay' />
      <div style={tooltipPosition()} className={`c-onboarding-tooltip-float`}>
        <div className='c-onboarding-tooltip'>
          <div className='c-onboarding-tooltip-content'>
            <div className="c-onboarding-tooltip__container">

              {(responsive.xs && !responsive.md) && (() => {
                if (arrowMobile === 'top-left') {
                  return <div className='c-onboarding-tooltip__arrow c-onboarding-tooltip__arrow-top-left' />
                } else if (arrowMobile === 'top-right') {
                  return <div className='c-onboarding-tooltip__arrow c-onboarding-tooltip__arrow-top-right' />
                } else if (arrowMobile === 'right') {
                  return <div className='c-onboarding-tooltip__arrow c-onboarding-tooltip__arrow-right' />
                }
                else if (arrowMobile === 'left') {
                  return <div className='c-onboarding-tooltip__arrow c-onboarding-tooltip__arrow-left' />
                } else if (arrowMobile === 'bottom') {
                  return <div className='c-onboarding-tooltip__arrow c-onboarding-tooltip__arrow-bottom' />
                }
              })()}

              {(responsive.md) && (() => {
                if (arrowDesktop === 'top-left') {
                  return <div className='c-onboarding-tooltip__arrow c-onboarding-tooltip__arrow-top-left' />
                } else if (arrowDesktop === 'top-right') {
                  return <div className='c-onboarding-tooltip__arrow c-onboarding-tooltip__arrow-top-right' />
                } else if (arrowDesktop === 'right') {
                  return <div className='c-onboarding-tooltip__arrow c-onboarding-tooltip__arrow-right' />
                }
                else if (arrowDesktop === 'left') {
                  return <div className='c-onboarding-tooltip__arrow c-onboarding-tooltip__arrow-left' />
                } else if (arrowDesktop === 'bottom') {
                  return <div className='c-onboarding-tooltip__arrow c-onboarding-tooltip__arrow-bottom' />
                }
              })()}
            </div>
            <button onClick={onHandleSkip} type="button" className="c-onboarding-tooltip__close-button btn-close" aria-label="Close"></button>
            <div className="d-flex justify-content-between p-0 pe-3 c-onboarding-tooltip-header">
              <h2 className="c-onboarding-tooltip__title o-ft-xl-700 m-0 mb-2">{title}</h2>
            </div>
            <div className="p-0 c-onboarding-tooltip-body">
              <span className="c-onboarding-tooltip__description o-ft-sm-400 d-block">{description}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center p-0 c-onboarding-tooltip-footer">
              <div>
                {[...Array(amountOfDots)].map((value, index) =>
                  <span key={index} className={`c-onboarding-tooltip__dot${activeDots(index) ? "--active" : ""}`} />
                )}
              </div>
              <div>
                {
                  (btnBack) && <span className='c-onboarding-tooltip__back-button o-ft-xs-700 o-cursor-pointer' onClick={onHandleBack}>Back</span>
                }
                <span className="c-onboarding-tooltip__next-button o-ft-xs-700 o-cursor-pointer" onClick={onHandleNext}>{button}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

