import React, { createContext, ReactElement, useRef } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useDropdown } from '@hooks/useDropdown';
import MaterialIcon from '../MaterialIcon';
import { SortList } from '@components/filters/SortList';
import useWindowSize from '@hooks/useWindowSize';

interface ISortProps {
  children?: ReactElement | ReactElement[];
  collapsible?: boolean;
  sort: ISort;
}

export interface ISort {
  id: number | null;
  name: string | null;
}

interface ISortContextProps {
  sort: ISort;
}

export const SortContext = createContext({} as ISortContextProps);

export const Sort = ({ children, collapsible, sort }: ISortProps) => {
  const ref = useRef(null);
  const { Provider } = SortContext;
  const { onClick, show } = useDropdown({ ref });
  const { responsive } = useWindowSize();

  const collapsibleClass = classNames({
    'd-none d-md-inline': _.isEqual(collapsible, true),
  });
  const orderButtonClass = classNames({
    'c-order__button--collapsible': collapsible && !responsive.md,
  });

  return (
    <Provider value={{ sort }}>
      <div ref={ref} className='position-relative'>
        <div className='c-order d-inline'>
          <button className={`c-order__button ${orderButtonClass} d-flex justify-content-evenly align-items-center`} onClick={onClick}>
            {
              (collapsible) &&
              <MaterialIcon
                className='d-inline-block d-md-none'
                icon='keyboard_arrow_down'
                size={18}
                color='o-cl-grey-100'
              />
            }
            <span className={`c-order__text o-ft-xs-600 pe-1 ${collapsibleClass}`}>{sort.name}</span>
            <MaterialIcon
              icon='sort'
              size={24}
              color='o-cl-grey-100'
            />
          </button>
        </div>
        {(show) && children}
      </div>
    </Provider>
  );
};

Sort.DropdownList = SortList;
