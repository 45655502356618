/* eslint-disable @typescript-eslint/no-explicit-any */
import MaterialIcon, { TIcon } from "./MaterialIcon";
import React, { useEffect, useRef, useState } from "react";
import { useGetDocumentVaults, useGetVaultsData } from "@redux/vaults/api";

import AdminHttpProvider from "../app/api/AdminHttpProvider";
import { CSSTransition } from "react-transition-group";
import { IAction } from "@models/Action.type";
import { IVault } from "@redux/vaults/types";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-activity";
import { createToast } from "@helpers/createToast";
import { formatDateDDmmmYYYY } from "@helpers/formatDate";
import { setVaultFolder } from "@redux/files/slices/fileSlice";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import useHandleClickOutside from "@hooks/useHandleClickOutside";
import useWindowSize from "@hooks/useWindowSize";

const api = new AdminHttpProvider();

type Props = {
  items: IAction[];
  file: any;
  setShow: React.Dispatch<boolean>;
  headerTitle: string;
  position?: "right" | "left";
};

const DropdownItem = (props: any) => {
  const {
    goToMenu,
    setActiveMenu,
    onClick,
    setShow,
    file,
    data,
    icon,
    iconColor,
    iconSize,
    children,
  } = props;
  const handleClick = () => {
    if (goToMenu) {
      setActiveMenu(goToMenu);
    } else {
      onClick(file || data.id);
      setShow(false);
    }
  };

  return (
    <div
      className="c-dots-submenu__menu-item o-cl-grey-100"
      onClick={handleClick}
      aria-hidden
    >
      <MaterialIcon
        icon={icon}
        color={iconColor || "o-cl-grey-100"}
        className="me-2"
        size={iconSize || 18}
      />
      {children}
    </div>
  );
};

const DropdownSubItem = (props: any) => {
  const { checks, id, onClick, loader, children } = props;
  const isSelected = checks.some((vault: any) => vault.id === id);

  return (
    <div className="c-dots-submenu__list-item">
      <div className="c-dots-submenu__list-item-checkbox">
        {loader.includes(id) ? (
          <Spinner size={10} />
        ) : (
          <MaterialIcon
            icon={isSelected ? "check_box" : "check_box_outline_blank"}
            size={20}
            onClick={onClick}
            color={isSelected ? "o-cl-grey-100" : "o-cl-grey-200"}
            className="o-cursor-pointer"
          />
        )}
      </div>

      <div className="d-flex align-items-center gap-1">
        <MaterialIcon icon="encrypted" color="o-cl-grey-100" size={20} />
        <span
          className="c-dots-submenu__item"
          title={children}
          onClick={onClick}
          aria-hidden
        >
          {children}
        </span>
      </div>
    </div>
  );
};

const DotsSubMenu: React.FC<Props> = ({
  items,
  file,
  setShow,
  headerTitle,
  position = "left",
}) => {
  const { responsive } = useWindowSize();

  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState<any>(null);
  const [loader, setLoader] = useState<number[]>([]);
  const [checks, setChecks] = useState<IVault[]>([]);

  const dropdownRef = useRef<any>(null);
  useHandleClickOutside(dropdownRef, () => setShow(false));

  // TODO: esto es solo para la vista de tabla, no muestra el menu si se usan los hooks (revisar por quÃ©)
  const { views } = useAppSelector((state) => state.toggle);

  const [nextUrl, setNextUrl] = useState(undefined);

  const { data: vaultsList } = useGetVaultsData(
    { nextUrl },
    {
      skip: views.admin.list,
    },
  );
  const { data: document, refetch } = useGetDocumentVaults(file?.id, {
    skip: !file || views.admin.list,
  });

  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.file);

  const [vaults, setVaults] = useState<any[]>([]);
  const [tableDocument, setTableDocument] = useState<any>();

  useEffect(() => {
    if (!vaultsList) return;
    setVaults([...vaults, ...vaultsList.data]);
  }, [vaultsList]);

  useEffect(() => {
    if (views.admin.grid) return;
    api.request("get", "api/vaults").then(({ data: vaultsData }: any) => {
      setVaults(vaultsData.data);
    });
  }, [views.admin.grid]);

  useEffect(() => {
    if (!file || views.admin.grid) return;
    api
      .request("get", `api/admin/documents/${file.id}`)
      .then(({ data: documentsData }) => setTableDocument(documentsData.data));
  }, [file, views.admin.grid]);

  useEffect(() => {
    if (!tableDocument || views.admin.grid) return;
    setChecks(tableDocument.vaults);
  }, [tableDocument, views.admin.grid]);

  useEffect(() => {
    if (!document || views.admin.list) return;
    setChecks(document.vaults);
  }, [document, views.admin.list]);

  useEffect(() => {
    if (views.admin.list) return;
    refetch();
  }, [checks, refetch, views.admin.list]);

  useEffect(() => {
    if (dropdownRef.current) {
      setMenuHeight(
        dropdownRef.current?.firstChild?.offsetHeight >= 260
          ? 260
          : dropdownRef.current.firstChild.offsetHeight + 20,
      );
    }
  }, [vaults]);

  const calcHeight = (el: any) => {
    const height = el.offsetHeight;
    setMenuHeight(height >= 260 ? 260 : height + 20);
  };

  const postVault = async (vault: IVault) => {
    const checkedItems = checks.some((check) => check.id === vault.id)
      ? checks.filter((check) => check.id !== vault.id)
      : [...checks, vault];

    setChecks(checkedItems);

    const params = new FormData();

    if (checkedItems.length) {
      checkedItems.forEach((check) => {
        params.append("vaults[]", check.id.toString());
      });
    }

    setLoader((prev) => [...prev, vault.id]);

    try {
      await api.request(
        "post",
        `api/admin/documents/${file.id}/store-vaults`,
        params,
      );
      dispatch(setVaultFolder({ id: file.id, vault }));
      if (checks.some((check) => check.id !== file.id)) {
        const text = `<strong>Folder removed from vault</strong><br><strong>"${file.name}"</strong> folder has been removed from file vault <strong>"${vault.name}â€</strong> successfully.<br/>`;
        createToast(text, "success", dispatch);
      } else {
        const text = `<strong>Folder added to vault</strong><br><strong>"${file.name}"</strong> folder has been added to file vault <strong>"${vault.name}â€</strong> successfully.<br/>`;
        createToast(text, "success", dispatch);
      }
    } catch (error) {
      const text = `<strong>Folder hasn't been added</strong><br><strong>"${file.name}"</strong> folder canâ€™t be added to file vault due to technical issues.`;
      createToast(text, "danger", dispatch);
    }

    setLoader((prev) => prev.filter((id) => id !== vault.id));
  };

  const dropdownIcon = (): TIcon => {
    let icon: TIcon = "inventory";
    if (file) {
      icon = "folder";
      if (file.type) {
        icon = "draft";
      }
    }
    return icon;
  };

  const dropdownColorIcon = () => {
    let colorIcon = "o-cl-brand-primary";
    if (file) {
      colorIcon = "o-cl-light-blue";
      if (file.type) {
        colorIcon = "o-cl-light-purple";
      }
    }
    return colorIcon;
  };

  return (
    <>
      <div
        className={`c-dots-submenu__dropdown ${position === "right" ? "c-dots-submenu__dropdown-right" : "c-dots-submenu__dropdown-left"} ${!responsive.md ? "c-dots-submenu__dropdown-bottom-slide" : ""}`}
        style={{
          height: responsive.md ? menuHeight : "auto",
          overflow: "auto",
        }}
        ref={dropdownRef}
        id="scrollableSubMenu"
      >
        <CSSTransition
          in={activeMenu === "main"}
          timeout={500}
          classNames="menu-primary"
          unmountOnExit
          onEnter={calcHeight}
        >
          <div className="c-dots-submenu__menu">
            {!responsive.md && (
              <div className="c-dots-header">
                <MaterialIcon
                  icon={dropdownIcon()}
                  color={dropdownColorIcon()}
                  className="me-3 filled-icon"
                  size={24}
                />
                <div className="d-flex flex-column">
                  <span className="o-ft-base-700 c-dots-header__title">
                    {headerTitle}
                  </span>
                  {file && (
                    <div>
                      <span>{file?.size}</span>
                      <span>{formatDateDDmmmYYYY(file?.created_at)}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {items.map(
              (item) =>
                (!item.hideInDesktop || !responsive.md) && (
                  <DropdownItem
                    key={item.id}
                    icon={item.icon}
                    goToMenu={item.goToMenu}
                    onClick={item.onClick}
                    setActiveMenu={setActiveMenu}
                    setShow={setShow}
                    file={file}
                    data={data}
                  >
                    {item.text}
                  </DropdownItem>
                ),
            )}
          </div>
        </CSSTransition>

        <CSSTransition
          in={activeMenu === "addVault"}
          timeout={500}
          classNames="menu-secondary"
          unmountOnExit
          onEnter={calcHeight}
        >
          <div className="c-dots-submenu__menu">
            <div className="c-dots-header px-0 d-flex align-items-center">
              <DropdownItem
                goToMenu="main"
                icon="arrow_back"
                iconSize={24}
                iconColor="o-cl-brand-primary"
                setActiveMenu={setActiveMenu}
              />
              {!responsive.md && <span className="o-ft-600">Vault</span>}
            </div>
            <InfiniteScroll
              dataLength={vaults?.length}
              next={() => {
                setNextUrl(vaultsList?.links?.next);
              }}
              hasMore={!!vaultsList?.links.next}
              scrollableTarget="scrollableSubMenu"
              loader={
                <div className="d-flex align-items-center px-3 gap-3">
                  <Spinner size={10} />
                  <span className="o-ft-sm-400 o-cl-grey-200">Loading...</span>
                </div>
              }
            >
              {vaults.map((vault: any) => (
                <DropdownSubItem
                  key={vault.id}
                  id={vault.id}
                  onClick={() => postVault(vault)}
                  checks={checks}
                  loader={loader}
                >
                  {vault.name}
                </DropdownSubItem>
              ))}
            </InfiniteScroll>
          </div>
        </CSSTransition>
      </div>

      {!responsive.md && <div className="c-dots-submenu__overlay" />}
    </>
  );
};

export default DotsSubMenu;
