import { IToast, IToastBody } from "@models/toast/Toast.type";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IToastsSlice {
  id: number | string;
  toasts: any[];
}

const initialState: IToastsSlice = {
  id: 0,
  toasts: [],
};

export const toastsSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    addToast: (state: IToastsSlice, action: PayloadAction<IToast>) => {
      state.toasts.push(action.payload);
    },
    updateToast: (state: IToastsSlice, action: PayloadAction<IToast>) => {
      state.toasts = state.toasts.map((toast) => {
        if (toast.id === action.payload.id) {
          return { ...toast, ...action.payload };
        }
        return toast;
      });
    },
    removeToast: (
      state: IToastsSlice,
      action: PayloadAction<number | string>
    ) => {
      state.toasts = state.toasts.filter(
        (toast: IToast) => toast.id !== action.payload
      );
    },
  },
});

export const { addToast, removeToast, updateToast } = toastsSlice.actions;
export default toastsSlice.reducer;
