import React from 'react'

import Button from '@components/Button'
import { useNavigate } from 'react-router-dom'
import useWindowSize from '@hooks/useWindowSize'
import MaterialIcon from '@components/MaterialIcon'

const GoBack = () => {
  const { responsive } = useWindowSize()

  const navigate = useNavigate()

  return (
    responsive.md ? (
      <Button
        value="Go Back"
        icon={{
          icon: 'arrow_back_ios',
          size: 12,
          position: 'right',
        }}
        onClick={() => navigate(-1)}
      />
    ) : (
      <label
        onClick={() => navigate(-1)}
        className='c-button--circle o-bg-brand-secondary:hover o-cl-brand-secondary o-cl-white:hover o-ft-xs-700 d-flex align-items-center justify-content-center mb-0 ms-4'
      >
        <MaterialIcon
          className="c-button__icon ms-1"
          icon={'arrow_back_ios'}
          size={12}
        />
      </label>
    )
  )
}

export default GoBack