/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";

import { images } from "@theme/images";

import Password from "./shared/forms/Password";
import Phone from "./shared/forms/Phone";
import Code from "./shared/forms/Code";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AuthController from "@controllers/AuthController";
import { createToast } from "@helpers/createToast";
import { useDispatch } from "react-redux";
/**
 * [] - Refactor de TODO + tests
 */

const CreatePassword: React.FC = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState<any>({});
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { token } = useParams();

  const onSuccessResetPassword = () => {
    setIsSubmitting(false);
    createToast("Password has been successfully created", "success", dispatch);
    navigate("/login", { replace: true });
  };
  const onErrorResetPassword = (error: any) => {
    setIsSubmitting(false);
    createToast(error, "danger", dispatch);
  };

  const handleNext = (formValues: any) => {
    setValues((p: any) => ({ ...p, ...formValues }));
    if (!process.env.REACT_APP_ENABLED_2FA) {
      setIsSubmitting(true);
      const params = {
        token: token || "",
        email: searchParams.get("email") || "",
        firebase: searchParams.get("firebase") || "",
        password: formValues.password,
        password_confirmation: formValues.confirmPassword,
      };
      AuthController.resetPassword(
        onSuccessResetPassword,
        onErrorResetPassword,
        params,
      );
    } else {
      setStep((p) => p + 1);
    }
  };

  const handleBack = () => {
    setStep((p) => p - 1);
  };

  const renderStep = () => {
    let componentToRender;
    switch (step) {
      case 1:
        componentToRender = (
          <Phone onNext={handleNext} currentValues={values} />
        );
        break;
      case 2:
        componentToRender = <Code goBack={handleBack} currentValues={values} />;
        break;
      default:
        componentToRender = (
          <Password onNext={handleNext} isSubmitting={isSubmitting} />
        );
    }
    return componentToRender;
  };
  return (
    <div
      style={{
        height: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
        paddingBottom: "9rem",
      }}
    >
      <div className="row">
        <div className="col-12 d-flex justify-content-center align-items-center">
          <img
            className="c-form-header__onboarding-logo-img"
            src={images.verticalBrandLogo}
            alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
          />
        </div>

        <div className="col-12 col-md-4 offset-md-4 px-4">{renderStep()}</div>
      </div>
    </div>
  );
};

export default CreatePassword;
