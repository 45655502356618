import React, { useRef, useState } from "react";

import MaterialIcon from "@components/MaterialIcon";
import { setFilters } from "@redux/files/slices/fileSlice";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import useHandleClickOutside from "@hooks/useHandleClickOutside";

const FolderFilter: React.FC = () => {
  const { folderFilters } = useAppSelector((state) => state.file);

  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<null | HTMLDivElement>(null);
  useHandleClickOutside(ref, () => setIsOpen(false));

  const handleSelect = (filter: string) => {
    dispatch(
      setFilters(
        folderFilters.includes(filter)
          ? folderFilters.filter((f) => f !== filter)
          : [...folderFilters, filter]
      )
    );
  };

  return (
    <div className="c-folder-filter" ref={ref}>
      <div className="c-folder-filter__main" onClick={() => setIsOpen(!isOpen)}>
        <span>Filter by...</span>
        <MaterialIcon
          icon={isOpen ? "expand_less" : "expand_more"}
          color="o-cl-brand-secondary"
        />
      </div>

      {isOpen && (
        <div className="c-folder-filter__dropdown">
          {/* <span className='o-ft-2xl-400 mb-3'>Filters</span> */}
          <div>
            <span className="o-ft-md-400">Folders</span>
            <div className="d-flex flex-column gap-2 mt-2">
              <div
                className="d-flex align-items-center gap-2"
                onClick={() => handleSelect("folders")}
              >
                <MaterialIcon
                  icon={
                    folderFilters.includes("folders")
                      ? "check_box"
                      : "check_box_outline_blank"
                  }
                  color={
                    folderFilters.includes("folders")
                      ? "o-cl-brand-secondary"
                      : ""
                  }
                  size={20}
                />
                <span className="o-ft-sm-400">Only Folders</span>
              </div>
              <div
                className="d-flex align-items-center gap-2"
                onClick={() => handleSelect("vaults")}
              >
                <MaterialIcon
                  icon={
                    folderFilters.includes("vaults")
                      ? "check_box"
                      : "check_box_outline_blank"
                  }
                  color={
                    folderFilters.includes("vaults")
                      ? "o-cl-brand-secondary"
                      : ""
                  }
                  size={20}
                />
                <span className="o-ft-sm-400">Vaults</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FolderFilter;
