import { getCookie } from '@api/AdminHttpProvider';
import { whichProvider } from '@helpers/whichProvider';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import sortBy from 'lodash/sortBy';
import { useEffect, useState } from 'react';

const getHeaders = () => {
  const currentWebsite = localStorage.getItem('current_website')
  const token = currentWebsite === 'platform' ? localStorage.getItem("access_token") : getCookie("access_token");
  return {
    "Authorization": `Bearer ${token}`,
    'Content-Type': `application/json`,
  }
}

const baseUrl = process.env.REACT_APP_BACK_BASE_URL

export const formsApi = createApi({
  reducerPath: 'formSlice',
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: ['Form'],
  endpoints: (builder) => ({
    getForms: builder.query({
      query: () => ({ url: 'api/forms', headers: getHeaders() }),
      providesTags: ['Form'],
      transformResponse: (res: { data: any[] }) => res.data.sort((a, b) => {
        var dateA = new Date(a.updated_at).getTime();
        var dateB = new Date(b.updated_at).getTime();
        return dateB - dateA;
      })
    }),
    getForm: builder.query({
      query: ({ id }) => ({ url: `api/forms/${id}`, headers: getHeaders() }),
      providesTags: ['Form'],
      transformResponse: (res: any) => res.data
    }),
    getFormGroups: builder.query({
      query: ({ id }) => ({ url: `api/forms/${id}/form-groups`, headers: getHeaders() }),
      providesTags: ['Form'],
      transformResponse: (res: any) => res.data
    }),
    getFieldsOfGroup: builder.query({
      query: ({ id }) => ({ url: `api/form-groups/${id}/form-fields`, headers: getHeaders() }),
      providesTags: ['Form'],
      transformResponse: (res: any) => res.data
    }),
    addForm: builder.mutation({
      query: (payload) => ({
        url: 'api/forms',
        method: 'POST',
        body: payload,
        headers: getHeaders()
      }),
      invalidatesTags: ['Form'],
      transformResponse: (res: any) => res.data
    }),
    addGroup: builder.mutation({
      query: (payload) => ({
        url: 'api/form-groups',
        method: 'POST',
        body: payload,
        headers: getHeaders()
      }),
      invalidatesTags: ['Form'],
      transformResponse: (res: any) => res.data
    }),
    addField: builder.mutation({
      query: (payload) => ({
        url: 'api/form-fields',
        method: 'POST',
        body: payload,
        headers: getHeaders()
      }),
      invalidatesTags: ['Form'],
      transformResponse: (res: any) => res.data
    }),
    deleteForm: builder.mutation({
      query: ({ id }) => ({
        url: `api/forms/${id}`,
        method: 'DELETE',
        headers: getHeaders()
      }),
      transformResponse: (res: any) => res.data
    }),
    cloneForm: builder.mutation({
      query: ({ id }) => ({
        url: `api/forms/${id}/clone`,
        method: 'POST',
        headers: getHeaders()
      }),
      invalidatesTags: ['Form'],
      transformResponse: (res: any) => res.data
    }),
    // update
    updateForm: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `api/forms/${id}`,
        method: 'PUT',
        body: rest,
        headers: getHeaders()
      }),
      transformResponse: (res: any) => res.data
    }),
    updateGroup: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `api/form-groups/${id}`,
        method: 'PUT',
        body: rest,
        headers: getHeaders()
      }),
      transformResponse: (res: any) => res.data
    }),
    updateField: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `api/form-fields/${id}`,
        method: 'PUT',
        body: rest,
        headers: getHeaders()
      }),
      transformResponse: (res: any) => res.data
    }),
    deleteGroup: builder.mutation({
      query: (id: number | undefined) => ({
        url: `api/form-groups/${id}`,
        method: 'DELETE',
        headers: getHeaders()
      })
    }),
    deleteField: builder.mutation({
      query: (id: number | undefined) => ({
        url: `api/form-fields/${id}`,
        method: 'DELETE',
        headers: getHeaders()
      })
    })
  }),
})
export const {
  useGetFormsQuery: useGetForms,
  useAddFormMutation: usePostForm,
  useAddGroupMutation: usePostGroup,
  useAddFieldMutation: usePostField,
  useDeleteFormMutation: useDeleteform,
  useCloneFormMutation: useCloneForm,
  //update
  useUpdateFormMutation: useUpdateForm,
  useUpdateGroupMutation: useUpdateGroup,
  useUpdateFieldMutation: useUpdateField,
  // delete
  useDeleteGroupMutation: useDeleteGroup,
  useDeleteFieldMutation: useDeleteField,
} = formsApi

export function useGetForm(id?: string | null) {
  const [form, setForm] = useState()
  const [isLoading, setIsLoading] = useState(false)

  // TODO: ejecutar solo si cambia el id
  useEffect(() => {
    if (!id) return

    setIsLoading(true)

    const getForm = async () => {
      const form = await whichProvider().request('get', `api/forms/${id}`);
      const groups = await whichProvider().request('get', `api/forms/${id}/form-groups`);

      let cards: any = []

      for await (const group of sortBy(groups.data?.data, [function (o) { return o.order; }])) {
        const fields = await whichProvider().request('get', `api/form-groups/${group.id}/form-fields`);

        cards.push({
          card_id: group.id,
          title: group.title,
          description: group.description ?? '',
          order: group.order,
          inputs: fields.data?.data?.map((field: any) => {
            return {
              id: field.id,
              code: field.field.code,
              title: field.title,
              description: field.description
            }
          }),
          required: !!fields.data?.data[0]?.is_required
        })
      }

      return {
        id: form.data.data.id,
        title: form.data.data.title,
        description: form.data.data.description,
        cards
      }
    }

    getForm().then((res: any) => {
      setIsLoading(false)
      setForm(res)
    })

  }, [id])

  return { isLoading, form, setForm }
}
