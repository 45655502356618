import { IUserResponse, TUser } from '@models/user/User.type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLoggedUser, updateLoggedUser } from '@redux/users/thunks/userThunk';

export interface IUserSlice {
  data: TUser | null;
  pending: boolean;
}

const initialState: IUserResponse & IUserSlice = {
  data: null,
  pending: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoggedUser: (state: any, action: PayloadAction<IUserResponse>) => {
      // const { id, name } = action.payload;
      // state.id = id;
      // state.name = name;
    },
    updateName: (state: any, action: PayloadAction<string>) => {
      // state.name = action.payload;
    },
    initializeUser: (state: IUserResponse & IUserSlice) => {
      state.data = null;
    },
    setOnboarding: (state: any, action: PayloadAction<boolean>) => {
      state.data.onboarding = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLoggedUser.fulfilled, (state, action: PayloadAction<IUserResponse>) => {
      const { data } = action.payload;
      state.data = data
      state.pending = false;
    });
    builder.addCase(getLoggedUser.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(getLoggedUser.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(updateLoggedUser.fulfilled, (state, action: PayloadAction<IUserResponse>) => {
      const { data } = action.payload;
      state.data = data
      state.pending = false;
    });
    builder.addCase(updateLoggedUser.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(updateLoggedUser.pending, (state) => {
      state.pending = true;
    });
  }
});

export const { setLoggedUser, updateName, initializeUser, setOnboarding } = userSlice.actions;
export default userSlice.reducer;
