/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from "react";
import { IAction } from "@models/Action.type";
import { TIcon } from "@components/MaterialIcon";
import { TEndpoints } from "../providers/PinUnpinProvider";

export enum EModulesToPin {
  Navigation = "navigation",
  Dashboard = "dashboard",
}

export type TModulesToPin = EModulesToPin.Dashboard | EModulesToPin.Navigation;

export interface IUsePinActionList {
  document?: any;
  parentId?: number;
  tooglePinDashboard?: any;
  tooglePinBottomBar?: any;
  context?: string;
  endpoint?: TEndpoints;
}
export const usePinActionList = ({
  document,
  tooglePinDashboard,
  tooglePinBottomBar,
  context,
  endpoint,
}: IUsePinActionList) => {
  const [module, setModule] = useState<TModulesToPin>(EModulesToPin.Dashboard);
  const getDashboardText = (): string => {
    let dashboardText = "Pin to Home";
    if (document?.is_dashboard_pinned) {
      dashboardText = "Unpin from Home";
    }
    return dashboardText;
  };

  const getBottomBarText = (): string => {
    let dashboardText = "Add to bottom bar";
    if (document?.is_bottom_bar_pinned) {
      dashboardText = "Remove from bottom bar";
    }
    return dashboardText;
  };

  const actionList: IAction[] = [];
  if (!["file-management", "trash", "forms"].includes(context ?? "")) {
    actionList.push(
      {
        id: "pin_to_home",
        icon: "push_pin" as TIcon,
        text: getDashboardText(),
        onClick: () => {
          setModule(EModulesToPin.Dashboard);
          tooglePinDashboard(document, endpoint);
        },
        type: "pin",
      },
      {
        id: "add_to_bottom_bar",
        icon: "add_box" as TIcon,
        text: getBottomBarText(),
        onClick: () => {
          setModule(EModulesToPin.Navigation);
          tooglePinBottomBar(document, endpoint);
        },
        hideInDesktop: true,
        type: "pin",
      },
    );
  }

  return {
    module,
    setModule,
    actionList,
  };
};
