import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import { IRow } from '@models/Table.type';
import { IAction } from '@models/Action.type';
import useAppSelector from '@hooks/useAppSelector';
import MaterialIcon from '@components/MaterialIcon';
import { useLocation } from 'react-router-dom';
import DotsSubMenu from './DotsSubMenu';

interface IDotsMenuProps {
  pending?: boolean;
  context?: IRow | any;
  actionList?: IAction[];
  dotsDirection: 'vertical' | 'horizontal';
  position?: 'right' | 'left';
  setIsDotMenuOpened?: (x: boolean) => void;
}

const sectionsExcludedActions: string[] = ['copy-link'];
const sectionExcludedActionsOnPlatform: string[] = ['download'];

const DotsMenu = ({ dotsDirection, context, actionList, pending, position, setIsDotMenuOpened }: IDotsMenuProps) => {
  const { file: { data } } = useAppSelector((state) => state);

  const [show, setShow] = useState<boolean>(false);

  const location = useLocation();

  const isInFileManagement = location.pathname.includes(`/admin/file-management`);
  const isInForm = location.pathname.includes(`/admin/all-forms`);

  const excludedActionsForSections = [...sectionsExcludedActions, ...(!isInFileManagement ? sectionExcludedActionsOnPlatform : [])];
  const actionListByType = (element: any) => {
    if (element && !element.deleted_at && element?.type)
    return actionList?.filter(action => !_.isEqual(action.id, 'addSection') && !_.isEqual(action.id, 'add_to_bottom_bar'))
  
  if (!element?.type)
  return actionList?.filter(action => !excludedActionsForSections.includes(action.id));

return actionList;
}

  const toggleShow = () => {
    setShow((prev) => !prev);
  }

  const headerTitle = isInForm ? context?.title : (context?.name || data?.name || 'File Management');

  useEffect(() => {
    setIsDotMenuOpened !== undefined && setIsDotMenuOpened(show)
  }, [show, setIsDotMenuOpened])
  
  return (
    <div className='position-relative d-flex flex-column flex-md-row justify-content-start'>
      <button type='button' onClick={toggleShow} className='bg-transparent border-0 d-flex align-items-end p-1'>
        <MaterialIcon color='o-cl-grey-200' icon={dotsDirection === 'vertical' ? 'more_vert' : 'more_horiz'} size={24} />
      </button>
      {
        show && (
          <DotsSubMenu
            position={position}
            headerTitle={headerTitle}
            setShow={setShow}
            file={context}
            // items={actionListByType(context)?.filter(action => action.hidden !== true) || []}
            items={actionListByType(context)?.filter(action => !action.hidden) || []}
          />
        )
      }
    </div>
  )
}

export default React.memo(DotsMenu)
