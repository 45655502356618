/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

import { formatCapitalizeFirstLetter } from "@helpers/formatCapitalizeFirstLetter";
import useAppSelector from "@hooks/useAppSelector";
import { useGetSidebarItems, useGetSidebarSubItem } from "@redux/sections/api";
import { Spinner } from "react-activity";

const NewSidebar = () => {
  const [activeSection, setActiveSection] = useState<any | null>(null);
  const [activeChild, setActiveChild] = useState<string | null>(null);
  const { data: processes } = useAppSelector((state) => state.process);
  const { data: sections, isLoading } = useGetSidebarItems();
  const { data: subItems, isFetching: isFetchingSubItem } =
    useGetSidebarSubItem(
      {
        sectionId: activeSection?.id,
      },
      {
        skip: activeSection === null || activeSection.path === "dashboard",
      },
    );

  const navigate = useNavigate();

  const menuItems = useMemo(
    () => [
      ...(sections
        ? sections.data.map((section: any) => {
            if (section.name === "Learning") {
              return {
                ...section,
                path: "https://franlylmsstg.wpenginepowered.com/",
              };
            }
            return section;
          })
        : []),
    ],
    [processes, sections],
  );

  return (
    <div id="sidebarOnboarding">
      {isLoading
        ? [...Array(10)].map((item, index) => (
            <SkeletonTheme
              key={index}
              baseColor="#D8D8D8"
              highlightColor="#FFFFFF"
            >
              <li className="c-menu-item">
                <h3 className="c-menu-item__skeleton o-ft-3xl">
                  <Skeleton />
                </h3>
              </li>
            </SkeletonTheme>
          ))
        : menuItems.map((section: any) => (
            <React.Fragment key={section.id}>
              <div
                className={`c-menu-item c-menu-item__link o-cursor-pointer o-ft-base-400 d-flex align-items-center ${activeSection === section.id ? "c-menu-item__link--active o-ft-700" : ""}`}
                onClick={() => {
                  setActiveSection(section);
                  if (section.name === "Learning") {
                    window.open(section.path, "_blank");
                    return;
                  }
                  navigate(section.path);
                }}
                aria-hidden
              >
                <span>{formatCapitalizeFirstLetter(section.name)}</span>
              </div>
              {activeSection &&
                activeSection.id === section.id &&
                activeSection.path !== "dashboard" && (
                  <ul className="c-menu-subsection">
                    {isFetchingSubItem ? (
                      <li>
                        <div className="d-flex ps-5 pt-2">
                          <Spinner size={15} />
                          <span className="ms-2 o-cl-grey-200">Loading</span>
                        </div>
                      </li>
                    ) : (
                      subItems.data.map((item: any, index: number) => (
                        <li
                          key={`${item.name}_${index}`}
                          className="c-menu-subsection-item c-menu-subsection-item--child"
                        >
                          <span
                            aria-hidden
                            onClick={() => {
                              navigate(item.path);
                              setActiveChild(`${item.name}_${index}`);
                            }}
                            className={`c-menu-subsection-item o-cursor-pointer o-ft-xs-700 ${activeChild && activeChild === `${item.name}_${index}` ? "c-menu-subsection-item--active" : ""}`}
                          >
                            {formatCapitalizeFirstLetter(item.name)}
                          </span>
                        </li>
                      ))
                    )}
                  </ul>
                )}
            </React.Fragment>
          ))}
    </div>
  );
};

export default NewSidebar;
