import * as Yup from "yup";

import { ErrorMessage, Form, Formik } from "formik";
import { InputSubtitle, InputText } from "@views/WalkthroughSteps/styled-step1";

import { AxiosError } from "axios";
import { CtaBackToLogin } from "@views/Walkthrough-styled";
import HttpProvider from "@api/HttpProvider";
import PhoneInput from "@components/PhoneInput/PhoneInput";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { createToast } from "@helpers/createToast";
import useAppDispatch from "@hooks/useAppDispatch";
import { useSearchParams } from "react-router-dom";

const api = new HttpProvider();

const validationSchema = Yup.object({
  phoneNumber: Yup.string()
    .required("The phone number is required.")
    .matches(/^[+0-9][0-9- ()_]{4,20}$/, "The field must contain only numbers.")
    .matches(
      /^([2-9]\d{2})(\d{3})(\d{4})$/,
      "Please enter a valid phone number.",
    ),
});

const PhoneForm: React.FC<{
  onNext: (values: any) => void;
  currentValues: any;
}> = ({ onNext, currentValues }) => {
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const handleSubmit = async (values: any) => {
    const params = {
      username: searchParams.get("email"),
      phone: `1${values.phoneNumber}`,
    };

    try {
      await api.request("post", "api/auth/phone/verification", params);
      onNext(values);
    } catch (error) {
      if ((error as AxiosError).response?.status === 429) {
        createToast(
          "You've requested a code recently, please wait 2 minutes to try again.",
          "warning",
          dispatch,
        );
        return;
      }

      if ((error as AxiosError).response?.status === 409) {
        createToast("This phone number is already in use", "danger", dispatch);
        return;
      }

      createToast("Unexpected error", "danger", dispatch);
    }
  };

  const initialValues = {
    phoneNumber: currentValues.phoneNumber || "",
  };

  return (
    <>
      <div>
        <p className="o-ft-2xl-400 text-center">Enter Phone Number</p>
        <p className="o-ft-sm-400 text-center">
          Please add your phone number to setup 2FA and secure your account. You
          will receive a code to verify your account.
        </p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {({ errors, touched, isValid, isSubmitting }) => (
          <Form
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <div className="d-flex gap-3 flex-column">
              <div>
                <InputSubtitle
                  className="px-2"
                  $error={!!errors.phoneNumber && !!touched.phoneNumber}
                >
                  Phone Number
                </InputSubtitle>
                <InputText $error={!!errors.phoneNumber}>
                  <PhoneInput
                    name="phoneNumber"
                    placeholder="000-0000"
                    error={!!errors.phoneNumber}
                    areaCode="1"
                  />
                  <ErrorMessage
                    name="phoneNumber"
                    render={(error) => (
                      <div className="o-ft-xs-400 o-cl-red my-1 mx-2">
                        {error}
                      </div>
                    )}
                  />
                </InputText>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4 o-ft-xs-400">
              By providing your mobile number, you agree to receive 2FA text
              messages from this platform.
            </div>
            <div className="d-flex justify-content-center">
              <CtaBackToLogin
                aria-disabled={!isValid || isSubmitting}
                disabled={!isValid || isSubmitting}
                type="submit"
              >
                {isSubmitting ? "Sending Code" : "Send Code"}
              </CtaBackToLogin>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PhoneForm;
