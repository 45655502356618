/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  getProcesses,
  getStepsByProcess,
} from "@redux/approval-process/thunks/processThunk";

import { ICustomFileAttributes } from "@models/files/File.type";

export interface IProcessSlice {
  data: Array<IProcess>;
  pending: boolean;
  steps: Array<IProcessStep>;
  stepsPending: boolean;
}

export interface IProcess {
  id: number;
  name: string;
  description: string;
  process_status: TProcessStatus;
  process_tags: [];
  process_steps: Array<IProcessStep>;
  project_process_steps: any[];
  route: string;
  background: string;
}

export interface IProcessStep {
  id: number;
  name: string;
  type?: string;
  content: any;
  order: number;
  files?: Array<ICustomFileAttributes>;
  documents?: Array<ICustomFileAttributes>;
  status?: number;
  form?: {
    id: number;
    title: string;
    description: string;
    created_at?: Date;
    updated_at?: Date;
  };
  approval_required: boolean;
}

type TProcessStatus = {
  id: number;
  name: string;
  code: string;
};

const initialState: IProcessSlice = {
  data: [],
  steps: [],
  pending: false,
  stepsPending: false,
};

export const processSlice = createSlice({
  name: "process",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getProcesses.fulfilled,
      (state: IProcessSlice, action: PayloadAction<any>) => {
        const { data } = action.payload;
        state.data = data;
        state.pending = false;
      },
    );
    builder.addCase(getProcesses.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(getProcesses.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(
      getStepsByProcess.fulfilled,
      (state: IProcessSlice, action: PayloadAction<any>) => {
        const { data } = action.payload;
        state.steps = data;
        state.stepsPending = false;
      },
    );
    builder.addCase(getStepsByProcess.rejected, (state) => {
      state.stepsPending = false;
    });
    builder.addCase(getStepsByProcess.pending, (state) => {
      state.steps = initialState.steps;
      state.stepsPending = true;
    });
  },
});

export default processSlice.reducer;
