import { IFile } from "@models/files/File.type";
import React, { useCallback, useState, useEffect } from "react";

export const useContextMenu = () => {
  const [file, setFile] = useState<IFile>();
  const [xPos, setXPos] = useState("0px");
  const [yPos, setYPos] = useState("0px");
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = useCallback(() => {
    setFile(undefined)
    isVisible && setIsVisible(false);
  }, [isVisible]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.addEventListener("click", handleClick);
    };
  });

  const setPosition = ({ x, y }: { x: number, y: number }) => {
    setXPos(`${x}px`)
    setYPos(`${y}px`)
  }

  return { xPos, yPos, setPosition, isVisible, setIsVisible, file, setFile };
};
