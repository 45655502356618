/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TEndpoints } from "../../providers/PinUnpinProvider";

const baseUrl = process.env.REACT_APP_BACK_BASE_URL;

interface IPinneable {
  pineableId: number;
  parentId: number | null;
  vaultId?: number;
  endpoint?: TEndpoints;
}
interface IDashboardPin extends IPinneable {
  dashboardPosition: number;
}
interface IBottomBarPin extends IPinneable {
  bottomBarPosition: number;
}

type QueryParams =
  | { id: number; parentId?: undefined }
  | { parentId: number | null; id?: undefined };

export const documentsApi = createApi({
  reducerPath: "documentsApi",
  tagTypes: ["documents"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      if (token) headers.set("Authorization", `Bearer ${token}`);
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDocument: builder.query<any, { id: number | null }>({
      query: ({ id }) => ({
        url: `api/v2/admin/documents/${id}`,
      }),
    }),
    getDocuments: builder.query<any, { parentId: number | null }>({
      query: ({ parentId }) => ({
        url: `api/v2/documents?filters[parent_id]=${parentId}`,
      }),
      providesTags: [{ type: "documents", id: "LIST" }],
    }),
    getDocumentsByVault: builder.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `api/v2/vaults/${id}/documents`,
      }),
    }),
    pinSectionBottomBar: builder.mutation<any, IBottomBarPin>({
      query: ({ pineableId, bottomBarPosition }) => ({
        url: `api/v2/section-pins/bottom-bar/documents`,
        method: "POST",
        body: {
          pineable_id: pineableId,
          bottom_bar_position: bottomBarPosition,
        },
      }),
      async onQueryStarted(
        { pineableId, parentId, vaultId, endpoint },
        { dispatch, queryFulfilled },
      ) {
        handleQueryStarted(
          {
            pineableId,
            isPinned: true,
            attrPinned: "is_bottom_bar_pinned",
            endpoint:
              endpoint || (vaultId ? "getDocumentsByVault" : "getDocuments"),
            queryParams: vaultId ? { id: vaultId } : { parentId },
          },
          { dispatch, queryFulfilled },
        );
      },
    }),
    pinSectionDashboard: builder.mutation<any, IDashboardPin>({
      query: ({ pineableId, dashboardPosition }) => {
        return {
          url: `api/v2/section-pins/dashboard/documents`,
          method: "POST",
          body: {
            pineable_id: pineableId,
            dashboard_position: dashboardPosition,
          },
        };
      },
      async onQueryStarted(
        { pineableId, parentId, vaultId, endpoint },
        { dispatch, queryFulfilled },
      ) {
        handleQueryStarted(
          {
            pineableId,
            endpoint:
              endpoint || (vaultId ? "getDocumentsByVault" : "getDocuments"),
            queryParams: vaultId ? { id: vaultId } : { parentId },
            isPinned: true,
            attrPinned: "is_dashboard_pinned",
          },
          { dispatch, queryFulfilled },
        );
      },
    }),
    removePinSectionDashboard: builder.mutation<any, IPinneable>({
      query: ({ pineableId }) => ({
        url: `api/v2/section-pins/dashboard/documents/${pineableId}`,
        method: "DELETE",
      }),
      async onQueryStarted(
        { pineableId, parentId, vaultId, endpoint },
        { dispatch, queryFulfilled },
      ) {
        handleQueryStarted(
          {
            pineableId,
            endpoint:
              endpoint || (vaultId ? "getDocumentsByVault" : "getDocuments"),
            queryParams: vaultId ? { id: vaultId } : { parentId },
            isPinned: false,
            attrPinned: "is_dashboard_pinned",
          },
          { dispatch, queryFulfilled },
        );
      },
    }),
    removePinSectionBottomBar: builder.mutation<any, IPinneable>({
      query: ({ pineableId }) => ({
        url: `api/v2/section-pins/bottom-bar/documents/${pineableId}`,
        method: "DELETE",
      }),
      async onQueryStarted(
        { pineableId, parentId, vaultId, endpoint },
        { dispatch, queryFulfilled },
      ) {
        handleQueryStarted(
          {
            pineableId,
            endpoint:
              endpoint || (vaultId ? "getDocumentsByVault" : "getDocuments"),
            queryParams: vaultId ? { id: vaultId } : { parentId },
            attrPinned: "is_bottom_bar_pinned",
            isPinned: false,
          },
          { dispatch, queryFulfilled },
        );
      },
    }),
  }),
});

async function handleQueryStarted(
  {
    pineableId,
    isPinned,
    attrPinned,
    endpoint,
    queryParams,
  }: {
    pineableId: number;
    isPinned: boolean;
    attrPinned: "is_dashboard_pinned" | "is_bottom_bar_pinned";
    endpoint: TEndpoints;
    queryParams: QueryParams;
  },
  { dispatch, queryFulfilled }: { dispatch: any; queryFulfilled: Promise<any> },
) {
  try {
    await queryFulfilled; // Espera a que la mutación se complete
    switch (endpoint) {
      case "getDocument":
        if (queryParams.parentId) {
          updateQueryData(
            dispatch,
            "getDocuments",
            { parentId: queryParams.parentId },
            pineableId,
            attrPinned,
            isPinned,
          );
        }
        updateQueryData(
          dispatch,
          endpoint,
          { id: pineableId },
          pineableId,
          attrPinned,
          isPinned,
        );
        break;

      case "getDocuments":
        if (queryParams.parentId) {
          updateQueryData(
            dispatch,
            endpoint,
            { parentId: queryParams.parentId },
            pineableId,
            attrPinned,
            isPinned,
          );
        }
        updateQueryData(
          dispatch,
          "getDocument",
          { id: pineableId },
          pineableId,
          attrPinned,
          isPinned,
        );
        break;

      default: // is getDocumentsByVaults
        updateQueryData(
          dispatch,
          endpoint,
          queryParams,
          pineableId,
          attrPinned,
          isPinned,
        );
    }
  } catch (error) {
    console.error("Failed to update pin:", error);
  }
}

function updateQueryData(
  dispatch: any,
  endpoint: TEndpoints,
  queryParams: QueryParams,
  pineableId: number,
  attrPinned: string,
  isPinned: boolean,
) {
  dispatch(
    documentsApi.util.updateQueryData(endpoint, queryParams, (draft) => {
      if (draft?.data) {
        const docum = Array.isArray(draft.data)
          ? draft.data.find((item: any) => item.id === pineableId)
          : draft.data;
        if (docum) {
          docum[attrPinned] = isPinned;
        } else {
          console.warn("Document not found:", draft.data);
        }
      } else {
        console.warn("Draft data is invalid:", draft.data);
      }
    }),
  );
}

export const {
  useGetDocumentsQuery: useGetDocuments,
  useGetDocumentQuery: useGetDocument,
  usePinSectionBottomBarMutation: usePinSectionBottomBar,
  usePinSectionDashboardMutation: usePinSectionDashboard,
  useRemovePinSectionDashboardMutation: useRemovePinSectionDashboard,
  useRemovePinSectionBottomBarMutation: useRemovePinSectionBottomBar,
  useGetDocumentsByVaultQuery: useGetDocumentsByVault,
} = documentsApi;
