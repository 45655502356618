import { createAsyncThunk } from "@reduxjs/toolkit";
import { setFileOrderFilters } from "../slices/adminResultSlice";
import { filterRouteByOrdering } from "@helpers/filterRouteByOrdering";
import AdminSearchController from '@controllers/AdminSearchController';

const adminSearchController = new AdminSearchController();

export const getResultsWithQuery = createAsyncThunk(
  'result/search',
  async (query: string, { rejectWithValue }) => {
    try {
      const response = await adminSearchController.search(query);
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const applyFileOrderFilters = createAsyncThunk(
  'result/filters/sort',
  async (args: { orderingId: number | string, label: string, query: string }, { rejectWithValue, dispatch }) => {
    const { orderingId, query } = args;
    try {
      dispatch(setFileOrderFilters({ ...args }));
      let response;
      let orderingQuery = filterRouteByOrdering(orderingId, query);
      response = await adminSearchController.search(orderingQuery);
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
