import React from 'react';
import { Spinner } from 'react-activity';
import SearchBarItem from './SearchBarItem';
import TContact from '../app/types/Contact.type';
import useAppSelector from '@hooks/useAppSelector';
import { IFile } from '../app/types/files/File.type';

interface IAdminSearchBarResultsProps {
  searchedText: any;
  onClick: (id_file: number) => void
}

const AdminSearchBarResults = ({ searchedText, onClick }: IAdminSearchBarResultsProps) => {

  const { query, data, pending } = useAppSelector(state => state.file.search);

  return (
    <div className='c-search-bar-result--admin position-absolute'>
      <div className='c-search-bar-result__title-section d-flex align-items-center justify-content-between'>
        <span className='c-search-bar-result__title o-cl-grey-100 o-ft-sm-400'>{(data.length == 0 && !pending && query) && "NO "}SEARCH RESULT FOR <strong>“{searchedText}”</strong></span>
        {pending &&
          <Spinner className='c-button__spinner' />
        }
      </div>
      {
        data && data.map((result) => (
          <div key={result.id} className='o-cursor-pointer' onClick={() => onClick(result.id)}>
            <SearchBarItem item={result as IFile & TContact} />
          </div>
        ))
      }
    </div>
  );
};

export default AdminSearchBarResults;
