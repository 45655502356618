/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from "lodash";
import React from "react";
import TableItem from "./TableItem";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { IRow } from "@models/Table.type";
import { useLocation } from "react-router-dom";
import useWindowSize from "@hooks/useWindowSize";
import useAppSelector from "@hooks/useAppSelector";
import { TDropZone, useDragAndDrop } from "@hooks/useDragAndDrop";

interface IResponsive {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
}

interface IColumn {
  id: string; // column identifier to differentiate keys of different columns (it could be removed and use header or field instead)
  header: string; // title of column
  field: string; // name of the property of the item object to show, E.g.: If the items is {title: 'Example', author: {name: 'Lorem', age: 54}}, field could be 'title', 'author.name' and so on.
  body?: (arg: IRow) => JSX.Element; // in case of needing a custom component and not just a text string
  collapsable?: boolean; // collapses a column before breakpoint lg - improvement: make collapse breakpoint dynamic like fixedWidth
  collapsableDesktop?: boolean;
  colspan?: IResponsive; // allows to add an specific colspan starting from an specific breakpoint, E.g: {sm: '1', lg: '2'}
  fixedWidth?: IResponsive; // allows to add an specific width starting from an specific breakpoint, E.g: {sm: '44px', lg: '20%', xl: 'unset'}, in case of not added or value 'unset' columns width will behave as auto
  hiddenMobileTitle?: boolean; // hides a column header title before breakpoint lg - improvement: make hidden breakpoint dynamic like fixedWidth
  parsedString?: (arg: IRow) => string; // similar to body, instead of returning a new component it modifies the string. E.g.: it can be used to parse a date to a readable form.
}

interface ITableProps {
  columns: IColumn[]; // Each column info
  data: IRow[]; // Rows data
  dynamicRowClassName?: (arg: IRow) => string; // Add a class string to a row based on a condition
  extraClassName?: string; // to add extra general styling to table
  onRowClick?: (arg: IRow, e: React.MouseEvent<HTMLTableRowElement>) => void; // Add onClick to each row
  showHeader?: boolean; // Show / hide table header
  handleFileLoad?: (e: React.ChangeEvent) => void;
  contextMenu?: any;
  draggable?: boolean;
  canClickRow?: boolean;
}

interface IHeader {
  width: string;
  column: IColumn;
  colspan: string;
  tcClass: string;
  thClass: string;
  componentId: string;
}

const TableHeader = ({
  colspan,
  tcClass,
  thClass,
  column,
  width,
  componentId,
}: IHeader) => (
  <th
    colSpan={Number.parseInt(colspan, 10)}
    className={`c-table__title ps-2 pe-2 o-ft-base-400 o-cl-grey-100 ${thClass}`}
    key={`${componentId}-${column.id}-th`}
    style={{ width }}
  >
    <span className={tcClass}>{column.header}</span>
  </th>
);

const Table = ({
  columns,
  data,
  dynamicRowClassName,
  contextMenu,
  extraClassName = "",
  onRowClick,
  showHeader = true,
  handleFileLoad,
  draggable,
  canClickRow = true,
}: ITableProps) => {
  const { responsive } = useWindowSize();
  const location = useLocation();
  const componentId = uuidv4(); // table identifier to differentiate keys of different tables with the same column data
  const {
    file: { draggingId },
  } = useAppSelector((state) => state);
  const {
    handleDrop,
    handleDrag,
    handleDragEnter,
    handleDragOver,
    handleDragLeave,
  } = useDragAndDrop();

  const folderId = location.pathname.split("/")[3];

  const tableClass = classNames({
    // 'o-drag-border--color': dragging,
    "o-drag-border--color": _.isEqual(draggingId, 0),
    "o-min-height-dropzone": draggable,
  });

  const onChange = (e: any, files: any) => {
    e.stopPropagation();
    e.preventDefault();

    const target: any = {
      target: {
        files,
      },
    };

    if (handleFileLoad) handleFileLoad(target);
  };

  return (
    <div
      className={`o-drag-border py-2 ${tableClass}`}
      draggable={draggable}
      onDrag={handleDrag}
      onDrop={(e) => handleDrop(e, { parent_id: folderId }, onChange)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) =>
        handleDragEnter(e, { id: 0, type: "dropzone" } as TDropZone)
      }
      onDragLeave={(e) => handleDragLeave(e, {})}
      onDragStart={(e) => e.preventDefault()}
    >
      <table className={`c-table ${extraClassName}`}>
        <thead>
          <tr className="c-table__thead-row p-0">
            {columns.length > 0 &&
              columns.map((column, columnIndex) => {
                const thClass = classNames({
                  "c-table__title--collapsable": column.collapsable,
                  "c-table__title--collapsable-desktop":
                    column.collapsableDesktop,
                  "c-table__title--hidden-mobile": column.hiddenMobileTitle,
                  "p-0": !showHeader,
                  "pb-3": showHeader,
                });
                const tcClass = classNames({
                  "c-table__title--hidden": !showHeader,
                });
                let width = "unset";
                if (column.fixedWidth) {
                  Object.keys(responsive).forEach((property) => {
                    if (
                      responsive[property] &&
                      column.fixedWidth![property as keyof IResponsive]
                    ) {
                      width =
                        column.fixedWidth![property as keyof IResponsive]!;
                    }
                  });
                }
                let colspan = "0";
                if (column.colspan) {
                  Object.keys(responsive).forEach((property) => {
                    if (
                      responsive[property] &&
                      column.colspan![property as keyof IResponsive]
                    ) {
                      colspan = column.colspan![property as keyof IResponsive]!;
                    }
                  });
                }

                return _.isEqual(column.id, "checkbox") ? (
                  responsive.md && (
                    <TableHeader
                      // eslint-disable-next-line react/no-array-index-key
                      key={columnIndex}
                      {...{
                        colspan,
                        tcClass,
                        thClass,
                        column,
                        width,
                        componentId,
                      }}
                    />
                  )
                ) : (
                  <TableHeader
                    // eslint-disable-next-line react/no-array-index-key
                    key={columnIndex}
                    {...{
                      colspan,
                      tcClass,
                      thClass,
                      column,
                      width,
                      componentId,
                    }}
                  />
                );
              })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <TableItem
              draggable={draggable}
              onContextMenu={(e: any) => {
                if (contextMenu && item.type) {
                  const { setPosition, setFile, setIsVisible } = contextMenu;
                  e.preventDefault();
                  e.stopPropagation();
                  setFile(item);
                  setPosition({ x: e.pageX, y: e.pageY });
                  setIsVisible(true);
                } else {
                  e.stopPropagation();
                }
              }}
              key={item.id}
              {...{
                dynamicRowClassName,
                onRowClick,
                columns,
                index,
                handleFileLoad,
                canClickRow,
              }}
              data={item}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
