import classNames from "classnames";
import _ from "lodash";
import MaterialIcon, { TIcon } from "./MaterialIcon";
import React from "react";

interface IRadioButtonProps {
  item: TRadioButton;
  active: boolean;
  index: number;
  onClick: (
    elem: IRadioButtonProps["item"],
    index: IRadioButtonProps["index"],
  ) => void;
  variant?: "block";
}

export type TRadioButton = {
  id: number;
  name: TRadioButtonName;
  value: string;
  icon?: TIcon;
  pin_to_dashboard?: { id: number };
  position?: number;
};

export type TRadioButtonName = "replace" | "keep" | "";

export enum RadioNames {
  Replace = "replace",
  Keep = "keep",
}

export const RadioButton = ({
  item,
  active,
  index,
  onClick,
  variant,
}: IRadioButtonProps) => {
  const { value } = item;

  const radioButtonClass = classNames({
    "c-radio-button__radio--active": active,
    "c-radio-button__radio--mr-0": _.isEqual(variant, "block"),
  });

  const handleClick = () => onClick(item, index);

  return _.isEqual(variant, "block") ? (
    <div
      aria-hidden
      onClick={handleClick}
      className="c-radio-button-container mb-3 d-flex justify-content-between align-items-center o-user-select-none o-cursor-pointer"
    >
      <div className="d-flex align-items-center">
        <div>
          <div className="o-border-icon-radius d-flex justify-content-center align-items-center">
            {item.icon ? <MaterialIcon icon={item.icon} size={20} /> : null}
          </div>
        </div>
        <div className="c-radio-button__value-container">
          <span className="c-radio-button__value o-cl-grey-100 o-ft-sm-700 ms-2">
            {item.value}
          </span>
        </div>
      </div>
      <div>
        <span className={`c-radio-button__radio ${radioButtonClass}`} />
      </div>
    </div>
  ) : (
    <div className="c-radio-button">
      <div
        aria-hidden
        className="d-inline-flex align-items-center o-user-select-none o-cursor-pointer"
        onClick={handleClick}
      >
        <span className={`c-radio-button__radio ${radioButtonClass}`} />
        <span className="o-cl-grey-100 o-ft-sm-700">{value}</span>
      </div>
    </div>
  );
};
