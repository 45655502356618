import { useEffect, useState } from "react";

interface IWindowSizeReturn {
  responsive: {
    [key: string]: boolean;
  };
  breakpoint: string;
}
const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

const getBreakpoint = (width: number) => {
  if (width < breakpoints.sm) return "xs";
  if (width < breakpoints.md) return "sm";
  if (width < breakpoints.lg) return "md";
  if (width < breakpoints.xl) return "lg";
  if (width < breakpoints.xxl) return "xl";
  return "xxl";
};

export default function useWindowSize(): IWindowSizeReturn {
  const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
  const [breakpoint, setBreakpoint] = useState(
    getBreakpoint(window.innerWidth),
  );
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerHeight, window.innerWidth]);
      setBreakpoint(getBreakpoint(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    responsive: {
      xs: size[1] > 0,
      sm: size[1] >= 576,
      md: size[1] >= 768,
      lg: size[1] >= 992,
      xl: size[1] >= 1200,
      xxl: size[1] >= 1400,
      xxxl: size[1] >= 1600,
    },
    breakpoint,
  };
}
