import Card from '@components/Card';
import TContact from '@models/Contact.type';

interface ICardListProps {
  data: TContact[];
}

const CardList = ({ data }: ICardListProps) => {
  return (
    <div className='c-card-list p-0'>
      <div className='c-card-list__row'>
        {data?.map((item) =>
          <Card key={item.id} item={item} />
        )}
      </div>
    </div>
  );
};

export default CardList;
