import AdminHttpProvider from '@api/AdminHttpProvider';

const adminApi = new AdminHttpProvider();

export default class AdminSearchController {
  search(query: string) {
    return adminApi.request('get', query);
  }

  mainSearchBy(query: string, filter: string) {
    return adminApi.request('get', `api/search?filters[${filter.toLowerCase()}]=${query}&perPage=4`);
  }

  getNextPage(query: string) {
    return adminApi.request('get', query);
  }
}
