import React, { useContext } from 'react'
import { PanelTabsContext } from './PanelTabs'

interface ITabProps {
  tab: {
    id: number;
    title: string;
  }
}

export default function TabButton({ tab }: ITabProps) {

  const { id, title } = tab
  const { onChange, activeTab } = useContext(PanelTabsContext)

  return (
    <div className={activeTab?.id === id ? 'c-tab-button__title c-tab-button__title--active' : 'c-tab-button__title'} onClick={() => onChange(tab)}>
      {title}
    </div>
  )
}
