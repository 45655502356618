import React, { useEffect, useState, Fragment } from "react";
import _ from "lodash";
import useWindowSize from "@hooks/useWindowSize";
import { NavLink, useLocation } from "react-router-dom";
import { TMenuSection } from "@models/section/MenuSection.type";
import { formatCapitalizeFirstLetter } from "@helpers/formatCapitalizeFirstLetter";

interface IBreadcrumb {
  routes: TMenuSection[] | null;
}

const Breadcrumb = ({ routes }: IBreadcrumb) => {

  const location = useLocation();
  const { responsive } = useWindowSize();
  const [breadcrumbItems, setBreadcrumbItems] = useState<(TMenuSection)[]>([])

  useEffect(() => {
    createBreadcrumb()
  }, [location])

  const createSubRoutes = () => {
    let route = location.pathname.slice(1).split('/')
    let subRoutes: string[] = []
    route.forEach((r, index) => {
      let subRoute = ""
      for (let i = 0; i <= index; i++) {
        if (i != 0)
          subRoute = subRoute.concat("/")
        subRoute = subRoute.concat(route[i])
      }
      subRoutes.push(subRoute)
    })
    return subRoutes
  }

  const createBreadcrumb = () => {
    let subRoutes = createSubRoutes()
    subRoutes = subRoutes.filter((sr, i) => sr !== "admin" || i)
    let breadcrumbRoutes: (TMenuSection)[] = [];
    if (routes) {
      subRoutes.forEach((item: string) => {
        const route = routes.find((route: TMenuSection) => route.route && _.isEqual(route.route, item))
        route && breadcrumbRoutes.push(route)
      })
      setBreadcrumbItems(breadcrumbRoutes)
    }
  }

  return (
    <div className="c-breadcrumb d-flex flex-row w-100">
      {(breadcrumbItems.length < 4 || (responsive.lg && breadcrumbItems.length < 5))
        ?
        <>
          {breadcrumbItems.slice(0, breadcrumbItems.length - 1).map((item) => {
            let name = formatCapitalizeFirstLetter(item?.name);
            return (
              <Fragment key={item.id}>
                <NavLink className="c-breadcrumb__text o-ft-base-400 d-none d-md-inline" to={`/${item.route}`}>{name}</NavLink>
                <span className="c-breadcrumb__separator d-none d-md-flex">/</span>
              </Fragment>
            )
          })}
          <span className="c-breadcrumb__text--active d-none d-md-inline">{formatCapitalizeFirstLetter(breadcrumbItems[breadcrumbItems.length - 1]?.name)}</span>
        </>
        :
        <>
          <NavLink className="c-breadcrumb__text o-ft-base-400 d-none d-md-flex flex-shrink-0" to={`/${breadcrumbItems[0].route}`}>{formatCapitalizeFirstLetter(breadcrumbItems[0]?.name)}</NavLink>
          <div className='c-breadcrumb__pop-up d-flex position-relative flex-shrink-0'>
            <span className="c-breadcrumb__separator d-none d-md-flex o-cursor-pointer flex-shrink-0">/... /</span>
            <div className='c-breadcrumb__pop-up-list position-absolute'>
              {
                breadcrumbItems.slice(1, breadcrumbItems.length - (responsive.xl ? 1 : 2)).map((route) => (
                  <NavLink key={route.id} className="c-breadcrumb__pop-up-item o-ft-base-400 o-cl-grey-100" to={`/${route.route}`}>{formatCapitalizeFirstLetter(route.name)}</NavLink>
                ))
              }
            </div>
          </div>
          {responsive.xl &&
            <>
              <NavLink className="c-breadcrumb__text o-ft-base-400 d-none d-md-flex flex-shrink-0" to={`/${breadcrumbItems[breadcrumbItems.length - 2].route}`}>{formatCapitalizeFirstLetter(breadcrumbItems[breadcrumbItems.length - 2]?.name)}</NavLink>
              <span className="c-breadcrumb__separator d-none d-md-flex flex-shrink-0">/</span>
            </>
          }
          <span className="c-breadcrumb__text--active d-none d-md-inline flex-shrink-1">{formatCapitalizeFirstLetter(breadcrumbItems[breadcrumbItems.length - 1]?.name)}</span>
        </>
      }
    </div>
  );
};

export default Breadcrumb;
