/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  GridFile,
  GridFolder,
  GridTrashFile,
  GridTrashFolder,
  GridVaultFolder,
} from "@components/svg";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Checkbox from "@components/Checkbox";
import DotsMenu from "@components/DotsMenu";
import { IAction } from "@models/Action.type";
import { IFile } from "@models/files/File.type";
import { IRow } from "@models/Table.type";
import { ITrashAttributes } from "@models/trash/Trash.type";
import MaterialIcon from "@components/MaterialIcon";
import { SectionModuleItem } from "./SectionModuleItem";
import { Thumbnail } from "./Thumbnail";
import _ from "lodash";
import classNames from "classnames";
import { fixUrl } from "../utils/utils";
import { formatExtension } from "@helpers/formatExtension";
import { previewFile } from "@helpers/previewFile";
import { setCurrentVault } from "@redux/vaults/slices/vaultsSlice";
import { toggleSelectedFile } from "@redux/files/thunks/fileThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { useDragAndDrop } from "@hooks/useDragAndDrop";
import { useGetDimensionsOfElement } from "@hooks/useGetDimensionsOfElement";
import { useHandleFavorite } from "@hooks/useHandleFavorite";
import { useRenameElementActive } from "@hooks/useRenameElementActive";
import useWindowSize from "@hooks/useWindowSize";
import { usePinActionList } from "@hooks/usePinActionList";
import { usePinUnpinContext } from "../providers/PinUnpinProvider";

interface IGridProps {
  data: IFile | ITrashAttributes | any;
  actionList?: IAction[];
  draggable?: boolean;
  checkboxes?: boolean;
  trashActionList?: IAction[];
  dynamicCardClassName?: (arg: IRow) => string; // Add a class string to a row based on a condition
  handleFileLoad?: (e: React.ChangeEvent) => void;
  replaceSpacesForUnderline?: boolean;
  context?: string;
}

const GridItem = ({
  data,
  actionList,
  trashActionList,
  dynamicCardClassName,
  handleFileLoad,
  draggable,
  checkboxes,
  replaceSpacesForUnderline = false,
  context,
}: IGridProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { responsive } = useWindowSize();
  const ref = useRef(null);
  const renameInputRef = useRef(null);
  const { width } = useGetDimensionsOfElement(ref);
  const { saved, handleFavorite } = useHandleFavorite();
  const {
    file: { selectedFiles, draggingId },
  } = useAppSelector((state) => state);
  const [isHovered, setIsHovered] = useState(false);
  const { tooglePinDashboard, tooglePinBottomBar } = usePinUnpinContext();
  const { actionList: pinActionList } = usePinActionList({
    context,
    document: data,
    tooglePinDashboard,
    tooglePinBottomBar,
  });

  const filteredActionList = useMemo(() => {
    const noFavoriteOption = [
      ...(actionList ?? []),
      ...(context !== "favorites" ? pinActionList : []),
    ]?.filter((action) => !_.isEqual(action.type, "favorite"));
    if (data.type === null) {
      return noFavoriteOption;
    }
    return noFavoriteOption?.filter((action) => action.id !== "addVault");
  }, [actionList, data.type]);

  const {
    handleDrop,
    handleDrag,
    handleDragEnter,
    handleDragOver,
    handleDragLeave,
    handleDragStart,
    handleDragEnd,
  } = useDragAndDrop();
  const { elementToRename, onKeyDown, onBlur, onClick, value, setValue } =
    useRenameElementActive(data?.name);
  const isInFileManagement = location.pathname.includes(
    `/admin/file-management`,
  );

  const isInVaults = location.pathname.includes(`/vaults`);
  const listWithoutFavoriteOption = [
    ...(actionList ?? []),
    ...pinActionList,
  ]?.filter((o) => !_.isEqual(o.type, "favorite"));

  const generateVaultDocumentUrl = (id: any, name: any) => {
    dispatch(setCurrentVault({ id, name }));
    return location.pathname.includes("documents")
      ? `${location.pathname}/${id}`
      : `documents/${id}`;
  };

  const [isDotsMenuOpened, setIsDotMenuOpened] = useState(false);

  useEffect(() => {
    setValue(data?.name);
  }, [elementToRename]);

  const onHandleClick = () => {
    if (!data.type && !data.deleted_at)
      navigate(`/admin/file-management/${data.id}`);
    else previewFile(data);
  };

  const isSelectedFile = () =>
    selectedFiles.some((file) => _.isEqual(file.id, data.id));
  const classFavoriteIcon = saved(data) ? "filled" : "outline";

  const gridNameClass = classNames({
    "o-hide-element": _.isEqual(data.id, elementToRename?.id) && responsive.md,
  });

  const gridChildClass = classNames({
    "o-drag-border--color": !data.type && _.isEqual(draggingId, data.id),
    // 'c-grid__child--border': (!data.type && _.isEqual(draggingId, data.id)),
    "c-grid__child--admin": isInFileManagement,
  });

  const actionsClassName = classNames({
    "justify-content-end": data.deleted_at || !checkboxes,
    "justify-content-between": !data.deleted_at && checkboxes,
  });

  const gridNameTextClass = classNames({
    "c-grid__name-text": data?.name?.length > 15,
    "d-inline-block": data?.name?.length <= 15,
  });

  const gridMobileActionsClass = classNames({
    "c-grid__mobile-actions--top": isInFileManagement,
  });

  const onChange = (e: any, filesData: any) => {
    e.stopPropagation();
    e.preventDefault();

    const target: any = {
      target: {
        files: filesData,
      },
    };

    if (handleFileLoad) handleFileLoad(target);
  };
  const displayDotsMenuDesktop =
    responsive.md && isDotsMenuOpened && "c-grid__actions--selected";
  const displayDotsMenuBackground =
    responsive.md && isDotsMenuOpened && "c-grid__child--admin-bg";

  const renderGridActions = () => (
    <>
      <div
        className={`c-grid__actions ${displayDotsMenuDesktop} px-1 position-absolute align-items-center w-100 ${actionsClassName}`}
      >
        {!data.deleted_at && !data.isDisabled && checkboxes && (
          <div
            className={`ps-2 ${
              isHovered || isSelectedFile() ? "visible" : "invisible"
            } ${!responsive.md && "invisible"}`}
          >
            <Checkbox
              item={data as IFile}
              onChange={() => dispatch(toggleSelectedFile(data))}
              checked={isSelectedFile()}
              showName={false}
            />
          </div>
        )}
        {!data.isDisabled && (
          <div className="d-flex flex-row">
            {!isInFileManagement && (
              <div
                className="me-1 o-cursor-pointer d-flex align-items-center"
                onClick={() => handleFavorite(data)}
                aria-hidden
              >
                <MaterialIcon
                  icon="favorite"
                  color="o-cl-brand-secondary"
                  size={20}
                  className={`material-symbols-${classFavoriteIcon}`}
                />
              </div>
            )}
            <div
              className={`${
                !isHovered || !responsive.md ? "invisible" : "visible"
              }`}
            >
              <DotsMenu
                setIsDotMenuOpened={setIsDotMenuOpened}
                actionList={
                  data.deleted_at ? trashActionList : filteredActionList
                }
                context={data}
                dotsDirection="horizontal"
              />
            </div>
          </div>
        )}
      </div>
      <div
        onClick={() => {
          if (!data.isDisabled) {
            onHandleClick();
          }
        }}
        className="c-grid__content d-flex align-items-center flex-column"
        title={`${data?.name}${data?.type ? formatExtension(data?.type) : ""}`}
        aria-hidden
      >
        <div className="position-relative">
          {data.type && data.deleted_at && <GridTrashFile />}
          {data.type && !data.deleted_at && data?.thumbnail && (
            <Thumbnail
              src={fixUrl(data?.thumbnail)}
              alt={data?.name || "image_alt"}
            />
          )}
          {data.type && !data.deleted_at && !data?.thumbnail && <GridFile />}

          {!data.type && data.deleted_at && <GridTrashFolder />}

          {!data.type && !data.deleted_at && data.vaults.length > 0 && (
            <GridVaultFolder />
          )}
          {!data.type && !data.deleted_at && data.vaults.length === 0 && (
            <GridFolder className={`${data.isDisabled && "opacity-25"}`} />
          )}
          {data?.type && !data?.thumbnail ? (
            <p className="c-grid__type position-absolute o-cl-white o-ft-lg-400 m-0 pe-1 pb-1 pb-md-2">
              {formatExtension(data?.type)}
            </p>
          ) : null}
        </div>
        {_.isEqual(data.id, elementToRename?.id) && responsive.md && (
          <div className="o-bg-white o-cl-grey-100 o-ft-sm-400 o-ft-lg-400@md d-flex mt-3">
            <input
              ref={renameInputRef}
              {...{ onKeyDown, onBlur, value, onClick }}
              style={{ maxWidth: `${width + 5}px` }}
              className="c-file-name__input o-cl-grey-100 o-ft-sm-400 o-ft-lg-400@md"
              onChange={(f) => setValue(f.target.value)}
              type="text"
            />
            {data?.type && <span>{formatExtension(data?.type)}</span>}
          </div>
        )}
        <p
          className={`c-grid__name o-cl-grey-100 o-ft-sm-400 o-ft-lg-400@md text-center pt-3 m-0 ${gridNameClass}`}
        >
          <span className={`${gridNameTextClass}`} ref={ref}>
            {data?.name}
          </span>
        </p>
      </div>
      {!responsive.md && (
        <div
          className={`c-grid__mobile-actions ${gridMobileActionsClass} position-absolute d-flex flex-row`}
        />
      )}
    </>
  );

  return (
    <div
      draggable={!data.deleted_at && draggable && !isDotsMenuOpened}
      onDrag={handleDrag}
      onDrop={(e) => handleDrop(e, data, onChange)}
      onDragOver={(e) => handleDragOver(e, data)}
      onDragLeave={(e) => handleDragLeave(e, data)}
      onDragStart={(e) => handleDragStart(e, data)}
      onDragEnter={(e) => handleDragEnter(e, data)}
      onDragEnd={handleDragEnd}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`c-grid__child ${
        isInFileManagement ? "o-drag-border" : ""
      } ${gridChildClass} ${
        isInFileManagement && displayDotsMenuBackground
      } o-cursor-pointer position-relative ${
        dynamicCardClassName && isInFileManagement && dynamicCardClassName(data)
      } `}
      key={data?.id}
    >
      {!data.type && !isInFileManagement && (
        <SectionModuleItem
          element={data}
          key={data.id}
          title={data.name}
          // onClick={() => navigate(`${location.pathname}/${data.section.route}`)}
          onClick={() => {
            let navigateTo = "";
            if (isInVaults)
              navigateTo = generateVaultDocumentUrl(data.id, data.name);
            if (!isInVaults && replaceSpacesForUnderline)
              navigateTo = `${location.pathname}/${data.name.replaceAll(" ", "_")}`;
            if (!isInVaults && !replaceSpacesForUnderline)
              navigateTo = `/documents/${data.id}`;
            navigate(navigateTo);
          }}
          actionList={listWithoutFavoriteOption}
          background={data?.section?.background}
        />
      )}
      {data.type && !isInFileManagement && (
        <div
          className={`o-bg-grey-300:hover c-grid__child-section ${
            dynamicCardClassName && dynamicCardClassName(data)
          } position-relative`}
        >
          <div
            className={`${displayDotsMenuBackground} ${
              dynamicCardClassName && dynamicCardClassName(data)
            } w-100 h-100`}
          >
            {renderGridActions()}
          </div>
        </div>
      )}
      {isInFileManagement && (
        <div
          className={` o-bg-grey-300:hover ${
            dynamicCardClassName && dynamicCardClassName(data)
          } position-relative`}
        >
          {renderGridActions()}
        </div>
      )}
    </div>
  );
};
export default GridItem;
