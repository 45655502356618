/* eslint-disable react/jsx-no-useless-fragment */
import Actions from "@components/Actions";
import { IRow } from "@models/Table.type";
import DotsMenu from "@components/DotsMenu";
import useWindowSize from "@hooks/useWindowSize";
import { IAction } from "@models/Action.type";
import React from "react";
import { usePinActionList } from "@hooks/usePinActionList";
import { usePinUnpinContext } from "../providers/PinUnpinProvider";

interface IActionsColumnProps {
  file: IRow;
  actionList: IAction[];
  dotsShowLength: number;
  dotsShow?: boolean;
  context?: string;
}

const ActionsColumn = ({
  file,
  actionList,
  dotsShowLength,
  dotsShow = true,
  context,
}: IActionsColumnProps) => {
  const { responsive } = useWindowSize();

  const { tooglePinDashboard, tooglePinBottomBar } = usePinUnpinContext();
  const { actionList: pinActionList } = usePinActionList({
    context,
    document: file,
    tooglePinDashboard,
    tooglePinBottomBar,
  });

  const fileActionList = [...actionList, ...pinActionList];

  return (
    <>
      {responsive.lg ? (
        <div className="d-flex align-items-center justify-content-between flex-grow-1">
          <Actions
            file={file}
            list={fileActionList?.slice(0, dotsShowLength)}
          />
          {dotsShow &&
            fileActionList &&
            fileActionList?.length > dotsShowLength && (
              <DotsMenu
                context={file}
                actionList={fileActionList?.slice(
                  dotsShowLength,
                  fileActionList?.length,
                )}
                dotsDirection="vertical"
              />
            )}
        </div>
      ) : (
        <DotsMenu
          context={file}
          actionList={fileActionList}
          dotsDirection="vertical"
        />
      )}
    </>
  );
};

export default ActionsColumn;
