import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { formatCapitalizeFirstLetter } from '@helpers/formatCapitalizeFirstLetter'
import useAppSelector from '@hooks/useAppSelector'

type CrumbProps = {
	currentLink: string;
	isActive: boolean;
	label: string;
	show: boolean;
}

const Crumb: React.FC<CrumbProps> = ({ currentLink, isActive, label, show }) => {
	const { state } = useLocation()
	const navigate = useNavigate()
	if(show) {
		const activeClass = isActive ? 'c-breadcrumb__text--active' : ''
		const classNames = `c-breadcrumb__text ${activeClass}`
	
		return (
			<>
				<div className={classNames} role='link' onClick={() => navigate(currentLink, { state })}>
					{formatCapitalizeFirstLetter(label)}
				</div>
				<span className="c-breadcrumb__separator">/</span>
			</>
		)
	}
	return <></>
}

export type Props = {
	offset?: number;
	withIds?: boolean;
}

const VaultBreadcrumb: React.FC<Props> = () => {
	const { pathname } = useLocation()
	const {breadcrumbArray, data: vaults} = useAppSelector((state) => state.vaults)
	const paths = pathname.split('/').filter(p => p !== '')

	let currentLink = '';
	const crumbs = paths.map((crumb, index, arr) => {
		currentLink += '/' + crumb;
	  
		const label = breadcrumbArray.find((bre: any) => bre.id == crumb) || vaults.find((vault) => vault.id == crumb);

		const props: CrumbProps = {
		  currentLink,
		  label: label?.name || crumb,
		  isActive: (index + 1 === arr.length),
			show: crumb !== "documents"
		}
	  
		return <Crumb key={index} {...props} />
	  })

	return <div className='c-breadcrumb d-flex flex-row'>{crumbs}</div>
}

export default React.memo(VaultBreadcrumb)