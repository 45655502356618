import React from "react";
import PinUnpinProvider from "../providers/PinUnpinProvider";
import TrashSection from "@sections/Trash/TrashSection";

const Trash = () => {
  return (
    <PinUnpinProvider context="trash">
      <TrashSection />
    </PinUnpinProvider>
  );
};

export default Trash;
