import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { getCookie } from "@api/AdminHttpProvider";

const getHeaders = () => {
  const token =
    localStorage.getItem("current_website") === "admin"
      ? getCookie("access_token")
      : localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": `application/json`,
  };
};

type ProjectProcessStep = {
  id: number;
  process_step_id: number;
  project_process_id: number;
  review_status: "To Review" | "Approved" | "Rejected";
  status: number;
};

const baseUrl = process.env.REACT_APP_BACK_BASE_URL;

export const processReviewApi = createApi({
  reducerPath: "processSlice",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getProcessReview: builder.query({
      query: ({ id }) => ({
        url: `api/projects/processes/steps/${id}/review`,
        headers: getHeaders(),
      }),
      transformResponse: (res: any) => res.data,
    }),
    getProjectProcessStepsByProjectProcessId: builder.query<
      ProjectProcessStep[],
      { id: number }
    >({
      query: ({ id }) => ({
        url: `api/projects/processes/${id}/steps`,
        headers: getHeaders(),
      }),
      transformResponse: (res: any) => res.data,
    }),
    getComments: builder.query({
      query: ({ projectProcessId }) => ({
        url: `api/projects/processes/${projectProcessId}/comments`,
        headers: getHeaders(),
      }),
      transformResponse: (res: any) => res.data,
    }),
  }),
  refetchOnFocus: true,
});
export const {
  useGetProcessReviewQuery: useGetProcessReview,
  useGetProjectProcessStepsByProjectProcessIdQuery:
    useGetProjectProcessStepsByProjectProcessId,
  useGetCommentsQuery: useGetComments,
} = processReviewApi;
