import React from 'react'
import { Outlet } from 'react-router-dom';

export type OutletContext = any

const ProcessReviewLayout: React.FC = () => {
	const context: OutletContext = {}

	return (
		<div className='o-admin-screen-container w-100'>
			<Outlet context={context} />
		</div >
	)
}

export default ProcessReviewLayout;
