import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMyStore, IMyStoreSlice, IProject } from './types';
import { IProcess } from '@redux/approval-process/slices/processSlice';

const initialState: IMyStoreSlice = {
	selectedStore: null,
	selectedProject: null,
	selectedProcess: null,
	projectProcessId: null,
	projectProcessSteps: null
}

export const myStoresSlice = createSlice({
	name: 'my-stores',
	initialState,
	reducers: {
		setSelectedStore: (state, action: PayloadAction<IMyStore | null>) => {
			state.selectedStore = action.payload
		},
		setSelectedProject: (state, action: PayloadAction<IProject | null>) => {
			state.selectedProject = action.payload
		},
		setSelectedProcess: (state, action: PayloadAction<IProcess | null>) => {
			state.selectedProcess = action.payload
		},
		setProjectProcessId: (state, action: PayloadAction<number | null>) => {
			state.projectProcessId = action.payload
		},
		setProjectProcessSteps: (state, action: PayloadAction<any[] | null>) => {
			state.projectProcessSteps = action.payload
		}
	}
});

export const {
	setSelectedStore,
	setSelectedProject,
	setSelectedProcess,
	setProjectProcessId,
	setProjectProcessSteps
} = myStoresSlice.actions;
export default myStoresSlice.reducer;
