import { ToastContext } from "../providers/ToastsProvider";
import { useContext } from "react";

const useToastContext = () => {
  const context = useContext(ToastContext);

  if (context === null) {
    throw new Error("toastContext must be used within a ToastProvider");
  }

  return context;
};

export default useToastContext;
