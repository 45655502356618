import { IAlertOption } from "@components/Alert/models/IAlertOption";
import { IAlertProps } from "@components/Alert/models/IAlertProps";
import MaterialIcon from "@components/MaterialIcon";
import React from "react";

const alertOptions: IAlertOption[] = [
  {
    type: "success",
    icon: "check_circle",
    color: "o-cl-green",
  },
  {
    type: "danger",
    icon: "error",
    color: "o-cl-red",
  },
  {
    type: "warning",
    icon: "error",
    color: "o-cl-yellow",
  },
  {
    type: "info",
    icon: "error",
    color: "o-cl-grey-200",
  },
];

const Alert: React.FC<IAlertProps> = ({ title, type }) => {
  const option = alertOptions.find((opt) => opt.type === type);

  if (!option) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <div
      className="d-flex align-items-center gap-2 py-2 px-2 o-bg-grey-300 rounded"
      data-testid="alert"
    >
      <MaterialIcon
        size={20}
        icon={option.icon}
        color={option.color}
        className="material-symbols-filled"
      />
      <span className="o-ft-xs-400 o-cl-grey-100" data-testid="alert-title">
        {title}
      </span>
    </div>
  );
};

export default Alert;
