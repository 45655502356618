import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { Spinner } from 'react-activity';

import FolderItem from '@components/FolderItem';
import { colors } from '@theme/colors';
import useAppDispatch from '@hooks/useAppDispatch';
import { setSelectedProject } from '@redux/my-stores/slice';
import { IProject } from '@redux/my-stores/types';
import { OutletContext } from '@views/MyStores/Layout/Layout';
import useWindowSize from '@hooks/useWindowSize';

const Projects: React.FC = () => {
  const { projects: { data, isLoading } } = useOutletContext<OutletContext>();

  const location = useLocation()
  const navigate = useNavigate()
  const { responsive } = useWindowSize()

  const dispatch = useAppDispatch()

  const goToProject = (project: IProject) => {
    dispatch(setSelectedProject(project))
    navigate(location.pathname + '/' + (project.name || project.code).replaceAll(' ', '-') + '-' + project.id)
  }

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center mt-5'>
        <Spinner className='v-contacts__spinner' color={colors.brandColorSecondary} />
      </div>
    )
  }

  return (
    <div className={`c-grid c-grid--sm-layout mt-4 mb-3 ${!responsive.md ? 'c-grid--no-gap' : ''}`}>
      {!data || data.length === 0 ? (
        <FolderItem isNew />
      ) : data && data.length > 0 && data?.map((project: any) => (
        <div className='c-grid__child o-drag-border' key={project.id}>
          <FolderItem
            project={project}
            onClick={() => goToProject(project)}
          />
        </div>
      ))}
    </div>
  )
}

export default Projects


