/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpProvider, {
  getClientSecret,
  getClientId,
  baseURL,
} from "@api/HttpProvider";
import axios from "axios";
// import AdminHttpProvider from "@api/AdminHttpProvider";

const api = new HttpProvider();
// const adminApi = new AdminHttpProvider();

type TAuth = {
  email: string;
  password: string;
};

type TResetPassword = {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
  firebase?: string;
};

type TForgotPassword = {
  email: string;
};

interface IAuth {
  (
    onSuccess: (response: any) => void,
    onError: (error: any) => void,
    params: TAuth,
  ): void;
}

interface IAuthWithSingleSignOn {
  (
    authProvider: string,
    onSuccess?: (response: any) => void,
    onError?: (error: any) => void,
  ): void;
}
interface ILogOutWithSingleSignOn {
  (authProvider: string): void;
}

interface IResetPassword {
  (
    onSuccess: (response: any) => void,
    onError: (error: any) => void,
    params: TResetPassword,
  ): void;
}
interface IForgotPassword {
  (
    onSuccess: (response: any) => void,
    onError: (error: any) => void,
    params: TForgotPassword,
  ): void;
}

export default class AuthController {
  static traditionalAuth: IAuth = (onSuccess, onError, params) => {
    const body = {
      grant_type: "password",
      client_id: getClientId(),
      client_secret: getClientSecret(),
      username: params.email,
      password: params.password,
    };

    api
      .request("post", "oauth/token", body) // traditional
      .then((res) => onSuccess(res?.data))
      .catch((err) => onError(err));
  };

  static auth: IAuth = (onSuccess, onError, params) => {
    const body = {
      grant_type: "password",
      client_id: getClientId(),
      client_secret: getClientSecret(),
      username: params.email,
      password: params.password, // params.code concatenar sms code cuando aplique
    };

    api
      .request("post", "api/auth/signin", body) // firebase
      //  api.request('post', 'oauth/token', body) //traditional
      .then((res) => onSuccess(res?.data))
      .catch((err) => onError(err));
  };

  static authForExternalsWithoutTwoFA = (
    onSuccess: any,
    onError: any,
    params: any,
  ) => {
    const body = {
      client_id: getClientId(),
      client_secret: getClientSecret(),
      username: params.email,
      password: params.password,
    };

    api
      .request("post", "api/auth/signin", body) // firebase
      //  api.request('post', 'oauth/token', body) //traditional
      .then((res) => onSuccess(res?.data, params))
      .catch((err) => onError(err));
  };

  static authWithSingleSignOn: IAuthWithSingleSignOn = (
    authProvider,
    onSuccess,
    onError,
  ) => {
    api
      .request("get", `auth/platform/${authProvider}/redirect`)
      .then((r) => onSuccess?.(r))
      .catch((e) => onError?.(e));
  };

  static logOutWithSingleSignOn: ILogOutWithSingleSignOn = (
    authProvider: string,
  ) => {
    api.request("get", `auth/platform/${authProvider}/logout`);
  };

  static forgotPassword: IForgotPassword = (onSuccess, onError, params) => {
    const body = {
      email: params.email,
    };
    AuthController.getClientCredentialToken()
      .then((res: any) => {
        const { access_token: accessToken } = res.data;
        axios
          .post(`${baseURL}api/auth/password/forgot`, body, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: "application/json",
              "Content-Type": `application/json`,
              "Access-Control-Allow-Methods":
                "GET,PUT,POST,DELETE,PATCH,OPTIONS",
              "Access-Control-Allow-Credentials": true,
            },
          })
          .then((re) => onSuccess(re?.data))
          .catch((err) => onError(err));
      })
      .catch((err: any) => onError(err));
  };

  static resetPassword: IResetPassword = (onSuccess, onError, params) => {
    const body: TResetPassword = {
      token: params.token,
      email: params.email,
      password: params.password,
      password_confirmation: params.password_confirmation,
    };

    if (params.firebase) {
      body.firebase = params.firebase;
    }

    AuthController.getClientCredentialToken()
      .then((res: any) => {
        const { access_token: accessToken } = res.data;

        axios
          .post(`${baseURL}api/auth/password/reset`, body, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: "application/json",
              "Content-Type": `application/json`,
              "Access-Control-Allow-Methods":
                "GET,PUT,POST,DELETE,PATCH,OPTIONS",
              "Access-Control-Allow-Credentials": true,
            },
          })
          .then((re) => onSuccess(re?.data))
          .catch((err) => onError(err));
      })
      .catch((err: any) => onError(err));
  };

  static logOut = () => {
    return api.request("delete", "api/logout");
  };

  static getClientCredentialToken = async () => {
    const body = {
      grant_type: "client_credentials",
      client_id: getClientId(),
      client_secret: getClientSecret(),
    };
    return api.request("post", "oauth/token", body);
  };
}
