import MaterialIcon from '@components/MaterialIcon';
import React from 'react'
import { IFilter } from './Results';
import { formatCapitalizeFirstLetter } from '@helpers/formatCapitalizeFirstLetter';

export type Props = {
	filter: IFilter;
	noIcon?: boolean;
	onSelect?: (item: IFilter) => void;
	onClose?: () => void;
}

const Filter: React.FC<Props> = ({ filter, onSelect, onClose, noIcon = false }) => {
	return (
		<div key={filter.id} className='me-2' onClick={() => onSelect?.(filter)}>
			<div className='c-tag o-cursor-pointer d-inline-flex align-items-center'>
				{filter.icon && !noIcon && (
					<div className='c-tag__icon-right-container d-flex'>
						<MaterialIcon className='material-symbols-outlined c-tag__icon o-cl-grey-100 o-ft-xl' icon={filter.icon} onClick={onClose} />
					</div>
				)}
				<span className='c-tag__text o-ft-sm-700'>{formatCapitalizeFirstLetter(filter.name)}</span>
				{onClose && (
					<div className='c-tag__icon-left-container d-flex'>
						<MaterialIcon icon='close' onClick={onClose} className='material-symbols-outlined c-tag__icon o-cl-grey-100 o-ft-xl' />
					</div>)
				}
			</div>
		</div>
	)
}

export default Filter