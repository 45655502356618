import React, { useState } from "react";

import _ from "lodash";
import useWindowSize from "./useWindowSize";

export const useHighLight = () => {

  const { responsive } = useWindowSize();
  const [isHighlight, setIsHighlight] = useState(false);
  const [highlightElementMeasures, setHighlightElementMeasures] = useState<DOMRect | null>()

  interface IMeasures {
    x: number;
    y: number;
    w: number;
    h: number;
  }

  const activateScroll = () => {
    document.body.style.removeProperty('width');
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('position');
  }

  const blockScroll = () => {
    document.body.style.width = '100%';
    document.body.style.position = 'sticky';
    document.body.style.overflow = "hidden";
  }

  const elementHighlight = (cloneElement: HTMLElement, targetID: string, measures: IMeasures, scrollPosition?: number) => {
    const { x, y, w, h } = measures;
    switch (targetID) {
      case "searchIconOnboarding":
      case "favoritesOnboarding":
        blockScroll()
        cloneElement.style.width = `${w + 25}px`;
        cloneElement.style.height = `${h + 20}px`;
        cloneElement.style.top = `${y - (responsive.md ? 10 : 13)}px`;
        cloneElement.style.left = `${x - 13}px`;
        break;
      case "dashboardOnboarding":
        blockScroll()
        cloneElement.style.width = `${w + 25}px`;
        cloneElement.style.height = `${h + 20}px`;
        cloneElement.style.top = `${y - 10}px`;
        cloneElement.style.left = `${x - 10}px`;
        break;
      case "dashboardBottomTabOnboarding":
        blockScroll()
        cloneElement.style.width = `${h + 40}px`;
        cloneElement.style.height = `${h + 40}px`;
        cloneElement.style.top = `${y - 20}px`;
        cloneElement.style.left = `${x - ((responsive.xs && !responsive.sm) ? 43 : 47) + (w / 2)}px`;
        break;
      case "sidebarOnboarding":
        blockScroll()
        cloneElement.style.width = `${w + 20}px`;
        cloneElement.style.height = `${h + 20}px`;
        cloneElement.style.top = `${y - 10}px`;
        cloneElement.style.left = `${x - 10}px`;
        break;
      case "menuOnboarding":
        blockScroll()
        cloneElement.style.width = `${w + 25}px`;
        cloneElement.style.height = `${h + 40}px`;
        cloneElement.style.top = `${y - 34}px`;
        cloneElement.style.left = `${x - 12}px`;
        break;
      case "lastEditedOnboarding":
        blockScroll()
        cloneElement.style.height = `${h + 20}px`;
        cloneElement.style.top = `${y - 10}px`;
        cloneElement.style.left = `${x - 10}px`;
        break;
      default:
        blockScroll()
        cloneElement.style.width = `${w + 20}px`;
        cloneElement.style.height = `${h + 20}px`;
        cloneElement.style.top = `${y - 10}px`;
        cloneElement.style.left = `${x - 10}px`;
        break;
    }
  }

  const setOverlayOnElement = (elem: HTMLElement, id: string, targetID: string, defaultMeasures?: any) => {

    let measures;

    const cloneElement = elem.cloneNode(true) as HTMLElement

    if (_.isEqual(targetID, "lastEditedOnboarding")) {
      createTabElement()
      cloneElement.style.borderRadius = "15px"
    }

    if (!defaultMeasures) {
      const rect = elem.getBoundingClientRect();
      measures = {
        x: rect.left,
        y: rect.top,
        w: rect.width,
        h: rect.height
      }
      setHighlightElementMeasures(rect)
    }
    else {
      measures = defaultMeasures;
      setHighlightElementMeasures(defaultMeasures)
    }

    cloneElement.setAttribute("id", id);
    elementHighlight(cloneElement, targetID, measures)
    cloneElement.className = "highlightComponent overlayDeleteClass text-decoration-none"
    document.body.appendChild(cloneElement);
  }

  const highlightElement = (targetID: string) => {
    setHighlightElementMeasures(null)
    let element: HTMLElement;
    let elementMeasures: DOMRect;
    let timer: NodeJS.Timeout | null = null;

    const createElement = () => {
      removeHighlightElement()
      if (!element) {
        const elem = document.getElementById(targetID);
        if (elem) {
          setOverlayOnElement(elem, "overlayID", targetID);
          setIsHighlight(true);
        }
      }
      else {
        setOverlayOnElement(element, "overlayID", targetID, elementMeasures);
        setIsHighlight(true);
      }

    }

    const scrollListener = () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        if (!_.isEqual(targetID, "lastEditedOnboarding")) {
          if (window.scrollY) {
            window.scrollTo(0, 0)
          }
          else {
            window.removeEventListener("scroll", scrollListener)
            createElement()
          }
        }
        else {
          if (window.scrollY) {
            //Que el highlight se encuentre dentro de la pantalla
            //Posicion del highlight < (pixeles scrolleados + alto de la pantalla)
            if (((elementMeasures?.top + 250) < (window.scrollY + window.innerHeight))) {
              window.removeEventListener("scroll", scrollListener)
              createElement()
            }
            else {
              window.scrollTo(0, 0)
            }
          }
          else {
            const lastEditedElement = document.getElementById(targetID);
            if (lastEditedElement) {
              element = lastEditedElement
              elementMeasures = lastEditedElement.getBoundingClientRect();
              window.scrollTo(0, elementMeasures.top - 250)
            }
          }
        }
      }, 150)
    }

    if (_.isEqual(targetID, "lastEditedOnboarding")) {
      if (window.scrollY) {
        window.scrollTo(0, 0)
        window.addEventListener('scroll', scrollListener, false);
      }
      else {
        const lastEditedElement = document.getElementById(targetID);
        if (lastEditedElement) {
          element = lastEditedElement
          elementMeasures = lastEditedElement.getBoundingClientRect();
          window.scrollTo(0, elementMeasures.top - 250)
          window.addEventListener('scroll', scrollListener, false);
        }
      }
    }
    else {
      if (!window.scrollY) {
        createElement()
      }
      else {
        window.scrollTo(0, 0)
        window.addEventListener('scroll', scrollListener, false);
      }
    }
  };


  const removeHighlightElement = (targetID?: string) => {
    const overlayObjects = document.querySelectorAll('.overlayDeleteClass');
    overlayObjects.forEach(obj => {
      obj.remove();
    });
    activateScroll()
    setIsHighlight(false);
  };

  const getElementMeasures = (targetID?: string) => {
    if (highlightElementMeasures) {
      return highlightElementMeasures;
    }
    return null
    // else {
    //   const elem = document.getElementById(targetID);
    //   if (elem) {
    //     const rect = elem.getBoundingClientRect();
    //     const measures = {
    //       x: rect.left,
    //       y: rect.top,
    //       w: rect.width,
    //       h: rect.height
    //     }
    //     return measures
    //   }
    //   return null
    // }
  }

  const createTabElement = () => {

    const tabNavigatorElement = document.getElementById("bottomTabOnboarding");

    if (tabNavigatorElement) {
      const rect = tabNavigatorElement.getBoundingClientRect();
      const measures = {
        x: rect.left,
        y: rect.top,
        w: rect.width,
        h: rect.height
      }

      var div = document.createElement('div');
      div.setAttribute("id", "overlayID");
      div.className = "overlayTab overlayDeleteClass"
      div.style.width = `${measures.w + 10}px`;
      div.style.height = `${measures.h}px`;
      div.style.top = `${measures.y}px`;
      div.style.left = `${measures.x - 5}px`;

      const cloneElement2 = tabNavigatorElement.cloneNode(true) as HTMLElement
      cloneElement2.setAttribute("id", "overlayID");
      cloneElement2.className = "highlightTab overlayDeleteClass text-decoration-none"
      cloneElement2.style.width = `${measures.w}px`;
      cloneElement2.style.height = `${measures.h}px`;
      cloneElement2.style.top = `${measures.y}px`;
      cloneElement2.style.left = `${measures.x}px`;

      document.body.appendChild(div);
      document.body.appendChild(cloneElement2);
    }
  }

  return {
    isHighlight,
    highlightElement,
    getElementMeasures,
    removeHighlightElement
  };
};
