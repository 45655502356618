import React, { useEffect, useRef, useState } from 'react';

import Chip from '@components/Chip';
import Button from '@components/Button';

import { uniqueId } from 'lodash'

type Props = {
  value: string;
  onChange: (fieldId: number, value: File[]) => void;
  setRemovedFiles?: React.Dispatch<any>;
  setErrors: any;
  fieldId: number;
  required: boolean;
  disabled?: boolean;
  errorFiles: any[];
  initialFiles: any[];
  loadingFiles: { [key: string]: boolean } | undefined
  setLoadingFiles: React.Dispatch<{ [key: string]: boolean } | undefined>
}

const UploadButton: React.FC<Props> = ({
  value,
  setErrors,
  fieldId,
  disabled,
  required,
  onChange,
  errorFiles,
  initialFiles,
  setRemovedFiles,
  loadingFiles,
  setLoadingFiles
}) => {
  const [files, setFiles] = useState<any[]>(initialFiles);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleAddFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files

    const reader = new FileReader();

    reader.onprogress = (e) => {
      setLoadingFiles({ [fieldId]: e.loaded !== e.total })
    };

    if (newFiles) {
      for (let index = 0; index < newFiles.length; index++) {
        const newFile: File = newFiles[index];

        reader.readAsDataURL(newFile);

        setFiles(prev => [...prev, { id: uniqueId(), value: newFile }])
        onChange(fieldId, [...files, { id: uniqueId(), value: newFile }])

        const fileSizeToKB = newFile.size / 1000;

        if (fileSizeToKB > 1000 * 512) {
          setErrors((prev: any) => ({ ...prev, [fieldId]: 'Some files are too big' }))
        }
      }
    }
  };

  useEffect(() => {
    setFiles(initialFiles)
  }, [initialFiles])

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleRemoveFile = (id: string | number) => {
    const filesUpdated = files.filter(prevFile => prevFile.id !== id)
    setFiles(filesUpdated)
    onChange(fieldId, filesUpdated)

    if (setRemovedFiles) {
      setRemovedFiles((prev: any) => [...prev, id])
    }

    if (filesUpdated.length === 0 && required) {
      setErrors((prev: any) => ({ ...prev, [fieldId]: 'files are required' }))
    } else {
      setErrors((prev: any) => ({ ...prev, [fieldId]: '' }))
    }
  }

  return (
    <>
      <input ref={inputRef} name='file' type="file" multiple onChange={handleAddFile} disabled={disabled} />
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '1rem' }}>
        {files.map((file: any, i) => (
          <div key={i}>
            <Chip
              error={
                (file.value.size / 1000) > (1000 * 512) ? 'Maximum of weight: 512MB'
                : errorFiles.find(errFile => errFile.name === file.value.original_name || file.value.name) ? "Couldn't upload file"
                : ''
              }
              isLoading={!!loadingFiles?.[fieldId]}
              id={file.id}
              name={file.value.original_name || file.value.name}
              icon={(file.value.size / 1000) > (1000 * 512) ? 'warning' : 'draft'}
              onClose={disabled ? () => { } : handleRemoveFile}
              onClick={() => {
                if (file.value) {
                  window.open(file.value.url, '_blank', 'noreferrer')
                }
              }}
            />
          </div>
        ))}
      </div>
      <div className='mt-4'>
        <Button
          onClick={handleClick}
          className='c-form-card__upload-button'
          size='big'
          icon={{ icon: 'upload', size: 24, position: 'left' }}
          value={value}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default UploadButton;
