import useAppSelector from "@hooks/useAppSelector";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { TTag } from '../app/types/Tag.type';

interface ITagResultsProps {
  onClick: (tag: TTag) => void;
}

export const TagResults = ({ onClick }: ITagResultsProps) => {
  const { tags: { data, currentTagValue, tagsByDocumentsEditable }, file: { elementToTag } } = useAppSelector((state) => state);
  const elementToTagId = elementToTag?.id || 0;

  const includesString = (o: TTag) => o.name.toLowerCase().includes(currentTagValue.toLowerCase()) || false;

  const filteredData = data.filter((o) => !_.find(tagsByDocumentsEditable[elementToTagId], o) && includesString(o));

  return (
    <div className='c-tag-results o-bg-white position-absolute'>
      <ul className='c-tag-results__list'>
        <li
          onClick={() => onClick({ id: uuidv4(), name: currentTagValue })}
          className='c-tag-results__item o-cl-grey-100 o-ft-sm-400'>{`Create tag: #${currentTagValue}`}
        </li>
        {filteredData.map(({ id, name }) => {
          const tagName = name.charAt(0).toUpperCase() + name.slice(1);
          return <li onClick={() => onClick({ id, name })} key={id} className='c-tag-results__item o-cl-grey-100 o-ft-sm-400'>{`#${tagName}`}</li>
        })}
      </ul>
    </div>
  );
}
