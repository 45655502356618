import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import Axios from "axios";
import TagManager from "react-gtm-module";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sass/app.scss";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BACK_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": `application/json`,
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Credentials": true,
  },
});

axios.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("access_token");

    if (request.headers && token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    Promise.reject(error);
  },
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {" "}
    {/* by wraping the whole application  with the provider its giving the info to all the app */}
    <App />
  </Provider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
