import React, { useState } from "react"

import MaterialIcon from "../MaterialIcon"

import Folder from "./Folder"
import { useGetAllFilesQuery } from "@redux/files/slices/fileSlice"
import { Spinner } from "react-activity"

export type Props = {
	onOpen?: React.Dispatch<React.SetStateAction<boolean>>
	loading?: boolean;
}

const TreeView: React.FC<Props> = ({ onOpen, loading }) => {
	const [openFolderId, setOpenedFolderId] = useState<number>()

	const { data, isLoading } = useGetAllFilesQuery<any>()

	const folders = data ? data.filter((f: any) => f.type === null) : []

	const toggleOpen = () => {
		onOpen?.(!openFolderId)
		setOpenedFolderId(openFolderId ? undefined : 1)
	}

	return (
		<>
			<div className="c-tree-view__main" onClick={toggleOpen}>
				<div className="c-tree-view__inside">
					<div className="c-tree-view__icon-container">
						<MaterialIcon icon="folder" className="filled-icon" color={openFolderId ? 'o-cl-grey-100' : 'o-cl-grey-200'} />
						<span className={`c-tree-view__title ${openFolderId ? 'o-cl-grey-100' : 'o-cl-grey-200'}`}>File Management</span>
					</div>
					<MaterialIcon icon={openFolderId ? 'keyboard_arrow_down' : 'keyboard_arrow_right'} color={openFolderId ? 'o-cl-grey-100' : 'o-cl-grey-200'} />
				</div>
			</div>

			{openFolderId && (
				<div className="c-tree-view__container c-tree-view--scrollable" >
					{(isLoading || loading) ? (
						<Spinner />
					) : folders.length > 0 ? (
						folders.map((folder: any) => (
							<Folder
								key={folder.id}
								id={Number(folder.id)}
								name={folder.name}
								parent={folder.parent}
								onSelect={setOpenedFolderId}
								close={openFolderId !== folder.id}
							/>
						))
					) : (
						<span>No results</span>
					)}
				</div>
			)}
		</>
	)
}

export default React.memo(TreeView)
