import MaterialIcon from "@components/MaterialIcon"
import { Field } from "formik"

type Props = {
  name: string;
  placeholder?: string;
  error?: boolean;
  areaCode: string;
}

const PhoneInput: React.FC<Props> = ({ name, placeholder, error, areaCode, ...rest }) => {
  return (
    <div className="c-phone-input">
      <div className="c-phone-input__icon c-phone-input__icon--left d-flex align-items-center gap-1">
        <MaterialIcon
          icon="phone"
          size={18}
          color='o-cl-grey-200'
        />
        {areaCode && <span className="o-ft-sm-400">+{areaCode}</span>}
      </div>
      <Field
        type='text'
        className="c-phone-input__field"
        name={name}
        placeholder={placeholder}
        {...rest}
      />
      {error && (
        <MaterialIcon
          className="c-phone-input__icon c-phone-input__icon--right"
          icon='error'
          size={18}
          color='o-cl-red'
        />
      )}
    </div>
  )
}

export default PhoneInput