/* eslint-disable @typescript-eslint/no-explicit-any */
import { Formik, Form as FormikForm } from "formik";
import { Modal, ModalProps } from "react-bootstrap";

import Button from "@components/Button";
import React from "react";
import TextField from "@components/TextField";
import _ from "lodash";

interface IBuildButtons {
  buttonLeftText?: string;
  buttonRightText?: string;
}

interface IInputs {
  id: string;
  name: string;
  type: string;
  placeholder: string;
}

interface IFormProps {
  inputs: IInputs[];
  isLoading?: boolean;
  initialValues: any;
  customButtons?: JSX.Element;
  onHandleSubmit: (values: any) => void;
}

type IFormModalProps = ModalProps &
  (IBuildButtons & IFormProps) & {
    isVisible: boolean;
    title: string | JSX.Element;
    setIsVisible: (value: boolean) => void;
  };

const FormModal = ({
  isVisible,
  setIsVisible,
  initialValues,
  FolderSchema,
  inputs,
  customInputs,
  customButtons,
  title,
  buttonLeftText,
  buttonRightText,
  onHandleSubmit,
  isLoading,
}: IFormModalProps) => {
  const onSubmit = (values: any) => {
    onHandleSubmit(values);
  };

  const validateFormHasEmptyFields = (values: any) => {
    let response = false;
    Object.entries(values).forEach(([prop]) => {
      if (_.isEmpty(values[prop].trim())) response = true;
    });
    return response;
  };

  return (
    <Modal
      centered
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName="border-0 c-form-modal"
      className="c-form-modal"
      show={isVisible}
    >
      <div className="c-form-modal__content d-flex justify-content-center flex-column">
        <h3 className="o-ft-2xl-600 o-cl-grey-100 mb-3">{title}</h3>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={FolderSchema}
        >
          {({ handleBlur, values, errors, isSubmitting }) => (
            <FormikForm
              className="d-flex flex-column flex-grow-1"
              noValidate
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              {inputs
                ? inputs.map((input: IInputs) => (
                    <TextField
                      key={input.id}
                      name={input.id}
                      label={input.name}
                      type={input.type}
                      handleBlur={handleBlur}
                      placeholder={input.placeholder}
                      isfocusOnRender
                      disabled={isLoading}
                    />
                  ))
                : customInputs}
              <div className="d-flex w-100 mt-4 align-items-center justify-content-between pt-1">
                {customButtons || (
                  <>
                    <Button
                      className="w-100 mx-2 mx-md-4"
                      onClick={() => setIsVisible(false)}
                      size="medium-big"
                      value={buttonLeftText || "Cancel"}
                    />
                    <Button
                      className="w-100 mx-2 mx-md-4"
                      type="submit"
                      size="medium-big"
                      isLoading={isLoading}
                      disabled={
                        validateFormHasEmptyFields(values) ||
                        !_.isEmpty(errors) ||
                        isLoading ||
                        isSubmitting
                      }
                      value={buttonRightText || "Continue"}
                    />
                  </>
                )}
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default FormModal;
