import _ from "lodash";
import { IFile } from "@models/files/File.type";
import { TCheckbox } from "@components/Checkbox";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { EToastTypes } from "@models/toast/Toast.type";
import { addToast } from "@redux/toasts/slices/toastsSlice";
import FavoritesController from "@controllers/FavoritesController";
import { filterRouteByOrdering } from "@helpers/filterRouteByOrdering";
import { IFilterDropdownItem } from "@components/filters/FilterDropdownList";
import { setFavoriteOrderFilters, setFavoritesFilters } from "../slices/favoriteSlice";

const favoritesController = new FavoritesController();

export const getFavorites = createAsyncThunk(
  'favorites/index',
  async (args: void, { rejectWithValue }) => {
    try {
      let response: any = await favoritesController.index();
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFavoritesIdsTypes = createAsyncThunk(
  'favorites/idstypes',
  async (args: void, { rejectWithValue }) => {
    try {
      let response: any = await favoritesController.getIdsTypes();
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addFavorites = createAsyncThunk(
  'favorites/post',
  async ({ id, name, type }: IFile, { rejectWithValue, dispatch }) => {
    try {
      let response: any = await favoritesController.add({ document_id: id });
      const text = `${name}.${type} added to Favorites`
      let toast = {
        id: Date.now(),
        text: text,
        type: EToastTypes.PRIMARY,
        autohide: true
      }
      dispatch(addToast(toast));
      dispatch(getFavoritesIdsTypes());
      dispatch(getFavorites());
      return response?.data;
    } catch (err: any) {
      let toast = {
        id: Date.now(),
        text: 'Something went wrong while processing your request. Please try again.',
        type: EToastTypes.DANGER,
        autohide: true
      }
      dispatch(addToast(toast));
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeFavorites = createAsyncThunk(
  'favorites/delete',
  async ({ data: { name, type }, id }: { data: IFile, id: number }, { rejectWithValue, dispatch }) => {
    const text = `${name}.${type} removed from Favorites`;
    let toast = {
      id: Date.now(),
      text: text,
      type: EToastTypes.PRIMARY,
      autohide: true
    }
    try {
      let response: any = await favoritesController.delete(id);
      dispatch(addToast(toast));
      return response?.data;
    } catch (err: any) {
      dispatch(addToast(toast));
      return rejectWithValue(err.response.data);
    }
  }
);

export const applyFavoritesFilters = createAsyncThunk(
  'favorites/filters',
  async (args: any, { rejectWithValue, dispatch }) => {
    const { appliedFilters, selectedFilter, nextStatus } = args;
    let response, query, filters;
    try {
      dispatch(setFavoritesFilters(selectedFilter));
      if (!nextStatus)
        filters = appliedFilters.filter((f: TCheckbox) => !_.isEqual(f, selectedFilter));
      else
        filters = [...appliedFilters, selectedFilter];

      query = filterRouteByType(filters);
      response = await favoritesController.filterBy(query);
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const applyFavoriteOrderFilters = createAsyncThunk(
  'favorites/filters/sort',
  async (args: any, { rejectWithValue, dispatch }) => {
    const { appliedFilters, orderingId } = args;
    try {
      dispatch(setFavoriteOrderFilters({ ...args }));
      let response;
      let query = filterRouteByType(appliedFilters, orderingId);
      response = await favoritesController.filterBy(query);
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeFavoriteFilter = createAsyncThunk(
  'favorites/filters',
  async (args: any, { rejectWithValue, dispatch }) => {
    const { appliedFilters, removedFilter } = args;
    try {
      dispatch(setFavoritesFilters(removedFilter));
      let response, query = "";
      if (!_.isEqual(removedFilter.name, "Clear All"))
        query = filterRouteByType(appliedFilters.filter((value: any) => value !== removedFilter));
      response = await favoritesController.filterBy(query);
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const filterRouteByType = (filters: IFilterDropdownItem[], orderingId?: number) => {
  let response = '';
  filters.forEach((o: IFilterDropdownItem, idx: number) => {
    if (!_.isEqual(idx, 0))
      response += '&';
    else
      response += '?';
    response += `filters[type][]=${o.name}`;
  });

  return orderingId ? filterRouteByOrdering(orderingId, response, 'document_id') : response;
}

export const nextPage = createAsyncThunk(
  'favorites/pagination',
  async (pagination: any, { rejectWithValue }) => {
    if (pagination.links.next) {
      try {
        const response = await favoritesController.getNextPage(pagination.links.next);
        return response?.data;
      } catch (err: any) {
        return rejectWithValue(err.response.data);
      }
    }
  }
);
