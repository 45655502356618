/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-explicit-any */
import "react-photo-view/dist/react-photo-view.css";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { PhotoProvider, PhotoView } from "react-photo-view";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Spinner } from "react-activity";
import { colors } from "@theme/colors";
import { cond } from "ramda";
import { getCookie } from "@api/AdminHttpProvider";
import { isString } from "ramda-adjunct";
import { useDocumentSlice } from "../../stores/useDocumentSlice";
import { useIsTabActive } from "@hooks/utils/useIsTabActive";
import { useShowDocument } from "@hooks/fetchs/useShowDocument";

function getFileExtension(filePath: string): string {
  const fileName = filePath.split("/").pop();
  if (fileName) {
    const dotIndex = fileName.lastIndexOf(".");
    if (dotIndex !== -1) {
      return fileName.slice(dotIndex + 1);
    }
  }
  return "";
}

function isImageFile(extension: string): boolean {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];

  return imageExtensions.includes(extension);
}

function isVideoFile(extension: string): boolean {
  const videoExtensions = ["mp4", "webm"];

  return videoExtensions.includes(extension);
}

const ShowDocument = () => {
  const { id } = useParams<{ id: string }>();
  const [doc, setDoc] = useState<string | null>(null);
  const [extension, setExtension] = useState<string | null>(null);
  const { getData, loading, loadingDownload } = useShowDocument();
  const { setLastFile } = useDocumentSlice((state) => state);
  const [permission, setPermission] = useState<boolean>(true);
  const isActiveTab = useIsTabActive();

  const navigate = useNavigate();

  function loadDocument() {
    const bearerToken =
      localStorage.getItem("current_website") === "admin"
        ? getCookie("access_token")
        : localStorage.getItem("access_token");

    if (!bearerToken) {
      setLastFile(id as string);
      navigate("/login");
    }

    getData(id as string).then((e: any) => {
      if (e.status === 200) {
        setDoc(e.data.file.url);
        setExtension(getFileExtension(e.data.file.path));
      } else {
        setPermission(false);
      }
    });
  }

  useEffect(() => {
    loadDocument();
  }, []);

  useEffect(() => {
    if (!loadingDownload) {
      setDoc(null);
      setExtension(null);
    }
    if (isActiveTab) {
      loadDocument();
    }
  }, [isActiveTab]);

  const onButtonClick = () => {
    getData(id as string).then((e) => {
      const { url } = e.data.file;
      const { name: fileName, type } = e.data;
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const objectUrl = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = objectUrl;
          link.download = `${fileName}.${type}`;
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(objectUrl);
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
        });
    });
  };

  return (
    <div
      className={
        localStorage.getItem("current_website") === "admin"
          ? "o-admin-screen-container"
          : ""
      }
    >
      {permission ? (
        <div className="d-flex justify-content-center min-vh-100 align-items-center flex-column">
          {loading ? (
            <Spinner
              className="v-contacts__spinner"
              color={colors.brandColorSecondary}
            />
          ) : (
            isString(doc) &&
            isString(extension) && (
              <>
                {loadingDownload ? (
                  <Spinner
                    className="v-contacts__spinner"
                    color={colors.brandColorSecondary}
                  />
                ) : (
                  <div
                    className=" o-cl-grey-100"
                    role="button"
                    onClick={onButtonClick}
                    aria-hidden
                  >
                    <i className="material-symbols-outlined me-2 o-cl-grey-100 o-ft-lg">
                      file_download
                    </i>
                    Download
                  </div>
                )}
                {cond([
                  [
                    (ext) => isImageFile(ext),
                    () => (
                      <div
                        style={{
                          height: "90vh",
                          display: "flex",
                        }}
                      >
                        <PhotoProvider>
                          <PhotoView src={doc as string}>
                            <img
                              src={doc}
                              alt=""
                              style={{ maxWidth: "75vw", maxHeight: "90vh" }}
                              className="img-thumbnail"
                              role="button"
                            />
                          </PhotoView>
                        </PhotoProvider>
                      </div>
                    ),
                  ],
                  [
                    (ext) => isVideoFile(ext),
                    () => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "75vw",
                          height: "90vh",
                        }}
                      >
                        <video
                          controls
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "50vw",
                            height: "75vh",
                          }}
                        >
                          <source src={doc} />
                        </video>
                      </div>
                    ),
                  ],
                  [
                    () => true,
                    () => (
                      <div>
                        <DocViewer
                          style={{ width: "75vw", height: "90vh" }}
                          documents={[{ uri: doc, fileType: extension }]}
                          pluginRenderers={DocViewerRenderers}
                          config={{
                            header: {
                              disableHeader: true,
                              disableFileName: true,
                              retainURLParams: false,
                            },
                            csvDelimiter: ",",
                            pdfZoom: {
                              defaultZoom: 1.1,
                              zoomJump: 0.2,
                            },
                            pdfVerticalScrollByDefault: true,
                          }}
                        />
                      </div>
                    ),
                  ],
                ])(extension as string)}
              </>
            )
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-center min-vh-100 align-items-center flex-column">
          <div
            style={{
              color: "#565250",
              textAlign: "center",
              fontSize: "24px",
              lineHeight: "normal",
              fontWeight: 400,
              position: "relative",
            }}
          >
            Access Denied
          </div>
          <div
            style={{
              marginTop: "20px",
              color: "#565250",
              textAlign: "center",
              fontSize: "14px",
              lineHeight: "normal",
              fontWeight: 400,
            }}
          >
            It looks like you don&#039;t have permissions to access this file.
            Please contact your platform Administrator.
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowDocument;
