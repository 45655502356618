/* eslint-disable @typescript-eslint/no-explicit-any */
import AdminHttpProvider from "@api/AdminHttpProvider";

const adminApi = new AdminHttpProvider();

export default class DocumentController {
  static show(id: number | undefined) {
    return adminApi.request(
      "get",
      `api/v2/admin/documents${id ? `/${id}` : ""}`,
    );
  }

  static get(filters?: any) {
    const params = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      params.append(`filters[${key}]`, String(value));
    });

    const apiUrl = decodeURIComponent(
      `api/v2/admin/documents?${params.toString()}`,
    );
    return adminApi.request("get", apiUrl);
  }

  static update(id: number, params: any) {
    return adminApi.request("put", `api/v2/admin/documents/${id}`, params);
  }

  static storeFolder(params: any) {
    return adminApi.request("post", "api/v2/admin/documents/folders", params);
  }
}
