import MaterialIcon from '@components/MaterialIcon';
import useHandleClickOutside from '@hooks/useHandleClickOutside';
import React, { useRef, useState } from 'react'

export type Props = {
  options: { id: number; name: string; }[];
  onSelect: (option: { id: number; name: string; }) => void;
}

const Order: React.FC<Props> = ({ options, onSelect }) => {
  const ref = useRef<null | HTMLDivElement>(null)
  const [selected, setSelected] = useState(options[0] || null)
  const [open, setIsOpen] = useState(false)

  useHandleClickOutside(ref, () => {
    setIsOpen(false)
  });

  const handleSelect = (option: { id: number; name: string; }) => {
    setSelected(option)
    onSelect(option)
  }

  return (
    <div className='position-relative' ref={ref}>
      <div data-testid="order" className='c-order d-inline' onClick={() => setIsOpen(p => !p)}>
        <button className='c-order__button d-flex justify-content-evenly align-items-center'>
          <span className='c-order__text o-ft-xs-600 pe-1'>{selected?.name}</span>
          <MaterialIcon size={24} className='o-cl-grey-100' icon='sort' />
        </button>
      </div>

      {open && (
        <div className='c-order-list position-absolute'>
          <ul className='p-0 m-0'></ul>
          {options.map((option) => (
            <li
              key={option.id}
              className={`c-order-list__item o-ft-sm o-cursor-pointer ${option.id === selected.id ? 'c-order-list__item--active' : ''}`}
              onClick={() => handleSelect(option)}
            >
              <span>{option.name}</span>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}

export default Order