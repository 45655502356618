import ActionModal from "@components/ActionModal";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { deleteTrashFiles } from "@redux/trash/thunks/trashThunk";

interface IDeleteModalProps {
  file: any;
  isVisible: boolean
  setVisible: (value: boolean) => void
}

export const DeleteFilePermanentlyModal = ({ isVisible, file, setVisible }: IDeleteModalProps) => {

  const dispatch = useAppDispatch();
  const { trash: { pendingDelete } } = useAppSelector((state) => state);
  const nameFileShortened = file?.name.length > 110 ? file?.name.substring(0, 60).concat('...') : file?.name;

  return (
    <ActionModal
      isLoading={pendingDelete}
      show={isVisible}
      title='Delete File'
      subtitle={<>Are you sure you want to delete "<b>{nameFileShortened}</b>" permanently?</>}
      buttonLeftText="Cancel"
      buttonRightText="Continue"
      onClickLeftButton={() => setVisible(false)}
      onClickRightButton={() => file && dispatch(deleteTrashFiles([file])).then(() => setVisible(false))}
    />
  )
};
