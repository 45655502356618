import styled from "styled-components";

export const StaticBottomButtons = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 25px 0;
    z-index: 1;
`;

export const CtaButtonReturn = styled.a`
    color: var(--brand-color-secondary);
    text-align: center;
    text-decoration: none;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
`;

export const CtaButtonNext = styled.a`
    flex-shrink: 0;
    text-align: center;
    text-decoration: none;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    display: inline-block;
    color: var(--brand-color-secondary);
    border: 1px solid var(--brand-color-secondary);
    width: 100px;
    border-radius: 23px;
    cursor: pointer;

    &[aria-disabled="true"] {
        border-color: #9FA0A0;
    }

    > input {
        background: none;
        border: none;
        padding: 6px 10px;
        width: 100%;
        font-weight: 700;
        color: var(--brand-color-secondary);

        &:disabled {
            color: #9FA0A0;
        }
    }
`;

export const CtaBackToLogin = styled.button`
    color: var(--brand-color-secondary);
    border: 1px solid var(--brand-color-secondary);
    width: 230px;
    border-radius: 23px;
    padding: 8px;
    font-size: 16px;
    margin: 70px auto;
    background: none;
    font-weight: 700;

    &[aria-disabled="true"] {
        color: #9FA0A0;
        border-color: #9FA0A0;
    }
`