import { FunctionComponent, ReactElement, useEffect } from 'react'
import useAppSelector from '@hooks/useAppSelector';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import useAppDispatch from '@hooks/useAppDispatch';
import { ProcessCard as Card } from '@components/ProcessCard';
import { IVaultsSlice, resetCurrentVault } from '@redux/vaults/slices/vaultsSlice';
import { setCurrentVault } from '@redux/vaults/slices/vaultsSlice';
const Vaults: FunctionComponent = (): ReactElement => {
  const { data: vaults }: IVaultsSlice | any = useAppSelector((state: any) => state.vaults);
  const dispatch = useAppDispatch();
  const navigate: NavigateFunction = useNavigate()

  useEffect(() => {
    dispatch(resetCurrentVault())
  }, [])

  const renderItems: ReactElement<any, any>[] = vaults.map((item: any): ReactElement<any, any> => (
    <Card
      key={item.id}
      icon='encrypted'
      onClick={() => {
        dispatch(setCurrentVault({ id: item.id, name: item.name }));
        navigate(`/vaults/${item.id}`)
      }
      }
      title={item.name}
      background={item?.background}
    />
  ));

  return (
    <div className='c-grid mb-3 c-grid--sm-layout'>
      {renderItems}
    </div>
  )
}

export default Vaults