import useHandleClickOutside from "@hooks/useHandleClickOutside";
import { useState } from "react";

interface IUseDropdownProps {
  ref: React.MutableRefObject<null>;
}

export const useDropdown = ({ ref }: IUseDropdownProps) => {
  const [show, setShow] = useState(false);
  useHandleClickOutside(ref, () => setShow(false));

  const onClick = () => setShow(!show);

  return {
    onClick,
    show
  }
}
