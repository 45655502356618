/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useState } from "react";
import ProcessStepDetails from "@components/ProcessStepDetails";
import StepStatus from "@components/StepStatus";
import { IProcessStep } from "@redux/approval-process/slices/processSlice";
import useAppSelector from "@hooks/useAppSelector";

interface IProcessStepProps {
  step: IProcessStep;
  review?: boolean;
  index: number;
  processStepId?: number;
  projectProcessId?: number;
  reviewStatus?: string;
  refetch?: () => void;
  status?: number;
  refetchProcessSteps?: () => void;
  refetchComments?: () => void;
}

interface IProcessStepContextProps {
  step: IProcessStep;
  projectProcessStep: any;
  review?: boolean;
  processStepId?: number;
  projectProcessId?: number;
  reviewStatus?: string;
  refetch?: () => void;
  refetchProcessSteps?: () => void;
  refetchComments?: () => void;
  status?: number;
}

export const ProcessStepContext = createContext({} as IProcessStepContextProps);

export const ProcessStep = ({
  step,
  index,
  // process review props
  review = false,
  processStepId,
  projectProcessId,
  reviewStatus,
  status,
  refetch,
  refetchProcessSteps,
  refetchComments,
}: IProcessStepProps) => {
  const { id, name, form, status: stepStatus } = step;

  const { projectProcessSteps, projectProcessId: projectProcessIdStore } =
    useAppSelector((state) => state.myStores);

  const projectProcessStep = projectProcessSteps?.find(
    (p: any) => p.process_step_id === id,
  );

  const [isOpen, setIsOpen] = useState(false);

  const { Provider } = ProcessStepContext;

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <Provider
      value={{
        step,
        projectProcessStep,
        review,
        reviewStatus,
        status,
        projectProcessId,
        processStepId,
        refetch,
        refetchProcessSteps,
        refetchComments,
      }}
    >
      <div
        key={id}
        className={`c-process-step w-100 ${isOpen && "c-process-step--selected"}`}
      >
        <div
          className="c-process-step__container d-flex align-items-center justify-content-between o-cursor-pointer px-4"
          onClick={toggleOpen}
          aria-hidden
        >
          <div className="d-flex align-items-center gap-2 w-100">
            <div
              className={`c-process-step__step d-flex align-items-center justify-content-center ${isOpen && "c-process-step__step--selected"}`}
            >
              <span
                className={`c-process-step__step-number o-ft-base-700${isOpen ? "c-process-step__step-number--selected" : "o-cl-grey-200"}`}
              >
                {index}
              </span>
            </div>
            <span className="c-process-step__title o-ft-base-700 o-cl-grey-100">
              {form?.title ?? name}
            </span>
          </div>
          <StepStatus
            currentIndex={projectProcessStep?.status ?? stepStatus}
            projectProcessId={projectProcessIdStore}
            projectProcessStep={projectProcessStep}
            stepId={id}
            disabled={!!form}
          />
        </div>

        {isOpen && <ProcessStepDetails />}
      </div>
    </Provider>
  );
};
