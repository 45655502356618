import ActionModal from "@components/ActionModal";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { restoreFile } from "@redux/trash/thunks/trashThunk";
import { getExistentFilesValuesByFolder } from "@redux/files/thunks/fileThunk";
import { setDragDropExistentFileData, setShowDragDropExistentFileModal } from "@redux/files/slices/fileSlice";

interface IDeleteModalProps {
  file: any;
  isVisible: boolean
  setVisible: (value: boolean) => void
}

export const RestoreModal = ({ isVisible, file, setVisible }: IDeleteModalProps) => {

  const dispatch = useAppDispatch();
  const parentId = parseInt(localStorage.getItem('parent_id_from_trash') || '', 10);
  const { trash: { pendingDelete } } = useAppSelector((state) => state);
  const nameFileShortened = file?.name.length > 110 ? file?.name.substring(0, 60).concat('...') : file?.name;

  const handleClickRightButton = () => {
    if (file) {
      const obj = { parentId, values: [`${file.name}.${file.type}`] };

      dispatch(getExistentFilesValuesByFolder(obj))
        .then((resp) => {
          const respExistentFilesValues = resp.payload;
          if (!respExistentFilesValues.includes(true)) {
            dispatch(restoreFile({ file: [file], parent_id: parentId || null })).then(() => setVisible(false));
          } else {
            dispatch(setDragDropExistentFileData({
              title: 'Restore File',
              action: 'restore',
              parameters: { file: [file], parent_id: parentId || null }
            }))
            setVisible(false);
            dispatch(setShowDragDropExistentFileModal(true));
          }
        });
    }
    localStorage.removeItem('parent_id_from_trash');
  }

  return (
    <ActionModal
      isLoading={pendingDelete}
      show={isVisible}
      title='Restore Deleted File'
      subtitle={<>Are you sure you want to restore "<b>{nameFileShortened}</b>"?</>}
      buttonLeftText="Cancel"
      buttonRightText="Continue"
      onClickLeftButton={() => setVisible(false)}
      onClickRightButton={handleClickRightButton}
    />
  )
};
