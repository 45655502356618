import React, { useContext, useRef, useState } from 'react'
import { useGetForm } from '@redux/forms/api';

import { ProcessStepContext } from './ProcessStep';
import { Spinner } from 'react-activity';
import ProcessForm from '@components/ProcessForm/ProcessForm';
import StepAttachments from './StepAttachments';
import MaterialIcon from './MaterialIcon';
import { createToast } from '@helpers/createToast';
import Modal from './Modal';
import useAppDispatch from '@hooks/useAppDispatch';
import { useSearchParams } from 'react-router-dom';
import { whichProvider } from '@helpers/whichProvider';
import Button from './Button';
import { MAX_FILE_SIZE } from '../utils/constants';
import { colors } from '@theme/colors';

type FileProps = {
  id: number
  name: string;
  error?: string;
  onClose: (id: number) => void;
}

const File: React.FC<FileProps> = ({ id, name, onClose, error = '' }) => {
  const threeDotsStyle: React.CSSProperties = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }

  return (
    <div className={`c-comments__file py-1 px-2 ${error ? 'c-comments__file--error' : ''}`} title={error}>
      <span className='o-ft-xs-400' style={threeDotsStyle}>{name}</span>
      <MaterialIcon icon='close' size={12} onClick={() => onClose(id)} />
    </div>
  )
}

const ProcessStepDetails: React.FC = () => {
  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()

  const {
    step: { content, form, approval_required },
    projectProcessStep,
    status,
    reviewStatus,
    review,
    refetch,
    refetchProcessSteps,
    refetchComments,
    processStepId,
    projectProcessId
  } = useContext(ProcessStepContext);

  const { form: f, isLoading }: any = useGetForm(form?.id.toString())

  const [reason, setReason] = useState<'approve' | 'reject' | null>(null)
  const [files, setFiles] = useState<File[]>([])
  const [comment, setComment] = useState('')

  const [isFocusComment, setIsFocusComment] = useState(false)

  const handleSubmit = async () => {
    const processId = searchParams.get('id')
    const url = `api/projects/processes/steps/${processId}`
    const body = {
      project_process_id: projectProcessId,
      process_step_id: processStepId,
      status: reason === 'approve' ? 2 : (projectProcessStep?.status || status),
      review_status: reason === 'approve' ? 2 : 1
    }

    const updateStepPromise = whichProvider().request('put', url, body)

    const commentsBody = new FormData()

    commentsBody.append('project_process_id', projectProcessId!.toString())
    commentsBody.append('project_process_step_id', (projectProcessStep?.id || searchParams.get('id')).toString())
    commentsBody.append('comment', comment)
    commentsBody.append('reason', reason || '')

    for (var i = 0; i < files.length; i++) {
      commentsBody.append('files[]', files[i]);
    }

    const postCommentPromise = whichProvider().request('post', 'api/projects/processes/comments', commentsBody)

    const promises = [
      updateStepPromise,
      postCommentPromise
    ]

    try {
      await Promise.all(promises)
      createToast(`Successfully ${reason === 'approve' ? 'approved' : 'rejected'}`, "success", dispatch)
    } catch (error) {
      createToast(`Error has ocurred while trying to ${reason === 'approve' ? 'Approve' : 'Reject'}`, "danger", dispatch)
    }

    refetch?.()
    refetchProcessSteps?.()
    refetchComments?.()

    setReason(null)
  }

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleAddFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles: File[] = Array.from(event.target.files!) || []
    const hasHeavyFiles = newFiles.some(file => file.size / 1000 > MAX_FILE_SIZE)

    if (hasHeavyFiles) {
      createToast('File too heavy! Please attach a smaller file to proceed.', 'danger', dispatch)
    }

    if (newFiles.length) {
      setFiles(prev => [...prev, ...newFiles])
    }

    // clear input file value
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  };

  const handleRemoveFile = (lastModified: number) => {
    const filesUpdated = files.filter(prevFile => prevFile.lastModified !== lastModified)
    setFiles(filesUpdated)
  }

  if (isLoading) {
    return (
      <div className='c-process-step__details d-flex flex-column flex-md-row justify-content-md-between'>
        <div className='d-flex align-items-center justify-content-center w-100'>
          <Spinner size={24} />
        </div>
      </div>
    )
  }

  return (
    <>
      {(projectProcessStep?.review_status === 'Approved' || reviewStatus === 'Approved') ? (
        <div className='c-process-step__details'>
          <div className='px-2 py-1 o-bg-grey-300 d-flex align-items-center gap-2'>
            <MaterialIcon icon='check_circle' color='o-cl-green material-symbols-filled' />
            <span>Step Approved</span>
          </div>
        </div>
      ) : (projectProcessStep?.review_status === 'Rejected' || reviewStatus === 'Rejected') ? (
        <div className='c-process-step__details'>
          <div className='px-2 py-1 o-bg-grey-300 d-flex align-items-center gap-2'>
            <MaterialIcon icon='cancel' color='o-cl-red material-symbols-filled' />
            <span>Step Rejected</span>
          </div>
        </div>
      ) : approval_required && (projectProcessStep?.status === 1 || status === 1) ? (
        <div className='c-process-step__details'>
          <div className='px-2 py-1 o-bg-grey-300 d-flex align-items-center gap-2'>
            <MaterialIcon icon='info' color='o-cl-yellow' className='material-symbols-filled' />
            <span>Pending approval</span>
          </div>
        </div>
      ) : approval_required && (projectProcessStep?.status === 2 || status === 2) ? (
        <div className='c-process-step__details'>
          <div className='px-2 py-1 o-bg-grey-300 d-flex align-items-center gap-2'>
            <MaterialIcon icon='check_circle' color='o-cl-green material-symbols-filled' />
            <span>Approved</span>
          </div>
        </div>
      ) : approval_required && (
        <div className='c-process-step__details'>
          <div className='px-2 py-1 o-bg-grey-300 d-flex align-items-center gap-2'>
            <MaterialIcon icon='error' color='o-cl-grey-200 material-symbols-filled' />
            <span>This step requires approval</span>
          </div>
        </div>
      )}

      <div className='c-process-step__details d-flex flex-column flex-md-row justify-content-md-between'>
        <div className='c-process-step__details-section'>
          <div className='c-process-step__details-content ' dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <StepAttachments />
      </div>
      {f && (
        <div className='c-process-step__details'>
          <ProcessForm cards={f?.cards || []} />
        </div>
      )}
      {review && approval_required && (
        <div className='c-process-step__details d-flex justify-content-end gap-4'>
          <Button
            value='Reject'
            size='big'
            onClick={() => setReason('reject')}
            disabled={reviewStatus !== 'To Review'}
          />
          <Button
            value='Approve'
            size='big'
            onClick={() => setReason('approve')}
            disabled={reviewStatus !== 'To Review'}
          />
        </div>
      )}

      <Modal
        title={`Please confirm you are ${reason === 'approve' ? 'approving' : 'rejecting'} this step.`}
        onConfirm={handleSubmit}
        onClose={() => { setReason(null); setFiles([]); setComment('') }}
        disableSubmit={!comment || files.some(file => file.size / 1000 > MAX_FILE_SIZE)}
        isOpen={!!reason}
        confirmText='Proceed'
        withCloseIcon={false}
        className='c-modal__size'
        centerButtons
      >
        <p className='o-ft-sm-400 mt-2'>
          {reason === 'approve' ?
            'You can provide additional information in the field below if you consider user may need more details about the approvement:' :
            'Please provide additional information in the field below so that the user can receive more details about the rejection:'}
        </p>

        <div className='mx-2'>
          <label className={`o-ft-xs-500 px-2 ${isFocusComment ? 'o-cl-brand-secondary' : ''}`}>Comment</label>
          <div
            className='d-flex flex-column'
            style={{
              border: isFocusComment ? `1px solid ${colors.brandColorSecondary}` : '1px solid #ccc'
            }}
          >
            <textarea
              name='comment'
              rows={4}
              className='o-ft-sm-400'
              onFocus={() => setIsFocusComment(true)}
              onBlur={() => setIsFocusComment(false)}
              style={{ resize: 'none', border: 'none', borderBottom: '1px solid #ccc', outline: 'none', padding: 10 }}
              placeholder='Add a comment'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <div className='px-1 py-2'>
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))', gap: '1rem' }}>
                <button onClick={handleClick} style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 'none',
                  background: 'transparent',
                }}>
                  <span className='o-ft-xs-500'>Attach Files</span>
                  <MaterialIcon icon='attach_file_add' size={18} />
                </button>

                {files.map((file, i) => (
                  <File
                    key={file.lastModified}
                    id={file.lastModified}
                    name={file.name}
                    error={(file.size / 1000) > MAX_FILE_SIZE ? 'Maximum of weight: 512MB' : ''}
                    onClose={handleRemoveFile}
                  />
                ))}
              </div>

              <input ref={inputRef} type="file" name='file' multiple onChange={handleAddFiles} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default React.memo(ProcessStepDetails)
