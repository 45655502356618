import React, { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ProcessStep } from '@components/ProcessStep'
import Comments from '@views/MyStores/shared/Process/shared/Comments'

import { createToast } from '@helpers/createToast'
import useAppDispatch from '@hooks/useAppDispatch'
import { Spinner } from 'react-activity'
import colors from '../../../brand/theme/colors'
import BackpackHeader from './BackpackHeader'
import Breadcrumb from '@components/NewBreadcrumb/Breadcrumb'
import { useGetComments, useGetProcessReview, useGetProjectProcessStepsByProjectProcessId } from '@redux/approval-process/api'
import useWindowSize from '@hooks/useWindowSize'

const ProcessReview: React.FC = () => {
  const { responsive } = useWindowSize()
  const [searchParams] = useSearchParams()

  const { data, isLoading, error, refetch } = useGetProcessReview({ id: searchParams.get('id') })
  const { data: comments, isLoading: loadingComments, refetch: refetchComments } = useGetComments({ projectProcessId: data?.project_process?.id })
  const {
    data: projectProcessSteps, refetch: refetchProcessSteps
  } = useGetProjectProcessStepsByProjectProcessId(
    { id: data?.project_process?.id },
    { skip: !data?.project_process?.id }
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (error) {
      const errorText = 'An error has ocurred while trying to get process info'
      createToast(errorText, 'danger', dispatch)
    }
  }, [error])

  const renderSteps = useMemo(() => {
    if (!data) return <></>

    // return (data?.steps || []).filter((step: any) => step.id === data.process_step.id).map((step: any, index: number) => (
    return (data?.steps || []).map((step: any, index: number) => {
      const projectProcessStep = projectProcessSteps?.find((p) => p.process_step_id === step.id);

      return (
        <ProcessStep
          key={step.id}
          step={step}
          index={index + 1}
          review
          processStepId={data?.process_step?.id}
          projectProcessId={data?.project_process?.id}
          reviewStatus={projectProcessStep?.review_status}
          status={projectProcessStep?.status}
          refetch={refetch} // no se está usando
          refetchProcessSteps={refetchProcessSteps}
          refetchComments={refetchComments}
        />
      )
    })
  }, [data, refetch, projectProcessSteps])

  return (
    <>
      {responsive.md && (
        <div className='d-flex justify-content-end'>
          <Breadcrumb offset={1} />
        </div>
      )}
      <div style={responsive.md ? { display: 'grid', gridTemplateColumns: '2fr 1fr' } : undefined}>
        {isLoading ? (
          <div className='d-flex justify-content-center mt-5'>
            <Spinner className='v-contacts__spinner' color={colors.brandColorSecondary} />
          </div>
        ) : (
          <div>
            <BackpackHeader info={data} />
            {renderSteps}
            <Comments comments={comments || []} loading={loadingComments} />
          </div>
        )}
      </div>
    </>
  )
}

export default ProcessReview