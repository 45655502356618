/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import React from "react";
import DashboardSection from "../sections/Dashboard/DashboardSection";
import PinUnpinProvider from "../providers/PinUnpinProvider";

const Dashboard: React.FC = () => {
  return (
    <PinUnpinProvider>
      <DashboardSection />
    </PinUnpinProvider>
  );
};

export default Dashboard;
